import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Container, ImageItem, PlusBtn, BtnWrapper, Carousel, DocItem, Item } from './style2'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import Autocomplete from '../../components/autosuggest'
import InputMask from "react-input-mask";

import api from '../../api'

import socketClient from "socket.io-client"

export default function () {
    const [socket, setSocket] = useState(null)

    const [comentario, setComentario] = useState('')
    const [obs, setObs] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [tipo, setTipo] = useState('')
    const [tipo2, setTipo2] = useState('')
    const [metragem, setMetragem] = useState('')
    const [quartos, setQuartos] = useState('0')
    const [bairro, setBairro] = useState('  ')
    const [analise, setAnalise] = useState('')
    const [suites, setSuites] = useState('0')
    const [dependencias, setDependencias] = useState('0')
    const [vagas, setVagas] = useState('0')
    const [condominio, setCondominio] = useState('R$ 0,00') 
    const [valorDesejado, setValorDesejado] = useState('R$ 0,00')
    const [valorFinalProposta, setValorFinalProposta] = useState('R$ 0,00')
    const [valorMercado, setValorMercado] = useState('R$ 0,00')
    const [valorAnalise, setValorAnalise] = useState('R$ 0,00')
    const [status, setStatus] = useState('')
    const [tipoArray, setTipoArray] = useState([])

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, setTelephone] = useState([])

    const [controlUser, setControlUser] = useState('')
    const [ID, setID] = useState('1')
    const [message, setMessage] = useState('')

    const [messages, setMessages] = useState([/*
        {_id: 1, user_message: 5302, type: 'admin', message: 'Bom dia', offer: 1660},
        {_id: 2, user_message: 5302, type: 'admin', message: 'Como voce esta?', offer: 1660},
        {_id: 3, user_message: 1090, type: 'agent', message: 'Bom dia, eu estou bem', offer: 1660},
        {_id: 4, user_message: 5302, type: 'admin', message: 'Estou fazendo um teste', offer: 1660},
        {_id: 5, user_message: 1090, type: 'agent', message: 'Que legal, esta indo bem?', offer: 1660},*/
    ])
    
    useEffect(()=>{
        if(!localStorage.getItem('type_user') && !localStorage.getItem('id_painel')){
            window.location.href='/admin/login'
        }else{
            setControlUser(localStorage.getItem('type_user'))
        }
        let wrapper = document.querySelector('.wrapper')
        wrapper.style.width = '100%'
        document.querySelector('.wrapper label').style.display = 'none'
        document.querySelector('.wrapper div').style.height = 'auto'
        
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        setSocket(socketClient(process.env.REACT_APP_API_URL, {query: {
            user: localStorage.getItem('id_painel'), 
            type: localStorage.getItem('type_user'),
            Offer: myParam
        }}))
        handleLoadTypes()
        handleLoadOffer()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    useEffect(() => {
        if(socket != null){ 
            socket.on('message', data => {
                const urlParams = new URLSearchParams(window.location.search);
                const myParam = urlParams.get('id');

                if(data[0].Offer == myParam) {
                    setMessages(data)
                } 
            })
        }
    }, [socket])
    
    useEffect(() => {
        if(document.getElementById('chat')){
            document.getElementById('chat').scrollTop = document.getElementById('chat').scrollHeight
        }
    }, [messages])

    async function handleLoadOffer(){
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if(myParam){
            var {data} = await api.get(`/offer/${myParam}`)
            if(data.nothing){
                window.history.back()
            }
            if(localStorage.getItem('type_user') == 'agent'){
                if(data.idUser){
                    if(data.idUser._id != localStorage.getItem('id_painel')){
                        window.history.back()
                    }
                }
            }
            setID(myParam)
            
            setNome(data.idUser? data.idUser.name:data.name)
            setEmail(data.idUser? data.idUser.email:data.email)
            setTelephone(data.idUser? data.idUser.telephone:data.telephone)
            setBairro(data.bairro)
            setEndereco(data.address)
            setComplemento(data.addressComplement)
            setNumero(data.addressNumber)
            setStatus(data.status)
            setComentario(data.coment)
            setQuartos(data.bedrooms)
            setDependencias(data.dependencies)
            setSuites(data.suites)
            setObs(data.observation)
            setVagas(data.parkingSpots)
            setMetragem(data.landSizeUseful)
            setTipo2(data.type? data.type.name:'')
            setTipo(data.type? data.type._id:'')
            setAnalise(data.analise)
            setCondominio(money(String(data.condominiumPrice)))
            setValorAnalise(money(String(data.valorFinal)))
            setValorDesejado(money(String(data.valueDesejado)))
            setValorMercado(money(String(data.valueMercado)))
            setValorFinalProposta(money(String(data.valorFinalProposta)))

            if(!String(data.valueDesejado).includes('.')){
                setValorDesejado(money(String(data.valueDesejado)+'00'))
            }
            if(!String(data.condominiumPrice).includes('.')){
                setCondominio(money(String(data.condominiumPrice)+'00'))
            }
            if(!String(data.valorFinal).includes('.')){
                setValorAnalise(money(String(data.valorFinal)+'00'))
            }
            if(!String(data.valueMercado).includes('.')){
                setValorMercado(money(String(data.valueMercado)+'00'))
            }
            if(!String(data.valorFinalProposta).includes('.')){
                setValorFinalProposta(money(String(data.valorFinalProposta)+'00'))
            }
            
            var response = await api.get(`/chat/${data._id}`)

            setMessages(response.data)

            response = await api.put(`/chat/${data._id}?type=${localStorage.getItem('type_user') == 'agent'?'admin':'agent'}`)
        }else{
            window.location.href = '/admin/solicitacao-ofertas'
        }

    }

    window.onbeforeunload = () => localStorage.setItem('fill', 'filter')

    function money (value) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    async function handleLoadTypes(){
        const {data} = await api.get('/propertyType/')

        let tips = new Array()
        data.propertyTypes.map(item => {
            tips.push({value: item._id, name: item.name})
        })
        
        setTipoArray(tips)
    }

    async function handleSubmit(){
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        const {data} = await api.put(`/offer/${myParam}`, {
            address: endereco,
            addressNumber: numero,
            addressComplement: complemento,
            type: tipo,
            landSizeUseful: metragem,
            bedrooms: quartos,
            suites,
            dependencies: dependencias,
            parkingSpots: vagas,
            condominiumPrice: moneyToNumber(condominio),
            coment: comentario,
            status,
            observation: obs,
            valorFinal: moneyToNumber(valorAnalise),
            valorFinalProposta: moneyToNumber(valorFinalProposta),
            valueDesejado: moneyToNumber(valorDesejado),
            valueMercado: moneyToNumber(valorMercado),
            bairro,
            location:window.location.href,
            email
        })

        if(data.analise){
            window.location.href = '/admin/solicitacao-ofertas#filter'
        }
    }

    function moneyToNumber(value){
        return (value.replace('R', '').replace('$', '').replaceAll(' ', '').replaceAll('.', '*').replace(',', '.').replaceAll('*', '')).substr(1, value.length - 1)
    }

    async function handleMessage(text) {        
        var msg = text? text : message
        
        let mess = messages.filter(e=>e)
        mess.push({message: msg, user_message: localStorage.getItem('id_painel'), type: localStorage.getItem('type_user'), Offer: ID})
        setMessage('')
        setMessages(mess)
        
        if(message.length > 0 || text){
            var {data} = await api.post(`/chat/`, {
                message: msg,
                user_message: localStorage.getItem('id_painel'),
                type: localStorage.getItem('type_user'),
                Offer: ID,
                email,
                address: endereco+(numero ? ', '+numero : '')+(!complemento ? '' : ' / '+complemento)+', '+bairro,
                telephone: telephone[0],
                location: window.location.href,
                createdAt: new Date().toLocaleDateString('pt-BR', {day: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {month: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {year: 'numeric'})
            })
            
            if(data){
                setMessages(data)
            }
        }
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        COMPRAMOS SEU IMÓVEL >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Ofertas de Venda para Imóvel Vazio > {window.innerWidth <= 600 ?  '':'Editar'}
                    </Link>
                    {window.innerWidth <= 600 ?  <Link to={window.location.pathname} className="where">Editar</Link>:''}
                </div>

                <div className="box-control">
                    <div className="container-input-box flex-column">
                        <div style={{flexDirection: 'column', width: '100%'}}>
                            {nome}<br></br>
                            {email}<br></br>
                            {/* target='_blank' href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${telephone[0]?telephone[0]:telephone[1]?telephone[1]:''}`} style={{textDecoration: 'underline', cursor: 'pointer', color:'black'}} */}
                            <a style={{color:'black'}}>{telephone[0]?telephone[0]:telephone[1]?telephone[1]:''}</a>
                        </div>

                        <div className="roll-page" style={{}}>
                            <button onClick={to=>window.scrollTo(0, window.innerWidth <= 600? 1270 : 600)} style={{}}>
                                Ler/Escrever Mensagens
                            </button>
                        </div>

                    </div>

                    <div className="box-divide">
                        <small>Endereço (sem número)</small>
                        <input disabled={controlUser == 'agent' ? true:false} value={endereco} onChange={comp => setEndereco(comp.target.value)}/>
                    </div>

                    <div className="container-input-box">
                        <div className="box-divide">
                            <small>Número</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="number" value={numero} onChange={comp => setNumero(comp.target.value)}/>
                        </div>
                        <div className="box-divide">
                            <small>Complemento</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} value={complemento} onChange={comp => setComplemento(comp.target.value)}/>
                        </div>
                    </div>

                    <div className="box-divide">
                        <small>Tipo de imóvel</small>
                        <Autocomplete inputName="Tipo" placeholder={tipo2} objs={tipoArray} onChange={comp => setTipo(comp.value)}/>
                    </div>


                    <div className="container-input-box">
                        <div className="box-divide">
                            <small>Metragem no IPTU (m2)</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} value={metragem} onChange={comp => setMetragem(comp.target.value)}/>
                        </div>
                        <div className="box-divide">
                            <small>Total Quartos (Exceto dependências)</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="number" value={quartos} onChange={comp => setQuartos(comp.target.value)}/>
                        </div>
                    </div>

                    <div className="container-input-box">
                        <div className="box-divide">
                            <small>Sendo quantas Suites?</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="number" value={suites} onChange={comp => setSuites(comp.target.value)}/>
                        </div>
                        <div className="box-divide">
                            <small>Quantas dependências de serviço?</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="number" value={dependencias} onChange={comp => setDependencias(comp.target.value)}/>
                        </div>
                    </div>
                    <div className="container-input-box">
                        <div className="box-divide">
                            <small>Número de Vagas?</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="number" value={vagas} onChange={comp => setVagas(comp.target.value)}/>
                        </div>
                        <div className="box-divide">
                            <small>Valor do Condomínio?</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} value={condominio} onChange={comp => setCondominio(money(comp.target.value))}/>
                        </div>
                    </div>

                    
                    <div className='infos'>
                        <strong>Confirme: </strong>
                        {quartos} quarto(s), sendo {suites} suíte(s) e {dependencias} dependência(s).
                    </div>

                    <div className="container-input-box">
                        <div className="box-divide">
                            <small>Valor desejado pelo imóvel</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="text" placeholder="É o valor que pagaremos pelo imóvel" value={valorDesejado} onChange={comp => setValorDesejado(money(comp.target.value))}/>
                        </div>
                        <div className="box-divide">
                            <small>Valor de mercado avaliado</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} type="text" placeholder="É o valor que podemos vender" value={valorMercado} onChange={comp => setValorMercado(money(comp.target.value))}/>
                        </div>
                    </div>

                    <div className="container-input-box flex-column">
                        <div className="box-divide max max-camp" style={{height: 400}}>
                            <small>Observação do corretor</small>
                            <textarea style={{height: '93%'}} disabled={controlUser == 'agent' ? true:false} placeholder="Digite aqui..." value={comentario} onChange={comp => setComentario(comp.target.value)}/>
                        </div> 

                        <div className="container-input-box max-camp" style={{flexDirection: 'column'}}>
                            <div className="box-divide max" style={{height: 320, paddingLeft:0, paddingRight:0, width: '100%'}}>
                                <small style={{marginLeft:10, paddingRight:15}}>Converse sobre este imóvel:</small>
                                <div id="chat" style={{height: '90%', width: '100%', overflowY: 'auto', padding: 15}}>
                                    {
                                        messages.map((message, i) => (
                                            <>
                                                {
                                                message.type == 'admin' && (messages[i > 0? i-1:i].type == 'agent' || i == 0) || messages[i > 0 ? i-1: i].createdAt != message.createdAt && message.type == 'admin' ?
                                                    <div style={{color: "admin" === message.type? '#C13818':'#05677C', width: '100%', marginTop: 7, fontWeight: 500, textAlign: "admin" == message.type?'left':'right'}}>{message.createdAt} - Imóvel Vazio:</div>
                                                :''
                                                }
                                                {
                                                message.type == 'agent' && messages[i > 0 ? i-1: i].createdAt != message.createdAt ?
                                                    <div style={{color: "admin" === message.type? '#C13818':'#05677C', width: '100%', marginTop: 7, fontWeight: 500, textAlign: "admin" == message.type?'left':'right'}}>{message.createdAt}:</div>
                                                :''
                                                }
                                                <div style={{color: "admin" === message.type? '#C13818':'#05677C', width: '100%', marginTop: 7, fontWeight: 500, textAlign: "admin" == message.type?'left':'right'}}>{message.message}</div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            
                            <div className="box-divide max max-camp" style={{height: 70, paddingLeft:0, paddingRight:0, width: '100%', marginTop: 10}}>
                                <small style={{marginLeft:10, paddingRight:15}}>Escreva uma mensagem</small>
                                <div className='divSend'>
                                    <textarea value={message} onChange={e => setMessage(e.target.value)} style={{height: '100%', width: '100%', overflowY: 'auto', color: '#aaa', border: 'none'}}/>
                                    <div onClick={async e => {
                                        /*await api.post(`http://api.bitly.com/v3/shorten?longUrl=${'imovelvazio.com'}&login=${'o_5d4idlm7pp'}&apiKey=${'285da56bc289bab00ea74bbc9909f485bea89a37'}&callback=?`).then(async (result) =>{
                                            console.log(result)
                                        });*/
                                        handleMessage()
                                    }} className="btSend">{'>'}</div>
                                </div>
                            </div>
                            {
                                localStorage.getItem('type_user') === 'admin' ? 
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                                        <p style={{fontSize: 17, marginTop: 10, cursor: 'pointer', color: '#EB5C1C'}} onClick={async e=>{
                                            await handleMessage(`Olá, ${nome.split(' ')[0]}`);
                                            await handleMessage(`Agradecemos por ter nos oferecido esse imóvel, mas não temos interesse na compra. Quando nos oferecer outra oportunidade, teremos o prazer em avaliar. Boas vendas!`);
                                        }}>
                                            [sem interesse]
                                        </p>
                                        <p style={{fontSize: 17, marginTop: 10, cursor: 'pointer', color: '#EB5C1C'}} onClick={async e=>{
                                            await handleMessage(`Olá, ${nome.split(' ')[0]}`);
                                            await handleMessage(`Infelizmente não conseguimos elaborar uma proposta de compra para o seu imóvel, pois o valor da nossa avaliação está muito diferente do informado. Caso concorde podemos fazer uma proposta baseada em nossa avaliação. Obrigado e Boas vendas!!`);
                                        }}>
                                            [sem proposta]
                                        </p>
                                        <p style={{fontSize: 17, marginTop: 10, cursor: 'pointer', color: '#EB5C1C'}} onClick={async e=>{
                                            await handleMessage(`Olá, ${nome.split(' ')[0]}`);
                                            await handleMessage(`Agradecemos por ter nos oferecido esse imóvel. `);
                                            await handleMessage(`Nossa proposta para a compra é de R$ ${valorFinalProposta}`);
                                            await handleMessage(`Boas vendas!`);
                                        }}>
                                            [proposta de R$]
                                        </p>
                                    </div>
                                : ''
                            }
                        </div>
                    </div>
                    
                    <div className="analise">
                        Pré-análise: {analise}
                    </div>

                    <div className="container-input-box">
                        <div className="box-divide">
                            <small>Status</small>
                            <select disabled={controlUser == 'agent' ? true:false} id="input-tolerancia" type="number" value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="Novo">Novo</option>
                                <option value="Analisando">Analisando</option>
                                <option value="Sem Interesse">Sem Interesse</option>
                                <option value="Negociando">Negociando</option>
                                <option value="Não Comprado">Não Comprado</option>
                                <option value="Comprado">Comprado</option>
                                <option value="Acima do Preço">Acima do Preço</option>
                                <option value="Avaliado">Avaliado</option>
                                <option value="Vendido">Vendido</option>
                            </select>
                        </div>

                        {
                            controlUser == 'agent' ? ''
                            :
                            <div className="box-divide">
                                <small>Valor final avaliado</small>
                                <InputMask disabled={controlUser == 'agent' ? true:false} value={valorAnalise} onChange={comp => setValorAnalise(money(comp.target.value))}/>
                            </div>
                        }
                        
                    </div>

                    <div className="container-input-box">
                        <div className="box-divide max" style={{display: controlUser == 'agent'?'none':'flex'}}>
                            <small>Observação do admin</small>
                            <textarea placeholder="Digite aqui..." value={obs} onChange={comp => setObs(comp.target.value)}/>
                        </div>
                        <div className="box-divide">
                            <small>Valor final da proposta</small>
                            <InputMask disabled={controlUser == 'agent' ? true:false} value={valorFinalProposta} onChange={comp => setValorFinalProposta(money(comp.target.value))}/>
                        
                        </div>
                    </div>

                    <div style={{marginTop: -50}} className="container-next">
                        <button onClick={e=>{
                            if(controlUser == 'agent'){
                                window.location.href='/admin/venda-corretor'
                            }else{
                                handleSubmit()
                            }
                        }}>{controlUser == 'agent'? 'Voltar': 'Salvar'}</button>
                    </div>

                </div>

            </Container>
            
        </>
    )
}
