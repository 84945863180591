/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Container, ImageItem, PlusBtn, BtnWrapper, Carousel, DocItem, Item } from './styles'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import Autosuggest from '../../components/autosuggest'

import api from '../../api'
import Axios from 'axios'

import socketClient from "socket.io-client"

export default function () {
    const [socket, setSocket] = useState(null)
    const [data, setData] = useState([])
    const [preAnalise, setPreAnalise] = useState('')
    const [status, setStatus] = useState('')
    const [bairros, setBairros] = useState([])
    const [algo, setAlgo] = useState('')

    const [parametro, setParametro] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFinal, setDataFinal] = useState('')

    const [newMessage, setNewMessage] = useState(false)

    const [cancel, setCancel] = useState(Axios.CancelToken.source())
    

    useEffect(()=>{
        reloadMessages()
        
        setSocket(socketClient(process.env.REACT_APP_API_URL, {query: {
            user: localStorage.getItem('id_painel'), 
            type: localStorage.getItem('type_user')
        }}))

        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }

        if(localStorage.getItem('fill'))
            window.location.href='/admin/solicitacao-ofertas#filter'
 
        if(window.location.hash.substring(1) == 'filter' && localStorage.getItem('params')){
            handleReload()
        }else{
            handleLoad()
        }
    }, [])


    useEffect(() => {
        if(socket != null){ 
            socket.on('listMessages', data => {
                reloadMessages()
            })
        }
    }, [socket])

    async function handleReload(){
        await codeNewMessage()
        let url = new URLSearchParams(localStorage.getItem('params').split('?')[1])
        for (let p of url) {
            if(p[0] == 'analise'){
                setPreAnalise(p[1])
            }

            if(p[0] == 'status'){
                setStatus(p[1])
            }
        }
        
        const {data} = await api.get(localStorage.getItem('params'), {
            cancelToken: cancel.token
        }) 
        if(data.Offer){
            setData(data.Offer)
        }else if(data) {
            setData(data)
        }

        window.scrollTo(0, Number(localStorage.getItem('telaOffer')))
        localStorage.removeItem('fill')
        
    }

    async function codeNewMessage(){
        var response = await api.get(`/offer?message=only-new&type=agent&cod=1`)
        if(response.data.length > 0){
            setNewMessage(true)
        }else{
            setNewMessage(false)
        }
    }

    async function handleNewMessages(){
        let query = ''
        query+=`message=only-new&type=agent&cod=2`
        document.body.style.cursor = 'wait'
        const {data} = await api.get(`/offer?${query}`)
        
        if(data.length == 0){
            setNewMessage(false)
        }else{
            setData(data)
        }
        cancel.cancel()
        clean()
        document.body.style.cursor = 'default'
    }

    function clean(){ 
        setCancel(Axios.CancelToken.source())
    }

    async function handleLoad(){
        localStorage.removeItem('fill')
        setPreAnalise('')
        setStatus('')
        setDataInicio('')
        setDataFinal('')
        
        await codeNewMessage()
        localStorage.setItem('params', '/offer?limit=250')
        const {data} = await api.get('/offer?limit=250', {
            cancelToken: cancel.token
        })
        
        // let response = await api.get(`/neighborhood`)
        // let arrayTest = new Array()

        // response.data.neighborhoods.map(item=>{
        //     console.log(item)
        //     arrayTest.push({id: item._id, name: item.name})
        // })
        
        // setBairros(arrayTest)
        if(data) setData(data.Offer)
    }

    // function search(type){
    //     let filtred = bairros.filter((value) => {return value.type == type})
    //     console.log(filtred)
    //     return filtred.name
    // }

    function money (value) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    async function handleDelete(id, archived=false) {
        if(archived){
            if(window.confirm('Deseja ativar esta oferta?')){
                const response = await api.delete(`/offer/${id}?archived=false`)
                if(response.data.deletedId){             
                    let objs = data.filter(r=>r._id != response.data.deletedId)
                    setData(objs);                
                }
            }
            return
        }

        if(window.confirm('Deseja excluir esta oferta?')){
            const response = await api.delete(`/offer/${id}`)
            if(response.data.deletedId){
                // handleLoad()                
                let objs = data.filter(r=>r._id != response.data.deletedId)
                setData(objs);                
            }
        }
    }

    async function filter(){
        document.body.style.cursor = 'wait'
        let dataInicial = dataInicio.split('-')[2]+'/'+dataInicio.split('-')[1]+"/"+dataInicio.split('-')[0].slice(0,4)
        let dataFinalizada = dataFinal.split('-')[2]+'/'+dataFinal.split('-')[1]+"/"+dataFinal.split('-')[0].slice(0,4)

        if(preAnalise || status){
            let query = ''
            if(preAnalise) query += `analise=${preAnalise}&`
            if(status){
                if(status == 'Excluido'){
                    query += `archived=${true}&`
                }else{
                    query += `status=${status}&`
                }
            }
            
            if(algo) query += `endereco=${algo}&`
 
            localStorage.setItem('params', `/offer?${query}&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
            const {data} = await api.get(`/offer?${query}&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
            setData(data?.Offer ? data.Offer : data)
        }else{            
            localStorage.setItem('params', `/offer?endereco=${algo}&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
            const {data} = await api.get(`/offer?endereco=${algo}&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
            setData(data?.Offer ? data.Offer : data)
        }
        
        document.body.style.cursor = 'default'
    }

    function hasQuery(){
        let dataInicial = dataInicio.split('-')[2]+'/'+dataInicio.split('-')[1]+"/"+dataInicio.split('-')[0].slice(0,4)
        let dataFinalizada = dataFinal.split('-')[2]+'/'+dataFinal.split('-')[1]+"/"+dataFinal.split('-')[0].slice(0,4)
        let query = `?dataInicio=${dataInicial}&dataFinal=${dataFinalizada}&`
        
        if(preAnalise) query += `analise=${preAnalise}&`
        if(status) query += `status=${status}&`
        if(algo) query += `endereco=${algo}&`

        return query
    }
    
    function toDate(dateStr) {
        var parts = dateStr.split("T")[0].split('-');
        
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    async function hasPrint(){//%20
        document.body.style.cursor = 'progress'
        var csvRow = []
        var A = [['ID', 'Nome', 'Email', 'Telefone', 'Endereco', 'Bairro', 'Pre%20Analise', 'Status', 'Valor%20Avaliado', 'Valor%20Proposta', 'Data%20de%20Cadastro']]
        var re = []

        const response = await api.get(`/offer${hasQuery()}&limit=99999`)    
        if(response.data.Offer){
            response.data.Offer.map(e=>{
                re.push(e)
            })
        }else{
            response.data.map(e=>{
                re.push(e)
            })
        }

        for(var item=0; item < re.length; item++){
            A.push([
                String(re[item]._id).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].idUser ? re[item].idUser.name : re[item].name? re[item].name:'Nenhum nome cadastrado').replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].idUser ? re[item].idUser.email:re[item].email).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].idUser ? re[item].idUser.telephone.length != 0 ? re[item].idUser.telephone[0] : 'Nenhum telefone cadastrado':re[item].telephone.length != 0 ? re[item].telephone[0] : 'Nenhum telefone cadastrado').replaceAll(' ', '%20').replaceAll('#39', ''), 
                String((re[item].address)+(re[item].addressNumber ? ', '+re[item].addressNumber : '')).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].bairro ? re[item].bairro : 'Rio de Janeiro, RJ').replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].analise).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].status == 'Não Comprado'?'Nao Comprado':re[item].status).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(money(String(re[item].valorFinal).includes('.') ? String(re[item].valorFinal) : String(re[item].valorFinal)+'00')).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(money(String(re[item].valorFinalProposta).includes('.') ? String(re[item].valorFinalProposta) : String(re[item].valorFinalProposta)+'00')).replaceAll(' ', '%20').replaceAll('#39', ''), 
                toDate(String(re[item].createdAt)).replaceAll(' ', '%20').replaceAll('#39', ''), 
            ])
        }

        for(var i=0; i<A.length; ++i){
            csvRow.push(A[i].join(';'))
        }

        var csvString = csvRow.join('%0A')

        document.body.style.cursor = 'default'
        // if(document.getElementById('a')){
        //     document.getElementById('a').href='data:attachment/csv,'+csvString
        //     // setDownload(document.getElementById('a'))
        //     document.getElementById('a').click()

        //     return
        // }

        var a=document.createElement("a")
        a.id='a'
        a.href='data:attachment/csv,'+csvString
        a.target='_Blank'
        a.download='ofertas.csv'
        document.body.appendChild(a) 
        a.click()
    }

    window.onscroll=(e)=>{
        localStorage.setItem('telaOffer', window.scrollY+'')
    }

    
    const reloadMessages = async () => {
        setTimeout(async () => {
            await codeNewMessage()
        }, 500)
    }
    
    function innerRow(row, index) {
        return (
            <>
            <div className="border-div h1-2">
                <div className="container-divide">
                    <div className="divData">Cadastrado em: {row.createdAt.split('-')[2].substr(0, 2)+'/'+row.createdAt.split('-')[1]+'/'+row.createdAt.split('-')[0]}</div>
                    <div className="border-top divDescription">{row.address}{row.addressNumber ? ', '+row.addressNumber : ''}{!row.addressComplement ? '' : ' / '+row.addressComplement}
                    {
                        // data.map(li => {
                        //     if(li.address == row.address && row.addressNumber == li.addressNumber && row.addressComplement == li.addressComplement && li._id != row._id){
                        //         return <p style={{fontSize: 11, color: '#EB5C1C', marginTop: 5}}>Já existe esta oferta - SEQ {li._id}</p>
                        //     }
                        // })
                        data.filter(li => {
                            if(li.address == row.address && row.addressNumber == li.addressNumber && row.addressComplement == li.addressComplement && li._id != row._id){
                                return li
                            }
                        }).length > 0 ?
                            <p style={{fontSize: 11, color: '#EB5C1C', marginTop: 5}}>Já existe esta oferta - SEQ {data.filter(li => {
                                if(li.address == row.address && row.addressNumber == li.addressNumber && row.addressComplement == li.addressComplement && li._id != row._id){
                                    return li
                                }
                            })[0]._id}</p>
                            
                        :''
                    }
                    </div>
                    
                    <div className="border-top divState">{row.bairro ? row.bairro : 'Rio de Janeiro, RJ'}</div>
                </div>
            </div>
            {
                /*data.map(i => {
                    //console.log(i)
                })*/
            }
            <div className="border-div h1-3">
                <div className="container-divide">
                    <div style={{fontSize: window.innerWidth <= 600 ? 14:16}} className="divNome">{row.idUser ? row.idUser.name : row.name? row.name:'Nenhum nome cadastrado'}</div>
                    <div style={{fontSize: 13}} className="border-top divEmail">{row.idUser? row.idUser.email:row.email}</div>
                    <div className="border-top divTelephone">{row.idUser?row.idUser.telephone.length != 0 ? row.idUser.telephone[0] : 'Nenhum telefone cadastrado':row.telephone.length != 0 ? row.telephone[0] : 'Nenhum telefone cadastrado'}</div>
                </div>
            </div>
            <div className="border-div h1-4">
                <div className="container-divide">
                    <div style={{fontSize: 16}} className="divEmail">Pré-análise: {row.analise}</div>
                    <div className="border-top divEmail">{row.status}</div>
                </div>
            </div>
            <div className="border-div h1-5">
                <div className="container-divide">
                    <div style={{fontSize: 15}} className="divEmail">Avaliado: {money(String(row.valorFinal).includes('.') ? String(row.valorFinal) : String(row.valorFinal)+'00')}</div>
                    <div style={{fontSize: 15}} className="border-top divEmail">Proposta: {money(String(row.valorFinalProposta).includes('.') ? String(row.valorFinalProposta) : String(row.valorFinalProposta)+'00')}</div>
                </div>
            </div>
            <div className="border-div row-last h1-6">
                <div className="container-divide">
                    <div className="btOptions" onClick={()=> {window.location.href = `/admin/ofertas/editar?id=${row._id}`}}>Editar</div>
                    <div className="border-top btOptions" onClick={()=> handleDelete(row._id, row.archived)}>{!row.archived?'Excluir':'Ativar'}</div>
                </div>
            </div>
            </>
        )
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        COMPRAMOS SEU IMÓVEL >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Ofertas de Venda para Imóvel Vazio
                    </Link>
                </div>
                <div className="box-control">
                    <div className="container-control">
                        <div className="input-tolerant"> 
                            <span>Status</span>
                            <select value={status} onChange={(e) => e.target.value == 'Selecione' ? setStatus('') : setStatus(e.target.value)} id="input-tolerancia" type="number" >
                                <option>Selecione</option>
                                <option value="Novo">Novo</option>
                                <option value="Analisando">Analisando</option>
                                <option value="Sem Interesse">Sem Interesse</option>
                                <option value="Negociando">Negociando</option>
                                <option value="Não Comprado">Não Comprado</option>
                                <option value="Comprado">Comprado</option>
                                <option value="Acima do Preço">Acima do Preço</option>
                                <option value="Avaliado">Avaliado</option>
                                <option value="Vendido">Vendido</option>
                                <option value="Excluido">Excluído</option>
                            </select>
                        </div>
                        <div className="input-tolerant"> 
                            <span>Pré-análise</span>
                            <select value={preAnalise} onChange={(e) => e.target.value == 'Selecione' ? setPreAnalise('') : setPreAnalise(e.target.value)} id="input-tolerancia" type="number" >
                                <option>Selecione</option>
                                <option value="Aceita">Aceita</option>
                                <option value="Negada">Negada</option>
                                <option value="Alterada">Alterada</option>
                                <option value="Nao Negada">Não Negada</option>
                            </select>
                        </div>
                        <div className="input-tolerant"> 
                            <span>Digite algo</span>
                            <input value={algo} onChange={(e) => setAlgo(e.target.value)}/>
                        </div>
                        <div className="input-tolerant"> 
                            <span>Data de Início</span>
                            {/* 451, 481, 501, 511 */}
                            <input value={dataInicio} onChange={e=> setDataInicio(e.target.value)} type='date'/>
                        </div>
                        <div className="input-tolerant"> 
                            <span>Data de Fim</span>
                            <input value={dataFinal} onChange={e=> setDataFinal(e.target.value)} type='date'/>
                        </div>
                    </div>

                    <div className="container-control flex-end">
                        {
                            newMessage ?
                                <button style={{inlineSize:'max-content', marginRight: window.innerWidth <= 600?0:8, marginBottom : window.innerWidth <= 600? 15:0, border: '1px solid #31AA26'}} onClick={handleNewMessages} className="outline btn-green">Novas Mensagens</button>
                            :''
                        }
                        <button onClick={()=>{
                            handleLoad()
                        }} className="buttonSave bt-inverse">Remover filtros</button>
                        <button onClick={filter} className="buttonSave">Aplicar Filtros</button>
                    </div>
                    <input hidden id="ArrayValues"/>
                </div>
                
                <div style={{display: 'flex', flexDirection: 'row', width: window.innerWidth <= 600 ? '100%':'120%', justifyContent: window.innerWidth <= 600 ? 'center':'space-between', marginTop: 20}}>
                    <p></p>
                    <a onClick={hasPrint} style={{color: '#00AEC8', textDecoration: 'none', cursor: 'pointer'}}>Fazer download da lista de ofertas</a>
                </div>
                
                <div className="box-control box-table">
                    <div className="box-header">
                        <h1 className="h1-border h1-1">SEQ.</h1>
                    {
                        window.innerWidth <= 600 ? 
                            <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                <h1 className="h1-border h1-2">IMÓVEL</h1>
                                <h1 className="h1-border h1-3">CONTATO</h1>
                                <h1 className="h1-border h1-4">STATUS</h1>
                                <h1 className="h1-border h1-5">VALORES</h1>
                                <h1 className="h1-border h1-6">AÇÕES</h1>
                            </div>
                        :
                        <>
                            <h1 className="h1-border h1-2">IMÓVEL</h1>
                            <h1 className="h1-border h1-3">CONTATO</h1>
                            <h1 className="h1-border h1-4">STATUS</h1>
                            <h1 className="h1-border h1-5">VALORES</h1>
                            <h1 className="h1-border h1-6">AÇÕES</h1>
                        </>
                    }
                    </div>
        
                    {
                        data.map((row, index) => (
                            <div className="box-header box-body">
                                <div className="table-row">
                                    <div className="table-index border-div h1-1 divID">{row._id}</div>
                                    
                                    {
                                        window.innerWidth <= 600 ? 
                                            <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                                {innerRow(row, index)}
                                            </div>    
                                        :
                                        <>
                                            {innerRow(row, index)}
                                        </>
                                    }
                                    
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
            

        </>
    )
}
