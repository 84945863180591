/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link,useHistory } from 'react-router-dom'
import crypto from 'crypto'

import { Container, ImageItem, PlusBtn, BtnWrapper, Carousel, DocItem, Item } from './styles'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import Picker from '../../components/picker'
import Autosuggest from '../../components/autosuggest'

import api from '../../api'

export default function AdminOfferProperty() {
    const [block, setBlock] = useState('Apenas um bloqueio, null')  
    const [bairro, setBairro] = useState('')   
    const [cidades, setCidades] = useState('Rio de Janeiro') 
    const [cidade, setCidade] = useState('Rio de Janeiro')   

    const history = useHistory()

    const [citys, setCitys] = useState([])
    const [neighborhoods, setNeighborhoods] = useState([])
    const [generalPropertyType, setGeneralPropertyType] = useState([])
    const [types, setTypes] = useState([])
    const [tags, setTags] = useState([])

    const [property, setProperty] = useState({
        _id: null,
        address: null,
        addressNumber: null,
        addressComplement: null,
        neighborhood: null,
        showNumber: true,
        showComplement: true,
        city: null,
        state: null,
        IPTU: null,
        IPTUNumber: null,
        sellingPrice: null,
        descountPrice: null,
        condominiumPrice: null,
        sharedSale: false,
        sellingNotes: null,
        comission: null,
        type: null,
        generalType: null,
        fullLandSize: null,
        bathrooms: null,
        suites: null,
        showHome: false,
        bedrooms: null,
        parkingSpots: null,
        features: [],
        tag: null,
        description: null,
        dependencies: null,
        sold: false,
        showTag: false
    })

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }

        let body = document.querySelector('body')

        document.querySelector('#autocomplete').addEventListener('change', f => {
          if(window.innerWidth < 1000 || navigator.userAgent.toLowerCase().indexOf("mozilla") != -1){
            handleGamg()
          }
        })
        
        document.querySelector('#autocomplete').addEventListener('keydown', f => {
          if(f.key == 'Enter'){
            // alert('Selecione uma opção utilizando o mouse.') 
            handleGamg()
          }
        })

        if(document.querySelector('#scriptMap') && document.querySelector('#scriptInput')){
          body.removeChild(document.querySelector('#scriptMap'))
          body.removeChild(document.querySelector('#scriptInput'))
          body.removeChild(document.querySelector('.pac-container'))
        }
        
        var script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg'}&callback=initAutocomplete&libraries=places&v=weekly`
        script.setAttribute("defer", '')
        script.id='scriptMap'

        body.appendChild(script)


        script = document.createElement('script')
        script.innerHTML=`

        var placeSearch;
        var autocomplete;
        var componentForm = {
          street_number: "short_name",
          route: "long_name",
          locality: "long_name",
          administrative_area_level_1: "short_name",
          country: "long_name",
          postal_code: "short_name",
        };
        
        function initAutocomplete() {
          autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            { types: ["geocode"] }
          );
          autocomplete.setFields(["address_component"]);
          autocomplete.addListener("place_changed", fillInAddress);
        }
        
        function fillInAddress() {
          var place = autocomplete.getPlace();
        
          for (var component in componentForm) {
            document.getElementById(component).value = "";
            document.getElementById(component).disabled = false;
          }
        
          for (var component of place.address_components) {
            var addressType = component.types[0];
        
            if (componentForm[addressType]) {
              var val = component[componentForm[addressType]];
              document.getElementById(addressType).value = val;
            }
          }
        }
        
        function geolocate() {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              const circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy,
              });
              autocomplete.setBounds(circle.getBounds());
            });
          }
        }

        `

        script.id="scriptInput"
        body.appendChild(script)

        document.querySelector('#autocomplete').setAttribute("onFocus", "geolocate()")

        window.onload = () => {
            var target = document.querySelector('.pac-container');
            var observers = new MutationObserver(function(mutationsList, observer) {
              var targetItem = document.querySelectorAll('.pac-item');
              if(targetItem.length != 0){
                for(let i = 0; i < targetItem.length; i++){
                  targetItem[i].addEventListener('mousedown', e => handleChangeAddress(e.path, e))
                }
              }
            });
  
            observers.observe(target, {characterData: false, childList: true, attributes: false});
          }
  
          window.onclick = () => {
            var target = document.querySelector('.pac-container');
            var observers = new MutationObserver(function(mutationsList, observer) {
              var targetItem = document.querySelectorAll('.pac-item');
              if(targetItem.length != 0){
                for(let i = 0; i < targetItem.length; i++){
                  targetItem[i].addEventListener('mousedown', e => handleChangeAddress(e.path, e))
                }
              }
            });
  
            observers.observe(target, {characterData: false, childList: true, attributes: false});
          }
    }, [])

    function handleGamg(){
      let input = document.querySelector('#autocomplete').value

        
        if(input.split(',')[0].includes('-')){
            setBlock(input.split(' - ')[0])
            setBairro(input.split(',')[0].split(' - ')[1])
        }

        if(input.includes('Niterói')){
            setProperty({...property, city: 1})
            setProperty({...property, city: 6989})
            setCidade('Niterói')
        }else{
            setProperty({...property, city: 1})
            setProperty({...property, city: 7043})
            setCidade('Rio de Janeiro')
        }

        setBlock(input.split(' - ')[0])
    }

    function handleChangeAddress(target, e) {
        let path = target.filter(e => {
          return e.className == 'pac-item'
        })
        path = path[0].innerHTML.split('>')
        let first

        if(path.length == 15){
            first = path[4].split('<')[0]+', '+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]+path[11].split('<')[0]+path[12].split('<')[0]
        }else if(path.length == 13){
            first = path[4].split('<')[0]+', '+path[7].split('<')[0]+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]
        }else if(path.length > 9){
            first = path[4].split('<')[0]+', '+path[8].split('<')[0]+path[9].split('<')[0]
        }else{
            first = path[4].split('<')[0] +path[5].split('<')[0]+', '+path[7].split('<')[0]
        }

        if(first.split(',').length == 4){
            let bairro
            if(path.length == 15){
                first = path[4].split('<')[0]+' - '+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]+path[11].split('<')[0]+path[12].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }else if(path.length == 13){
                first = path[4].split('<')[0]+' - '+path[7].split('<')[0]+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }else if(path.length > 9){
                first = path[4].split('<')[0]+' - '+path[8].split('<')[0]+path[9].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }else{
                first = path[4].split('<')[0] +path[5].split('<')[0]+' - '+path[7].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }

            setBairro(bairro)
        }else{
            let bairro = String(first).split(',')[0]
            setBairro(bairro)
        }
        
        if(first.includes('Niterói')){
            setProperty({...property, city: 1})
            setProperty({...property, city: 6989})
            setCidade('Niterói')
        }else{
            setProperty({...property, city: 1})
            setProperty({...property, city: 7043})
            setCidade('Rio de Janeiro')
        }
        setBlock(path[4].split('<')[0] +path[5].split('<')[0])
                
      }

    useEffect(() => {
        getCity()

        api.get('/propertyType').then(res => {
            setTypes(res.data.propertyTypes)
        }).catch(err => console.log(err))

        api.get('/generalPropertyType').then(res => {
            setGeneralPropertyType(res.data.generalPropertyTypes)
           
        })

        api.get('/tag').then(res => setTags(res.data.tags)).catch(err => console.log(err))

    }, [])

    useEffect(()=>{
        if(types.length > 1){
            setProperty({
                ...property,
                type : property.type
            })
        }

        if(generalPropertyType.length > 1){
            setProperty({
                ...property,
                generalType: property.generalType
            })
        }
    },[property.type, types, generalPropertyType])

    
    async function getCity() {
        await api.get('/city?stateName=Rio de Janeiro').then((res) => {
            setCitys(res.data.citys)
        })
    }

    async function getNeighborhood() {
        await api.get('/neighborhood?city=' + property.city).then(res => {
            res.data.neighborhoods.map(item => {
                if(item.name.normalize("NFD").replace(/[^a-zA-Zs]/g, "").toUpperCase() == bairro.toUpperCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "")){
                    setProperty({...property, neighborhood: item._id})
                }
            })
            setNeighborhoods(res.data.neighborhoods)
        })

    }


    async function sendImages() {

        const filtered = imageCounter.filter(value => {
            if (value.image) {
                return value
            }
        })
        
        let formData

        for(var i = 0;i<filtered.length;){
  
            formData = new FormData()
            formData.append('show', filtered[i].show)
            formData.append('property', property._id)
            formData.append('file', filtered[i].image)

            const response = await api.post('/file/image', formData)
            i++
        }

    }

    function sendDocs() {

        const filtered = docCounter.filter(value => {
            if (value.file) {
                return value
            }
        })

        let formData

        const images = filtered.map(value => {
  
            formData = new FormData()
            formData.append('property', property._id)
            formData.append('file', value.file)

            api.post('/file/doc', formData).then(res => {
                
            }).catch(err => {
                console.log(err.response)
                alert('Falha ao inserir documentos,tente novamente mais tarde')
            })

        })

    }

    async function create() {
        var first = document.querySelector("#autocomplete").value
        var end = first.split(',')[0]
        if(String(first).split('-').length > 2){
            end = String(first).split(',')[0].split(' - ')[0]
        }
        var obj = property
        delete obj['_id']
        Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
        obj['address'] = end
        
        if(!obj['neighborhood']){
            console.log(obj['neighborhood'])
            return alert('Preencha o endereço corretamente (Trabalhamos apenas no Rio de Janeiro).')
        }

        obj['createdAt'] = new Date().toLocaleDateString('pt-BR', {day: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {month: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {year: 'numeric'})
        
        if(obj['bathrooms'] && obj['bedrooms'] && obj['city'] && obj['condominiumPrice'] && obj['fullLandSize'] && obj['parkingSpots'] && obj['suites'] && obj['IPTU'] && obj['IPTUNumber'] && obj['addressNumber'] && obj['dependencies'] && obj['type'] && obj['generalType']){ //&& obj['tag'] && obj['features']){
            api.post('/property', obj).then(res => {
                setProperty({
                    ...property,
                    _id: res.data._id
                })
                
                alert('Imovel adicionado com sucesso')
            }).catch(err => {
                console.log(err)
                return alert('Falha ao criar imovel,tente novamente mais tarde')
            })
        }else{
            let inputs = document.querySelectorAll('.input-compose')

            !obj['addressNumber'] ? inputs[1].style.border = '2px solid red' : inputs[1].style.border = '0px solid red'
            !obj['condominiumPrice'] ? inputs[6].style.border = '2px solid red' : inputs[6].style.border = '0px solid red'
            !obj['IPTU'] ? inputs[7].style.border = '2px solid red' : inputs[7].style.border = '0px solid red'
            !obj['IPTUNumber'] ? inputs[8].style.border = '2px solid red' : inputs[8].style.border = '0px solid red'
            !obj['sellingPrice'] ? inputs[9].style.border = '2px solid red' : inputs[9].style.border = '0px solid red'
            !obj['fullLandSize'] ? inputs[14].style.border = '2px solid red' : inputs[14].style.border = '0px solid red'
            !obj['bedrooms'] ? inputs[15].style.border = '2px solid red' : inputs[15].style.border = '0px solid red'
            !obj['suites'] ? inputs[16].style.border = '2px solid red' : inputs[16].style.border = '0px solid red'
            !obj['bathrooms'] ? inputs[17].style.border = '2px solid red' : inputs[17].style.border = '0px solid red'
            !obj['dependencies'] ? inputs[18].style.border = '2px solid red' : inputs[18].style.border = '0px solid red'
            !obj['parkingSpots'] ? inputs[19].style.border = '2px solid red' : inputs[19].style.border = '0px solid red'
            //!obj['features'].length > 0 ? document.querySelector('.sc-eirseW').style.border = '2px solid red' : document.querySelector('.sc-eirseW').style.border = '0px solid red'
            //!obj['tag'] ? inputs[19].style.border = '2px solid red' : inputs[19].style.border = '0px solid red'
            !obj['generalType'] ? inputs[12].style.border = '2px solid red' : inputs[12].style.border = '0px solid red'
            !obj['type'] ? inputs[13].style.border = '2px solid red' : inputs[13].style.border = '0px solid red'
            alert('Preencha os campos em vermelho.')
        }
    }

    useEffect(() => {
        if (property.city && !property.neighborhood) {
            getNeighborhood()
        }
    }, [property.city])

    useEffect(() => {
        if (property._id) {
            sendImages()
            sendDocs()
            history.push(`/imoveis/${property._id}`)
        }
        
    }, [property._id])


    const [imageCounter, setImageCounter] = useState([
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        }
    ])

    const [docCounter, setDocCounter] = useState([
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        }
    ])

    function addImage(files, id) {

        if (!files.length) {
            return null;
        }

        if(files.length == 1){
            const checkbox = document.querySelector(`input[name=checkbox-${id}]`)

            const url = URL.createObjectURL(files[0])

            const value = imageCounter.find(value => value.id === id)
            const clear = imageCounter.filter(value => value.id !== id)

            value.image = files[0]
            value.url = url
            value.show = checkbox.checked

            setImageCounter([
                ...clear,
                value
            ])
        }else{
            let arrayImage = []
            imageCounter.map(row => {
                if(row.image != null){
                    arrayImage.push(row)
                }
            })

            for(let i = 0; i < files.length; i++){
                const url = URL.createObjectURL(files[i])
                arrayImage.push({
                    id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                    image: files[i],
                    url: url,
                    show: true,
                    name: null,
                })
            }

            setImageCounter(arrayImage)
        }

    }

    function removeImage(id) {
        const removed = imageCounter.filter((value) => value.id !== id)
        setImageCounter(removed)
    }

    function editImageStatus(id, status) {
        const edited = imageCounter.map(value => {
            if (value.id === id) {
                value.show = `${status}`
            }
            return value
        })
        
        setImageCounter(edited)
    }

    function addDoc(files, id) {

        if (!files.length) {
            return null;
        }

        if(files.length == 1){
            const url = URL.createObjectURL(files[0])

            const value = docCounter.find(value => value.id === id)
            const clear = docCounter.filter(value => value.id !== id)

            value.file = files[0]
            value.url = url

            setDocCounter([
                ...clear,
                value
            ])
        }else{
            let arrayDocs = []
            docCounter.map(row => {
                if(row.image != null){
                    arrayDocs.push(row)
                }
            })

            for(let i = 0; i < files.length; i++){
                const url = URL.createObjectURL(files[i])
                arrayDocs.push({
                    id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                    image: files[i],
                    url: url,
                    file: files[i],
                })
            }

            setDocCounter(arrayDocs)
        }
    }

    function removeDoc(id) {
        const removed = docCounter.filter((value) => value.id !== id)
        setDocCounter(removed)
    }


    function moneyToNumber(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }

    function money (value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Anúncios Corretores >
                    </Link>
                    <Link to={window.location.pathname} className="where">
                        Novo
                    </Link>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Endereço (sem número)</label>
                        <input id="autocomplete" autoComplete="off" type="text" value={property.address} onChange={(e) => setProperty({
                            ...property,
                            address: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Número</label>
                        <input type="text" value={property.addressNumber} onChange={(e) => setProperty({
                            ...property,
                            addressNumber: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Complemento</label>
                        <input type="text" value={property.addressComplement} onChange={(e) => setProperty({
                            ...property,
                            addressComplement: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="select-group">
                        <input type="checkbox" name="" id="" checked={!property.showNumber} value={!property.showNumber} onChange={(e) => setProperty({
                            ...property,
                            showNumber: !e.target.checked
                        })} />
                        <span>Não desejo exibir o número no anúncio</span>
                    </div>
                    <div className="select-group">
                        <input type="checkbox" name="" id="" checked={!property.showComplement} value={property.showComplement} onChange={(e) => setProperty({
                            ...property,
                            showComplement: !e.target.checked
                        })} />
                        <span>Não desejo exibir o complemento no anúncio</span>
                    </div>
                </div>
                <div className="input-group" style={{ marginTop: '30px' }}>
                    <div className="input-compose">
                        <label htmlFor="">Cidade</label>
                        <Autosuggest values={cidade} onChange={(e) => {
                            setProperty({
                                ...property,
                                city: e._id
                            })
                        }}
                            objs={citys}
                        />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Estado</label>
                        <Autosuggest onChange={(e) => {
                            setProperty({
                                ...property,
                                state: e
                            })

                        }}
                            objs={[
                                { name: 'Rio de Janeiro' }
                            ]}
                            defaultValue={'Rio de Janeiro'}
                        />
                    </div>
                </div>
                <div className="input-group" >
                    <div className="input-compose">
                        <label htmlFor="">Bairro</label>
                        <Autosuggest values={bairro} onChange={(e) => {
                            setProperty({
                                ...property,
                                neighborhood: e._id
                            })
                        }}
                            objs={neighborhoods}
                        />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Valor do condomínio</label>
                        <input type="text" value={money(property.condominiumPrice)} onChange={(e) => setProperty({
                            ...property,
                            condominiumPrice: moneyToNumber(e.target.value)
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">IPTU Valor</label>
                        <input type="text" value={money(property.IPTU)} onChange={(e) => setProperty({
                            ...property,
                            IPTU: moneyToNumber(e.target.value)
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">IPTU Numero</label>
                        <input type="text" value={property.IPTUNumber} onChange={(e) => setProperty({
                            ...property,
                            IPTUNumber: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Valor de venda do imóvel</label>
                        <input type="text" value={money(property.sellingPrice)}
                            onChange={(e) => {
                                setProperty({
                                    ...property,
                                    sellingPrice: moneyToNumber(e.target.value)
                                })
                            }}

                        />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Valor de venda com desconto</label>
                        <input type="text" value={money(String(property.descountPrice))}
                            onChange={(e) => {
                                setProperty({
                                    ...property,
                                    descountPrice: moneyToNumber(e.target.value)
                                })

                                
                                if(Number(moneyToNumber(e.target.value)) > Number(moneyToNumber(money(property.sellingPrice)))){
                                    setProperty({
                                        ...property,
                                        descountPrice: property.sellingPrice
                                    })
                                    return alert('O desconto não pode ser maior que o valor original.')
                                }
                            }}

                        />
                    </div>
                </div>
                <div className="share">
                    <div className="text">
                        <div className="row">
                            <input type="checkbox" name="" id="" value={property.sharedSale} onChange={(e) => setProperty({
                                ...property,
                                sharedSale: e.target.checked
                            })} />
                            <h1> Você deseja acelerar a venda deste imóvel com a ajuda de outros corretores? </h1>
                        </div>
                        <p>Vamos dar um exemplo para explicar melhor como funciona este recurso. Se você está ganhando 6% por esta venda, você pode oferecer parte desta comissão para outro corretor caso ele te ajude a vender, como por exemplo 3%.
                        A comissão compartilhada é uma escolha sua. </p>
                    </div>
                    <div className="column">
                        <div className="input-compose">
                            <p>Qual a comissão compartilhada em %?</p>
                            <input type="text" disabled={!property.sharedSale} value={property.comission} onChange={(e) => setProperty({
                                ...property,
                                comission: e.target.value
                            })} />
                        </div>
                        <textarea
                            placeholder="Escreva as instruções de venda"
                            value={property.sellingNotes} onChange={(e) => setProperty({
                                ...property,
                                sellingNotes: e.target.value
                            })}
                        ></textarea>
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Residencial ou comercial</label>
                        <select name="" id="" onChange={(e) => setProperty({
                            ...property,
                            generalType: e.target.value != 'Selecione' ? e.target.value:null
                        })}>
                            <option defaultValue>Selecione</option>
                            {
                                generalPropertyType.map(value => (
                                    <option value={value._id} key={value._id}>{value.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Tipo</label>
                        <select 
                        onChange={(e) => {
                            setProperty({
                                ...property,
                                type: e.target.value == 'Selecione' ? null:e.target.value
                            })
                        }}
                        >
                        <option defaultValue>Selecione</option>

                            {
                                types.map(value => (
                                    <option value={value._id} key={value._id}>{value.name}</option>
                                ))
                            }

                        </select>
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Metragem (m²)</label>
                        <input type="text" value={property.fullLandSize} onChange={(e) => setProperty({
                            ...property,
                            fullLandSize: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Total quartos (Exceto dependências)</label>
                        <input type="text" value={property.bedrooms} onChange={(e) => setProperty({
                            ...property,
                            bedrooms: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Sendo quantas Suites?</label>
                        <input type="text" value={property.suites} onChange={(e) => setProperty({
                            ...property,
                            suites: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Total de banheiros (Exceto dependências)</label>
                        <input type="text" value={property.bathrooms} onChange={(e) => setProperty({
                            ...property,
                            bathrooms: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Total de dependências de serviço</label>
                        <input type="text" value={property.dependencies} onChange={(e) => setProperty({
                            ...property,
                            dependencies: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Número de vagas</label>
                        <input type="text" value={property.parkingSpots} onChange={(e) => setProperty({
                            ...property,
                            parkingSpots: e.target.value
                        })} />
                    </div>
                </div>
                <h1>Selecione todas as características deste imóvel</h1>
                <Picker setValue={(value) => setProperty({...property, features: value})} />
                <div className="tag" style={{ marginTop: '30px' }}>
                    <h2>Escolha uma TAG que melhor represente esta oportunidade</h2>
                    <p>Esta TAG será aplicada eventualmente no seu anúncio para destacá-lo.</p>
                    <div className="input-group">
                        <div className="input-compose">
                            <label htmlFor="">TAG</label>
                            <select onChange={(e) => setProperty({
                                ...property,
                                tag: e.target.value != 'Selecione' ? e.target.value:null
                            })}>
                                <option>Selecione</option>
                                {
                                    tags.map(value => (
                                        <option value={value._id} key={value._id}>{value.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="select-group" style={{marginBottom: 20, marginTop: -5}}>
                        <input type="checkbox" name="" id="" value={property.showTag} onChange={(e) => setProperty({
                            ...property,
                            showTag: e.target.checked
                        })} />
                        <span style={{marginLeft: 10}}>Marque para forçar a aplicação desta tag no imóvel</span>
                    </div>
                    <div className="input-group">
                        <div className="input-compose">
                            <label htmlFor="">Para finalizar, descreva o seu imóvel (opcional)</label>
                            <textarea name="" id="" cols="30" rows="6" value={property.description} onChange={(e) => {setProperty({
                                ...property,
                                description: e.target.value
                            });}}></textarea>
                        </div>
                    </div>
                </div>
                <h2>Adicione fotos e documentos.  Se preferir, pode adicionar posteriormente.</h2>

                <Carousel
                    style={{ marginTop: '30px' }}
                >
                    {
                        imageCounter.map((value, index) => (
                            <Item key={index}>
                                <ImageItem htmlFor={`image-${value.id}`} image={value.url ? value.url : ''} className="image-container">
                                    <input multiple='multiple' type="file" name="image" id={`image-${value.id}`} onInput={(e) => addImage(e.target.files, value.id)} />
                                    <button className="close" show={value.image ? 'true' : undefined} onClick={() => removeImage(value.id)}>
                                        x
                                    </button>
                                </ImageItem>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <input type="checkbox" name={`checkbox-${value.id}`} id="" defaultChecked onChange={(e) => {
                                        editImageStatus(value.id, e.target.checked)
                                    }} />
                                    <label htmlFor="" style={{ fontWeight: 'normal' }}>Exibir no anúncio </label>
                                </div>
                            </Item>
                        ))
                    }
                    <BtnWrapper>
                        <PlusBtn onClick={() => setImageCounter([...imageCounter, {
                            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
                        }])}>
                            <img src={require('../../assets/adminOfferProperty/plus.svg')} alt="" />
                        </PlusBtn>
                    </BtnWrapper>
                </Carousel>
                <Carousel
                    style={{ marginTop: '30px' }}
                >
                    {
                        docCounter.map((value, index) => (
                            <Item key={index}>
                                <DocItem htmlFor={`doc-${value.id}`} image={value.file ? require('../../assets/adminOfferProperty/docok.svg') : ''} className="doc-container">
                                    <input multiple='multiple' type="file" name="doc" id={`doc-${value.id}`} onInput={(e) => addDoc(e.target.files, value.id)} />
                                    <button className="close" show={value.file ? 'true' : 'false'} onClick={() => removeDoc(value.id)}>
                                        x
                                    </button>
                                </DocItem>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <label htmlFor="" style={{ fontWeight: 'normal' }}>{value.file ? value.file.name : ''}</label>
                                </div>
                            </Item>
                        ))
                    }
                    <BtnWrapper>
                        <PlusBtn onClick={() => setDocCounter([...docCounter, {
                            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
                        }])}>
                            <img src={require('../../assets/adminOfferProperty/plus.svg')} alt="" />
                        </PlusBtn>
                    </BtnWrapper>
                </Carousel>
                <div className="save" style={{marginTop: window.innerWidth <= 600? 0:0}}>
                    <button onClick={create}>
                        Salvar
                </button>
                </div>
            </Container>
        </>
    )
}
