import React from 'react';
import Routes from './routes.js'


function App() {
  return (
    <Routes />
  );
}

export default App;
