import React, { useEffect, useState } from 'react'
import {Head,Logo,Links} from './styles'
import logo from '../../assets/logo.svg'
import {Link} from 'react-router-dom'
import '../../global.css';
import api from '../../api';
export default function Header() {
    const [menuActive, setMenuActive] = useState(true)

    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search)
        if(searchParams.has('app')){
            setMenuActive(false)
        }
    }, [])

    return menuActive ? (
        <Head className="header">
            <div>
                <Link onClick={click=>document.querySelector('body').style.overflow='scroll'} to="/">
                    <Logo src={logo} />
                </Link>
            </div>
            <Links>
                <ul>
                    <li>
                        <Link to={'/imoveis'}>
                            <h2>Imóveis à venda</h2>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={click=>document.querySelector('body').style.overflow='scroll'} to="/correspondente-bancario">
                            <h2>Correspondente</h2>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={click=> {document.querySelector('body').style.overflow='scroll'}} to="/sobre-nos">
                            <h2>Sobre nós</h2>
                        </Link>
                    </li>
                    <li>
                        <Link to={window.location.pathname} onClick={async click=> {
                            document.querySelector('body').style.overflow='scroll'; 
                            //localStorage.removeItem('id')
                            if(localStorage.getItem('id')){
                                localStorage.setItem('id_painel', localStorage.getItem('id'))
                                let {data} = await api.get('/user/'+localStorage.getItem('id'))
                                
                                localStorage.setItem('type_user', data[0].type)
                                window.location.href = '/admin/home-corretor' 
                            }else{
                                window.location.href = '/login'
                            }
                            }}>
                            <h2>
                                {localStorage.getItem('id') ? 'Minha Conta' : 'Login'}
                            </h2>
                        </Link>
                    </li>
                    <li>
                        <div onClick={click=> {
                            document.querySelector('body').style.overflow='scroll'
                            // if(!localStorage.getItem('id')){
                            //     window.location.href="/login" 
                            // }else{
                                window.location.href="/ofertar-imovel" 
                            // }
                        }} style={{cursor: 'pointer'}} id="offer-button">
                            Compramos seu Imóvel
                        </div>
                    </li>
                </ul>
            </Links>
        </Head>
    )
    :
    <></>
}
