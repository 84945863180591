import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/Footer'
import { Body, ImageWrapper, TextWrapper } from './styles'

export default function Privacy() {
    return (
        <div>
            <Header />
            <Body>
                <ImageWrapper>
                    <TextWrapper>
                        <h1>Política de Privacidade</h1>
                    </TextWrapper>
                </ImageWrapper>
                <div className="text">
                    <p style={{textAlign: 'start'}}>
                        Nossa Política de Privacidade busca reafirmar o compromisso da Imóvel Vazio com a adoção das melhores práticas de segurança, transparência e privacidade no acesso e tratamento das suas informações. Ela busca esclarecer como coletamos, usamos, armazenamos e tratamos os seus dados.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        1. QUAIS DADOS SÃO COLETADOS?
                    </h3>

                    <p style={{textAlign: 'start'}}>
                        Nós coletamos os seguintes tipos de dados:

                        Dados Pessoais:
                        Quaisquer dados que permitam a identificação pessoal do Usuário. Os Dados Pessoais incluem, entre outros, o nome completo, e-mail, número de telefone, CPF e eventuais informações financeiras fornecidas pelo Usuário à Imóvel Vazio.


                        Estas informações podem ainda ser obtidas através do Corretor de imóveis credenciado que tenha permissão do Usuário para compartilhar informações com a Imóvel Vazio. Algumas das informações que poderão ser compartilhadas conosco são o nome completo, um nome de usuário, endereço de e-mail, número de telefone, endereço físico ou, para determinados produtos e serviços, o número de identidade, número de CPF, número de CNPJ.

                        Dados de Imóveis:
                        Quaisquer dados que detalham, de forma específica, informações de imóveis, como por exemplo: endereço, metragem, valor declarado, estrutura interna e/ou proprietários.

                        Dados de Acesso:
                        - Informações coletadas de Usuários ao usar o Site e o Blog: inclui, entre outros, o navegador de acesso do Usuário; endereço do protocolo de Internet (IP); data e hora do acesso; a localização do Usuário; e as ações do Usuário no site.
                        - Comunicação entre Usuário e a Imóvel Vazio: inclui quaisquer comunicações havidas entre a Imóvel Vazio e o Usuário por e-mail e/ou telefone.
                        - Cookies: conforme disposto no item 6 desta Política de Privacidade.

                    </p>

                    <h3 style={{textAlign: 'start'}}>2. COMO USAMOS OS DADOS COLETADOS? </h3>

                    <p style={{textAlign: 'start'}}>
                        Funcionamento das Plataformas Imóvel Vazio:
                        Os dados coletados são utilizados para manutenção e aprimoramento das nossas plataformas, como o Blog e Site. Esses dados nos possibilitam:
                        (i) oferecer uma melhor experiência de navegação para os Usuários;
                        (ii) desenvolver novas funcionalidades;
                        (iii) realizar estatísticas de monitoramento de uso das Plataformas Imóvel Vazio;
                        (iv) verificar a proteção da Imóvel Vazio contra erros, fraudes ou qualquer outro crime eletrônico; e (v) oferecer produtos e serviços que se enquadrem no perfil do Usuário.

                        Contato Direto com o Usuário:
                        A Imóvel Vazio poderá entrar em contato com os Usuários, seja através de ligações telefônicas, envio de e-mails ou notificações com alertas e comunicados relacionados à Imóvel Vazio. Caso o Usuário não queira mais receber e-mails da Imóvel Vazio, ele deverá clicar no link de cancelamento (“Cancelar Assinatura”) presente no rodapé dos e-mails que enviamos.

                        Oferecimento de Produtos e/ou Serviços:
                        A Imóvel Vazio poderá processar os Dados Pessoais e Dados de Matrícula, de forma automatizada e codificada, com o uso do Software, para identificar necessidades específicas dos Usuários e, com base nisto, desenvolver e/ou oferecer produtos e serviços que se enquadrem no seu perfil. Da mesma forma, os dados coletados poderão ser tratados para fins de prevenção a fraudes no oferecimento e contratação de serviços e produtos por Usuários. Em hipótese alguma, a Imóvel Vazio compartilhará os Dados Pessoais e/ou Dados de Matrícula com Parceiros sem o prévio e expresso consentimento do Usuário, conforme previsto nesta Política de Privacidade.

                    </p>

                    <h3 style={{textAlign: 'start'}}> 3. COMO USAMOS OS DADOS DE USUÁRIOS NOS ANÚNCIOS PUBLICITÁRIOS? </h3>
                    <p style={{textAlign: 'start'}}>
                        Conforme já explicamos, o Imóvel Vazio poderá, de forma automatizada e codificada, agrupar os Usuários em grupos de acordo com categorias e critérios referentes ao perfil dos Usuários ("Grupo"). Os Dados Pessoais de quem integra cada Grupo não serão informados ou transmitidos a terceiros sem o prévio e expresso consentimento do Usuário, conforme previsto nesta Política de Privacidade. Detalhamos abaixo como isso pode ocorrer:

                        Que tipo de anúncio publicitário o Imóvel Vazio poderá fazer?
                        Poderemos fazer anúncios publicitários por meio do seu telefone, e-mail, endereço físico ou no próprio Site. Queremos que cada Usuário receba anúncios que sejam do seu interesse e possam auxiliá-lo no processo de compra ou venda de apartamentos.

                        Os Parceiros poderão ter acesso aos meus dados em alguma hipótese?
                        A Imóvel Vazio nunca irá transmitir os seus dados a Parceiros sem a sua autorização, mas
                        lembre-se que caso você clique em um banner publicitário ou de qualquer forma aceite ser direcionado ao site de um terceiro, você não estará mais sujeito à Política de Privacidade do Imóvel Vazio.

                    </p>

                    <h3 style={{textAlign: 'start'}}>4. COMO ARMAZENAMOS AS INFORMAÇÕES COLETADAS?</h3>

                    <p style={{textAlign: 'start'}}>
                        A segurança dos dados relacionados aos nossos Usuários é uma das prioridades da Imóvel Vazio. Buscamos o constante aprimoramento dos nossos processos e a adoção das melhores práticas de Segurança da Informação para proteger os Usuários e dados a que temos acesso. As informações são protegidas com tecnologia SSL (Secure Sockets Layer) para que os dados do Usuário permaneçam em sigilo. Essa tecnologia também visa impedir que as informações sejam transmitidas ou acessadas por terceiros não autorizados.


                        Nos termos desta Política de Privacidade, caso suas informações venham a ser armazenadas por terceiros, a Imóvel Vazio não terá qualquer responsabilidade sobre sua guarda e segurança, considerando que estes não terão qualquer forma de controle ou ingerência sobre os mesmos.

                    </p>

                    <h3 style={{textAlign: 'start'}}>5. COMPARTILHAMENTO DE DADOS COM TERCEIROS </h3>
                    <p style={{textAlign: 'start'}}>
                        Os dados coletados do Usuário poderão ser compartilhados com:

                        A autorização do Usuário:
                        A Imóvel Vazio poderá compartilhar, bem como receber seus dados mediante o seu consentimento ou sob sua orientação. Tal troca de informações poderá ocorrer, por exemplo, por meio de uma conexão com uma plataforma de terceiros.

                        Empresas do mesmo grupo econômico:
                        Os dados coletados poderão ser compartilhados com empresas que façam parte do mesmo grupo econômico e/ou estrutura societária da Imóvel Vazio, desde que tal compartilhamento seja necessário para fins de operação, manutenção e/ou melhoria na prestação dos serviços oferecidos aos Usuários. Nesse contexto, qualquer empresa que venha a ter acesso aos dados deverá adotar pelo menos os mesmos níveis de segurança utilizados e garantidos pela Imóvel Vazio aos seus Usuários.

                        Empresas que prestem serviço para a Imóvel Vazio:
                        A Imóvel Vazio poderá compartilhar dados coletados com prestadores de serviços terceirizados na medida em que tais informações sejam necessárias à prestação do serviço, a qual é feita sob orientação. Esses prestadores de serviços terceirizados podem ter sido contratados para, por exemplo, prestar atendimento ao Usuário, auxiliar na resolução de problemas técnicos, realizar a checagem de informações, avaliar e gerenciar riscos, entre outros.

                        Terceiros conforme obrigação legal:
                        A Imóvel Vazio poderá compartilhar dados com terceiros quando permitido ou exigido por lei como, por exemplo, para o cumprimento de obrigação legal ou regulatória; para fins de auditoria; para investigar violações ou cumprir uma previsão de um contrato celebrado com o Usuário; para cumprir uma ordem judicial, entre outros. Ressaltamos que, em nenhum caso, os dados dos Usuários serão vendidas a terceiros pela Imóvel Vazio.

                    </p>

                    <h3 style={{textAlign: 'start'}}>6. COOKIES</h3>
                    <p style={{textAlign: 'start'}}>
                        Utilizamos cookies e tecnologias similares, como pixels e tags, para nos certificarmos de que os serviços prestados estão de acordo com o melhor padrão de desempenho esperado pelo Usuário. Os cookies coletados pela Imóvel Vazio fornecem somente estatísticas e não serão utilizados para propósitos diversos dos expressamente previstos nesta Política de Privacidade.

                        O que é cookie?
                        Cookie é um pequeno arquivo adicionado ao seu dispositivo ou computador para fornecer uma experiência personalizada de acesso à Imóvel Vazio.

                        Como a Imóvel Vazio faz a coleta de cookies?
                        A Imóvel Vazio utiliza empresas especializadas em veiculação de propagandas, dentro e fora da Imóvel Vazio como, por exemplo, o Google e Facebook. Ao aceitar esta Política de Privacidade, você concorda com a coleta dos cookies por empresas contratadas pela Imóvel Vazio para esse fim.

                        Que tipos de cookies a Imóvel Vazio utiliza?
                        A Imóvel Vazio permite a coleta de dois tipos de cookies: salvo e temporário.
                        (i) O cookie salvo serve para armazenar informações, como nome e senha, de maneira que o Usuário não tenha que se conectar sempre que acessar as plataformas da Imóvel Vazio. (ii) Um cookie temporário é aquele que é usado para identificar uma visita específica ao Site da Imóvel Vazio.


                        Estes cookies são removidos do terminal de acesso (ex.: computador, tablet, etc.) do Usuário assim que este finaliza a utilização do navegador e são utilizados para armazenar informações temporárias.

                        Para que os cookies são utilizados ?
                        O Imóvel Vazio utiliza cookies para vários fins, incluindo:
                        (i) ações de marketing para remarketing. Este recurso nos permite atingir os visitantes do Site para lembrá-los de efetuarem o cadastro na Imóvel Vazio ou para que saibam das atualizações em nosso portfólio, locais de atuação da Imóvel Vazio;
                        (ii) entender o comportamento de uso do Site e do Blog para melhor desenvolvimento do produto.

                        É possível limitar a coleta de cookies?
                        Os navegadores em geral permitem que seja desabilitada a coleta de cookies. Dessa forma, caso você não altere os padrões de coleta de cookie do seu navegador, nós iremos considerar que você concorda com a coleta de cookies. Porém, as plataformas da Imóvel Vazio poderão não funcionar da forma desejada caso seja desabilitada a coleta de cookies.

                    </p>

                    <h3 style={{textAlign: 'start'}}>7. DIREITOS DOS USUÁRIOS</h3>
                    <p style={{textAlign: 'start'}}>
                        - São garantidos aos Usuários os seguintes direitos:
                        - Confirmação de que existe um ou mais tratamento de dados sendo realizado;
                        - Acesso aos dados pessoais armazenados pela Imóvel Vazio que lhe dizem respeito;
                        - Correção dos seus dados pessoais que estejam incompletos, inexatos ou desatualizados;
                        - Informação sobre o compartilhamento de seus dados com entes públicos e privados, caso sejam solicitados ou autorizados;
                        - Informação sobre não consentimento, ou seja, sobre a opção de não autorizar o tratamento e as consequências da não autorização;
                        - Revogação do consentimento nos termos da lei;
                        - Oposição, caso discorde de um tratamento realizado sem o seu consentimento e o considere irregular;
                        - Eliminação dos seus dados, exceto quando o tratamento é legal (previsto na legislação brasileira), mesmo sem o seu consentimento.


                        Para solicitações ou dúvidas sobre estes direitos, envie um e-mail para <b>contato@imovelvazio.com.br</b>

                    </p>
                </div>
            </Body>
            <Footer />
        </div>
    )
}
