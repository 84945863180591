const { default: styled } = require("styled-components");

export const Body = styled.div`
    width : 100%;
    padding : 10%;
    height : auto;
    text-align: justify;
    
    .text{
        margin-top : 40px;
        font-size : 18px;
        p,h1,h2,h3{
            margin-bottom : 20px;
        }
    }
`
export const ImageWrapper = styled.div`
    width : 100%;
    height : 256px;
    border-radius : 16px;
    background : url(${require('../../assets/politics/bg.svg')});
    background-repeat : no-repeat;
    background-size : cover;
    display : flex;
    justify-content : center;
    align-items : center;
    
    @media(max-width:600px){
        height: 200px;
        padding: 15px;
    }
`
export const TextWrapper = styled.div`
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px);
    padding : 40px;
    padding-left : 80px;
    padding-right : 80px;
    border-radius: 8px;

    h1{
        font-size : 36px;
        color: #1E1D1D;
    }

    @media(max-width:600px){
        padding-left : 40px;
        padding-right : 40px;    
        padding: 15px;

        h1{
            font-size: 25px;
            text-align: start;
        }
    }
`