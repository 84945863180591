import React from 'react';

import { BgImg, Container, FormContainer, TableContainer } from './style';
import Header from '../../components/header'
import Footer from '../../components/Footer'

function CBanking() {
  return (
    <>
      <BgImg>
        <Header />
        <Container>
          <TableContainer>
            <h1>Tabela de Repasse (R$)</h1>
            <p>Valor do financiamento</p>
            <table>
              <thead>
                <tr>
                  <th className="initial initial-th">Inicial</th>
                  <th className="final-th">Final</th>
                  <th>Repasse Corretor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>80.000,00</td>
                  <td>100.000,00</td>
                  <td>150,00</td>
                </tr>
                <tr>
                  <td>100.001,00</td>
                  <td>200.000,00</td>
                  <td>250,00</td>
                </tr>
                <tr>
                  <td>200.001,00</td>
                  <td>300.000,00</td>
                  <td>425,00</td>
                </tr>
                <tr>
                  <td >300.001,00</td>
                  <td>400.000,00</td>
                  <td>600,00</td>
                </tr>
                <tr>
                  <td >400.001,00</td>
                  <td>500.000,00</td>
                  <td>775,00</td>
                </tr>
                <tr>
                  <td>500.001,00</td>
                  <td>600.000,00</td>
                  <td>950,00</td>
                </tr>
                <tr>
                  <td>600.001,00</td>
                  <td>700.000,00</td>
                  <td>1125,00</td>
                </tr>
                <tr>
                  <td>700.001,00</td>
                  <td>800.000,00</td>
                  <td>1300,00</td>
                </tr>
                <tr>
                  <td>800.001,00</td>
                  <td>900.000,00</td>
                  <td>1475,00</td>
                </tr>
                <tr>
                  <td>900.001,00</td>
                  <td>1.000.000,00</td>
                  <td>1650,00</td>
                </tr>
                <tr>
                  <td>1.000.001,00</td>
                  <td>1.100.000,00</td>
                  <td>1825,00</td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <FormContainer>
          <div class="caption">
                            <p>
                                Prezado corretor,
                            </p>
                            <p>
                                Você precisa de aprovação na Caixa para o financiamento do seu cliente? Oferecemos esse serviço e ainda fazemos um repasse para você, vide tabela ao lado.
                            </p>
                            <p>
                                Entre em contato pelo telefone <strong>(21) 99997-1300</strong> ou e-mail <strong>correspondente@imovelvazio.com.br</strong>.
                            </p>
                            <p>
                                Segue relação de documentos necessários:
                            </p>
                            <p>
                                - <strong>Identidade</strong>: CNH na validade, RG, ID militar, carteira de trabalho ou passaporte
                                <br />
                                - <strong>CPF</strong>: Somente o número, se constar na indentidade não precisa
                                <br />
                                - <strong>Comprovante de residência</strong>: Em nome do cliente/participante - Pode ser Conta de Consumo (Água, Luz, Telefone e Gás) com a parte de dentro da conta ou Cartão de Crédito, fatura aberta. Validade 30 dias
                                <br />
                                - <strong>Comprovante de Estado Civil</strong>:
                                <br />
                                <span style={{marginLeft:'3em'}}>Solteiro:</span> Certidão de Nascimento
                                <br />
                                <span style={{marginLeft:'3em'}}>Casado:</span> Certidão de Casamento
                                <br />
                                <span style={{marginLeft:'3em'}}>Divorciado:</span> Certidão de Casamento com Averbação do divórcio
                                <br />
                                <span style={{marginLeft:'3em'}}>Viúvo:</span> Certidão de Casamento e Atestado de Óbito
                                <br />
                                - <strong>Comprovante de Renda</strong>:
                                <br />
                                <span style={{marginLeft:'3em'}}>Assalariado, funcionário privado ou público:</span> Contra-Cheque
                                <br />
                                <span style={{marginLeft:'3em'}}>Empresários, comerciantes e donos de empresas:</span> Pró-Labore (último mês + comprovante de pagamento de imposto do pró-labore DARF) ou IR completo + recibo de entrega + contrato empresa
                                <br />
                                <span style={{marginLeft:'3em'}}>Aposentados/Pensionistas:</span> Último extrato do benefício ou comprovante de pagamento
                            </p>
                        </div>
          </FormContainer>
        </Container>
      </BgImg>
      <Footer />
    </>
  );
}

export default CBanking;