import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import '../../global.css';
import Select from 'react-select';
import api from '../../api'
import Slider from '@material-ui/core/Slider'
import ReactInputMask from 'react-input-mask';

export default function Modal({setFlex}) {
    const [configs, setConfigs] = useState(1)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [senhaConfirm, setSenhaConfirm] = useState('')
    const [code, setCode] = useState('')
    const [codeBack, setCodeBack] = useState('')
    const [celular, setCelular] = useState('')

    async function handleSend(){
        if(configs == 1 || configs == 404){
            if(email && email.length > 4 || celular.length == 15 && !celular.includes('_')){
                const {data} = await api.post('/user/validate', {email, celular})
                console.log(data);

                if(data.success == false) {
                    setConfigs(404)
                    let email = document.querySelector('.emil')
                    email.style.border = '2px solid red'
                    setTimeout(() => {email.style.border = '0px solid red'}, 2000);
                    
                    let cel = document.querySelector('.celul')
                    cel.style.border = '2px solid red'
                    setTimeout(() => {cel.style.border = '0px solid red'}, 2000);
                }
                if(data.success == true) {
                    const {data} = await api.post('/user/recover?change=false', {email, celular})
                    
                    if(data.codeBack){
                        if(data.op == "celular") {
                            setConfigs(3)
                        }else {
                            setConfigs(2)
                        }
                        setCodeBack(data.codeBack)
                    }
                }
            }else{
                if(!email && email.length <= 4){
                    let email = document.querySelector('.emil')
                    email.style.border = '2px solid red'
                    setTimeout(() => {email.style.border = '0px solid red'}, 2000);
                }
                if(celular.length != 15 || celular.includes('_')){
                    let celular = document.querySelector('.celul')
                    celular.style.border = '2px solid red'
                    setTimeout(() => {celular.style.border = '0px solid red'}, 2000);
                }
            }
        }else{
            if(senha && senha.length > 5 && senhaConfirm == senha && code == codeBack){
                const {data} = await api.post('/user/recover?change=true', {email, celular, password: senha})

                if(data[0].email){
                    setFlex(false);
                    setConfigs(1)
                    alert('Senha alterada com sucesso.')
                }
            }else{
                let senhas = document.querySelectorAll('.password-box')[0]
                let senhaConfirms = document.querySelectorAll('.password-box')[1]
                let codes = document.querySelector('.min')

                if(senha.length < 6){
                    senhas.style.border = '2px solid red'
                    setTimeout(() => {senhas.style.border = '0px solid red'}, 2000);
                }
                if(senhaConfirm != senha){
                    senhaConfirms.style.border = '2px solid red'
                    setTimeout(() => {senhaConfirms.style.border = '0px solid red'}, 2000);
                }
                if(code != codeBack){
                    codes.style.border = '2px solid red'
                    setTimeout(() => {codes.style.border = '0px solid red'}, 2000);
                }
            }
        }
    }

    return (
        <>
            <div className="blackModal" onClick={item => {setConfigs(1); document.querySelector('body').style.overflow='scroll'; setFlex(false)}} style={{position: 'absolute', height: '500%', width: '100%', backgroundColor: '#111', zIndex: 1, opacity: '0.3', left: 'auto'}}>
            </div>
            <div className="contentModal" style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'absolute', backgroundColor: '#fff', height: configs == 2? window.innerWidth <= 600 ? '85%':'65%':'50%', width: window.innerWidth <= 600 ? '90%':'38%', zIndex: 2, marginTop: window.innerWidth <= 600 ? '24%':'10%', left: window.innerWidth <= 600 ? '5%':'32%', opacity: '1', borderRadius: '6px'}}>
                <div style={{display: 'flex', width: '100%', height: '8%', borderBottom: '1px solid #ddd', flexDirection: 'row', alignItems: 'center'}}>
                    <h2 style={{width: '95%', display: 'flex', justifyContent: 'center'}}></h2>
                    <h4 onClick={item => {setFlex(false); setConfigs(1);document.querySelector('body').style.overflow='scroll'}} style={{color: "#EA5C1E", cursor: 'pointer'}}>X</h4>
                </div>
                <div style={{height: '92%', display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "center"}}>
                    <h1 style={{marginTop: 18, textAlign: 'center'}}>Recuperação de senha</h1>
                    {
                        configs == 1 || configs == 404? 
                        <>
                        <p style={{marginTop: 10}}>Recupere sua senha com celular ou e-mail</p>
                            <div className="email-box celul" style={{marginBottom: 10}}>
                                <small htmlFor="">Celular/SMS</small>
                                <ReactInputMask mask="(99) 99999-9999" value={celular}  onChange={text => setCelular(text.target.value)} placeholder="Ex.: (00) 00000-0000" pattern="\(\d{2}\)\d{4}-\d{4}"/>
                            </div>
                            ou
                            <div className="email-box emil" style={{marginTop: 10}}>
                                <small>E-mail</small>
                                <input value={email} onChange={e=>setEmail(e.target.value)}/>
                            </div>
                        </>
                        : ''
                    }

                    { 
                        configs == 2 ? 
                        <>
                            <p style={{marginTop: 18, textAlign: 'center'}}>Enviamos um código para o seu email, digite abaixo:</p>
                            <div className="password-box min">
                                <small>Código</small>
                                <input value={code} onChange={e=>setCode(e.target.value)} type='number'/>
                            </div>
                            
                            <div className="password-box">
                                <small>Nova senha</small>
                                <input placeholder='Minimo 6 caracteres' value={senha} onChange={e=>setSenha(e.target.value)} type='password'/>
                            </div>

                            <div className="password-box">
                                <small>Confirme sua nova senha</small>
                                <input value={senhaConfirm} onChange={e=>setSenhaConfirm(e.target.value)} type='password'/>
                            </div>

                        </>:''
                    }
                    
                    { 
                        configs == 3 ? 
                        <>
                            <p style={{marginTop: 18, textAlign: 'center'}}>Enviamos um código via SMS, digite abaixo:</p>
                            <div className="password-box min">
                                <small>Código</small>
                                <input value={code} onChange={e=>setCode(e.target.value)} type='number'/>
                            </div>
                            
                            <div className="password-box">
                                <small>Nova senha</small>
                                <input placeholder='Minimo 6 caracteres' value={senha} onChange={e=>setSenha(e.target.value)} type='password'/>
                            </div>

                            <div className="password-box">
                                <small>Confirme sua nova senha</small>
                                <input value={senhaConfirm} onChange={e=>setSenhaConfirm(e.target.value)} type='password'/>
                            </div>

                        </>:''
                    }

                    <div onClick={handleSend} id='btnEmail'>
                        {configs == 1 || configs == 404? 'Enviar':'Confirmar'}
                    </div>   
                    
                    {
                    configs == 404?
                        <h5 style={{width: '80%', fontWeight: 400, marginTop: 27, textAlign: 'center', fontSize: 17}}>
                            Este email não está em nosso banco de dados.
                        </h5>
                        :''
                    }

                    { 
                        configs == 2 ? 
                        <h5 style={{width: window.innerWidth <= 600 ? '90%':'80%', fontWeight: 400, marginTop: 10, textAlign: 'center', fontSize: window.innerWidth <= 600 ? 12 :13, marginBottom: window.innerWidth <= 600 ? 20:'0'}}>Caso não tenha recebido o email verifique em sua caixa de spam.
    Em alguns casos, o envio do e-mail pode demorar alguns minutos.</h5>
                    :''
                    }

                    { 
                        configs == 3 ? 
                        <h5 style={{width: window.innerWidth <= 600 ? '90%':'80%', fontWeight: 400, marginTop: 10, textAlign: 'center', fontSize: window.innerWidth <= 600 ? 12 :13, marginBottom: window.innerWidth <= 600 ? 20:'0'}}>
                        Caso não tenha recebido o SMS verifique em sua caixa de mensagens. Em alguns casos, o envio de SMS pode demorar alguns minutos.</h5>
                    :''
                    }
                </div>
            </div>
        </>
    )
}
