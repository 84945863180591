import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import { BgImg, FilterContainer} from './styles'
import Footer from '../../components/Footer'
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour';
import StepEight from './stepEight';

export default function Offer() {
    const [step, setStep] = useState(1)
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [tipo, setTipo] = useState('')
    const [bairro, setBairro] = useState('')

    const [metragem, setMetragem] = useState('')
    const [quartos, setQuartos] = useState(0)
    const [suites, setSuites] = useState(0)
    const [dependencias, setDependencias] = useState(0)
    const [vagas, setVagas] = useState('')
    const [condominio, setCondominio] = useState('')

    const [valorImovel, setValorImovel] = useState('0')
    const [valorMercado, setValorMercado] = useState('0')
    const [comentario, setComentario] = useState('')

    const [email, setEmail] = useState('')
    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    
    const [block, setBlock] = useState('Apenas um bloqueio, null')  
    useEffect(() => {
        // if(!localStorage.getItem('id')){
        //     window.location.href="/login"
        // }
    }, [])

    async function handleRegister(valorDesejado, analise){
        let valueDesejado = valorImovel.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        valueDesejado = valueDesejado.substr(0, valueDesejado.length-2)+'.'+valueDesejado.substr(valueDesejado.length-2, 2)

        let valueMercado = valorMercado.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        valueMercado = valueMercado.substr(0, valueMercado.length-2)+'.'+valueMercado.substr(valueMercado.length-2, 2)

        let id_user = localStorage.getItem('id')
        
        let response = await api.post('/offer', {
            address: endereco,
            addressNumber : numero,
            addressComplement : complemento,
            type: tipo,
            landSizeUseful : metragem,
            bedrooms : quartos,
            suites, 
            bairro,
            dependencies : dependencias,
            parkingSpots : vagas,
            condominiumPrice : condominio,
            valueDesejado: valorDesejado ? valorDesejado : valueDesejado,
            valueMercado,
            analise: analise ? analise : 'Aceita',
            coment: comentario,
            id_user,
            email,
            nome,
            telefone,
            location: window.location.origin
        })
        
        if(response.data){
            if(analise == 'Negada'){
                return setStep(7)
            }
        }

        if(response.error){
            return setStep(7)
        }

    }

    return (
        <>
            <BgImg>
                <Header />
                <FilterContainer>
                    <div className="login-wrapper">
                        <div className="info-box">

                            {
                                step == 1 ? (
                                    <div className='info-box-2'>
                                        <h1>Prezado corretor</h1>
                                        <p>Sabe aquele imóvel que ninguém quer comprar, ou porque está com problema de documentação, ou porque está precisando de muita reforma, ou qualquer outro motivo? Preencha o formulário ao lado somente se o preço do seu imóvel estiver abaixo do valor de mercado.</p>
                                    </div>
                                ) : ''
                            }

                            {
                                step == 2 ? (
                                    <div className='info-box-2'>
                                        <h1>Você está a poucos passos de ofertar o seu imóvel!</h1>
                                        <p>Complete com os detalhes do imóvel.</p>
                                    </div>
                                ) : ''
                            }

                            {
                                step == 3 ? (
                                    <div className='info-box-2'>
                                        <h1>Quanto custa o seu imóvel?</h1>
                                        <p>Preencha os campos de valor desejado e valor de mercado.</p>
                                    </div>
                                ) : ''
                            }

                            {
                                step == 4 ? (
                                    <div className='info-box-2'>
                                        <h1>Estamos analisando sua oferta</h1>
                                        <p>Aguarde a resposta em instantes.</p>
                                    </div>
                                ) : ''
                            }
                            
                            {
                                step == 5 ? (
                                    <div className='info-box-2'>
                                        <h1>Já temos uma resposta para sua oferta!</h1>
                                    </div>
                                ) : ''
                            }

                            {
                                step == 6? (
                                    <div className='info-box-2'>
                                        <h1>Talvez possamos fechar negócio!</h1>
                                    </div>
                                ) : ''
                            }
                            
                            {
                                step == 7? (
                                    <div className='info-box-2'>
                                        <h1>Ainda não foi dessa vez!</h1>
                                    </div>
                                ) : ''
                            }

                            {
                                step == 8 ? (
                                    <div className='info-box-2'>
                                        <h1>Quanto custa o seu imóvel?</h1>
                                        <p>Preencha os campos de valor desejado e valor de mercado.</p>
                                    </div>
                                ) : ''
                            }
                            

                        </div>

                        <div className="register-box">

                            <div className="body-box">

                                <div className="steps-box">
                                    <div className="step-horizontal">
                                        <div className={step == 1 ? "step-circle" : "step-complete"}>1</div>
                                        
                                        {window.innerWidth <= 600 ? step == 1 ? 'Endereço' : '' :'Endereço'}
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 2 ? "step-circle" : step < 2 ? "step-disable" : "step-complete"}>2</div>
                                        
                                        {window.innerWidth <= 600 ? step == 2 ? 'Informação' : '' :'Informação'}
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 3 ? "step-circle" : step < 3 ? "step-disable" : "step-complete"}>3</div>
                                        
                                        {window.innerWidth <= 600 ? step == 3 ? 'Valor' : '' :'Valor'}
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 4 || step == 5 || step == 6 || step == 7 || step == 8 ? "step-circle" : step < 4 ? "step-disable" : "step-complete"}>4</div>
                                        
                                        {window.innerWidth <= 600 ? step == 4 ? 'Confirmação' : '' :'Confirmação'}
                                    </div>
                                </div>


                                {
                                    step == 1 ? 
                                        <StepOne step={step} block={block} setBlock={setBlock} setBairro={setBairro} setTipo={setTipo} tipoSelected={tipo} setStep={setStep} complemento={complemento} setComplemento={setComplemento} endereco={endereco} setEndereco={setEndereco} numero={numero} setNumero={setNumero} />
                                    : 
                                        step == 2 ?
                                            <StepTwo setStep={setStep} condominio={condominio} setCondominio={setCondominio} metragem={metragem} setMetragem={setMetragem} dependencias={dependencias} setDependencias={setDependencias} quartos={quartos} setQuartos={setQuartos} suites={suites} setSuites={setSuites} vagas={vagas} setVagas={setVagas} />
                                        :
                                            step == 3 ?  
                                                <StepThree setStep={setStep} setComentario={setComentario} comentario={comentario} setValorImovel={setValorImovel} valorImovel={valorImovel} setValorMercado={setValorMercado} valorMercado={valorMercado}/> 
                                            :
                                                step >= 4 && step !== 8 ?   
                                                    <StepFour setValorMercado={setValorMercado} setValorProposta={setValorImovel} setStep={setStep} step={step} valorMercado={valorMercado} valorProposta={valorImovel} handleRegister={handleRegister}/>
                                                :
                                                    step >= 8 ?
                                                        <StepEight handleRegister={handleRegister} setTelefone={setTelefone} telefone={telefone} setStep={setStep} setEmail={setEmail} email={email} setNome={setNome} nome={nome}/>
                                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </FilterContainer>
            </BgImg>

            <Footer />

        </>
    )
}
