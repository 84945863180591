import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

export default function AdminMarketingList() {
    const [data, setData] = useState([])
    const [algo, setAlgo] = useState('')
    const [acessos, setAcessos] = useState('')
    const [dias, setDias] = useState('')
    const [reEmail, setReEmail] = useState('')
    const [ordena, setOrdena] = useState('')
    const [lista, setListar] = useState('100')
    const [total, setTotal] = useState(0)

    const [page, setPage] = useState(0)
    const [lastPage, setLastPage] = useState(1)
    
    const [download, setDownload] = useState('')
        
    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        } 
        handleLoad()
    }, [])

    function cleanStates(){
        setAlgo('')
        setAcessos('') 
        setDias('')
        setListar('100')
        setReEmail('')
        setOrdena('')
        setTotal('')
    }

    async function handleLoad(){
        cleanStates()
        const response = await api.get(`/user?limit=${lista}`)
        
        setData(response.data.users)
        setLastPage(response.data.pages)
        setPage(0)
        setTotal(response.data.total)

    }

    function hasQuery(){
        let querys = `?`
        
        if(algo){
            querys+=`buscaLivre=${algo}&`
        }

        if(acessos && dias){
            querys+=`betweenDate=${dias}&acesso=${acessos}&`
        }

        if(reEmail){
            querys+=`receiveEmail=${reEmail}&`
        }

        if(ordena){
            querys+=`order=${ordena}&`
        }

        return querys
    }

    async function handleApply(){
        var querys = hasQuery()

        if(lista){
            switch(lista){
                case '5':
                    querys += `limit=5&`
                    break
                case '10':
                    querys += `limit=10&`
                    break
                case '15':
                    querys += `limit=15&`
                    break
                case '20':
                    querys += `limit=20&`
                    break
                case '50':
                    querys += `limit=50&`
                    break
                case '100':
                    querys += `limit=100&`
                    break
                case '500':
                    querys += `limit=500&`
                    break
                case '99999':
                    querys += `limit=99999&`
                    break
            }
        }
        
        const response = await api.get(`/user${querys}`)
        
        setData(response.data.users)
        setLastPage(response.data.pages)
        setTotal(response.data.total)
    }


    async function handleuUpdate(pages, e){
        var querys = hasQuery()
        setListar(e.target.value)
        const response = await api.get(`/user${querys}limit=${e.target.value}&page=${1}`)
        setTotal(response.data.total)
        if(response.data.users){
            setPage(0)
            setData(response.data.users)
            setLastPage(response.data.pages)
        }
    }

    async function handlePage(pages){
        var querys = hasQuery()
        const response = await api.get(`/user${querys}limit=${lista}&page=${pages.selected+1}`)
        setTotal(response.data.total)
        if(response.data.users){
            setPage(pages.selected)
            setData(response.data.users)
            setLastPage(response.data.pages)
        }
    }

    async function handleDelete(id){         
        if(window.confirm('Tem certeza que deseja excluir esse corretor?\nTodos os dados serão perdidos.')){
            let response = await api.delete(`/user/${id}`)

            if(response.data.deletedId){
                let vector = []
                data.map(e => {
                    if(e._id != id){
                        vector.push(e)
                    }
                })
                setData(vector)
            }
        }
    }

    function toDate(dateStr) {
        var parts = dateStr.split("T")[0].split('-');
        
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    // function print(){
    //     if(data.length == 0) return alert('Nenhum corretor')

    //     var style = "<style>"
    //         style = style + ".container {width: 100%; display: flex; flex-direction: column;}"
    //         style = style + ".row {page-break-before: always; width: 100%; display: flex; flex-direction: column; margin-top: 30px;}"
    //     style = style + "</style>"

    //     let win = window.open('', '', 'height=700, width=700')
    //     win.document.write('<html><head>')
    //     win.document.write('<title>Corretores</title>')
    //     win.document.write(style)
    //     win.document.write('</head>')
    //     win.document.write('<body><div class="container">')

    //     data.map(row => {
    //         win.document.write(`
    //             <div class="row">
    //                 ${row.name}<br>
    //                 ${row.email}<br>
    //                 CPF: ${row.cpf} - Creci: ${row.creci}<br>
    //                 Data Cadastro: ${toDate(row.createdAt)}<br>
    //                 ${row.lastAccess ? (
    //                     `Último acesso: ${toDate(row.lastAccess)}<br>`
    //                 ):''}
    //             </div>
    //         `)
    //     })        
        
    //     win.document.write(`</div></body></html>`)
    //     win.print()
    //     win.close()
    // }


    async function hasPrint(){//%20
        document.body.style.cursor = 'progress'
        var csvRow = []
        var A = [['Corretor', 'Email', 'CPF', 'CRECI', 'Data%20de%20Cadastro', 'Ultimo%20acesso', 'Telefone', 'Outro%20telefone']]
        var re = []
        const response = await api.get(`/user${hasQuery()}&limit=99999`)
        response.data.users.map(e=>{
            re.push(e)
        })

        for(var item=0; item < re.length; item++){
            A.push([
                String(re[item].name).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].email).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].cpf).replaceAll(' ', '%20').replaceAll('#39', ''), 
                String(re[item].creci).replaceAll(' ', '%20').replaceAll('#39', ''), 
                toDate(String(re[item].createdAt)).replaceAll(' ', '%20').replaceAll('#39', ''), 
                toDate(String(re[item].lastAccess)).replaceAll(' ', '%20').replaceAll('#39', ''),
                String(re[item]?.telephone[0]?re[item]?.telephone[0]:'').replaceAll(' ', '%20').replaceAll('#39', ''),
                String(re[item]?.telephone[1]?re[item]?.telephone[1]:'').replaceAll(' ', '%20').replaceAll('#39', '')
            ])
        }

        for(var i=0; i<A.length; ++i){
            csvRow.push(A[i].join(';'))
        }

        var csvString = csvRow.join('%0A')

        document.body.style.cursor = 'default'
        if(document.getElementById('a')){
            document.getElementById('a').href='data:attachment/csv,'+csvString
            setDownload(document.getElementById('a'))
            document.getElementById('a').click()

            return
        }

        var a=document.createElement("a")
        a.id='a'
        a.href='data:attachment/csv,'+csvString
        a.target='_Blank'
        a.download='corretores.csv'
        a.click()
        document.body.appendChild(a) 
    }

    function innerRow(row) {
        return (
            <>
                <div className="body-active">
                    <div>
                        <p style={{fontWeight: 600}}>{row.name}</p>
                        <p>Email: {row.email}</p>
                        <p>CPF: {row.cpf} - Creci: {row.creci}</p>
                        <p>Data Cadastro: {toDate(row.createdAt)}</p>
                        {row.lastAccess ? (
                            <p>Último acesso: {toDate(row.lastAccess)}</p>
                        ):''}
                    </div> 
                </div>
                <div className="body-imovel">
                    <div className="opt-left">
                        <p>{!row.receiveEmail ? row.receiveEmail? 'Sim':'Não' : 'Sim'}</p>
                    </div>
                </div>
                <div className="body-actions">
                    <div>
                        <a style={{fontSize: window.innerWidth <= 600 ? '2.5vh' :'1.3vw'}} onClick={e => window.location.href=`/admin/marketing/editar?id=${row._id}`}>Editar</a>
                    </div>
                    <div>
                        <a onClick={() => handleDelete(row._id)} style={{fontSize: window.innerWidth <= 600 ? '2.5vh' :'1.3vw'}} >Excluir</a>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        MARKETING > 
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Lista de Corretores
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Digite algo</label>
                        <input value={algo} onChange={e => setAlgo(e.target.value)}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Acessos</label>
                        <select value={acessos} onChange={e => setAcessos(e.target.value)}>
                            <option></option>
                            <option value={1}>Acessou o site nos últimos </option>
                            <option value={2}>Não acessa o site por mais de </option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Dias</label>
                        <input type='number' min='0' value={dias} onChange={e => setDias(e.target.value)}/>
                    </div>
                    <div className="input-group"> 
                        <label htmlFor="">Recebe e-mail</label>
                         <select value={reEmail} onChange={e => setReEmail(e.target.value)}>
                            <option></option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Ordenar por</label>
                         <select value={ordena} onChange={select => setOrdena(select.target.value)}>
                            <option value={''}>Ordenar por</option>
                            <option value={'a-z'}>Ordem Alfabética (A-Z)</option>
                            <option value={'z-a'}>Ordem Alfabética (Z-A)</option>
                            <option value={'recentes'}>Cadastros Mais Recentes</option>
                            <option value={'antigos'}>Cadastros Mais Antigos</option>
                            <option value={'acesso-recente'}>Data do último acesso (mais recente p/ mais antigo)</option>
                            <option value={'acesso-antigo'}>Data do último acesso (mais antigo p/ mais recente)</option>
                            <option value={'visualizou-mais'}>Quem viu mais imóveis</option>
                            <option value={'visualizou-menos'}>Quem viu menos imóveis</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Listar todos</label>
                        <select value={lista} onChange={select => handleuUpdate(page, select)}>
                            <option value='100'>Listar todos</option>
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                            <option value='99999'>1000+</option>
                        </select>
                    </div>
                    <div className="button-group">
                        <button onClick={handleLoad} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                    <p style={{width: '100%', textAlign: 'end'}}>A busca livre é feita no nome e e-mail do corretor. O número de dias inclui a data de hoje.</p>
                </div>

                <div style={{display: 'flex', flexDirection: window.innerWidth <= 600 ? 'column':'row', justifyContent: 'space-between', marginTop: 20, textAlign: window.innerWidth <= 600 ? 'center':'unset'}}>
                    <p style={{textAlign: window.innerWidth <= 600 ? 'center':'unset'}}>Foram encontrados {total} corretores.</p>
                    <a onClick={hasPrint} style={{color: '#00AEC8', textDecoration: 'none', cursor: 'pointer'}}>Fazer download da lista de corretores</a>
                </div>
                

                <div className="header" style={{marginTop: 30}}>
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ? 
                            <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                <div className="active">
                                    <h2>CORRETOR</h2>
                                </div>
                                <div className="imovel">
                                    <h2>RECEBE E-MAIL</h2>
                                </div>
                                <div className="engajamento">
                                    <h2>AÇÕES</h2>
                                </div>
                            </div>
                        :
                        <>
                            <div className="active">
                                <h2>CORRETOR</h2>
                            </div>
                            <div className="imovel">
                                <h2>RECEBE E-MAIL</h2>
                            </div>
                            <div className="engajamento">
                                <h2>AÇÕES</h2>
                            </div>
                        </>
                    } 
                </div>

                {
                    data.map(row => (
                        <div className="body">
                            <div className="body-cod">{row._id}</div>
                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row)}
                                    </div>    
                                :
                                <>
                                    {innerRow(row)}
                                </>
                            }
                        </div>
                    ))
                }


                <div className="paginate">
                    {/* <div onClick={r => handlePage(1)} className="firstDiv">primeiro</div>
                    <div onClick={r => handlePage(lastPage == page ? page-3 : page+1)}>{lastPage == page ? page-3 : page+1}</div>
                    <div onClick={r => handlePage(lastPage == page ? page-2 : page+2)}>{lastPage == page ? page-2 : page+2}</div>
                    <div onClick={r => handlePage(lastPage == page ? page-1 : page+3)}>{lastPage == page ? page-1 : page+3}</div>
                    <div onClick={r => handlePage(lastPage)} className="lastDiv">última</div> */}
                    <ReactPaginate
                        
                        pageCount={lastPage}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={e => handlePage(e)}
                        forcePage={page}
                        previousLabel={"Anterior"}
                        nextLabel={"Proximo"}
                        breakLabel={<a href="">...</a>}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
                </div>
            </Container>
        </>
    )
}