import React, {useEffect, useState} from 'react'
import { Container, Button } from './style'
import { Link } from 'react-router-dom'
import api from '../../api'


export default function AdminSideBar() {
    const [permission, setPermission] = useState('*')
    const [sidebar, setSidebar] = useState({})

    async function handleUser(){
        var {data} = await api.get(`/user/${localStorage.getItem('id_painel')}`)
        setPermission(data[0].permission)
        
        localStorage.setItem('type_user', data[0].type);
        localStorage.setItem('type', data[0].type);
        localStorage.setItem('id', data[0]._id);
        localStorage.setItem('id_painel', data[0]._id);
        localStorage.setItem('permission', data[0].permission)
    }
    
    useEffect(()=>{
        if(localStorage.getItem('permission'))
            setPermission(localStorage.getItem('permission'))
        handleUser()
    }, [])

    window.onscroll = () => {
        //setSidebar({height: window.innerWidth<=600? '100%': window.innerHeight > document.body.scrollHeight ? window.innerHeight-100: document.body.scrollHeight-100})
        setSidebar({position: 'fixed'})
    }

    if(localStorage.getItem('id_painel')){
        if(localStorage.getItem('type_user') == 'agent'){
            return (
                <Container id="sidebar-4" style={sidebar}>
                    {
                        window.innerWidth <= 600 ? 
                        (
                            <img onClick={() => {
                                let e = document.getElementById('sidebar-4')
                                e.style.display = 'none'
                            }} src={require('./menu.svg')} alt="" style={{position:'absolute',left:0, marginLeft: 15, marginTop: -8, cursor: 'pointer'}}/>
                        )
                        :
                        ''
                    }

                    <h1 style={{marginTop: window.innerWidth <= 600 ? 20:0}}>IMÓVEIS À VENDA</h1>
                    <div className="link-group">
                        {/* <Link to="/admin/propostas-corretor">Propostas de Compra Enviadas</Link> */}
                        <Link to="/admin/portfolio-corretor">Meu Portfólio (imóveis interessados)</Link>
                    </div>

                    <h1>COMPRAMOS SEU IMÓVEL</h1>
                    <div className="link-group">
                        <Link to="/admin/venda-corretor">Ofertas de Venda para Imóvel Vazio</Link>
                    </div>

                    <h1>CORRESPONDENTE BANCÁRIO</h1>
                    <div className="link-group">
                        <Link to="/admin/solicitacoes-corretor">Minhas Solicitações</Link>
                    </div>

                    <h1>MINHA CONTA</h1>
                    <div className="link-group">
                        <Link to="/admin/meu-perfil">Editar Meus Dados</Link>
                    </div>
                    <Button onClick={()=>{
                        window.location.href = '/'
                    }}>
                        Acessar o site
                    </Button>


                </Container>
            )
        }

        if(permission == 'only-marketing'){
            const urls = ["admin/marketing/listar", "admin/metricas-gerais", "admin/criacao-tag", "admin/marketing/editar", "admin/solicitacao-ofertas", "admin/ofertas/editar"];
            if(urls.filter(e => window.location.href.includes(e)).length == 0){
                return window.location.href = '/admin/marketing/listar'
            }

            return (

                <Container style={sidebar}>
                    {
                        window.innerWidth <= 600 ? 
                        (
                            <img onClick={() => {
                                let e = document.getElementById('sidebar-4')
                                e.style.display = 'none'
                            }} src={require('./menu.svg')} alt="" style={{position:'absolute',left:0, marginLeft: 15, marginTop: -8, cursor: 'pointer'}}/>
                        )
                        :
                        ''
                    }
                    <h1>COMPRAMOS SEU IMÓVEL</h1>
                    <div className="link-group">
                        <Link to="/admin/solicitacao-ofertas">Ofertas de Venda para Imóvel Vazio</Link>
                        {/* <Link to="/admin/tabela-avaliacao">Cadastro de Tabela de Avaliação</Link> BACKUP */}
                    </div>

                    <h1>MARKETING</h1>
                    <div className="link-group">
                        <Link to="/admin/marketing/listar">Lista de Corretores </Link>
                        <Link to="/admin/metricas-gerais">Métricas Gerais de Desempenho</Link>
                        <Link to="/admin/criacao-tag">Criação de Tags</Link>
                    </div>

                    <Button onClick={()=>{
                        window.location.href = '/'
                    }}>
                        Acessar o site
                    </Button>
                </Container>
            )
        }

        if(permission == 'only-banking'){
            if(!window.location.href.includes('admin/correspondente/listar') && !window.location.href.includes('admin/correspondente/negotiate')){
                return window.location.href = '/admin/correspondente/listar'
            }

            return (

                <Container style={sidebar}>
                    {
                        window.innerWidth <= 600 ? 
                        (
                            <img onClick={() => {
                                let e = document.getElementById('sidebar-4')
                                e.style.display = 'none'
                            }} src={require('./menu.svg')} alt="" style={{position:'absolute',left:0, marginLeft: 15, marginTop: -8, cursor: 'pointer'}}/>
                        )
                        :
                        ''
                    }
                    <h1>CORRESPONDENTE BANCÁRIO</h1>
                    <div className="link-group">
                        <Link to="/admin/correspondente/listar">Lista de Solicitações</Link>
                    </div>

                    <Button onClick={()=>{
                        window.location.href = '/'
                    }}>
                        Acessar o site
                    </Button>
                </Container>
            )
        }
    }

    return (
        
        <Container id="sidebar-4" style={sidebar}>
            {
                window.innerWidth <= 600 ? 
                (
                    <img onClick={() => {
                        let e = document.getElementById('sidebar-4')
                        e.style.display = 'none'
                    }} src={require('./menu.svg')} alt="" style={{position:'absolute',left:0, marginLeft: 15, marginTop: -8, cursor: 'pointer'}}/>
                )
                :
                ''
            }
            <h1>IMÓVEIS À VENDA</h1>
            <div className="link-group">
                <Link to="/admin/property/list">Anúncios Imóvel Vazio</Link>
                {/* <Link>Anúncios Corretores</Link> */}
                <Link to='/admin/ordenacao'>Ordenação de Imóveis</Link>
                {/* <Link to='/admin/propostas-admin'>Propostas Recebidas</Link> */}
            </div> 
            <h1>COMPRAMOS SEU IMÓVEL</h1>
            <div className="link-group">
                <Link to="/admin/solicitacao-ofertas">Ofertas de Venda para Imóvel Vazio</Link>
                {/* <Link to="/admin/tabela-avaliacao">Cadastro de Tabela de Avaliação</Link> BACKUP */}
            </div>
            <h1>CORRESPONDENTE BANCÁRIO</h1>
            <div className="link-group">
                <Link to="/admin/correspondente/listar">Lista de Solicitações</Link>
            </div>
            <h1>MARKETING</h1>
            <div className="link-group">
                <Link to="/admin/marketing/listar">Lista de Corretores </Link>
                <Link to="/admin/metricas-gerais">Métricas Gerais de Desempenho</Link>
                <Link to="/admin/criacao-tag">Criação de Tags</Link>
            </div>
            <Button onClick={()=>{
                window.location.href = '/'
            }}>
                Acessar o site
            </Button>
        </Container>
    )
}
