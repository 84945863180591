/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link,useHistory} from 'react-router-dom'
import crypto from 'crypto'

import { Container, ImageItem, PlusBtn, BtnWrapper, Carousel, DocItem, Item } from './styles'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import Picker from '../../components/picker'
import Autosuggest from '../../components/autosuggest'

import api from '../../api'
import { runInNewContext } from 'vm'

export default function AdminOfferPropertyEdit() {
    const [itemStart, setItemStart] = useState('')
    
    const [block, setBlock] = useState('Apenas um bloqueio, null')  
    const [bairro, setBairro] = useState('')   
    const [imageAlter, setImageAlter] = useState(false)   
    const [docAlter, setDocAlter] = useState(false)   
    const [pdfAlter, setPdfAlter] = useState(false)   
    const [cidade, setCidade] = useState('Rio de Janeiro')   

    const [iptu, setIptu] = useState('')   
    const [condominio, setCondominio] = useState('')   
    const [vendaImovel, setVendaImovel] = useState('')   
    const [descontoImovel, setDescontoImovel] = useState('')   

    const history = useHistory()

    const [citys, setCitys] = useState([])
    const [neighborhoods, setNeighborhoods] = useState([])
    const [generalPropertyType, setGeneralPropertyType] = useState([])
    const [types, setTypes] = useState([])
    const [tags, setTags] = useState([])
    const [teste, setteste] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [endereco, setEndereco] = useState('')

    const [ID, setID] = useState(1)
    const [property, setProperty] = useState({
        _id: null,
        address: null,
        addressNumber: null,
        addressComplement: null,
        neighborhood: null,
        showNumber: true,
        showComplement: true,
        city: null,
        state: null,
        IPTU: null,
        IPTUNumber: null,
        sellingPrice: null,
        descountPrice: null,
        condominiumPrice: null,
        sharedSale: false,
        sellingNotes: null,
        comission: null,
        type: 24,
        generalType: null,
        fullLandSize: null,
        bathrooms: 0,
        suites: 0,
        bedrooms: 0,
        parkingSpots: 0,
        features: [],
        tag: null,
        description: null,
        dependencies: 0,
        showTag: false,
        vendedor: null
    })

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }
        handleLoad()
    }, [])

    async function handleLoad(){
        var url   = window.location.search.replace("?", "");
        var items = url.split('&')
        setID(items[0].split('=')[1])
        const {data} = await api.get(`/property/${items[0].split('=')[1]}`)

        setBairro(data.neighborhood.name)

        data.images.sort((a, b)=>{
            if(a['order'] > b['order']){
                return 1
            }else{
                return -1
            }
        })

        console.log(data)

        // {
        //     id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        // },
        let imagens = []
        let docs = []
        data.images.map(image=>{
            imagens.push({
                id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                url: `${process.env.REACT_APP_API_URL}/file/${data._id}/fotos/${image.image}`,
                image: 'teste',
                name: image.image,
                show: image.show? image.show: "true",
                order: image.order
            })
        })

        data.documents.map(doc=>{
            docs.push({
                id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                url: `${process.env.REACT_APP_API_URL}/file/${data._id}/docs/${doc.name}`,
                image: 'teste',
                name: doc.name
            })
        })
        if(data.pdf){
            setPdf([{
                id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                url: `${process.env.REACT_APP_API_URL}/file/${data._id}/${data.pdf}`,
                image: 'teste',
                name: data.pdf
            }])
        }
        
        setImageCounter(imagens)
        setDocCounter(docs)
        setBlock(data.address)
        setProperty({
            ...property,
            address: data.address,
            addressNumber: data.addressNumber,
            addressComplement: data.addressComplement,
            IPTU: data.IPTU,
            IPTUNumber: data.IPTUNumber,
            bathrooms: data.bathrooms,
            bedrooms: data.bedrooms,
            suites: data.suites,
            dependencies: data.dependencies?data.dependencies:0,
            description: data.description,
            condominiumPrice: String(data.condominiumPrice).includes('.')? data.condominiumPrice:data.condominiumPrice+'00',
            features: data.features,
            sellingPrice: String(data.sellingPrice).includes('.')? data.sellingPrice : data.sellingPrice+'00',
            sellingNotes: data.sellingNotes,
            fullLandSize: data.fullLandSize,
            generalType: data.generalType? data.generalType._id : data.type.generalType,
            type: data.type,
            parkingSpots: data.parkingSpots,
            sharedSale: data.sharedSale,
            showComplement: data.showComplement != null ? data.showComplement : true,
            showNumber: data.showNumber != null ? data.showNumber : true,
            state: data.state,
            tag: data.tag?data.tag._id?data.tag._id:data.tag:data.tag,
            neighborhood: data.neighborhood._id,
            city: data.city._id,
            comission: data.comission,
            type: data.type._id,
            showTag: data.showTag,
            vendedor: data.vendedor
        })
        
        setCondominio(String(data.condominiumPrice).includes('.')? data.condominiumPrice:data.condominiumPrice+'00')
        setVendaImovel(String(data.sellingPrice).includes('.')? data.sellingPrice : data.sellingPrice+'00')
        setDescontoImovel(String(data.descountPrice).includes('.')? data.descountPrice : data.descountPrice+'00')
        setIptu(data.IPTU)
    }
    useEffect(() => {
        let body = document.querySelector('body')

        document.querySelector('#autocomplete').addEventListener('change', f => {
          if(window.innerWidth < 1000 || navigator.userAgent.toLowerCase().indexOf("mozilla") != -1){
            handleGamg()
          }
        })
        
        document.querySelector('#autocomplete').addEventListener('keydown', f => {
          if(f.key == 'Enter'){
            // alert('Selecione uma opção utilizando o mouse.') 
            handleGamg()
          }
        })

        if(document.querySelector('#scriptMap') && document.querySelector('#scriptInput')){
          body.removeChild(document.querySelector('#scriptMap'))
          body.removeChild(document.querySelector('#scriptInput'))
          body.removeChild(document.querySelector('.pac-container'))
        }
        
        var script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg'}&callback=initAutocomplete&libraries=places&v=weekly`
        script.setAttribute("defer", '')
        script.id='scriptMap'

        body.appendChild(script)


        script = document.createElement('script')
        script.innerHTML=`

        var placeSearch;
        var autocomplete;
        var componentForm = {
          street_number: "short_name",
          route: "long_name",
          locality: "long_name",
          administrative_area_level_1: "short_name",
          country: "long_name",
          postal_code: "short_name",
        };
        
        function initAutocomplete() {
          autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            { types: ["geocode"] }
          );
          autocomplete.setFields(["address_component"]);
          autocomplete.addListener("place_changed", fillInAddress);
        }
        
        function fillInAddress() {
          var place = autocomplete.getPlace();
        
          for (var component in componentForm) {
            document.getElementById(component).value = "";
            document.getElementById(component).disabled = false;
          }
        
          for (var component of place.address_components) {
            var addressType = component.types[0];
        
            if (componentForm[addressType]) {
              var val = component[componentForm[addressType]];
              document.getElementById(addressType).value = val;
            }
          }
        }
        
        function geolocate() {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              const circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy,
              });
              autocomplete.setBounds(circle.getBounds());
            });
          }
        }

        `

        script.id="scriptInput"
        body.appendChild(script)

        document.querySelector('#autocomplete').setAttribute("onFocus", "geolocate()")

        window.onload = () => {
            var target = document.querySelector('.pac-container');
            var observers = new MutationObserver(function(mutationsList, observer) {
              var targetItem = document.querySelectorAll('.pac-item');
              if(targetItem.length != 0){
                for(let i = 0; i < targetItem.length; i++){
                  targetItem[i].addEventListener('mousedown', e => handleChangeAddress(e.path, e))
                }
              }
            });
  
            observers.observe(target, {characterData: false, childList: true, attributes: false});
          }
  
          window.onclick = () => {
            var target = document.querySelector('.pac-container');
            var observers = new MutationObserver(function(mutationsList, observer) {
              var targetItem = document.querySelectorAll('.pac-item');
              if(targetItem.length != 0){
                for(let i = 0; i < targetItem.length; i++){
                  targetItem[i].addEventListener('mousedown', e => handleChangeAddress(e.path, e))
                }
              }
            });
  
            observers.observe(target, {characterData: false, childList: true, attributes: false});
          }
    }, [])

    function handleGamg(){
      let input = document.querySelector('#autocomplete').value

        if(input.split(',')[0].includes('-')){
            setBlock(input.split(' - ')[0])
            setBairro(input.split(',')[0].split(' - ')[1])
            setEndereco(input.split(',')[0].split(' - ')[0])
        }

        if(input.includes('Niterói')){
            setteste(true)
            setteste(false)
            setCidade('Niterói')
        }else{
            setteste(false)
            setteste(true)
            setCidade('Rio de Janeiro')
        }

        // setBlock(input.split(' - ')[0])
    }

    function handleChangeAddress(target, e) {
        let path = target.filter(e => {
          return e.className == 'pac-item'
        })
        path = path[0].innerHTML.split('>')
        let first

        if(path.length == 15){
            first = path[4].split('<')[0]+', '+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]+path[11].split('<')[0]+path[12].split('<')[0]
        }else if(path.length == 13){
            first = path[4].split('<')[0]+', '+path[7].split('<')[0]+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]
        }else if(path.length > 9){
            first = path[4].split('<')[0]+', '+path[8].split('<')[0]+path[9].split('<')[0]
        }else{
            first = path[4].split('<')[0] +path[5].split('<')[0]+', '+path[7].split('<')[0]
        }

        setEndereco(first)
        if(first.split(',').length == 4){
            let bairro
            if(path.length == 15){
                first = path[4].split('<')[0]+' - '+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]+path[11].split('<')[0]+path[12].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }else if(path.length == 13){
                first = path[4].split('<')[0]+' - '+path[7].split('<')[0]+path[8].split('<')[0]+path[9].split('<')[0]+path[10].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }else if(path.length > 9){
                first = path[4].split('<')[0]+' - '+path[8].split('<')[0]+path[9].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }else{
                first = path[4].split('<')[0] +path[5].split('<')[0]+' - '+path[7].split('<')[0]
                bairro = String(first).split(',')[0].split(' - ')[1]
            }

            setBairro(bairro)
            setEndereco(first)
        }else{
            let bairro = String(first).split(',')[0]
            setBairro(bairro)
        }

        if(first.includes('Niterói')){
            setteste(true)
            setteste(false)
            setCidade('Niterói')
        }else{
            setteste(false)
            setteste(true)
            setCidade('Rio de Janeiro')
        }
        setBlock(path[4].split('<')[0] +path[5].split('<')[0])
      }

      useEffect(() => {
          setProperty({...property, address: endereco})
          getNeighborhood()
      }, [teste])

    useEffect(() => {
        getCity()

        api.get('/propertyType').then(res => {
            setTypes(res.data.propertyTypes)
        })

        api.get('/generalPropertyType').then(res => {
            setGeneralPropertyType(res.data.generalPropertyTypes)
        })

        api.get('/tag').then(res => setTags(res.data.tags))

    }, [])

    useEffect(()=>{
        if(types.length > 1){
            setProperty({
                ...property,
                type : property.type
            })
        }

        if(generalPropertyType.length > 1){
            setProperty({
                ...property,
                generalType: property.generalType
            })
        }
    },[property.type, types, generalPropertyType])

    
    async function getCity() {
        await api.get('/city?stateName=Rio de Janeiro').then((res) => {
            setCitys(res.data.citys)
        })
    }

    async function getNeighborhood(param) {
        setConfirm(false)
        await api.get(`/neighborhood?limit=10000&city=${teste?'7043':'6989'}`).then(res => {
            res.data.neighborhoods.map(item => {
                // if(item._id == 33624){
                //     console.log(item.name.split(' ')[0].toUpperCase())
                //     console.log(bairro.toUpperCase().normalize("NFD").replace(/[^a-zA-Zs]/g, ""))
                // }
                if(item.name.normalize("NFD").replace(/[^a-zA-Zs]/g, "").toUpperCase() == bairro.toUpperCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "") || bairro.toUpperCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "").includes(item.name.split(' ')[0].toUpperCase())){
                    setProperty({...property, neighborhood: item._id})
                    setConfirm(true)
                }
            })
            setNeighborhoods(res.data.neighborhoods)
        })

    }

    function handleOrdenation(event){
        var itemEnd = event.target.closest("div div").id
        if(itemEnd == itemStart) return

        let array = []

        imageCounter.map((f, index)=>{ 
            if(itemEnd < itemStart){
                if(index == itemEnd){
                    array.push(imageCounter[itemStart])
                    array.push(f)
                }else if(index == itemStart){
                    
                }else{
                    array.push(f)
                }
            }else{
                if(index == itemStart){
                }else if(index == itemEnd){
                    array.push(f)
                    array.push(imageCounter[itemStart])
                }else{
                    array.push(f)
                }
            }
        })

        setImageCounter(array)

    }

    
    function sendDocs() {
        const filtered = docCounter.filter(value => {
            if (value.image && value.image != 'teste') {
                return value
            }
        })

        let formData

        const images = filtered.map(value => {
  
            formData = new FormData()
            formData.append('property', ID)
            formData.append('file', value.file)

            api.post('/file/doc', formData).then(res => {
                let docs = []
                res.data.documents.map(doc=>{
                    docs.push({
                        id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                        url: `${process.env.REACT_APP_API_URL}/file/${res.data._id}/docs/${doc.name}`,
                        image: 'teste',
                        name: doc.name
                    })
                })
                
                setDocCounter(docs)
            }).catch(err => {
                alert('Falha ao inserir documentos,tente novamente mais tarde')
            })
        })

        docCounter.map(e => {
            e.image='teste'
        })

    }
    function sendPdf() {
        const filtered = pdf.filter(value => {
            if (value.image && value.image != 'teste') {
                return value
            }
        })

        let formData
        const images = filtered.map(value => {
  
            formData = new FormData()
            formData.append('property', ID)
            formData.append('file', value.file) 

            api.post('/file/pdf', formData).then(res => { 
                setPdf([{
                    id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                    url: `${process.env.REACT_APP_API_URL}/file/${res.data._id}/${res.data.pdf}`,
                    image: 'teste',
                    name: res.data.pdf
                }])
            }).catch(err => {
                alert('Falha ao inserir documentos,tente novamente mais tarde')
            })
        })
        if(filtered.length != []){
            pdf.map(e => {
                e.image='teste'
            })
            
        }
    }

    async function handleSaveOrder(){
        var {data} = await api.post(`/property/ordenation/${ID}`,{
            images: imageCounter
        })
    }

    async function create() {
        handleSaveOrder()

        var first = document.querySelector("#autocomplete").value
        var end = first.split(',')[0]
        if(String(first).split('-').length > 2){
            end = String(first).split(',')[0].split(' - ')[0]
        }

        var obj = property
        delete obj['_id']
        obj['sellingPrice'] = !String(vendaImovel).includes('undefined') ? vendaImovel:''
        obj['descountPrice'] = !String(descontoImovel).includes('undefined') ? descontoImovel:''
        obj['IPTU'] = !String(iptu).includes('undefined') ? iptu:''
        obj['condominiumPrice']= !String(condominio).includes('undefined') ? condominio:''

        Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
        
        obj['address'] = end
        
        if(obj['sellingPrice'] == '.' || obj['sellingPrice'] == '.00' || obj['sellingPrice'] == '00'){
            obj['sellingPrice'] = ''
        }

        if(obj['descountPrice'] == '.' || obj['descountPrice'] == '.00' || obj['descountPrice'] == '00'){
            obj['descountPrice'] = ''
        }

        if(obj['IPTU'] == '.' || obj['IPTU'] == '.00' || obj['IPTU'] == '00'){
            obj['IPTU'] = ''
        }

        if(obj['condominiumPrice'] == '.' || obj['condominiumPrice'] == '.00' || obj['sellicondominiumPricengPrice'] == '00'){
            obj['condominiumPrice'] = ''
        }

        if(!String(obj['sellingPrice']).includes('.')){
            obj['sellingPrice']=obj['sellingPrice'].substr(0, obj['sellingPrice'].length-2)
        }
        if(!String(obj['descountPrice']).includes('.')){
            obj['descountPrice']=obj['descountPrice'].substr(0, obj['descountPrice'].length-2)
        }
        if(!String(obj['condominiumPrice']).includes('.')){
            obj['condominiumPrice']=obj['condominiumPrice'].substr(0, obj['condominiumPrice'].length-2)
        }
        if(!String(obj['IPTU']).includes('.') && String(obj['IPTU']) != 'undefined'){
            obj['IPTU']=obj['IPTU'].substr(0, obj['IPTU'].length-2)
        }

        if(!confirm && block.toUpperCase() != obj['address'].toUpperCase()){
            delete obj['address']
            delete obj['city']
        }

        if(!obj['neighborhood']){
            return alert('Preencha o endereço corretamente (Trabalhamos apenas no Rio de Janeiro).')
        }
        
        if(obj['bathrooms'] != null && obj['bedrooms'] != null && obj['suites'] != null  && obj['fullLandSize'] != null && obj['parkingSpots'] != null && obj['dependencies'] != null && obj['city'] && obj['addressNumber'] && obj['condominiumPrice'] && obj['IPTU']){// && obj['features'].length > 0  && obj['IPTUNumber'] && obj['tag']){
            api.put('/property/'+ID, obj).then(res => {
                setProperty({
                    ...property,
                    _id: res.data._id
                })
                
                alert('Imovel atualizado com sucesso')
            }).catch(err => {
                console.log(err)
                return alert('Falha ao atualizar imovel,tente novamente mais tarde')
            })
        }else{
            alert('Preencha os campos em vermelho.')
            if(!String(obj['sellingPrice']).includes('.')){
                obj['sellingPrice']=obj['sellingPrice']+'00'
            }
            if(!String(obj['condominiumPrice']).includes('.')){
                obj['condominiumPrice']=obj['condominiumPrice']+'00'
            }
            if(!String(obj['IPTU']).includes('.')){
                obj['IPTU']=obj['IPTU']+'00'
            }
            let inputs = document.querySelectorAll('.input-compose')
            
            !obj['addressNumber'] ? inputs[1].style.border = '2px solid red' : inputs[1].style.border = '0px solid red'
            obj['condominiumPrice'] == '00' ? inputs[6].style.border = '2px solid red' : inputs[6].style.border = '0px solid red'
            obj['IPTU'] == '00' ? inputs[7].style.border = '2px solid red' : inputs[7].style.border = '0px solid red'
            obj['sellingPrice'] == '00' ? inputs[9].style.border = '2px solid red' : inputs[9].style.border = '0px solid red'
            
            // !obj['IPTUNumber'] ? inputs[7].style.border = '2px solid red' : inputs[7].style.border = '0px solid red'
            // !obj['features'].length > 0 ? document.querySelector('.sc-eirseW').style.border = '2px solid red' : document.querySelector('.sc-eirseW').style.border = '0px solid red'
            // !obj['tag'] ? inputs[19].style.border = '2px solid red' : inputs[19].style.border = '0px solid red'
            
            !obj['fullLandSize'] && obj['fullLandSize'] != '0' ? inputs[14].style.border = '2px solid red' : inputs[14].style.border = '0px solid red'
            !obj['bedrooms'] && obj['bedrooms'] != '0' ? inputs[15].style.border = '2px solid red' : inputs[15].style.border = '0px solid red'
            !obj['suites'] && obj['suites'] != '0'  ? inputs[16].style.border = '2px solid red' : inputs[16].style.border = '0px solid red'
            !obj['bathrooms'] && obj['bathrooms'] != '0' ? inputs[17].style.border = '2px solid red' : inputs[17].style.border = '0px solid red'
            !obj['dependencies'] && obj['dependencies'] != '0' ? inputs[18].style.border = '2px solid red' : inputs[18].style.border = '0px solid red'
            !obj['parkingSpots'] && obj['parkingSpots'] != '0' ? inputs[19].style.border = '2px solid red' : inputs[19].style.border = '0px solid red'
            
            !obj['generalType'] ? inputs[12].style.border = '2px solid red' : inputs[12].style.border = '0px solid red'
            !obj['type'] ? inputs[13].style.border = '2px solid red' : inputs[13].style.border = '0px solid red'

            !obj['address'] ? inputs[0].style.border = '2px solid red' : inputs[0].style.border = '0px solid red'
            !obj['city'] ? inputs[0].style.border = '2px solid red' : inputs[0].style.border = '0px solid red'
        }
    }

    useEffect(() => {
        if (property.city && !property.neighborhood) {
            getNeighborhood()
        }
    }, [property.city])

    useEffect(() => {
        if (property._id) {
            window.location.href='/admin/property/list'
        }
        
    }, [property._id])


    const [imageCounter, setImageCounter] = useState([
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
            order: null
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
            order: null
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
            order: null
        }
    ])

    const [docCounter, setDocCounter] = useState([
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        }
    ])

    const [pdf, setPdf] = useState([
        {
            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
        },
    ])

    function addImage(files, id) {
        if (!files.length) {
            return null;
        }
        if(files.length == 1){
            const checkbox = document.querySelector(`input[name=checkbox-${id}]`)
    
            const url = URL.createObjectURL(files[0])
            
            const value = imageCounter.find(value => value.id === id)
            const clear = imageCounter.filter(value => value.id !== id)
            
            value.image = files[0]
            value.url = url
            value.show = checkbox.checked
            value.name = null
            
            setImageCounter([
                ...clear,
                value
            ])
            
        }else{
            let arrayImage = []
            imageCounter.map(row => {
                if(row.image != null){
                    arrayImage.push(row)
                }
            })

            for(let i = 0; i < files.length; i++){
                const url = URL.createObjectURL(files[i])
                arrayImage.push({
                    id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                    image: files[i],
                    url: url,
                    show: true,
                    name: null,
                })
            }

            setImageCounter(arrayImage)
        }

        setImageAlter(!imageAlter)
    }

    function removeImage(id) {
        const remove = imageCounter.filter((value) => value.id === id)
        // console.log(remove[0])
        if(remove[0].name){
            api.post('/file/remove', { data: remove[0], id: ID})

            console.log(remove)
        }
        const removed = imageCounter.filter((value) => value.id !== id)
        setImageCounter(removed)
    }

    function editImageStatus(id, status) {
            const edited = imageCounter.map((value) => {
                if (value.id === id) {
                        api.post('/file/changeShow', { status: `${status}`, name: value.name, id: ID})
                    value.show = `${status}`
                }
                return value
            })
            
            setImageCounter(edited)
            alert('Espere 1-2 segundos para atualizar outra imagem.')
    }

    function addDoc(files, id) {

        if (!files.length) {
            return null;
        }

        if(files.length == 1){
            const url = URL.createObjectURL(files[0])

            const value = docCounter.find(value => value.id === id)
            const clear = docCounter.filter(value => value.id !== id)

            value.file = files[0]
            value.url = url
            value.image = files[0]

            setDocCounter([
                ...clear,
                value
            ])
        }else{
            let arrayDocs = []
            docCounter.map(row => {
                if(row.image != null){
                    arrayDocs.push(row)
                }
            })

            for(let i = 0; i < files.length; i++){
                const url = URL.createObjectURL(files[i])
                arrayDocs.push({
                    id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                    image: files[i],
                    url: url,
                    file: files[i],
                })
            }

            setDocCounter(arrayDocs)
        
        }

        setDocAlter(!docAlter)
    }
    
    function addPdf(files, id) { 
        if (!files.length) {
            return null;
        }
        const url = URL.createObjectURL(files[0])

        const value = pdf.find(value => value.id === id)
        const clear = pdf.filter(value => value.id !== id)

        value.file = files[0]
        value.url = url
        value.image = files[0]

        setPdf([
            ...clear,
            value
        ])

        setPdfAlter(!pdfAlter)
    }
    function removePdf(id) {
        const remove = pdf.filter((value) => value.id === id)
        if(remove[0].name){
            api.post('/file/pdfRemove', { pdf: remove[0].name, id: ID})

            console.log(remove)
        }
        const removed = pdf.filter((value) => value.id !== id)
        if(removed.length == 0){
            setPdf([{id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')}])
            return
        }
        setPdf(removed)
    }

    function removeDoc(id) {
        const remove = docCounter.filter((value) => value.id === id)
        if(remove[0].name){
            api.post('/file/docRemove', { data: remove[0], id: ID})

            console.log(remove)
        }
        const removed = docCounter.filter((value) => value.id !== id)
        setDocCounter(removed)
    }

    useEffect(() => {
        async function sendImages() {
            const filtered = imageCounter.filter(value => {
                if (value.image && value.image != 'teste') {
                    return value
                }
            })
            
            let formData
    
            for(var i = 0;i<filtered.length;){
      
                formData = new FormData()
                formData.append('show', filtered[i].show)
                formData.append('property', ID)
                formData.append('file', filtered[i].image)
    
                const res = await api.post('/file/image', formData)
                let imagens = []
                res.data.images.map(image=>{
                    imagens.push({
                        id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex'),
                        url: `${process.env.REACT_APP_API_URL}/file/${res.data._id}/fotos/${image.image}`,
                        image: 'teste',
                        name: image.image,
                        show: image.show? image.show: "true",
                        order: image.order
                    })
                })
        
                setImageCounter(imagens)
                i++

            }
    
            imageCounter.map(e => {
                e.image='teste'
            })
        }

        sendImages()
    }, [imageAlter])
    useEffect(() => sendDocs(), [docAlter])
    useEffect(() => sendPdf(), [pdfAlter])

    function moneyToNumber(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }

    function money (value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Anúncios Corretores >
                    </Link>
                    <Link to={window.location.pathname} className="where">
                        Novo
                    </Link>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Endereço (sem número)</label>
                        <input id="autocomplete" autoComplete="off" type="text" value={property.address} onChange={(e) => {
                                setProperty({
                                    ...property,
                                    address: e.target.value
                                })
                            }
                        } />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Número</label>
                        <input type="text" value={property.addressNumber} onChange={(e) => setProperty({
                            ...property,
                            addressNumber: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Complemento</label>
                        <input type="text" value={property.addressComplement} onChange={(e) => setProperty({
                            ...property,
                            addressComplement: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="select-group">
                        <input type="checkbox" name="" id="" checked={!property.showNumber} onChange={(e) => setProperty({
                            ...property,
                            showNumber: !e.target.checked
                        })} />
                        <span>Não desejo exibir o número no anúncio</span>
                    </div>
                    <div className="select-group">
                        <input type="checkbox" name="" id="" checked={!property.showComplement} onChange={(e) => setProperty({
                            ...property,
                            showComplement: !e.target.checked
                        })} />
                        <span>Não desejo exibir o complemento no anúncio</span>
                    </div>
                </div>
                <div className="input-group" style={{ marginTop: '30px' }}>
                    <div className="input-compose">
                        <label htmlFor="">Cidade</label>
                        <Autosuggest values={cidade} onChange={(e) => {
                            setProperty({
                                ...property,
                                city: e._id
                            })
                        }}
                            objs={citys}
                        />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Estado</label>
                        <Autosuggest onChange={(e) => {
                            setProperty({
                                ...property,
                                state: e
                            })

                        }}
                            objs={[
                                { name: 'Rio de Janeiro' }
                            ]}
                            defaultValue={'Rio de Janeiro'}
                        />
                    </div>
                </div>
                <div className="input-group" >
                    <div className="input-compose">
                        <label htmlFor="">Bairro</label>
                        <Autosuggest values={bairro} onChange={(e) => {
                            setProperty({
                                ...property,
                                neighborhood: e._id
                            })
                        }}
                            objs={neighborhoods}
                        />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Valor do condomínio</label>
                        <input type="text" value={money(condominio)} onChange={(e) => setCondominio(moneyToNumber(e.target.value))} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">IPTU Valor</label>
                        <input type="text" value={money(iptu)} onChange={(e) => setIptu(moneyToNumber(e.target.value))} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">IPTU Numero</label>
                        <input type="text" value={property.IPTUNumber} onChange={(e) => setProperty({
                            ...property,
                            IPTUNumber: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Valor de venda do imóvel</label>
                        <input type="text" value={money(String(vendaImovel))}
                            onChange={(e) => {
                                setVendaImovel(moneyToNumber(e.target.value))
                            }}

                        />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Valor de venda com desconto</label>
                        <input type="text" value={money(String(descontoImovel))}
                            onChange={(e) => {
                                setDescontoImovel(moneyToNumber(e.target.value))

                                if(Number(moneyToNumber(e.target.value)) > Number(moneyToNumber(money(vendaImovel)))){
                                    setDescontoImovel(vendaImovel)
                                    return alert('O desconto não pode ser maior que o valor original.')
                                }
                            }}

                        />
                    </div>
                </div>
                <div className="share">
                    <div className="text">
                        <div className="row">
                            <input type="checkbox" name="" id="" value={property.sharedSale} onChange={(e) => setProperty({
                                ...property,
                                sharedSale: e.target.checked
                            })} />
                            <h1> Você deseja acelerar a venda deste imóvel com a ajuda de outros corretores? </h1>
                        </div>
                        <p>Vamos dar um exemplo para explicar melhor como funciona este recurso. Se você está ganhando 6% por esta venda, você pode oferecer parte desta comissão para outro corretor caso ele te ajude a vender, como por exemplo 3%.
                        A comissão compartilhada é uma escolha sua. </p>
                    </div>
                    <div className="column">
                        <div className="input-compose">
                            <p>Qual a comissão compartilhada em %?</p>
                            <input type="text" disabled={!property.sharedSale} value={property.comission} onChange={(e) => setProperty({
                                ...property,
                                comission: e.target.value
                            })} />
                        </div>
                        <textarea
                            placeholder="Escreva as instruções de venda"
                            value={property.sellingNotes} onChange={(e) => setProperty({
                                ...property,
                                sellingNotes: e.target.value
                            })}
                        ></textarea>
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Residencial ou comercial</label>
                        <select value={property.generalType} onChange={(e) => setProperty({
                            ...property,
                            generalType: e.target.value != 'Selecione' ? e.target.value:null
                        })}>
                            <option value="Selecione">Selecione</option>
                            {
                                generalPropertyType.map(value => (
                                    <option value={value._id}>{value.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Tipo</label>
                        <select 
                        value={property.type}
                        onChange={(e) => {
                            setProperty({
                                ...property,
                                type: e.target.value
                            })
                        }}
                        >

                            {
                                types.map(value => (
                                    <option value={value._id} key={value._id}>{value.name}</option>
                                ))
                            }

                        </select>
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Metragem (m²)</label>
                        <input type="number" value={property.fullLandSize} onChange={(e) => setProperty({
                            ...property,
                            fullLandSize: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Total quartos (Exceto dependências)</label>
                        <input type="number" value={property.bedrooms} onChange={(e) => setProperty({
                            ...property,
                            bedrooms: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Sendo quantas Suites?</label>
                        <input type="number" value={property.suites} onChange={(e) => setProperty({
                            ...property,
                            suites: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Total de banheiros (Exceto dependências)</label>
                        <input type="number" value={property.bathrooms} onChange={(e) => setProperty({
                            ...property,
                            bathrooms: e.target.value
                        })} />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-compose">
                        <label htmlFor="">Total de dependências de serviço</label>
                        <input type="number" value={property.dependencies} onChange={(e) => setProperty({
                            ...property,
                            dependencies: e.target.value
                        })} />
                    </div>
                    <div className="input-compose">
                        <label htmlFor="">Número de vagas</label>
                        <input type="number" value={property.parkingSpots} onChange={(e) => setProperty({
                            ...property,
                            parkingSpots: e.target.value
                        })} />
                    </div>
                </div>
                <h1>Selecione todas as características deste imóvel</h1>
                <Picker value={property.features} setValue={(value) => setProperty({...property, features: value})} />
                <div className="tag" style={{ marginTop: '30px' }}>
                    <h2>Escolha uma TAG que melhor represente esta oportunidade</h2>
                    <p>Esta TAG será aplicada eventualmente no seu anúncio para destacá-lo.</p>
                    <div className="input-group">
                        <div className="input-compose">
                            <label htmlFor="">TAG</label>
                            <select value={property.tag} onChange={(e) => setProperty({
                                ...property,
                                tag: e.target.value != 'Selecione' ? e.target.value:'nulle'
                            })}>
                                <option>Selecione</option>
                                {
                                    tags.map(value => (
                                        <option value={value._id} key={value._id}>{value.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="select-group" style={{marginBottom: 20, marginTop: -5}}>
                        
                        <input type="checkbox" name="" id="" checked={property.showTag} onChange={(e) => {
                            setProperty({
                            ...property,
                            showTag: e.target.checked
                        })}} />
                        <span style={{marginLeft: 10}}>Marque para forçar a aplicação desta tag no imóvel</span>
                    </div>
                    <div className="input-group">
                        <div className="input-compose">
                            <label htmlFor="">Para finalizar, descreva o seu imóvel (opcional)</label>
                            <textarea name="" id="" cols="30" rows="6" value={property.description} onChange={(e) => {setProperty({
                                ...property,
                                description: e.target.value
                            });}}></textarea>
                        </div>
                    </div>
                </div>
                <h2>Adicione fotos e documentos.  Se preferir, pode adicionar posteriormente.</h2>

                <Carousel 
                    style={{ marginTop: '30px' }}
                >
                    {
                        imageCounter.map((value, index) => (
                            <Item key={index} draggable={true} id={index} onDragOver={e => e.preventDefault()} onDragStart={f=>setItemStart(f.target.id)} onDropCapture={handleOrdenation}>
                                <ImageItem htmlFor={`image-${value.id}`} image={value.url ? `'${value.url}'` : ''} className="image-container">
                                    <input multiple='multiple' disabled={value.image == 'teste'?true:false} type="file" name="image" id={`image-${value.id}`} onInput={(e) => addImage(e.target.files, value.id)} />
                                    <button className="close" show={value.image ? 'true' : undefined} onClick={() => {
                                        if(value.image == 'teste'){
                                            if(window.confirm('A imagem será deletada, deseja continuar?')){
                                                removeImage(value.id)
                                            }
                                        }else{
                                            removeImage(value.id)
                                        }
                                        }}>
                                        x
                                    </button>
                                </ImageItem>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <input type="checkbox" name={`checkbox-${value.id}`} id="" defaultChecked checked={value.show == 'false'? false:true} onChange={(e) => {
                                        editImageStatus(value.id, e.target.checked)
                                    }} />
                                    <label htmlFor="" style={{ fontWeight: 'normal' }}>Exibir no anúncio </label>
                                </div>
                            </Item>
                        ))
                    }
                    <BtnWrapper>
                        <PlusBtn onClick={() => setImageCounter([...imageCounter, {
                            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
                        }])}>
                            <img src={require('../../assets/adminOfferProperty/plus.svg')} alt="" />
                        </PlusBtn>
                    </BtnWrapper>
                </Carousel>
                <Carousel
                    style={{ marginTop: '30px' }}
                >
                    {
                        docCounter.map((value, index) => (
                            <Item key={index}>
                                <DocItem htmlFor={`doc-${value.id}`} image={value.file || value.image == 'teste' ? require('../../assets/adminOfferProperty/docok.svg') : ''} className="doc-container">
                                    <input multiple='multiple' type="file" disabled={value.image == 'teste'?true:false} name="doc" id={`doc-${value.id}`} onInput={(e) => addDoc(e.target.files, value.id)} />
                                    <button className="close" show={value.file ? 'true' : 'false'} onClick={() => {
                                        if(value.image == 'teste'){
                                            if(window.confirm('O documento será deletado, deseja continuar?')){
                                                removeDoc(value.id)
                                            }
                                        }else{
                                            removeDoc(value.id)
                                        }
                                    }}>
                                        x
                                    </button>
                                </DocItem>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <label htmlFor="" style={{ fontWeight: 'normal' }}>{value.file && value.image != 'teste' ? value.file.name : ''}{!value.file && value.image == 'teste' ? value.name : ''}</label>
                                </div>
                            </Item>
                        ))
                    }
                    <BtnWrapper>
                        <PlusBtn onClick={() => setDocCounter([...docCounter, {
                            id: crypto.createHash('sha256').update(Math.random().toString()).digest('hex')
                        }])}>
                            <img src={require('../../assets/adminOfferProperty/plus.svg')} alt="" />
                        </PlusBtn>
                    </BtnWrapper>
                </Carousel>
                <Carousel
                    style={{ marginTop: '30px' }}
                >
                    {
                        pdf.map((value, index) => (
                            <Item key={index}>
                                <DocItem htmlFor={`doc-${value.id}`} image={value.file || value.image == 'teste' ? require('../../assets/adminOfferProperty/docok.svg') : ''} className="doc-container">
                                    <input type="file" disabled={value.image == 'teste'?true:false} name="doc" id={`doc-${value.id}`} onInput={(e) => addPdf(e.target.files, value.id)} />
                                    <button className="close" show={value.file ? 'true' : 'false'} onClick={() => {
                                        if(value.image == 'teste'){
                                            if(window.confirm('O documento será deletado, deseja continuar?')){
                                                removePdf(value.id)
                                            }
                                        }else{
                                            removePdf(value.id)
                                        }
                                    }}>
                                        x
                                    </button>
                                </DocItem>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <label htmlFor="" style={{ fontWeight: 'normal' }}>{value.file && value.image != 'teste' ? value.file.name : ''}{!value.file && value.image == 'teste' ? value.name : ''}</label>
                                </div>
                            </Item>
                        ))
                    } 
                </Carousel>

                <h2>Vendedor</h2>
                <h4 style={{marginBottom: 20}}>Preencha para exibir na lista de imóveis vendidos</h4>

                <div className="input-group" >
                    <div className="input-compose" style={{width: window.innerWidth <= 600 ? '100%' : "50%"}}>
                        <label htmlFor="">Nome do vendedor</label>
                        <input value={property.vendedor} onChange={(e) => setProperty({
                            ...property,
                            vendedor: e.target.value
                        })} />
                    </div>
                </div>

                <div className="save">
                    <button onClick={create}>
                        Salvar
                    </button>
                </div>
            </Container>
        </>
    )
}
