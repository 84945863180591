import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'

export default function Interested() {
    const [data, setData] = useState([])

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }
        handleLoad()
    }, [])

    async function handleLoad(){
        let ID = new URL(window.location).searchParams.get('id')
         
        const {data} = await api.get(`/interested/${ID}`)
        
        setData(data)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} onClick={e=>e.preventDefault()} className="where">
                        Anúncios Imóvel Vazio > Corretores Interessados
                    </Link>
                </div>
                
                <div className="header">
                    <div className="cod" style={{width: '8%'}}>
                        <h2>SEQ.</h2>
                    </div>
                    <div className="active" style={{width: '82%'}}>
                        <h2>CORRETORES INTERESSADOS / ADICIONARAM ESTE IMÓVEL EM SEUS PORTÓLIOS</h2>
                    </div>
                </div>

                {
                    data.map((row, index) => (
                        <div className="body" style={{height: 60}}>
                            <div className="body-cod" style={{width: '8%'}}>{index+1}</div>
                            <div className="body-active" style={{width: '40%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17, fontWeight: 600}}>
                                {row.user? row.user.name:row.name}
                            </div>
                            <div className="body-imovel" style={{width: '52%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17}}>
                                <div>Email: {row.user? row.user.email:row.email}</div>
                                <div>{row.user? row.user.telephone[0]:row.telephone}{row.user? row.user.telephone[1] ? ` - ${row.user.telephone[1]}`:'':''}</div>
                            </div>
                        </div>
                    ))
                }

            </Container>
        </>
    )
}