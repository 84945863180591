import styled from 'styled-components'

export const FooterBase = styled.footer`
    width:100vw;
    padding:60px;
    display:flex;
    flex-wrap:wrap;

    @media(max-width:600px){
        padding:20px;
        width:100vw;
    }

    .links{
        width:60%;  
        padding-left:60px;
        display:flex;
        flex-wrap:wrap;

        @media(max-width:600px){
            padding-left:0px;
            width:100vw;
        }

        .imovel{
        display:flex;
        flex-direction:column;
        margin-right:40px;

        @media(max-width:600px){
            width:50%;
            margin-right:0px;
        }

        h4{
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            margin-bottom:10px;
        }

        a{
            text-decoration:none;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            margin-bottom:10px;
            color: #1E1D1D;
        }

        
    }

    .politicas{
        display:flex;
        flex-direction:column;

        @media(max-width:600px){
            width:50%;
            margin-right:0px;
        }

        h4{
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            margin-bottom:10px;
        }

        a{
            text-decoration:none;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            margin-bottom:10px;
            color: #1E1D1D;
        }

        .advertise{
            margin-top:20px;

            // @media(max-width:600px){
            //     margin-top:82px;
            // }
        }
        
        
    }

}

    .contact{
        width:40%;
        @media(max-width:600px){
            width:100%;
        }
        .box{
            width:100%;
            max-width:421px;
            background: #ECECEC;
            border-radius: 8px;
            padding:20px;

            .header{
                display:flex;

                img{
                    margin-right:20px;
                }

                h6{
                    font-size: 14px;
                    line-height: 16px;
                    /* identical to box height */

                    letter-spacing: 0.05em;

                    color: #1E1D1D;
                }
            }

            .input-group{
                margin-top:10px;
                width:100%;
                display:flex;
                flex-direction:column;
                padding:5px;
                background: #FFFFFF;
                opacity: 0.7;
                border-radius: 4px;
                label{
                    font-size: 14px;
                    line-height: 14px;
                    margin-left:0px;
                    letter-spacing: 0.05em;
                    color: #1E1D1D;
                }
                input{
                    border:none;
                    border-bottom:1px solid #C4C4C4;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    /* identical to box height */

                    letter-spacing: 0.05em;
                }

                textarea{
                    height: 128px;
                    border:none;
                    border-bottom:1px solid #C4C4C4;
                    resize:none;
                    padding-top:5px;
                }
            }

            button{
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.05em;

                color: #EA5C1E;
                width:100%;
                height: 38px;
                border: 1px solid #EA5C1E;
                border-radius: 4px;
            }
        }
    }

    
`