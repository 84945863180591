import React, { useState } from 'react'
import { Container, Image,Card,Header, Button } from './style'
import Slider from "react-slick"

export default function PhotosModal({ id, images,setModal }) {
    const [index, setIndex] = useState('0')
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    // {value.show == 'true'? false:true}
    async function handleDownload(){
        var item = {
            url: `${window.location.origin}/file/${id}/fotos/${images[index].image}`
        }
        
        /*fetch(item.url).then( async (result) =>{
            var blob = await result.blob();// recuperandoo um blob para baixar
            var anchor = window.document.createElement('a');
         
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = item.url.split('/').pop();
            anchor.click();
            window.URL.revokeObjectURL(anchor.href);
        });*/
        window.open(`${window.location.origin}/d?url=${item.url}`, '_blank')
    }

    return (
        <Container >
            <Header>
                <Button onClick={handleDownload} >Baixar Foto</Button>
                <h1 onClick={()=> setModal(false)} >&times;</h1>
            </Header>
            <Card>
                <Slider afterChange={e=>setIndex(e)} {...settings} className="slider">
                    {
                        images.map((value,index) => {
                            if(value.show == 'true' || value.show == undefined){
                                return (
                                    // <img style={{objectFit: window.innerWidth > 600 ? 'contain': 'fill', imageRendering: window.innerWidth <= 600 ? 'pixelated':'auto'}} key={index} src={`${process.env.REACT_APP_API_URL}/file/${id}/fotos/${value.image}`} height={500}  alt=""/>
                                    <img style={{objectFit: 'contain'}} key={index} src={`${process.env.REACT_APP_API_URL}/file/${id}/fotos/${value.image}`} height={500}  alt=""/>
                                    )
                            }
                    })
                    }
                </Slider>
            </Card>
        </Container>
    )
}
