import React, {useEffect, useState} from 'react'
import '../../global.css';
import api from '../../api'
import InputMask from "react-input-mask";

export default function StepOne({setStep, metragem, setMetragem, quartos, setQuartos, suites, setSuites, dependencias, setDependencias, vagas, setVagas, condominio, setCondominio}) {

    function handleNext(){
        console.log(condominio)
        if(condominio == '' || condominio == 'R$ 0,00' || condominio == '.00'){
            setCondominio('0.00')
        }
        console.log(condominio)
        
        if(metragem && vagas) {// && !condominio.includes('R$ 0,00') && condominio != '.00'){
            setStep(3)
        }else{
            let errors = document.querySelectorAll('.input-box')
            if(!metragem){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }
            if(!vagas){
                errors[4].style.border = "2px solid red"
                styleBorderError(errors[4])
            }
            /*if(!condominio || condominio.includes('R$ 0,00') || condominio == '.00'){
                errors[5].style.border = "2px solid red"
                styleBorderError(errors[5])
            }*/
        }
    }

    function styleBorderError(object){
        setTimeout(() => {
            object.style.border = "none"
        }, 3000);
    }

    function money (value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    function moneyToNumber(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        value = value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
        console.log(value)
        return value
    }
    
    return (
        <>  
            <div className="step">
                <div className="container-input-box">
                    <div className="input-box">
                        <small>Metragem no IPTU (m2)</small>
                        <InputMask onKeyDown={e => e.key == '-'? e.preventDefault():''} onChange={text => setMetragem(text.target.value)} value={metragem}/>
                    </div>
                    <div className="input-box">
                        <small>Total Quartos (Exceto dependências)</small>
                        <InputMask min={0} type="number" onKeyDown={e => e.key == '-'? e.preventDefault():''} value={quartos}  onChange={text => setQuartos(text.target.value)} />
                    </div>
                </div>

                <div className="container-input-box">
                    <div className="input-box">
                        <small>Sendo quantas Suites?</small>
                        <InputMask min={0} type="number" onChange={text => setSuites(text.target.value)} onKeyDown={e => e.key == '-'? e.preventDefault():''}  value={suites}/>
                    </div>
                    <div className="input-box">
                        <small>Quantas dependências de serviço?</small>
                        <input min={0} type="number" value={dependencias} onKeyDown={e => e.key == '-'? e.preventDefault():''} onChange={text => setDependencias(text.target.value)}/>
                    </div>
                </div>
                <div className="container-input-box">
                    <div className="input-box">
                        <small>Número de Vagas?</small>
                        <InputMask min={0} type="number" onChange={text => setVagas(text.target.value)} value={vagas} onKeyDown={e => e.key == '-'? e.preventDefault():''} />
                    </div>
                    <div className="input-box">
                        <small>Valor do Condomínio?</small>
                        <InputMask type="text" value={money(condominio)} onChange={text => setCondominio(moneyToNumber(text.target.value))} />
                    </div>
                </div>

                <div className="container-next info">
                    <div className='infos'>
                        <strong>Confirme e avance:</strong><br/>
                        {quartos} quarto(s), sendo {suites} suíte(s) e {dependencias} dependência(s).
                    </div>
                    <button className="btBack" onClick={click => setStep(1)}>Voltar</button>
                    <button onClick={handleNext}>Avançar</button>
                </div>
            </div>
        </>
    )
}
