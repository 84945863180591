import React, {useEffect, useState} from 'react'
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'
import InputMask from "react-input-mask";

export default function StepOne({setStep, setNome, nome, setCpf, cpf, setCreci, creci}) {

    function handleNext(){
        if(nome.length >= 3 && cpf.length == 14 && validaCpfCnpj(cpf) && creci.length >= 3){
            setStep(2)
        }else{
            let errors = document.querySelectorAll('.input-box')
            if(nome.length < 3){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }
            if(cpf.length != 14 || !validaCpfCnpj(cpf)){
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }
            
            if(creci.length < 3){
                errors[2].style.border = "2px solid red"
                styleBorderError(errors[2])
            }
        }
    }
    function styleBorderError(object){
        setTimeout(() => {
            object.style.border = "none"
        }, 3000);
    }
    function validaCpfCnpj(val) {
        if (val.length == 14) {
            var cpf = val.trim();
         
            cpf = cpf.replace(/\./g, '');
            cpf = cpf.replace('-', '');
            cpf = cpf.split('');
            
            var v1 = 0;
            var v2 = 0;
            var aux = false;
            
            for (var i = 1; cpf.length > i; i++) {
                if (cpf[i - 1] != cpf[i]) {
                    aux = true;   
                }
            } 
            
            if (aux == false) {
                return false; 
            } 
            
            for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
                v1 += cpf[i] * p; 
            } 
            
            v1 = ((v1 * 10) % 11);
            
            if (v1 == 10) {
                v1 = 0; 
            }
            
            if (v1 != cpf[9]) {
                return false; 
            } 
            
            for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
                v2 += cpf[i] * p; 
            } 
            
            v2 = ((v2 * 10) % 11);
            
            if (v2 == 10) {
                v2 = 0; 
            }
            
            if (v2 != cpf[10]) {
                return false; 
            } else {   
                return true; 
            }
        } else if (val.length == 18) {
            var cnpj = val.trim();
            
            cnpj = cnpj.replace(/\./g, '');
            cnpj = cnpj.replace('-', '');
            cnpj = cnpj.replace('/', ''); 
            cnpj = cnpj.split(''); 
            
            var v1 = 0;
            var v2 = 0;
            var aux = false;
            
            for (var i = 1; cnpj.length > i; i++) { 
                if (cnpj[i - 1] != cnpj[i]) {  
                    aux = true;   
                } 
            } 
            
            if (aux == false) {  
                alert("cnpj invalido")
                return false; 
            }
            
            for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
                if (p1 >= 2) {  
                    v1 += cnpj[i] * p1;  
                } else {  
                    v1 += cnpj[i] * p2;  
                } 
            } 
            
            v1 = (v1 % 11);
            
            if (v1 < 2) { 
                v1 = 0; 
            } else { 
                v1 = (11 - v1); 
            } 
            
            if (v1 != cnpj[12]) {  
                alert("cnpj invalido")
                return false; 
            } 
            
            for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
                if (p1 >= 2) {  
                    v2 += cnpj[i] * p1;  
                } else {   
                    v2 += cnpj[i] * p2; 
                } 
            }
            
            v2 = (v2 % 11); 
            
            if (v2 < 2) {  
                v2 = 0;
            } else { 
                v2 = (11 - v2); 
            } 
            
            if (v2 != cnpj[13]) {   
                alert("cnpj invalido")
                return false; 
            } else {  
                return true; 
            }
        } else {
            return false;
        }
     }
    
    return (
        <>  
            <div className="step">
                <div className="input-box">
                    <small>Nome</small>
                    <input value={nome}  onChange={text => setNome(text.target.value)} placeholder="Ex.: João Silva" autoCapitalize/>
                </div>

                <div className="container-input-box">
                    <div className="input-box">
                        <small>CPF</small>
                        <InputMask mask="999.999.999-99" onChange={text => setCpf(text.target.value)} value={cpf} placeholder="Ex.: 000.000.000-00"/>
                    </div>
                    <div className="input-box">
                        <small>CRECI</small>
                        <InputMask value={creci}  onChange={text => setCreci(text.target.value)} />
                    </div>
                </div>

                <div className="container-next">
                    <button onClick={handleNext}>Avançar</button>
                </div>
            </div>
        </>
    )
}
