import React, { useState, useEffect } from 'react';
import { FooterBase } from './styles';
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

function Footer() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [context, setContext] = useState('')
    const [menuActive, setMenuActive] = useState(true)

    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search)
        if(searchParams.has('app')){
            setMenuActive(false)
        }
    }, [])

    async function handleEmail(){
        if(name.length > 2 && email.length > 4){
            const {data} = await api.post('/sendEmail', {
                content: `
                    <h2>Nome: ${name}</h2>
                    <h2>Email: ${email}</h2>
                    <h3>${context}</h3>
                `
            })
            if(data){
                alert('Sua mensagem foi enviada. Responderemos o mais breve possível')
            }
        }
    }

    return (
        <>
        {menuActive? 
            (
            <>
                <hr style={{ background: '#C3C8C9', marginLeft: '40px', marginRight: '40px' }} />

                <FooterBase>
                    <div className="links">
                        <div className="imovel">
                            <h4>Imóvel Vazio</h4>
                            <Link onClick={e => window.scrollTo(0, 0)} to="/ofertar-imovel">Compramos seu Imóvel</Link>
                            <Link onClick={e => window.scrollTo(0, 0)} to="/imoveis">Imóveis à Venda</Link>
                            {/* <Link>Para Corretores </Link> */}
                            <Link onClick={e => window.scrollTo(0, 0)} to="/correspondente-bancario">Correspondente Bancário</Link>
                            <Link onClick={e => window.scrollTo(0, 0)} to="/sobre-nos">Sobre Nós</Link>
                            <a href="https://blog.imovelvazio.com.br/">Blog</a>
                            <Link onClick={e => window.scrollTo(0, 0)} to="/contato">Contato</Link>
                            {/* <div className="socials" style={{ marginTop: '20px' }}>
                                <a href="" style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/home/fbicon.svg')} alt="" />
                                </a>
                                <a href="" style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/home/linkedin.svg')} alt="" />
                                </a>
                                <a href="" style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/home/insta.svg')} alt="" />
                                </a>
                            </div> */}
                            <div className="downloads" style={{ marginTop: '20px', marginBottom: '20px', display: "flex", flexDirection: "row" }}>
                                <a style={{marginRight: window.innerWidth <= 600 ? 4:20}}>
                                    <img style={{width: window.innerWidth <= 600 ? (window.innerWidth / 2) - 22 : '' }} src={require('../../assets/home/appstore.svg')} alt="" />
                                </a>
                                
                                <a className="advertise">
                                    <img style={{width: window.innerWidth <= 600 ? (window.innerWidth / 2) - 22 : '' }} src={require('../../assets/home/playstore.svg')} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="politicas">
                            <h4>Políticas</h4>
                            <Link onClick={e => window.scrollTo(0,0)} to="/politica-de-privacidade">Política de Privacidade</Link>
                            <Link onClick={e => window.scrollTo(0,0)} to="/politica-de-uso">Política de Uso do Corretor de Imóveis</Link>
                            <Link onClick={e => window.scrollTo(0,0)} to="/termos-e-condicoes">Termos e Condições de Uso Imóvel Vazio</Link>
                            {/* <Link style={{color: "white", cursor: 'default'}}>.</Link>
                            <Link style={{color: "white", cursor: 'default'}}>.</Link> */}
                        </div>
                    </div>
                    <div className="contact">
                        <div className="box">
                            <div className="header">
                                <img src={require('../../assets/home/contactUs.svg')} alt="" />
                                <div>
                                    <h6>Atendimento</h6>
                                    <p>Deixe seu contato.</p>
                                </div>
                            </div>

                            <form action="">
                                <div className="input-group">
                                    <label htmlFor="">Nome</label>
                                    <input value={name} onChange={e=>setName(e.target.value)} type="text" placeholder="Ex.:João Silva" />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="">Email</label>
                                    <input value={email} onChange={e=>setEmail(e.target.value)} type="text" placeholder="Ex.:joao.silva@email.com.br" />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="">Mensagem</label>
                                    <textarea value={context} onChange={e=>setContext(e.target.value)} cols="30" rows="10" placeholder="Digite aqui..."></textarea>
                                </div>

                                <button onClick={handleEmail} type="submit">
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>
                
                </FooterBase>
                
                </>)
            :
            <></>
            }
        </>
    );
}

export default Footer;