import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import socketClient from "socket.io-client"

export default function AdminCorrespondenteList() {
    const [data, setData] = useState([])
    const [status, setStatus] = useState('false')
    const [FGTS, setFGTS] = useState('')
    const [regime, setRegime] = useState('')
    const [socket, setSocket] = useState(null)
    const [imovelProprio, setImovelProprio] = useState('')
    const [cancel, setCancel] = useState(Axios.CancelToken.source())
    const [newMessage, setNewMessage] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }
        
        
        setSocket(socketClient(process.env.REACT_APP_API_URL, {query: {
            user: localStorage.getItem('id_painel'), 
            type: localStorage.getItem('type_user')
        }}))
        
        reloadMessages()

        handleLoad()
    }, [])
    
    const reloadMessages = async () => {
        setTimeout(async () => {
            await codeNewMessage()
        }, 500)
    }
    
    
    useEffect(() => {
        if(socket != null){ 
            socket.on('listMessages', data => {
                reloadMessages()
            })
        }
    }, [socket])

    // window.onfocus = () => {
    //     reloadMessages()
    // }   
    
    async function codeNewMessage(){
        var response = await api.get(`/banking?message=only-new&type=agent&cod=1`)
        if(response.data.length > 0){
            setNewMessage(true)
        }else{
            setNewMessage(false)
        }
    }

    function clean(){ 
        setCancel(Axios.CancelToken.source())
    }
    function cleanStates(){
        setImovelProprio('')
        setFGTS('')
        setRegime('')
        setStatus('')
    }
    
    async function handleNewMessages(){
        let query = ''
        query+=`message=only-new&type=agent&cod=2`
        document.body.style.cursor = 'wait'
        const {data} = await api.get(`/banking?${query}`)
        
        if(data.length == 0){
            setNewMessage(false)
        }else{
            setData(data)
        }
        cancel.cancel()
        clean()
        document.body.style.cursor = 'default'
    }


    async function handleLoad(){
        cleanStates()
        await codeNewMessage()

        const response = await api.get(`/banking?limit=99999`, {
            cancelToken: cancel.token
        })

        let array = []

        response.data.Banking.map(e=>{
            e.financiamento=String(e.financiamento).includes('.')? e.financiamento:e.financiamento+'00'
            e.valorCompra=String(e.valorCompra).includes('.')? e.valorCompra:e.valorCompra+'00'
            e.rendaBruta=String(e.rendaBruta).includes('.')? e.rendaBruta:e.rendaBruta+'00'
            array.push(e)
        })
        
        array.sort((a, b) => {
            if(a.status == 'Novo'){
                return -1
            }
        })

        setData(array)

    }

    async function handleApply(){
        let querys = `?`

        if(FGTS){
            querys += `FGTS=${FGTS}&`
        }

        if(imovelProprio){
            querys += `imovelProprio=${imovelProprio}&`
        }

        if(regime){
            querys += `contratacao=${regime}&`
        }

        if(status){
            querys += `status=${status}&`
        }

        if(querys == '?'){
            return
        }
        let {data} = await api.get(`/banking${querys}&limit=10000`)
        
        setData(data)
    }

    function removeMoney(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }

    function money(value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }
    
    async function handleDelete(id){
        let {data} = await api.delete('/banking/'+id)
        if(data.deletedId){
            window.location.reload()
        }
    }

    function innerRow(row, index) {
        return (
            <>
                <div className="body-active">
                    <div>
                        <a>{row.idUser?row.idUser.name:row.name}</a>
                    </div>
                    <div>
                        <a>{row.idUser?row.idUser.email:row.email}</a>
                    </div>
                    <div>
                        <a>{row.idUser?row.idUser.telephone[0]:row.telephone}</a>
                    </div>
                </div>
                <div className="body-imovel">
                    <div className="opt-left">
                        <a>
                            <img src={require(`./icons/${row.FGTS?'success.svg':'error.svg'}`)} />
                            Uso do FGTS
                        </a>
                        <a>
                            <img src={require(`./icons/${row.imovelProprio?'success.svg':'error.svg'}`)} />
                            Imóvel próprio
                        </a>
                        <a>
                            <img src={require(`./icons/${row.imovelNovo?'success.svg':'error.svg'}`)} />
                            Imóvel Novo
                        </a>
                    </div>
                    <div className="opt-right">
                        <a>Nasc.: {row.dateBorn}</a>
                        <a>{row.contratacao}</a>
                        <a>Renda:  {money(row.rendaBruta)}</a>
                    </div>
                </div>
                <div className="body-engajamento">
                    <div>
                        <a style={{textAlign: 'center'}}>Compra/Venda: {money(row.valorCompra)}</a>
                    </div>
                    <div>
                        <a style={{textAlign: 'center'}}>Financiamento : {money(row.financiamento)}</a>
                    </div>
                </div>
                <div className="body-stats">
                    <div style={{flexDirection:'column'}}>
                        <a style={{textAlign: 'center', cursor: 'default', fontSize: '12px'}}>{`Cadastrado:`}</a>
                        <a style={{textAlign: 'center', marginTop: 8, cursor: 'default', fontSize: '12px'}}>{`${row.createdAt}`}</a>
                    </div>
                    <div style={{borderTop: '1px solid #aaa'}}>
                        <a style={{cursor: 'default', fontSize:  window.innerWidth <= 600 ? '3vh':'1.2vw', color: '#EB5C1C'}}>{row.status? row.status:'Novo'}</a>
                    </div>
                </div>
                <div className="body-actions">
                    <div>
                        <a style={{fontSize: window.innerWidth <= 600 ? '3vh':'1.3vw'}} onClick={e => window.location.href=`/admin/correspondente/negotiate?id=${row._id}`}>Negociar</a>
                    </div>
                    <div>
                        <a style={{fontSize: window.innerWidth <= 600 ? '3vh':'1.3vw'}} onClick={e=>handleDelete(row._id)}>Excluir</a>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        CORRESPONDENTE BANCÁRIO > 
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Lista de Solicitações
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Uso do FGTS</label>
                        <select value={FGTS} onChange={e => setFGTS(e.target.value)}>
                            <option></option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                    <div className="input-group">
                        
                        <label htmlFor="">Imóvel Próprio</label>
                         <select value={imovelProprio} onChange={e => setImovelProprio(e.target.value)}>
                            <option></option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Regime de Contratação</label>
                         <select value={regime} onChange={select => setRegime(select.target.value)}>
                            <option></option>
                            <option value={'CLT'}>CLT</option>
                            <option value={'Autônomo'}>Autônomo</option>
                            <option value={'Servidor Público'}>Servidor Público</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Status</label>
                        <select value={status} onChange={select => setStatus(select.target.value)}>
                            <option></option>
                            <option>Novo</option>
                            <option>Analisando</option>
                            <option>Concluído</option>
                            <option>Não concluído</option>
                            <option>Reprovado</option>
                            <option>Aprovado</option>
                        </select>
                    </div>
                    <div className="button-group">
                        {
                            newMessage ?
                                <button style={{inlineSize:'max-content', marginRight: window.innerWidth <= 600?0:8, marginBottom : window.innerWidth <= 600? 15:0, color: '#31AA26', border: '1px solid #31AA26'}} onClick={handleNewMessages} className="outline btn-green">Novas Mensagens</button>
                            :''
                        }
                        <button onClick={handleLoad} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                </div>

                <div className="header" style={{marginTop: 30}}>
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ? 
                            <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                <div className="active">
                                    <h2>CORRETOR</h2>
                                </div>
                                <div className="imovel">
                                    <h2>COMPRADOR</h2>
                                </div>
                                <div className="engajamento">
                                    <h2>VALORES</h2>
                                </div>
                                <div className="stats">
                                    <h2>STATUS</h2>
                                </div>
                                <div className="actions">
                                    <h2>AÇÕES</h2>
                                </div>
                            </div>
                        :
                        <>
                            <div className="active">
                                <h2>CORRETOR</h2>
                            </div>
                            <div className="imovel">
                                <h2>COMPRADOR</h2>
                            </div>
                            <div className="engajamento">
                                <h2>VALORES</h2>
                            </div>
                            <div className="stats">
                                <h2>STATUS</h2>
                            </div>
                            <div className="actions">
                                <h2>AÇÕES</h2>
                            </div>
                        </>
                    }
                </div>

                {
                    data.map((row, index) => (
                        <div className="body">
                            <div className="body-cod">{row._id}</div>
                            
                            
                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row, index)}
                                    </div>    
                                :
                                <>
                                    {innerRow(row, index)}
                                </>
                            }
                        </div>
                    ))
                }

            </Container>
        </>
    )
}