import React, { useEffect, useState } from 'react'

export default function Download({ id, images}) {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('url');
        if(myParam){
            handleDownload(myParam)

            setTimeout(() => {
                window.close()
            }, 8000);
        }else{
            window.location.href='/'
        }
        
    }, [])
    async function handleDownload(url){
        var item = {
            url: `${url}`
        }
        
        fetch(item.url).then(async (result) =>{
            var blob = await result.blob();// recuperandoo um blob para baixar
            var anchor = window.document.createElement('a');
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = item.url.split('/').pop();
            anchor.click();
            window.URL.revokeObjectURL(anchor.href);
        });
    }

    return <></>
}
