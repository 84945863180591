import React, { useEffect, useState } from "react";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import {
  Container,
  Carousel,
  PlusBtn,
  BtnWrapper,
  DocItem,
  Item,
  ItemLink,
} from "./styles";
import api from "../../api";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputMask from "react-input-mask";
import crypto from "crypto";
import socketClient from "socket.io-client";

export default function Negotiate() {
  const [socket, setSocket] = useState(null);
  const [status, setStatus] = useState("false");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [telefone, setTelefone] = useState("");
  const [ID, setID] = useState("");
  //Etapa 1
  const [dataN, setDataN] = useState("");
  const [FGTS, setFGTS] = useState("");
  const [imovelNovo, setImovelNovo] = useState(false);
  const [regime, setRegime] = useState("");
  const [imovelProprio, setImovelProprio] = useState("");
  const [compra, setCompra] = useState("");
  const [renda, setRenda] = useState("");
  const [financiamento, setFinanciamento] = useState("");
  const [comentario, setComentario] = useState("");

  const [controlUser, setControlUser] = useState("");
  //Etapa 2
  const [cpfs, setCpfs] = useState({ cpf1: "", cpf2: "", cpf3: "", cpf4: "" });
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [docAlter4, setDocAlter4] = useState(false);
  const [docAlter3, setDocAlter3] = useState(false);
  const [docStep4, setDocStep4] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      block: true,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      block: true,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      block: true,
    },
  ]);

  const [docStep3, setDocStep3] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      block: true,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      block: true,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      block: true,
    },
  ]);

  async function handleMessage(text) {
    var msg = text ? text : message;

    let mess = messages.filter((e) => e);
    mess.push({
      message: msg,
      user_message: localStorage.getItem("id_painel"),
      type: localStorage.getItem("type_user"),
      Banking: ID,
    });
    setMessage("");
    setMessages(mess);

    if (message.length > 0 || text) {
      var { data } = await api.post(`/chatBanking/`, {
        message: msg,
        user_message: localStorage.getItem("id_painel"),
        type: localStorage.getItem("type_user"),
        Banking: ID,
        location: window.location.href,
        email,
        createdAt:
          new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
      });

      if (data) {
        setMessages(data);
      }
    }
  }

  function removeDoc(id, step) {
    if (step == 4) {
      const remove = docStep4.filter((value) => value.id === id);
      if (remove[0].name) {
        api.post("/file/removeCorrespondente", {
          data: remove[0],
          id: ID,
          step,
        });
      }
      const removed = docStep4.filter((value) => value.id !== id);
      setDocStep4(removed);
    } else {
      const remove = docStep3.filter((value) => value.id === id);
      if (remove[0].name) {
        api.post("/file/removeCorrespondente", {
          data: remove[0],
          id: ID,
          step,
        });
      }
      const removed = docStep3.filter((value) => value.id !== id);
      setDocStep3(removed);
    }
  }
  function addDoc(files, id, step) {
    if (!files.length) {
      return null;
    }

    if (files.length == 1) {
      const url = URL.createObjectURL(files[0]);
      let value;
      let clear;
      if (step == 4) {
        value = docStep4.find((value) => value.id === id);
        clear = docStep4.filter((value) => value.id !== id);
      } else {
        value = docStep3.find((value) => value.id === id);
        clear = docStep3.filter((value) => value.id !== id);
      }
      value.file = files[0];
      value.url = url;
      value.image = files[0];
      value.block = null;

      if (step == 4) {
        setDocStep4([...clear, value]);
      } else {
        setDocStep3([...clear, value]);
      }
    } else {
      let arrayDocs = [];
      if (step == 4) {
        docStep4.map((row) => {
          if (row.image != null) {
            arrayDocs.push(row);
          }
        });
      } else {
        docStep3.map((row) => {
          if (row.image != null) {
            arrayDocs.push(row);
          }
        });
      }

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayDocs.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          file: files[i],
        });
      }
      if (step == 4) {
        setDocStep4(arrayDocs);
      } else {
        setDocStep3(arrayDocs);
      }
    }

    if (step == 4) {
      setDocAlter4(!docAlter4);
    } else {
      setDocAlter3(!docAlter3);
    }
  }

  function sendDocs(step) {
    if (step == 4) {
      let block = docStep4.filter((e) => {
        return e.block == true;
      });
      if (block.length == docStep4.length) {
        return;
      }
    } else {
      let block = docStep3.filter((e) => {
        return e.block == true;
      });
      if (block.length == docStep3.length) {
        return;
      }
    }

    let filtered = [];
    if (step == 4) {
      filtered = docStep4.filter((value) => {
        if (value.image && value.image != "teste") {
          return value;
        }
      });
    } else {
      filtered = docStep3.filter((value) => {
        if (value.image && value.image != "teste") {
          return value;
        }
      });
    }

    let formData;

    const images = filtered.map((value) => {
      formData = new FormData();
      formData.append("correspondente", ID);
      formData.append("step", step == 4 ? "passo4" : "passo3");
      formData.append("file", value.file);

      api
        .post("/file/docCorrespondente", formData)
        .then((res) => {
          let docs = [];

          if (step == 4) {
            res.data.step4.map((doc) => {
              docs.push({
                id: crypto
                  .createHash("sha256")
                  .update(Math.random().toString())
                  .digest("hex"),
                url: `${process.env.REACT_APP_API_URL}/file/correspondentes/${res.data._id}/passo4/${doc.name}`,
                image: "teste",
                name: doc.name,
              });
            });
            setDocStep4(docs);
          } else {
            res.data.step3.map((doc) => {
              docs.push({
                id: crypto
                  .createHash("sha256")
                  .update(Math.random().toString())
                  .digest("hex"),
                url: `${process.env.REACT_APP_API_URL}/file/correspondentes/${res.data._id}/passo3/${doc.name}`,
                image: "teste",
                name: doc.name,
              });
            });
            setDocStep3(docs);
          }
        })
        .catch((err) => {
          alert("Falha ao inserir documentos,tente novamente mais tarde");
        });
    });

    if (step == 4) {
      docStep4.map((e) => {
        e.image = "teste";
      });
    } else {
      docStep3.map((e) => {
        e.image = "teste";
      });
    }
  }

  useEffect(() => sendDocs(3), [docAlter3]);
  useEffect(() => sendDocs(4), [docAlter4]);
  useEffect(() => {
    if (
      !localStorage.getItem("type_user") &&
      !localStorage.getItem("id_painel")
    ) {
      window.location.href = "/admin/login";
    } else {
      setControlUser(localStorage.getItem("type_user"));
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    setSocket(
      socketClient(process.env.REACT_APP_API_URL, {
        query: {
          user: localStorage.getItem("id_painel"),
          type: localStorage.getItem("type_user"),
          Banking: myParam,
        },
      })
    );

    handleLoad();
  }, []);

  useEffect(() => {
    if (socket != null) {
      socket.on("messageBanking", (data) => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("id");

        if (data[0].Banking == myParam) {
          setMessages(data);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (document.getElementById("chat")) {
      document.getElementById("chat").scrollTop =
        document.getElementById("chat").scrollHeight;
    }
  }, [messages]);

  async function handleCompact(id) {
    let response = await api.post("/downloadCorrespondente", { id: id });
  }

  async function handleLoad() {
    let id = new URL(window.location).searchParams.get("id");
    setID(id);
    const { data } = await api.get(`/banking/${id}?limit=20`);

    if (data.nothing) {
      window.history.back();
    }

    if (localStorage.getItem("type_user") == "agent") {
      if (data.idUser) {
        if (data.idUser._id != localStorage.getItem("id_painel")) {
          window.history.back();
        }
      }
    }

    setComentario(data.observation);
    handleCompact(id);
    setImovelNovo(data.imovelNovo);
    setStatus(data.status);
    setFGTS(data.FGTS);
    setDataN(
      data.dateBorn.split("/")[2] +
        "-" +
        data.dateBorn.split("/")[1] +
        "-" +
        data.dateBorn.split("/")[0]
    );
    setImovelProprio(data.imovelProprio);
    setRegime(data.contratacao);
    setName(data.idUser ? data.idUser.name : data.name);
    setEmail(data.idUser ? data.idUser.email : data.email);
    setTelefone(
      data.idUser
        ? data.idUser.telephone && typeof data.idUser.telephone == Array
          ? data.idUser.telephone[0]
          : data.idUser.telephone
        : data.telephone
    );
    setCpfs({
      cpf1: data.cpf1,
      cpf2: data.cpf2,
      cpf3: data.cpf3,
      cpf4: data.cpf4,
    });
    setRenda(
      String(data.rendaBruta).includes(".")
        ? data.rendaBruta
        : data.rendaBruta + "00"
    );
    setFinanciamento(
      String(data.financiamento).includes(".")
        ? data.financiamento
        : data.financiamento + "00"
    );
    setCompra(
      String(data.valorCompra).includes(".")
        ? data.valorCompra
        : data.valorCompra + "00"
    );

    let docs = [];

    data.step3.map((doc) => {
      docs.push({
        id: crypto
          .createHash("sha256")
          .update(Math.random().toString())
          .digest("hex"),
        url: `${process.env.REACT_APP_API_URL}/file/correspondentes/${data._id}/passo3/${doc.name}`,
        image: "teste",
        name: doc.name,
      });
    });
    setDocStep3(docs.length == 0 ? docStep3 : docs);

    docs = [];

    data.step4.map((doc) => {
      docs.push({
        id: crypto
          .createHash("sha256")
          .update(Math.random().toString())
          .digest("hex"),
        url: `${process.env.REACT_APP_API_URL}/file/correspondentes/${data._id}/passo4/${doc.name}`,
        image: "teste",
        name: doc.name,
      });
    });
    setDocStep4(docs.length == 0 ? docStep4 : docs);

    var response = await api.get(`/chatBanking/${data._id}`);

    setMessages(response.data);

    response = await api.put(
      `/chatBanking/${data._id}?type=${
        localStorage.getItem("type_user") == "agent" ? "admin" : "agent"
      }`
    );
  }

  function moneyToNumber(value) {
    value = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");
    return (
      value.substr(1, value.length - 3) +
      "." +
      value.substr(value.length - 2, 2)
    );
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  async function handleSave() {
    let campos = document.querySelectorAll(".input-box");
    if (dataN.length == 10) {
      campos[2].style.border = "0px solid red";
      if (
        renda &&
        renda != "." &&
        renda != ".00" &&
        compra &&
        compra != "." &&
        compra != ".00" &&
        financiamento &&
        financiamento != "." &&
        financiamento != ".00" &&
        status
      ) {
        campos[1].style.border = "0px solid red";
        campos[2].style.border = "0px solid red";
        campos[3].style.border = "0px solid red";

        if (cpfs.cpf1) {
          if (!validaCpfCnpj(cpfs.cpf1)) {
            campos[6].style.border = "2px solid red";
            return alert("CPF invalido.");
          } else {
            campos[6].style.border = "0px solid red";
          }
        }
        if (cpfs.cpf2) {
          if (!validaCpfCnpj(cpfs.cpf2)) {
            campos[7].style.border = "2px solid red";
            return alert("CPF invalido.");
          } else {
            campos[7].style.border = "0px solid red";
          }
        }
        if (cpfs.cpf3) {
          if (!validaCpfCnpj(cpfs.cpf3)) {
            campos[8].style.border = "2px solid red";
            return alert("CPF invalido.");
          } else {
            campos[8].style.border = "0px solid red";
          }
        }
        if (cpfs.cpf4) {
          if (!validaCpfCnpj(cpfs.cpf4)) {
            campos[9].style.border = "2px solid red";
            return alert("CPF invalido.");
          } else {
            campos[9].style.border = "0px solid red";
          }
        }

        let finan, comp, rend;

        finan = financiamento;
        comp = compra;
        rend = renda;

        if (
          financiamento == "." ||
          financiamento == ".00" ||
          financiamento == "00"
        ) {
          setFinanciamento("");
          finan = "";
        }

        if (!String(financiamento).includes(".")) {
          setFinanciamento(financiamento.substr(0, financiamento.length - 2));
          finan = financiamento.substr(0, financiamento.length - 2);
        }

        if (compra == "." || compra == ".00" || compra == "00") {
          setCompra("");
          comp = "";
        }

        if (!String(compra).includes(".")) {
          setCompra(compra.substr(0, compra.length - 2));
          comp = compra.substr(0, compra.length - 2);
        }

        if (renda == "." || renda == ".00" || renda == "00") {
          setRenda("");
          rend = "";
        }

        if (!String(renda).includes(".")) {
          setRenda(renda.substr(0, renda.length - 2));
          rend = renda.substr(0, renda.length - 2);
        }
        let dateFormated =
          dataN.split("-")[2] +
          "/" +
          dataN.split("-")[1] +
          "/" +
          dataN.split("-")[0];

        const { data } = await api.put(`/banking/${ID}`, {
          FGTS,
          imovelProprio,
          contratacao: regime,
          status,
          dateBorn: dateFormated,
          financiamento: finan,
          valorCompra: comp,
          rendaBruta: rend,
          imovelNovo,
          observation: comentario,
          cpf1: cpfs.cpf1 ? cpfs.cpf1 : "",
          cpf2: cpfs.cpf2 ? cpfs.cpf2 : "",
          cpf3: cpfs.cpf3 ? cpfs.cpf3 : "",
          cpf4: cpfs.cpf4 ? cpfs.cpf4 : "",
        });
        if (data._id) {
          if (localStorage.getItem("type_user") == "agent") {
            window.location.href = "/admin/solicitacoes-corretor";
          } else {
            window.location.href = "/admin/correspondente/listar";
          }
        }
      } else {
        !status
          ? (campos[1].style.border = "2px solid red")
          : (campos[1].style.border = "0px solid red");
        !renda || renda == "." || renda == ".00"
          ? (campos[3].style.border = "2px solid red")
          : (campos[3].style.border = "0px solid red");
        !compra || compra == "." || compra == ".00"
          ? (campos[4].style.border = "2px solid red")
          : (campos[4].style.border = "0px solid red");
        !financiamento || financiamento == "." || financiamento == ".00"
          ? (campos[5].style.border = "2px solid red")
          : (campos[5].style.border = "0px solid red");
      }
    } else {
      campos[2].style.border = "2px solid red";
    }
  }

  function validaCpfCnpj(val) {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, "");
      cpf = cpf.replace("-", "");
      cpf = cpf.split("");

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = (v1 * 10) % 11;

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = (v2 * 10) % 11;

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else if (val.length == 18) {
      var cnpj = val.trim();

      cnpj = cnpj.replace(/\./g, "");
      cnpj = cnpj.replace("-", "");
      cnpj = cnpj.replace("/", "");
      cnpj = cnpj.split("");

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] != cnpj[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        alert("cnpj invalido");
        return false;
      }

      for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = v1 % 11;

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = 11 - v1;
      }

      if (v1 != cnpj[12]) {
        alert("cnpj invalido");
        return false;
      }

      for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = v2 % 11;

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = 11 - v2;
      }

      if (v2 != cnpj[13]) {
        alert("cnpj invalido");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      <Header />
      <Aside />
      <Container style={{ width: window.innerWidth <= 600 ? "100vw" : "65vw" }}>
        <div className="bread-crumb">
          <h1 className="category">CORRESPONDENTE BANCÁRIO ></h1>
          <Link to={window.location.pathname} className="where">
            {controlUser == "agent"
              ? "Minhas Solicitações > Negociar"
              : "Lista de Solicitações > Negociar"}
          </Link>
        </div>
        <div className="container-input-box">
          <div
            className="input-box input-center"
            style={{ background: "white", marginTop: -10 }}
          >
            <strong style={{ color: "#0A677C" }}>CÓD. {ID}</strong>
            <small>{name}</small>
            <small>{email}</small>
            <small>{telefone}</small>
          </div>

          <div className="input-box input-center">
            <small>Status</small>
            <select
              value={status}
              onChange={(select) => setStatus(select.target.value)}
            >
              <option></option>
              <option>Novo</option>
              <option>Analisando</option>
              <option>Concluído</option>
              <option>Não concluído</option>
              <option>Reprovado</option>
              <option>Aprovado</option>
            </select>
          </div>
        </div>
        <h2 style={{ marginTop: 15, color: "#EB5C1C" }}>ETAPA 1 - Simulação</h2>
        <div className="container-input-box">
          <div className="input-box input-center">
            <small>Data de Nascimento</small>
            <input
              value={dataN}
              onChange={(e) => setDataN(e.target.value)}
              type="date"
              placeholder="Participante mais velho"
            />
          </div>

          <div className="input-box input-center">
            <small>Renda Bruta</small>
            <input
              value={money(renda)}
              onChange={(e) => setRenda(moneyToNumber(e.target.value))}
              placeholder="Total da renda familiar"
            />
          </div>
        </div>
        <div className="container-input-box">
          <div className="input-box input-center">
            <small>Valor de Compra/Venda</small>
            <input
              value={money(compra)}
              onChange={(e) => setCompra(moneyToNumber(e.target.value))}
              placeholder=""
            />
          </div>

          <div className="input-box input-center">
            <small>Valor do financiamento no banco</small>
            <input
              value={money(financiamento)}
              onChange={(e) => setFinanciamento(moneyToNumber(e.target.value))}
              placeholder=""
            />
          </div>
        </div>
        <div
          className="container-input-box"
          style={{ marginTop: 15, justifyContent: "flex-start" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={FGTS}
                onChange={(change) => setFGTS(!FGTS)}
                name="checkedB"
                color="primary"
              />
            }
            label="Sim, pretende usar o FGTS"
          />

          <FormControlLabel
            style={{ marginLeft: window.innerWidth <= 600 ? -11 : 50 }}
            control={
              <Checkbox
                checked={imovelProprio}
                onChange={(change) => setImovelProprio(!imovelProprio)}
                name="checkedB"
                color="primary"
              />
            }
            label="Sim, possui imóvel próprio"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={imovelNovo}
                onChange={(change) => setImovelNovo(!imovelNovo)}
                name="checkedB"
                color="primary"
              />
            }
            style={{ marginLeft: window.innerWidth <= 600 ? -11 : 50 }}
            label="Imóvel Novo"
          />
        </div>
        <h2 style={{ marginTop: 15 }}>
          Qual o regime de contratação do comprador?{" "}
        </h2>
        <div className="container-input-box" style={{ marginTop: 15 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={regime == "CLT" ? true : false}
                onChange={(change) => setRegime("CLT")}
                name="checkedB"
                color="primary"
              />
            }
            label="CLT"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={regime == "Autônomo" ? true : false}
                onChange={(change) => setRegime("Autônomo")}
                name="checkedB"
                color="primary"
              />
            }
            label="Autônomo"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={regime == "Servidor Público" ? true : false}
                onChange={(change) => setRegime("Servidor Público")}
                name="checkedB"
                color="primary"
              />
            }
            label="Servidor Público"
          />
        </div>
        <h2 style={{ marginTop: 20, color: "#EB5C1C", fontWeight: 600 }}>
          ETAPA 2 - Consulta de CPF
        </h2>
        <h4 style={{ marginTop: 20, fontWeight: 500 }}>
          Prencha a Etapa 2 somente quando nossa equipe solicitar.
        </h4>
        <div className="container-input-box">
          <div className="input-box input-center">
            <small>CPF do Participante 1</small>
            <InputMask
              mask="999.999.999-99"
              onChange={(text) => setCpfs({ ...cpfs, cpf1: text.target.value })}
              value={cpfs.cpf1}
              placeholder="Ex.: 000.000.000-00"
            />
          </div>

          <div className="input-box input-center">
            <small>CPF do Participante 2</small>
            <InputMask
              mask="999.999.999-99"
              onChange={(text) => setCpfs({ ...cpfs, cpf2: text.target.value })}
              value={cpfs.cpf2}
              placeholder="Ex.: 000.000.000-00"
            />
          </div>
        </div>
        <div className="container-input-box">
          <div className="input-box input-center">
            <small>CPF do Participante 3</small>
            <InputMask
              mask="999.999.999-99"
              onChange={(text) => setCpfs({ ...cpfs, cpf3: text.target.value })}
              value={cpfs.cpf3}
              placeholder="Ex.: 000.000.000-00"
            />
          </div>

          <div className="input-box input-center">
            <small>CPF do Participante 4</small>
            <InputMask
              mask="999.999.999-99"
              onChange={(text) => setCpfs({ ...cpfs, cpf4: text.target.value })}
              value={cpfs.cpf4}
              placeholder="Ex.: 000.000.000-00"
            />
          </div>
        </div>
        <h2 style={{ marginTop: 35, color: "#EB5C1C", fontWeight: 600 }}>
          ETAPA 3 - Aprovação do Crédito Imobiliário dos Compradores
        </h2>
        <h4 style={{ marginTop: 20, fontWeight: 500 }}>
          Prencha a Etapa 3 somente quando nossa equipe solicitar.
        </h4>
        <br />
        <strong>Identidade:</strong>
        <strong style={{ fontWeight: 500 }}>
          {" "}
          CNH na validade, RG, ID Militar ou ID Funcional.{" "}
        </strong>
        <br />
        <strong>CPF:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Se constar na identidade não precisa.{" "}
        </strong>
        <br />
        <strong>Comprovante de Residência:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Em nome do cliente/participante - Conta de Consumo (Água, Luz,
          Telefone e Gás) com a parte de dentro da conta ou Cartão de Crédito,
          fatura aberta. Validade: 30 dias{" "}
        </strong>
        <br />
        <strong>Comprovante de Estado Civil:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Solteiro: Certidão de Nascimento / Casado: Certidão de Casamento /
          Divorciado: Cert. Casamento com Averbação do divórcio / Viúvo:
          Certidão de Casamento e Atestado de Óbito{" "}
        </strong>
        <br />
        <strong>União Estável:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Declaração de união estável não é aceito como comprovante de estado
          civil, porém se possuir enviar para compor o dossiê juntamente com as
          certidões acimas citadas.
        </strong>
        <br />
        <strong>Comprovante de Renda:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Assalariado e Funcionário Privado ou Público - Contracheque /
          Empresários e Comerciantes e donos de Empresas - Pró-Labore (Último
          mês + Comprovante de pagamento de imposto do pró-labore (DARF) ou IR
          Completo + Recibo + Contrato Empresa / Aposentados e Pensionista -
          Último Extrato do benefício.
        </strong>
        <br />
        <Carousel style={{ marginTop: "30px" }}>
          {docStep3.map((value, index) => (
            <Item
              key={index}
              className="download"
              href={`${process.env.REACT_APP_API_URL}/file/correspondentes/${ID}/passo3/${value.name}`}
              download
            >
              <DocItem
                htmlFor={`doc-${value.id}`}
                style={value.image == "teste" ? { cursor: "default" } : {}}
                image={
                  value.url
                    ? `'${require("../../assets/adminOfferProperty/docok.svg")}'`
                    : ""
                }
                className="doc-container"
              >
                <input
                  multiple="multiple"
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addDoc(e.target.files, value.id, 3)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removeDoc(value.id, 3);
                      }
                    } else {
                      removeDoc(value.id, 3);
                    }
                  }}
                >
                  x
                </button>
              </DocItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/file/correspondentes/${ID}/etapa3.zip`}
                  download
                  htmlFor=""
                  style={
                    value.image == "teste"
                      ? { cursor: "pointer", fontWeight: "normal" }
                      : { cursor: "default", fontWeight: "normal" }
                  }
                >
                  {value.file && value.image != "teste" ? value.file.name : ""}
                  {!value.file && value.image == "teste" ? value.name : ""}
                </a>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setDocStep3([
                  ...docStep3,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel>
        <h2 style={{ marginTop: 20, color: "#EB5C1C", fontWeight: 600 }}>
          ETAPA 4 - Pós Aprovação – Andamento do Processo
        </h2>
        <h4 style={{ marginTop: 20, fontWeight: 500 }}>
          Prencha a Etapa 4 somente quando nossa equipe solicitar.
        </h4>
        <br />
        <strong>VENDEDORES</strong>
        <br />
        <br />
        <strong>Identidade:</strong>
        <strong style={{ fontWeight: 500 }}>
          {" "}
          CNH na validade, RG, ID Militar, ID Funcional.{" "}
        </strong>
        <br />
        <strong>CPF:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Se constar na identidade não precisa.{" "}
        </strong>
        <br />
        <strong>Comprovante de Residência:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Em nome do cliente/participante - Conta de Consumo (Água, Luz,
          Telefone e Gás) com a parte de dentro da conta ou Cartão de Crédito,
          fatura aberta. Validade: 30 dias{" "}
        </strong>
        <br />
        <strong>Comprovante de Estado:</strong>{" "}
        <strong style={{ fontWeight: 500 }}>
          {" "}
          Civil Solteiro: Certidão de Nascimento /Casado: Certidão de Casamento
          /Divorciado: Cert. Casamento com Averbação do divórcio /Viúvo:
          Certidão de Casamento e Atestado de Óbito.
        </strong>
        <br />
        <br />
        <strong>IMÓVEL</strong>
        <br />
        <strong style={{ fontWeight: 500 }}>
          Matricula do Imóvel (Ônus Reais).{" "}
        </strong>
        <br />
        <strong style={{ fontWeight: 500 }}>IPTU</strong>
        <br />
        <Carousel style={{ marginTop: "30px" }}>
          {docStep4.map((value, index) => (
            <Item
              key={index}
              className="download"
              href={`${process.env.REACT_APP_API_URL}/file/correspondentes/${ID}/passo4/${value.name}`}
              download
            >
              <DocItem
                style={value.image == "teste" ? { cursor: "default" } : {}}
                htmlFor={`doc-${value.id}`}
                image={
                  value.url
                    ? `'${require("../../assets/adminOfferProperty/docok.svg")}'`
                    : ""
                }
                className="doc-container"
              >
                <input
                  multiple="multiple"
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addDoc(e.target.files, value.id, 4)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removeDoc(value.id, 4);
                      }
                    } else {
                      removeDoc(value.id, 4);
                    }
                  }}
                >
                  x
                </button>
              </DocItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/file/correspondentes/${ID}/etapa4.zip`}
                  download
                  style={
                    value.image == "teste"
                      ? { cursor: "pointer", fontWeight: "normal" }
                      : { cursor: "default", fontWeight: "normal" }
                  }
                  htmlFor=""
                >
                  {value.file && value.image != "teste" ? value.file.name : ""}
                  {!value.file && value.image == "teste" ? value.name : ""}
                </a>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setDocStep4([
                  ...docStep4,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel>
        <div
          className="container-input-box max-camp"
          style={{
            flexDirection: controlUser == "agent" ? "row" : window.innerWidth<= 600 ? "column-reverse" : "row-reverse",
            height: window.innerWidth<= 600 ? 'none':420,
          }}
        >
          {controlUser != "agent" ? (
            <div
              className="box-divide max"
              style={{ width: window.innerWidth<= 600 ? "100%":"48%", height: window.innerWidth<= 600 ? 180:"inherit" }}
            >
              <small>Observação do operador/admin:</small>
              <textarea
                placeholder="Digite aqui..."
                value={comentario}
                onChange={(comp) => setComentario(comp.target.value)}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            className="container-input-box max-camp"
            style={{ flexDirection: "column", width: window.innerWidth<= 600 ? "100%" : "48%" }}
          >
            <div
              className="box-divide max"
              style={{
                height: 320,
                paddingLeft: 0,
                paddingRight: 0,
                width: "100%",
              }}
            >
              <small style={{ marginLeft: 10, paddingRight: 15 }}>
                Converse sobre este imóvel:
              </small>
              <div
                id="chat"
                style={{
                  height: "90%",
                  width: "100%",
                  overflowY: "auto",
                  padding: 15,
                }}
              >
                {messages.map((message, i) => (
                  <>
                    {(message.type == "admin" &&
                      (messages[i > 0 ? i - 1 : i].type == "agent" ||
                        i == 0)) ||
                    (messages[i > 0 ? i - 1 : i].createdAt !=
                      message.createdAt &&
                      message.type == "admin") ? (
                      <div
                        style={{
                          color:
                            "admin" === message.type ? "#C13818" : "#05677C",
                          width: "100%",
                          marginTop: 7,
                          fontWeight: 500,
                          textAlign: "admin" == message.type ? "left" : "right",
                        }}
                      >
                        {message.createdAt} - Imóvel Vazio:
                      </div>
                    ) : (
                      ""
                    )}
                    {message.type == "agent" && messages[i > 0 ? i - 1 : i].createdAt != message.createdAt || message.type == "agent" && messages[i > 0 ? i - 1 : i].type != message.type ? (
                      <div
                        style={{
                          color:
                            "admin" === message.type ? "#C13818" : "#05677C",
                          width: "100%",
                          marginTop: 7,
                          fontWeight: 500,
                          textAlign: "admin" == message.type ? "left" : "right",
                        }}
                      >
                        {message.createdAt}:
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        color: "admin" === message.type ? "#C13818" : "#05677C",
                        width: "100%",
                        marginTop: 7,
                        fontWeight: 500,
                        textAlign: "admin" == message.type ? "left" : "right",
                      }}
                    >
                      {message.message}
                    </div>
                  </>
                ))}
              </div>
            </div>

            <div
              className="box-divide max max-camp"
              style={{
                height: 70,
                paddingLeft: 0,
                paddingRight: 0,
                width: "100%",
                marginTop: 10,
              }}
            >
              <small style={{ marginLeft: 10, paddingRight: 15 }}>
                Escreva uma mensagem
              </small>
              <div className="divSend">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                    color: "#aaa",
                    border: "none",
                  }}
                />
                <div
                  onClick={async (e) => {
                    handleMessage();
                  }}
                  className="btSend"
                >
                  {">"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-next"
          style={{ justifyContent: "flex-end", marginTop: 20 }}
        >
          <button
            onClick={handleSave}
            style={{ width: window.innerWidth <= 600 ? "100%" : "30%" }}
          >
            Salvar
          </button>
        </div>
      </Container>
    </>
  );
}
