import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import Autocomplete from '../../components/autosuggest'
import api from '../../api'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

export default function AdminPropertyList() {
    const [data, setData] = useState([])
    const [dataOrder, setDataOrder] = useState([])

    const [verification, setVerification] = useState(true)

    const [city, setCity] = useState([])
    const [neighborhoods, setNeighborhoods] = useState([])
    const [neighSelect, setNeighSelect] = useState([])
    const [types, setTypes] = useState([])
    const [total, setTotal] = useState([])

    const [valorMaximo, setValorMaximo] = useState('R$ 0,00')
    const [valorMinimo, setValorMinimo] = useState('R$ 0,00')
    const [type, setType] = useState('')
    const [generalType, setGeneralType] = useState('')
    const [quartos, setQuartos] = useState('')
    const [Banheiros, setBanheiros] = useState('')
    const [vagas, setVagas] = useState('')
    const [areaMinima, setAreaMinima] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [status, setStatus] = useState('false')
    const [algo, setAlgo] = useState('')

    const [page, setPage] = useState(0)
    const [lastPage, setLastPage] = useState(1)

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }

        handleLoad()
    }, [])

    function cleanStates(){
        setValorMaximo('R$ 0,00')
        setValorMinimo('R$ 0,00')
        setType('')
        setGeneralType('')
        setQuartos('')
        setBanheiros('')
        setVagas('')
        setAreaMinima('')
        setBairro('')
        setCidade('')
        setStatus('false')
        setAlgo('')
    }
    async function handleLoadReset(){
        cleanStates()
        const response = await api.get(`/property?limit=20&sold=${status}&arquive=false`)
        setLastPage(response.data.pages)
        setData(response.data.propertys)
        setTotal(response.data.total)
		
	}

    async function handleLoad(){
        cleanStates()
        const response = await api.get(`/property?limit=20&sold=${status}&arquive=false`)
        setLastPage(response.data.pages)
        setData(response.data.propertys)
        setTotal(response.data.total)
        var {data} = await api.get('/city?limit=999&stateName=Rio de Janeiro')
        setCity([{_id: 7043, name: 'Rio de Janeiro'},{_id: 6989, name: 'Niterói'}])
        
        var {data} = await api.get('/neighborhood?limit=10000&city='+7043)
        setNeighborhoods(data.neighborhoods)
        setNeighSelect(data.neighborhoods)

        var {data} = await api.get('/propertyType')
        
        setTypes(data.propertyTypes)
    }

    async function handleDelete(id) {
        if(window.confirm('Tem certeza que quer arquivar este imovel?')){
            const {data} = await api.delete(`/property/${id}?arquive=true`, {
                headers: {
                    data: JSON.stringify({arquive: true, showHome: false, updateAt: Date.now()}),
                }
            })
            
            handleLoad()
        }
    }

    async function handleUpdate(id, sold, arquive, index, object, setObject) {
        if(sold && !arquive){
            return alert('Este imovel ja foi vendido.')
        }

        if(window.confirm('Tem certeza que quer atualizar este imovel?')){
            const {data} = await api.put(`/property/${id}`, {
                sold: true, 
                soldDate: new Date().toLocaleDateString('pt-BR', {day: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {month: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {year: 'numeric'})
            })
            if(data.sold == true){
                object = object.filter(r=>r._id != "null")
                object[index].sold = true;
                setObject(object);
            }
            // handleLoad();
        }
    }

    async function handleUpdateRevert(id, sold, arquive, index, object, setObject) {
        if(window.confirm('Tem certeza que quer atualizar este imovel?')){
            const response = await api.put(`/property/${id}`, {sold: false})
            if(response.data.sold === false) {
                object = object.filter(r=>r._id != "null")
                object[index].sold = false;
                setObject(object);
            }
        }
    }

    async function handlePage(pages){
        console.log(pages.selected)
        const response = await api.get(`/property?limit=20&page=${pages.selected+1}`)
        setTotal(response.data.total)
        
        if(response.data.propertys){
            setPage(pages.selected)
            setData(response.data.propertys)
        }
    }

    async function handleApply(){
        let querys = `?`

        if(cidade){
            querys += `city_id=${cidade}&`
        }

        if(bairro){
            querys += `neigh_id=${bairro}&`
        }
         
        if(generalType){
            querys += `generalType=${generalType}&`
        }

        if(type){
            querys += `generalTypes=${type}&`
        }

        if(quartos){
            querys += `bedrooms=${quartos}&`
        }

        if(vagas){
            querys += `parkingSpots=${vagas}&`
        }
        
        if(Banheiros){
            querys += `bathrooms=${Banheiros}&`
        }
        //6989
        if(areaMinima){
            querys += `fullLandSize=${areaMinima}&`
        }

        if(valorMaximo != 'R$ 0,00'){
            querys += `sellingPrice=${removeMoney(valorMaximo)}&`
        }
        
        if(valorMinimo != 'R$ 0,00'){
            querys += `sellingPriceMin=${removeMoney(valorMinimo)}&`
        }

        if(algo){
            querys += `endereco=${algo}&`
        }

        if(status){
            if(status == 'arquivado'){
                querys += `arquive=true&`
            }else{
                querys += `sold=${status}&arquive=false&`
            }
        }
        
        console.log(querys)

        let {data} = await api.get(`/property${querys}&limit=10000`)
        
        setTotal(data.total? data.total : 0)
        setLastPage(data.pages)
        setData(data.propertys)
    }

    function removeMoney(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }

    async function handleOrder(order){
        setDataOrder([])
        switch(order){
            case 'recent': 
                    var response = await api.get(`/property?limit=20&arquive=false`, {headers: {orderby: JSON.stringify({_id: -1})}})
                    
                    setVerification(true)
                    setDataOrder([])
                    setData(response.data.propertys)
                    setTotal(response.data.total)
                    setLastPage(response.data.pages)
                break;

            case 'antigo': 
                    var response = await api.get(`/property?limit=20&arquive=false`, {headers: {orderby: JSON.stringify({_id: 1})}})
                    setVerification(true)
                    setDataOrder([])
                    setData(response.data.propertys)
                    setTotal(response.data.total)
                    setLastPage(response.data.pages)
                break;
            case 'maior-menor': 
                var response = await api.get(`/property?limit=20&arquive=false`, {headers: {orderby: JSON.stringify({sellingPrice: -1 })}})
                setVerification(true)
                setDataOrder([])
                setData(response.data.propertys)
                setTotal(response.data.total)
                setLastPage(response.data.pages)
                break;

            case 'menor-maior': 
                var response = await api.get(`/property?limit=20&arquive=false`, {headers: {orderby: JSON.stringify({sellingPrice: 1 })}})
                setVerification(true)
                setDataOrder([])
                setData(response.data.propertys)
                setTotal(response.data.total)
                setLastPage(response.data.pages)
                break;

            case 'me-maior-menor': 
                var response = await api.get(`/property?limit=20&arquive=false`, {headers: {orderby: JSON.stringify({fullLandSize: -1 })}})
                setVerification(true)
                setDataOrder([])
                setData(response.data.propertys)
                setTotal(response.data.total)
                setLastPage(response.data.pages)
                break;

            case 'me-menor-maior': 
                var response = await api.get(`/property?limit=20&arquive=false`, {headers: {orderby: JSON.stringify({fullLandSize: 1 })}})
                setVerification(true)
                setDataOrder([])
                setData(response.data.propertys)
                setTotal(response.data.total)
                setLastPage(response.data.pages)
                break;

            case 'a-z': 
                var sortDatas = data.sort(function (a, b) {
                    var textA = ''
                    var textB = ''
                    if(a.neighborhood && b.neighborhood){
                        textA = a.neighborhood.name.toUpperCase();
                        textB = b.neighborhood.name.toUpperCase();
                    }
                
                    return textA.localeCompare(textB);
                })
                
                if(!verification){
                    setData(sortDatas)
                    setVerification(true)
                }else{
                    setDataOrder(sortDatas)
                    setVerification(false)

                }
                break;

            case 'z-a': 
                var sortDatas = data.sort(function (a, b) {
                    var textA = ''
                    var textB = ''
                    if(a.neighborhood && b.neighborhood){
                        textA = a.neighborhood.name.toUpperCase();
                        textB = b.neighborhood.name.toUpperCase();
                    }
                
                    return textA.localeCompare(textB);
                }).reverse()
                
                if(!verification){
                    setData(sortDatas)
                    setVerification(true)
                }else{
                    setDataOrder(sortDatas)
                    setVerification(false)
                }
                break;
        }
        
    }

    function handleChangeCity(value){
        console.log(value)
        setNeighSelect(neighborhoods.filter(target => {
            return target.city == value
        }))
        
        setCidade(value)
    }

    function money(value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }
    
    async function handleSetHome(id, valor, target, e){
        if(!valor ? window.confirm('Deseja remover este imóvel da home?') : window.confirm('Deseja adicionar este imóvel na home?')){
            const response = await api.put(`/property/${id}`, {
                showHome: valor,
                teste: true
            })
            
            // valor ? target.src = target.src.replace('error.afd590d1.svg', 'success.1a4eee6c.svg') : target.src = target.src.replace('success.1a4eee6c.svg', 'error.afd590d1.svg')
            let ar = []
            data.map(e => {
                if(e._id == id){
                    e.showHome = response.data.showHome
                }
                ar.push(e)
            })
            setData(ar)
        }
    }

    function dateBr(value){
        return value.substr(8, 2)+'/'+value.substr(5, 2)+'/'+value.substr(0,4)
    }

    function innerRow(row, index) {
        return (
            <>
                <div className="body-active">
                    {
                        row.showHome && !row.arquive ? 
                            <div className="home" onClick={e => handleSetHome(row._id, false, e.target, e)}>
                                HOME 1
                                <img src={require('./icons/success.svg')}/>
                            </div>
                        :
                            <div className="home" style={{cursor: row.arquive? 'default' : 'pointer'}}  onClick={e => {  
                                if(!row.arquive){
                                    handleSetHome(row._id, true, e.target, e)
                                }
                            }}>
                                HOME 1
                                <img src={require('./icons/error.svg')}/>
                            </div>
                    }{
                        !row.arquive ? 
                        <div>
                            CATÁLOGO
                            <img src={require('./icons/success.svg')}/>
                        </div>
                        :
                        <div>
                            CATÁLOGO
                            <img src={require('./icons/error.svg')}/>
                        </div>
                    }
                </div>
                <div className="body-imovel">
                    <small>Cadastrado em: {String(row.createdAt).split(' ')[0].includes('/') ? String(row.createdAt).split(' ')[0] : dateBr(String(row.createdAt).split(' ')[0])}</small>

                    <h2 style={{fontSize: 18}}><a style={{color: 'inherit'}} href={`${process.env.REACT_APP_API_URL}/imoveis/${row._id}`} target='_blank'>{row.address ? row.address : ''}{row.addressNumber ? ', '+row.addressNumber : ''} {row.addressComplement? ` / ${row.addressComplement}`:''}</a></h2>
                    <h3>{row.neighborhood ? row.neighborhood.name : ''}{row.city ? " - "+row.city.name : ''}{row.neighborhood ? ', '+row.neighborhood.state : ''}</h3>
                    
                    <h5>{row.type ? row.type.name : ''} {row.generalType ? row.generalType.name : (row.type ? row.type.generalType  == '2372820374682508' ? 'Residencial' : 'Comercial':'')}</h5>
                    <h6>Vendido? {row.sold && !row.arquive ? 'Sim' : !row.arquive ? 'Não' : 'Arquivado'}</h6>

                </div>
                <div className="body-engajamento">
                    <div>
                        <a onClick={e=>window.location.href=`/admin/property/visualization?id=${row._id}`}>Visualizações</a>
                    </div>
                    <div>
                        <a onClick={e=>window.location.href=`/admin/property/interested?id=${row._id}`}>Corretores interessados</a>
                    </div>
                </div>
                <div className="body-actions">
                    <div>
                        <a onClick={i => window.location.href=`/admin/property/edit?id=${row._id}`}>Editar</a>
                    </div>
                    <div>
                        <a onClick={i => handleDelete(row._id)}>Excluir</a>
                    </div>
                    <div>
                        {
                        row.sold && !row.arquive ? 
                            <a onClick={i => handleUpdateRevert(row._id, row.sold, row.arquive, index, data, setData)}>Reverter para ativo</a>
                        :
                            <a onClick={i => handleUpdate(row._id, row.sold, row.arquive, index, data, setData)}>Colocar como vendido</a>
                        }

                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Anúncios Imóvel Vazio
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Estado</label>
                        <select>
                            <option>Rio de Janeiro</option>
                        </select>
                    </div>
                    <div className="input-group">
                        
                        <label htmlFor="">Cidade</label>
                         <select onChange={e => e.target.value != 'Selecione:' ? handleChangeCity(e.target.value):handleChangeCity('')}>
                                {/* <option defaultChecked="true">Selecione: </option> */}
                                <option defaultChecked="true">Selecione: </option>
                            {   
                                city.map(city => (
                                    <option value={city._id}>{city.name}</option>
                                ))
                            }
                        </select>
                        {/* {
                            city.length != 0 ? 
                                <Autocomplete placeholder="Selecione" onChange={(value) => handleChangeCity(value._id)} inputName="Cidade" objs={city} />
                                :
                                ''
                        } */}
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Bairro</label>
                         <select onChange={select => setBairro(select.target.value)}>
                                <option defaultChecked="true">Selecione: </option>
                            {   
                                neighSelect.map(neigh => (
                                    <option value={neigh._id}>{neigh.name}</option>
                                ))
                            }
                        </select>
                        {/* {
                            neighSelect.length != 0 ?
                                <Autocomplete placeholder="Selecione" onChange={(value) => setBairro(value._id)} inputName="Bairro" objs={neighSelect} />
                                :
                                ''
                        } */}
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Status</label>
                        <select value={status} onChange={select => setStatus(select.target.value)}>
                            <option selected value="false">À venda</option>
                            <option value="true">Vendido</option>
                            <option value="arquivado">Arquivado</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Residencial ou comercial</label>
                        <select onChange={select => setGeneralType(select.target.value)}>
                            <option>Selecione</option>
                            <option value="2372820374682508">Residencial</option>
                            <option value="1644602035863502">Comercial</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Tipo de imovel</label>
                        <select onChange={select => setType(select.target.value)}>
                            <option defaultChecked="true">Selecione: </option>
                            {   
                                types.map(neigh => (
                                    <option value={neigh._id}>{neigh.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Valor minimo</label>
                        <input value={valorMinimo} onChange={input => setValorMinimo(money(input.target.value))} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Valor maximo</label>
                        <input value={valorMaximo} onChange={input => setValorMaximo(money(input.target.value))} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Quartos</label>
                        <input type="number" value={quartos} onChange={input => setQuartos(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Banheiros</label>
                        <input type="number" value={Banheiros} onChange={input => setBanheiros(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Vagas</label>
                        <input type="number" value={vagas} onChange={input => setVagas(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Área minima</label>
                        <input type="number" value={areaMinima} onChange={input => setAreaMinima(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Digite algo</label>
                        <input type="text" value={algo} onChange={input => setAlgo(input.target.value)} />
                    </div>
                    <div className="button-group">
                        <button onClick={() => window.location.href = "/admin/property/new"} style={{marginRight: 10}} className="outline">Adicionar Imovel</button>
                        <button onClick={handleLoadReset} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                </div>
                <div className="row-ordenate">
                    <p>Foram encontrados {total} imóveis</p>
                    <div className="input-group">
                        <select onChange={option => handleOrder(option.target.value)}>
                            <option value="">Ordenar por</option>
                            <option value="recent">Mais Recentes</option>
                            <option value="antigo">Mais Antigos</option>
                            <option value="maior-menor">Valor (Maior-Menor)</option>
                            <option value="menor-maior">Valor (Menor-Maior)</option>
                            <option value="me-maior-menor">Metragem (Maior-Menor)</option>
                            <option value="me-menor-maior">Metragem (Menor-Maior)</option>
                            <option value="a-z">Bairro (A-Z)</option>
                            <option value="z-a">Bairro (Z-A)</option>
                        </select>
                    </div>

                </div>
                <div className="header">
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ? 
                            <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                <div style={{width: 90}} className="active">
                                    <h2>ATIVO</h2>
                                </div>
                                <div style={{width: 210}} className="imovel">
                                    <h2>IMÓVEL</h2>
                                </div>
                                <div style={{width: 210}} className="engajamento">
                                    <h2>ENGAJAMENTO</h2>
                                </div>
                                <div style={{width: 150}} className="actions">
                                    <h2>AÇÕES</h2>
                                </div>
                            </div>
                        :
                        <>
                            <div className="active">
                                <h2>ATIVO</h2>
                            </div>
                            <div className="imovel">
                                <h2>IMÓVEL</h2>
                            </div>
                            <div className="engajamento">
                                <h2>ENGAJAMENTO</h2>
                            </div>
                            <div className="actions">
                                <h2>AÇÕES</h2>
                            </div>
                        </>
                    }
                </div>

                {
                    verification ? data.map((row, index) => (
                        <div className="body">
                            <div className="body-cod">{row._id}</div>
                            
                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row, index)}
                                    </div>    
                                :
                                <>
                                    {innerRow(row, index)}
                                </>
                            }
                        </div>
                    ))
                    :
                    ''
                }



                {
                    !verification ? dataOrder.map((row, index) => (
                        <div className="body">
                            <div className="body-cod">{row._id}</div>
                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row, index)}
                                    </div>    
                                :
                                <>
                                    {innerRow(row, index)}
                                </>
                            }
                        </div>
                    ))
                    :
                    ''
                }

                <div className="paginate">
                    {/* <div onClick={r => handlePage(1)} className="firstDiv">primeiro</div>
                    <div onClick={r => handlePage(lastPage == page ? page-3 : page+1)}>{lastPage == page ? page-3 : page+1}</div>
                    <div onClick={r => handlePage(lastPage == page ? page-2 : page+2)}>{lastPage == page ? page-2 : page+2}</div>
                    <div onClick={r => handlePage(lastPage == page ? page-1 : page+3)}>{lastPage == page ? page-1 : page+3}</div>
                    <div onClick={r => handlePage(lastPage)} className="lastDiv">última</div> */}
                    <ReactPaginate
                        
                        pageCount={lastPage}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={e => handlePage(e)}
                        forcePage={page}
                        previousLabel={"Anterior"}
                        nextLabel={"Proximo"}
                        breakLabel={<a href="">...</a>}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
                </div>
            </Container>
        </>
    )
}