import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'

export default function HomeCorretor() {
    const [interessados, setInteressados] = useState(0)
    const [ofertas, setOfertas] = useState(0)
    const [correspondentes, setCorrespondentes] = useState(0)

    useEffect(() => {
        if(!localStorage.getItem('type_user')){
            window.location.href='/admin/login'
        }

        handleLogin()
    }, [])
    
    async function handleLogin(){
        var {data} = await api.get('/interested?idUser='+localStorage.getItem('id_painel'))
        if(data.total){
            setInteressados(data.total)
        }
        
        var {data} = await api.get('/Offer?idUser='+localStorage.getItem('id_painel'))

        if(data.total){
            setOfertas(data.total)
        }

        var {data} = await api.get('/banking?idUser='+localStorage.getItem('id_painel'))

        if(data.total){
            setCorrespondentes(data.total)
        }
        console.log(data)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                
                <div className="background-home">
                    <h1>Corretor(a), seja bem-vindo(a) à Imóvel Vazio!</h1>
                </div>

                <h1>Informações</h1>

                <div className="box">
                    <strong>Meu Portfólio</strong> - Adicione ao seu portfólio imóveis que podem ser vendidos por você com a melhor corretagem do mercado. 
                    <br></br>
                    <br></br>

                    <strong>Ofertas de Venda para a Imóvel Vazio</strong> - Você também pode vender um imóvel diretamente para a Imóvel Vazio. Mas é preciso ser uma real oportunidade, que esteja abaixo do valor de mercado. 
                    <br></br>
                    <br></br>

                    <strong>Correspondete Bancário / Minhas Solicitações</strong> - Se você precisa de aprovação bancária para o financiamento do seu cliente, conte com os nossos especialistas. E lembre, aqui você também é comissionado!
                    <br></br>

                </div>

                <h1>Seus números</h1>

                <div className="numbers">

                    <div className="box-infos">
                        <strong>Meu Portfólio (imóveis interessados)</strong>
                        <h2>{interessados}</h2>
                    </div>

                    <div className="box-infos box-left">
                        <strong>Ofertas para Imóvel Vazio</strong>
                        <h2>{ofertas}</h2>
                    </div>

                    <div className="box-infos box-left">
                        <strong>Correspondente Bancário</strong>
                        <h2>{correspondentes}</h2>
                    </div>

                </div>

                <button className="btOffer" onClick={e => {
                    window.location.href='/ofertar-imovel'
                }}>Clique aqui para ofertar um imóvel</button>
            </Container>
        </>
    )
}