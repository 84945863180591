import React, {useEffect, useState} from 'react'
import '../../global.css';
import api from '../../api'
import InputMask from "react-input-mask";

export default function StepOne({setStep, valorImovel, setValorImovel, valorMercado, setValorMercado, comentario, setComentario}) {
    const [valorMer, setValorMer] = useState('')
    const [valorIM, setValorIM] = useState('')

    function handleNext(){
        if(valorIM && valorMer && valorMer != "R$ 0,00" && valorIM != "R$ 0,00"){
            setStep(4)
        }else{
            let errors = document.querySelectorAll('.input-box')
            if(!valorIM){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }
            if(!valorMer){
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }
            if(valorIM == "R$ 0,00"){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }
            if(valorMer == "R$ 0,00"){
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }
        }
    }

    function styleBorderError(object){
        setTimeout(() => {
            object.style.border = "none"
        }, 3000)
    }
    
    function money (value) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }


    return (
        <>  
            <div className="step">
                <div className="container-input-box">
                    <div className="input-box">
                        <small>Valor desejado pelo imóvel</small>
                        <InputMask type="text" onChange={text => {setValorImovel(text.target.value); setValorIM(money(text.target.value))}} value={valorIM} placeholder="É o valor que pagaremos pelo imóvel"/>
                    </div>
                    <div className="input-box">
                        <small>Valor de mercado avaliado</small>
                        <InputMask type="text" value={valorMer}  onChange={text => {setValorMercado(text.target.value); setValorMer(money(text.target.value))}} placeholder="É o valor que podemos vender" />
                    </div>
                </div>

                <div className="input-box max">
                    <small>Desejar fazer algum comentário?</small>
                    <textarea value={comentario}  onChange={text => setComentario(text.target.value)} placeholder="Digite aqui..."/>
                </div>

                <div className="container-next min">
                    <button className="btBack" onClick={click => setStep(2)}>Voltar</button>
                    <button onClick={handleNext}>Avançar</button>
                </div>
            </div>
        </>
    )
}
