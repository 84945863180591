const { default: styled } = require("styled-components");

export const Container = styled.div`
    margin-left:20vw;
    width:80vw;
    height:100%;
    padding:30px;
    padding-top:12vh;

    @media only screen and (max-width: 600px) {
        padding-top: 4vh;
        margin-left: 0;
        width: 100%;
    }
    
  .bread-crumb{
    display:flex;
    align-items:center; 
    margin-bottom:40px;
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }

    .category{
        color: #0A677C;
        margin-right:3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    .where{
        color: #EB5C1C;
        text-decoration:none;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
  }

    .filter{
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        padding:20px;
        display:flex;
        flex-wrap:wrap;
        .input-group{
            width: 230px;
            height: 59px;
            background: #DADADA;
            border-radius: 4px;
            padding:5px;
            margin-right:10px;
            margin-bottom:10px;
            
            @media only screen and (max-width: 600px) {
                width: 100%;
                margin-right: 0;
            }

            label{
                width:100%;
            }
            input,select{
                width:100%;
                height:60%;
                background:transparent;
                border:none;
                outline:none;
                margin-top: 3px;
                padding-left: 10px;
            }
           
        }
        .button-group{
            margin-left:auto;
            margin-right:85px;

            @media only screen and (max-width: 600px) {
                margin-right: 0;
            }

            .outline{
                background:transparent;
                outline:none;
                border: 1px solid #EA5C1E;
                padding:10px;
                border-radius: 4px;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */
                text-align: center;
                letter-spacing: 0.05em;
                /* #EA5C1E */
                color: #EA5C1E;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    margin-top: 10px;
                }                
            }
            .full{
                outline:none;
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                border-radius: 4px;
                border:none;
                padding:10px;
                color:#ffffff;
                margin-left:10px;
                
                @media only screen and (max-width: 600px) {
                    width: 100%;
                    margin-top: 10px;
                    margin-left: 0px;
                }          
            }
        }
    }
    .row-ordenate{
        display:flex;
        width:100%;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
        margin-bottom:20px;
        .input-group{
            width: 230px;
            height: 59px;
            background: #DADADA;
            border-radius: 4px;
            padding:5px;
            margin-right:10px;
            margin-bottom:10px;
            
            label{
                width:100%;
            }
            input,select{
                width:100%;
                height:100%;
                background:transparent;
                border:none;
                outline:none;
            }
           
        }
    }
    .header{
        display:flex;
        background: #0A677C;
        border: 1px solid #0A677C;
        border-radius: 4px;
        color:#ffffff;
        height: 50px;

        div{
            height: 100%;
            h2{
                padding-left: 12px;
                display: flex;
                align-items: center;
                height: 100%
            }
        }

        div + div{
            border-left:1px solid #ffffff;
        }

        .cod{
            width: 7%;
            
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }

        .active{
            width: 10%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }

        .imovel{
            width: 35%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }
        
        .engajamento{
            width: 27%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }

        .actions{
            width: 18%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }
    }

    .body{
        height: 180px;
        width: 100%;
        margin-top: 20px;
        border-radius: 5px;
        border: 1px solid #aaa;
        display: flex;
        flex-direction: row;

        div{
            display: flex;
        }
        
        .body-cod{
            border-right: 1px solid #aaa;
            width: 7%;
            height: 100%;
            color: #0A677C;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 17px;
            
            @media only screen and (max-width: 600px) {
                width: 18%;
            }
        }

        .body-active{
            border-right: 1px solid #aaa;
            width: 10%;
            height: 100%;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px) {
                width: 90px;
            }

            .home{
                cursor: pointer;
            }

            div{
                display: flex;
                margin-bottom: 20px;
                flex-direction: column;
                
                
                img {
                    margin-top: 9px;
                    height: 24px
                }
            }
        }
        .body-imovel{
            border-right: 1px solid #aaa;
            width: 35%;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            
            @media only screen and (max-width: 600px) {
                width: 210px;
                overflow: auto;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            small {
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
            }

            h2, h3{
                width: 100%;
                color: #0A677C;
                padding-left: 20px;
            }
            h2{
                font-size: 20px;
            }

            h3{
                font-weight: 400;
                font-size: 18px;
            }

            h5{
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
                font-weight: 400;
                margin-top: 10px;
            }
            h6{
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
                font-weight: 400;
            }
        }
        .body-engajamento{
            border-right: 1px solid #aaa;
            width: 27%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px) {
                width: 210px;
            }

            div{
                height: 100%;
                display: flex;
                justify-content: flex-start;
                padding-left: 18px;
                align-items: center;
                width: 100%;
                color: #00AEC8;
                font-size: 20px;

                a{
                    cursor: pointer;
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
        .body-actions{
            width: 21%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            @media only screen and (max-width: 600px) {
                width: 150px;
            }
            div{
                height: 100%;
                display: flex;
                justify-content: flex-start;
                padding-left: 18px;
                align-items: center;
                width: 100%;
                color: #00AEC8;
                font-size: 20px;

                a{
                    cursor: pointer;
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
    }

    .paginate{
        width: 100%;
        height: 30px;
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .pagination{
            display: flex;
            height: 100%;
            list-style-type: none;
            
            li{
                list-style-type: none;

                a{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            
        }

        div, li{
            height: 100%;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #aaa;
            border-top: 1px solid #aaa;
            border-left: 1px solid #aaa;
        }

        .previous {
            width: 70px;
            font-size: 16px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .next{
            width: 70px;
            border-right: 1px solid #aaa;
            border-top-right-radius: 5px;
            font-size: 16px;
            border-bottom-right-radius: 5px;
        }

        li:hover {
            color: white;
            background: #0A677C;
            cursor: pointer;
        }

        .active {
            background: #0A677C;
            color: white;
        }
    }
`