import React, { useEffect, useState } from 'react';
import { Body, Menu, ItemsContainer, Tag, Favorite, OurProperty, Description, ItemHeader, SeeMore } from './styles'

function OrderData({data, filterTag, ItemHeader, Link, HeadImage}) {

  return (
    <>
    {
        data.map((value, index) => (
          <Link
            onClick={(e) => {
              if (document.querySelector("#scriptBack")) {
                document
                  .querySelector("body")
                  .removeChild(document.querySelector("#scriptBack"));
                window.onscroll = (e) => {};
              }
              localStorage.setItem(
                "tela",
                JSON.stringify(window.scrollY)
              );
            }}
            to={`/imoveis/${value._id}${
              new URLSearchParams(window.location.search).has("app")
                ? "?app"
                : ""
            }`}
            key={value._id}
          >
            {HeadImage(value, index)}

            <Description>
              <div className="row">
                <p className="city">
                  {
                    /*value.city.name*/ value.neighborhood
                      ? value.neighborhood.name
                      : ""
                  }
                </p>
                <p className="price">
                  {value.descountPrice &&
                  value.descountPrice != value.sellingPrice &&
                  value.descountPrice < value.sellingPrice
                    ? // Intl.NumberFormat('pt-BR', {
                      //   style: 'currency',
                      //   currency: 'BRL',
                      // }).format(+value.descountPrice)
                      `R$ ${value.descountPrice.toLocaleString("pt-BR")}`
                    : // Intl.NumberFormat('pt-BR', {
                      //   style: 'currency',
                      //   currency: 'BRL',
                      // }).format(+value.sellingPrice)
                      `R$ ${value.sellingPrice.toLocaleString("pt-BR")}`}
                </p>
              </div>
              <div className="row">
                <p className="street">
                  {localStorage.getItem("id")
                    ? value.address
                    : value.address}
                  {localStorage.getItem("id") &&
                  String(value.showNumber).toString() == "true" &&
                  value.addressNumber
                    ? // <span className="number">
                      ", " + value.addressNumber
                    : // </span>
                      ""}
                  {localStorage.getItem("id") &&
                  value.showComplement &&
                  value.addressComplement
                    ? //<span className="number">
                      " / " + value.addressComplement
                    : //</span>
                      ""}
                </p>

                {localStorage.getItem("id") ? (
                  <p className="commission">
                    Comissão <span>{value.comission}%</span>
                    {/*<span>6%</span></p>*/}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="row faq">
                {value.sold ? (
                  <strong>{`Vendido ${
                    value.soldDate ? "em " + value.soldDate : ""
                  } por ${
                    value.vendedor ? value.vendedor : "Corretor Autônomo"
                  } ${!value.soldDate ? "(sem data)" : ""}`}</strong>
                ) : (
                  <>
                    <div className="icon-group">
                      <img
                        className="ruler"
                        src={require("../../assets/outline_icons/grey/ruler.png")}
                        alt=""
                      />
                      <p>{value.fullLandSize} m²</p>
                    </div>
                    <div className="icon-group">
                      <img
                        className="parking"
                        src={require("../../assets/outline_icons/grey/car_parking.png")}
                        alt=""
                      />
                      <p>
                        {value.parkingSpots}{" "}
                        {value.parkingSpots > 1 ? "vagas" : "vaga"}
                      </p>
                    </div>
                    <div className="icon-group">
                      <img
                        className="bed"
                        src={require("../../assets/outline_icons/grey/bed.png")}
                        alt=""
                      />
                      <p>
                        {value.bedrooms}{" "}
                        {value.bedrooms > 1 ? "quartos" : "quarto"}
                      </p>
                    </div>
                    <div className="icon-group">
                      <img
                        className="bed"
                        src={require("../../assets/outline_icons/grey/bed.png")}
                        alt=""
                      />
                      <p>
                        {value.suites}{" "}
                        {value.suites > 1 ? "suítes" : "suíte"}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </Description>
          </Link>
        ))
      }
    </>
  )
}

export default OrderData;