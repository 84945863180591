import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import {
  Body,
  Menu,
  ItemsContainer,
  Tag,
  Favorite,
  OurProperty,
  Description,
  ItemHeader,
  SeeMore,
} from "./styles";
import moment from "moment";
import ModalFilter from "./ModalFilter";
import OrderComponent from "./OrderComponent";
import api from "../../api";

function PropertiesList() {
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 20,
    pages: 0,
    currentPage: 0,
    prevPage: null,
    nextPage: 0,
  });
  const [verification, setVerification] = useState(true);
  const [dataOrder, setDataOrder] = useState([]);

  const [data, setData] = useState([]);
  const [modalAlter, setModalAlter] = useState("none");
  const [scrollY, setScrollY] = useState();
  const [page, setPage] = useState(1);
  const [reff, setReff] = useState(false);

  useEffect(() => {
    if (reff == true) {
      backLocation();
    } else {
      setReff(true);
    }
  }, [data]);
  // useEffect(() => {
  //   backLocation()
  // }, [])

  function backLocation() {
    if (document.querySelector("#scriptBack")) {
      document
        .querySelector("body")
        .removeChild(document.querySelector("#scriptBack"));
    }

    let script = document.createElement("script");
    script.id = "scriptBack";

    script.innerHTML = `
      var posicao = localStorage.getItem('tela');
      if(posicao) {
        setTimeout(function() {
            window.scrollTo(0, posicao);
        }, 1);
      }
      if(document.querySelector('#gambi').innerText == 'Imóveis à Venda'){
          window.onscroll = function (e) {
            posicao = window.scrollY;
            localStorage.setItem('tela', JSON.stringify(posicao));
        }
      }else{
        window.onscroll = (e) => {}
      }
    `;
    document.querySelector("body").appendChild(script);
  }

  async function fetchData(filterModal) {
    if (localStorage.getItem("id")) {
      api.get(`/user/access/${localStorage.getItem("id")}`);
    }
    const response = await api.get(
      `/property?page=${page}&arquive=false&limit=30&recent=true`
    );
    const { total, pages, limit, currentPage, prevPage, nextPage } =
      response.data;
    setPagination({ total, pages, limit, currentPage, prevPage, nextPage });

    if (filterModal) {
      var newData = response.data.propertys;
    } else {
      var newData = data.concat(response.data.propertys);
    }

    newData.sort((a, b) => {
      const aAux = a?.soldDate?.split('/').reverse().join('');
      const bAux = b?.soldDate?.split('/').reverse().join('');
      if(!bAux) return -1
      return aAux < bAux ? 1 : aAux > bAux ? -1 : 0;
    });

    newData.map((obj) => {
      obj.images.sort((a, b) => {
        if (a["order"] > b["order"]) {
          return 1;
        } else {
          return -1;
        }
      });
      return obj;
    });

    newData.sort((a, b) => {
      if(!a.sold){
        if (a["orderPageList"] < b["orderPageList"]) {
          return 1;
        } else if (a["orderPageList"] == undefined) {
          return 1;
        } else {
          return -1;
        }
      }else{
        return 1
      }
    });

    setData(newData);
  }

  function filterTag(value) {
    if (value != undefined) {
      if (Date.parse(value.createdAt)) {
        const date = new Date(value.createdAt);

        const createdAt = moment(date).diff(moment(Date.now()));

        const age = moment.duration(createdAt);

        let datase =
          value.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (value.sold) {
          value.tag = {
            name: "VENDIDO",
            color: "#FF0000",
          };

          return null;
        }

        if (value.showTag && value.tag) {
          value.tag = {
            ...value.tag,
            color: "#00AEC8",
          };
          return null;
        }

        if (diffDays <= 2) {
          value.tag = {
            name: "NOVIDADE",
            color: "#1C9C40",
          };

          return null;
        }

        if (
          value.descountPrice &&
          value.descountPrice != value.sellingPrice &&
          value.descountPrice < value.sellingPrice
        ) {
          value.tag = {
            name:
              "DESCONTO DE " +
              Math.abs(
                Math.floor(
                  (value.descountPrice / value.sellingPrice) * 100 - 100
                )
              ).toString() +
              "%",
            color: "#EA1E1E",
          };

          return null;
        }

        value.tag = {
          ...value.tag,
          color: "#00AEC8",
        };

        if (value.tag) {
          if (!value.tag.name) {
            value.tag.color = "transparent";
            value.tag.name = "";
            return null;
          }
        }
      } else {
        const createdAt = moment(value.createdAt, "DD/MM/YYYY HH:mm").diff(
          moment(Date.now()),
          "days"
        );

        let datase =
          value.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (value.sold) {
          value.tag = {
            name: "VENDIDO",
            color: "#FF0000",
          };
          return null;
        }

        if (value.showTag && value.tag) {
          value.tag = {
            ...value.tag,
            color: "#00AEC8",
          };
          return null;
        }

        if (diffDays <= 2) {
          value.tag = {
            name: "NOVIDADE",
            color: "#1C9C40",
          };

          return null;
        }

        if (
          value.descountPrice &&
          value.descountPrice != value.sellingPrice &&
          value.descountPrice < value.sellingPrice
        ) {
          value.tag = {
            name:
              "DESCONTO DE " +
              Math.abs(
                Math.floor(
                  (value.descountPrice / value.sellingPrice) * 100 - 100
                )
              ).toString() +
              "%",
            color: "#EA1E1E",
          };

          return null;
        }

        value.tag = {
          ...value.tag,
          color: "#00AEC8",
        };

        if (value.tag) {
          if (!value.tag.name) {
            value.tag.color = "transparent";
            value.tag.name = "";
            return null;
          }
        }
      }
    }
  }

  useEffect(() => {
    var url = window.location.search.replace("?", "");
    var items = url.split("&");
    if (items.length == 1) {
      if (page == 1) {
        fetchData(true);
      } else {
        fetchData();
      }
    } else {
      handleFilterQuery();
    }
  }, [page]);

  useEffect(() => {
    if (window.scrollY != 0 && scrollY < window.scrollY) {
      window.scrollTo(0, window.scrollY - (window.scrollY - scrollY));
    }
  }, [data]);

  async function handleFilterQuery() {
    var url = window.location.search.replace("?", "");
    var items = url.split("&");
    if (items.length > 0) {
      let query = "";
      items.map((querys) => {
        query += querys + "&";
      });
      let { data } = await api.get(`/property?${query}&limit=30&arquive=false`);
      setData(data.propertys);
      const { total, pages, limit, currentPage, prevPage, nextPage } = data;
      setPagination({ total, pages, limit, currentPage, prevPage, nextPage });
    }
  }

  async function handleOrder(order) {
    setDataOrder([]);
    switch (order) {
      case "recent":
        var response = await api.get(
          `/property?limit=30&arquive=false&recent=true`,
          { headers: { orderby: JSON.stringify({ _id: -1 }) } }
        );
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        break;

      case "antigo":
        var response = await api.get(`/property?limit=30&arquive=false`, {
          headers: { orderby: JSON.stringify({ sold: 1, _id: 1 }) },
        });
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        break;
      case "maior-menor":
        var response = await api.get(`/property?limit=30&arquive=false`, {
          headers: { orderby: JSON.stringify({ sold: 1, sellingPrice: -1 }) },
        });
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        break;

      case "menor-maior":
        var response = await api.get(`/property?limit=30&arquive=false`, {
          headers: { orderby: JSON.stringify({ sold: 1, sellingPrice: 1 }) },
        });
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        break;

      case "me-maior-menor":
        var response = await api.get(`/property?limit=30&arquive=false`, {
          headers: { orderby: JSON.stringify({ sold: 1, fullLandSize: -1 }) },
        });
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        break;

      case "me-menor-maior":
        var response = await api.get(`/property?limit=30&arquive=false`, {
          headers: { orderby: JSON.stringify({ sold: 1, fullLandSize: 1 }) },
        });
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        break;

      case "a-z":
        var sortDatas = data.sort(function (a, b) {
          if(!a.sold){
            var textA = "";
            var textB = "";
            if (a.neighborhood && b.neighborhood) {
              textA = a.neighborhood.name.toUpperCase();
              textB = b.neighborhood.name.toUpperCase();
            }

            return textA.localeCompare(textB);
          }else{
            return 1
          }
        });

        if (!verification) {
          setData(sortDatas);
          setVerification(true);
        } else {
          setDataOrder(sortDatas);
          setVerification(false);
        }
        break;

      case "z-a":
        var sortDatas = data
          .sort(function (a, b) {
            if(!a.sold){
              var textA = "";
              var textB = "";
              if (a.neighborhood && b.neighborhood) {
                textA = a.neighborhood.name.toUpperCase();
                textB = b.neighborhood.name.toUpperCase();
              }

              return textA.localeCompare(textB);
            }else{
              return -1
            }
          })
          .reverse();

        if (!verification) {
          setData(sortDatas);
          setVerification(true);
        } else {
          setDataOrder(sortDatas);
          setVerification(false);
        }
        break;
    }
  }

  function HeadImage(value, index) {
    let ImagesTrue = [];
    for (let i = 0; i < value.images.length; i++) {
      if (value.images[i].show != "false") {
        ImagesTrue.push(value.images[i]);
      }
    }

    return (
      <ItemHeader
        image={
          ImagesTrue[0]
            ? `'${process.env.REACT_APP_API_URL}/file/${value._id}/fotos/${ImagesTrue[0].image}'`
            : ""
        }
        style={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
      >
        {filterTag(value)}
        {/* value.tag.name == 'VENDIDO'? {width: '120%', top: '38%', left: '-10%', height: '60px', transform: 'rotate(29deg)'}:{} */}
        {value.tag !== null ? (
          value.tag.name == "VENDIDO" ? (
            <Tag
              background={value.tag ? value.tag.color : ""}
              style={{
                width: "120%",
                top: "38%",
                left: "-10%",
                height: "60px",
                transform: "rotate(29deg)",
              }}
            >
              <h6 style={{ fontSize: 23 }}>
                {value.tag ? value.tag.name : ""}
              </h6>
            </Tag>
          ) : (
            <Tag background={value.tag ? value.tag.color : ""}>
              <h6>{value.tag ? value.tag.name : ""}</h6>
            </Tag>
          )
        ) : (
          ""
        )}

        {/* <Favorite /> */}

        {/* <OurProperty >
          <h6>
            {
              value.owner ? 'PROPRIEDADE IMÓVEL VAZIO' : null
            }
          </h6>
        </OurProperty> */}
      </ItemHeader>
    );
  }

  return (
    <>
      <ModalFilter
        flex={modalAlter}
        setFlex={setModalAlter}
        setPropertys={setData}
        removeFilter={fetchData}
        setPage={setPage}
      />
      <Header />
      <Body>
        <h1 id="gambi">Imóveis à Venda</h1>

        <Menu>
          {/* <Link>Visão de Corretor de Imóveis</Link> */}

          <div className="input-group">
            <div
              className="filters"
              onClick={(item) => {
                document.querySelector("body").style.overflow = "hidden";
                window.scrollTo(0, 0);
                setModalAlter("flex");
              }}
              name=""
              id=""
            >
              Filtro
            </div>
            <select
              style={{ width: window.innerWidth <= 600 ? "100%" : "310px" }}
              onChange={(option) => handleOrder(option.target.value)}
            >
              <option value="">Ordenar por</option>
              <option value="recent">Mais Recentes</option>
              <option value="antigo">Mais Antigos</option>
              <option value="maior-menor">Valor (Maior-Menor)</option>
              <option value="menor-maior">Valor (Menor-Maior)</option>
              <option value="me-maior-menor">Metragem (Maior-Menor)</option>
              <option value="me-menor-maior">Metragem (Menor-Maior)</option>
              <option value="a-z">Bairro (A-Z)</option>
              <option value="z-a">Bairro (Z-A)</option>
            </select>
          </div>
        </Menu>

        <ItemsContainer>
          {!verification ? (
            <OrderComponent
              HeadImage={HeadImage}
              ItemHeader={ItemHeader}
              Link={Link}
              filterTag={filterTag}
              data={dataOrder}
            />
          ) : (
            ""
          )}
          {verification
            ? data.map((value, index) => (
                <Link
                  onClick={(e) => {
                    if (document.querySelector("#scriptBack")) {
                      document
                        .querySelector("body")
                        .removeChild(document.querySelector("#scriptBack"));
                      window.onscroll = (e) => {};
                    }
                    localStorage.setItem(
                      "tela",
                      JSON.stringify(window.scrollY)
                    );
                  }}
                  to={`/imoveis/${value._id}${
                    new URLSearchParams(window.location.search).has("app")
                      ? "?app"
                      : ""
                  }`}
                  key={value._id}
                >
                  {HeadImage(value, index)}

                  <Description>
                    <div className="row">
                      <p className="city">
                        {
                          /*value.city.name*/ value.neighborhood
                            ? value.neighborhood.name
                            : ""
                        }
                      </p>
                      <p className="price">
                        {value.descountPrice &&
                        value.descountPrice != value.sellingPrice &&
                        value.descountPrice < value.sellingPrice
                          ? // Intl.NumberFormat('pt-BR', {
                            //   style: 'currency',
                            //   currency: 'BRL',
                            // }).format(+value.descountPrice)
                            `R$ ${value.descountPrice.toLocaleString("pt-BR")}`
                          : // Intl.NumberFormat('pt-BR', {
                            //   style: 'currency',
                            //   currency: 'BRL',
                            // }).format(+value.sellingPrice)
                            `R$ ${value.sellingPrice.toLocaleString("pt-BR")}`}
                      </p>
                    </div>
                    <div className="row">
                      <p className="street">
                        {localStorage.getItem("id")
                          ? value.address
                          : value.address}
                        {localStorage.getItem("id") &&
                        String(value.showNumber).toString() == "true" &&
                        value.addressNumber
                          ? // <span className="number">
                            ", " + value.addressNumber
                          : // </span>
                            ""}
                        {localStorage.getItem("id") &&
                        value.showComplement &&
                        value.addressComplement
                          ? //<span className="number">
                            " / " + value.addressComplement
                          : //</span>
                            ""}
                      </p>

                      {localStorage.getItem("id") ? (
                        <p className="commission">
                          Comissão <span>{value.comission}%</span>
                          {/*<span>6%</span></p>*/}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row faq">
                      {value.sold ? (
                        <strong>{`Vendido ${
                          value.soldDate ? "em " + value.soldDate : ""
                        } por ${
                          value.vendedor ? value.vendedor : "Corretor Autônomo"
                        } ${!value.soldDate ? "(sem data)" : ""}`}</strong>
                      ) : (
                        <>
                          <div className="icon-group">
                            <img
                              className="ruler"
                              src={require("../../assets/outline_icons/grey/ruler.png")}
                              alt=""
                            />
                            <p>{value.fullLandSize} m²</p>
                          </div>
                          <div className="icon-group">
                            <img
                              className="parking"
                              src={require("../../assets/outline_icons/grey/car_parking.png")}
                              alt=""
                            />
                            <p>
                              {value.parkingSpots}{" "}
                              {value.parkingSpots > 1 ? "vagas" : "vaga"}
                            </p>
                          </div>
                          <div className="icon-group">
                            <img
                              className="bed"
                              src={require("../../assets/outline_icons/grey/bed.png")}
                              alt=""
                            />
                            <p>
                              {value.bedrooms}{" "}
                              {value.bedrooms > 1 ? "quartos" : "quarto"}
                            </p>
                          </div>
                          <div className="icon-group">
                            <img
                              className="bed"
                              src={require("../../assets/outline_icons/grey/bed.png")}
                              alt=""
                            />
                            <p>
                              {value.suites}{" "}
                              {value.suites > 1 ? "suítes" : "suíte"}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </Description>
                </Link>
              ))
            : ""}
        </ItemsContainer>
        {page <= pagination.pages ? (
          <SeeMore>
            <button
              onClick={() => {
                setScrollY(window.scrollY);
                setPage(page + 1);
              }}
            >
              Exibir mais imóveis
            </button>
          </SeeMore>
        ) : (
          ""
        )}
      </Body>
      <Footer />
    </>
  );
}

export default PropertiesList;
