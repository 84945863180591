import React, { useEffect, useState } from 'react'
import Header from '../../components/header'
import { BgImg, FilterContainer, Announcement, SellingProperties, BtnContainer, MarketingContainer, PropertyOffer, Bank } from './styles'
import Footer from '../../components/Footer'
import '../../global.css';
import { Link } from 'react-router-dom'
import Autocomplete from '../../components/autosuggest'
import Carousel from '../../components/carousel/index'
import api from '../../api'

export default function Home() {
    const [city, setCity] = useState('')
    const [bairro, setBairro] = useState([])
    const [tipo, setTipo] = useState([])
    const [citySelected, setCitySelected,] = useState('')
    const [bairroSelected, setBairroSelected] = useState('')
    const [tipoSelected, setTipoSelected] = useState('')

    useEffect(() => {
        handleLoad()

        document.querySelectorAll('.react-autosuggest__input')[2].addEventListener('keyup', (e) => money(e))
    }, [])

    async function handleLoad(){
        if(localStorage.getItem('id')){
            api.get(`/user/access/${localStorage.getItem('id')}`)
        }

        api.get('/city?stateName=Rio de Janeiro').then((res) => {
            let citysOptions = new Array()
            res.data.citys.map(item => {
                citysOptions.push({value: item._id, name: item.name2})
            })
            setCity(citysOptions)
        })
        api.get('/neighborhood?city='+7043).then((res) => {
            let bairros = new Array()
            res.data.neighborhoods.map(item => {
                bairros.push({value: item._id, name: item.name})
            })
            setBairro(bairros)
        })

        api.get('/propertyType/').then((res) => {
            let tipos = new Array()
            res.data.propertyTypes.map(item => {
                tipos.push({value: item._id, name: item.name})
            })
            setTipo(tipos)
        })
    }
    function money(value) {
        value = String(value.target.value)
        return document.querySelectorAll('.react-autosuggest__input')[2].value = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    async function handleSend(){
        let valor = document.querySelectorAll('.react-autosuggest__input')[2].value
        valor = removeMoney(valor)
        let querys = `?`
        if(citySelected){
            querys += `city_id=${citySelected}&`
        }
        if(bairroSelected){
            querys += `neighborhood_id=${bairroSelected}&`
        }
        if(tipoSelected){
            querys += `generalTypes=${tipoSelected}&`
        }

        if(valor != '.' && valor){
            querys +=`sellingPriceMin=0&sellingPrice=${valor}&no_selling=true`
        }

        window.location.href='/imoveis'+querys
    }
    
    function removeMoney(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }

    return (
        <>
            <BgImg>
                <Header />
                <FilterContainer>
                    <div className="text-wrapper">
                        <h1>O imóvel ideal está a {window.innerWidth <= 600 ? '' : <br />}
                    poucos cliques de você</h1>
                    </div>
                    <div className="filter-wrapper">
                        <div className="input-wrapper">
                            <Autocomplete placeholder="Selecione" onChange={(value) => console.log(value)} inputName="Estado" objs={[]} defaultValue={'Rio de janeiro'} />
                        </div>
                        <div className="input-wrapper">
                            <Autocomplete placeholder="Selecione" onChange={(value) => setCitySelected(value.value)} defaultValue={'Rio de Janeiro'} inputName="Cidade" objs={city} />
                        </div>
                        <div className="input-wrapper">
                            {/* <Autocomplete placeholder="Selecione" onChange={(value) => setBairroSelected(value.value)} inputName="Bairro" objs={bairro} /> */}
                            <small>
                                Bairro
                            </small>
                            <select  onChange={value => setBairroSelected(value.target.value)}>
                                    <option>Selecione</option>
                                {
                                    bairro.map(value => (
                                        <option value={value.value}>{value.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="input-wrapper">
                            {/* <Autocomplete placeholder="Selecione" onChange={(value) => setTipoSelected(value.value)} inputName="Tipo" objs={tipo} /> */}
                            <small>
                                Tipo
                            </small>
                            <select  onChange={value => setTipoSelected(value.target.value)}>
                                    <option>Selecione</option>
                                {
                                    tipo.map(value => (
                                        <option value={value.value}>{value.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="input-wrapper">
                            <Autocomplete placeholder="Digite o valor" inputName="Preço até" objs={[]} />
                        </div>
                        <Link to={window.location.pathname} className="searchBtn" onClick={handleSend}>
                            <img src={require('../../assets/search.svg')} alt="" />
                            <p>Buscar</p>
                        </Link>
                    </div>
                </FilterContainer>
            </BgImg>

            <Announcement className="announcement">
                <div><h2>Quer vender um imóvel?</h2><h2 style={{cursor: "pointer"}} onClick={e => window.location.href=`/ofertar-imovel${new URLSearchParams(window.location.search).has('app')?'?app':''}`}>A Imóvel Vazio pode comprar o seu imóvel</h2></div>
            </Announcement>

            <SellingProperties>
                <h1 className="title">Imóveis à venda</h1>
                <p>Amplie o seu portfólio de imóveis e acelere as suas vendas</p>
                <Carousel className="carousel" />

                <BtnContainer style={{marginTop: -5}}>
                    <Link className="verTodos" to={`/imoveis${new URLSearchParams(window.location.search).has('app')?'?app':''}`}>Ver todos os imóveis que Corretores podem vender</Link>
                </BtnContainer>
            </SellingProperties>

            <MarketingContainer>
                <h1 style={{fontSize: 36}}>Por que a Imóvel Vazio é a melhor opção para corretores?</h1>
                <p>Saiba porque somos pioneiros no mercado</p>

                <section className="images">
                    <img style={{width: window.innerWidth <= 600 ? 'inherit': ''}} src={require('../../assets/home/aboutUs.svg')} alt="A Imóvel Vazio é um grupo de investimento imobiliário que atua há mais de 10 anos no mercado. Possuímos imóveis em diferentes bairros do Estado do Rio de Janeiro, disponíveis para venda." />
                    <img style={{width: window.innerWidth <= 600 ? 'inherit': ''}} src={require('../../assets/home/ourProperties.svg')} alt="Todos os  nossos imóveis são legalizados, com possibilidade de financiamento pela rede bancária. Em sua grande maioria, eles já estão reformados e prontos para morar. E todos eles contam com serviços totalmente gratuitos de despachante e registro, para facilitar o trabalho do corretor.   " />
                    <img style={{width: window.innerWidth <= 600 ? 'inherit': ''}} src={require('../../assets/home/ourPrice.svg')} alt="Buscamos sempre ter o melhor preço de mercado e qualidade do imóvel disponibilizado, de forma a ter alta liquidez, e trazendo retorno mais rápido para o corretor que está trabalhando nossos imóveis. Além disso, oferecemos a melhor taxa de corretagem do mercado! " />
                </section>
            </MarketingContainer>


            <PropertyOffer>
                <h1>
                    Compramos seu Imóvel
                </h1>
                <p><strong>Quer vender um imóvel?</strong> A Imóvel Vazio pode comprar o seu imóvel</p>
                <Link onClick={e => window.scrollTo(0,0)} to={localStorage.getItem('id') ? `/ofertar-imovel${new URLSearchParams(window.location.search).has('app')?'?app':''}` : `/login${new URLSearchParams(window.location.search).has('app')?'?app':''}`}>
                    Cadastre a sua oferta
                </Link>
            </PropertyOffer>

            <Bank>
                <h1>Correspondente Bancário</h1>
                <p>Você precisa de aprovação bancária para o financiamento do seu cliente? <br /> Conte com os nossos especialistas e simplifique as suas vendas! </p>
                <Link onClick={e => window.scrollTo(0,0)} to={`correspondente-bancario${new URLSearchParams(window.location.search).has('app')?'?app':''}`}>Clique Aqui</Link>
            </Bank>



            <Footer />

        </>
    )
}