import React, { useEffect, useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import arrowleft from "../../assets/carousel/arrowLeft.svg";
import arrowright from "../../assets/carousel/arrowRight.svg";
import "../../global.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { Item, Card, Tag } from "./styles";
import api from "../../api";
export default function CustomCarousel({ itemsToShow = 3, showCard = true }) {
  const [carouselObj, setCarouselObj] = useState([]);
  const [show, setShow] = useState(false);
  const [nSlides, setSlides] = useState(3);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowleft} alt="" />
      ) : (
        <img src={arrowright} alt="" />
      );
    return (
      <button
        onClick={onClick}
        disabled={isEdge}
        style={{ background: "transparent", border: "none" }}
      >
        {pointer}
      </button>
    );
  };
  useEffect(() => {
    handleShowCarousel();
  }, []);

  async function handleShowCarousel() {
    const { data } = await api.get(`/property?showHome=true&orderby=true`, {
      headers: {
        orderby: JSON.stringify({ _id: -1 }),
      },
    });
    data.propertys.sort((a, b) => {
      if (!a.sold) {
        if (a["orderHome"] < b["orderHome"]) {
          return 1;
        } else if (a["orderHome"] == undefined) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 1;
      }
    });
    setCarouselObj(data.propertys);
  }

  function filterTag(value) {
    if (value != undefined) {
      if (Date.parse(value.createdAt)) {
        const date = new Date(value.createdAt);

        const createdAt = moment(date).diff(moment(Date.now()));

        let datase =
          value.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        const age = moment.duration(createdAt);

        if (value.sold) {
          value.tag = {
            name: "VENDIDO",
            color: "#FF0000",
          };

          return null;
        }

        if (value.showTag && value.tag) {
          value.tag = {
            ...value.tag,
            color: "#00AEC8",
          };
          return null;
        }

        if (diffDays <= 2) {
          value.tag = {
            name: "NOVIDADE",
            color: "#1C9C40",
          };

          return null;
        }

        if (
          value.descountPrice &&
          value.descountPrice != value.sellingPrice &&
          value.descountPrice < value.sellingPrice
        ) {
          value.tag = {
            name:
              "DESCONTO DE " +
              Math.abs(
                Math.floor(
                  (value.descountPrice / value.sellingPrice) * 100 - 100
                )
              ).toString() +
              "%",
            color: "#EA1E1E",
          };

          return null;
        }
        if (value.tag) {
          if (!value.tag.name) {
            value.tag.color = "transparent";
            value.tag.name = "";
            return null;
          }
        }

        value.tag = {
          ...value.tag,
          color: "#00AEC8",
        };
      } else {
        const createdAt = moment(value.createdAt, "DD/MM/YYYY HH:mm").diff(
          moment(Date.now()),
          "days"
        );

        let datase =
          value.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (value.sold) {
          value.tag = {
            name: "VENDIDO",
            color: "#FF0000",
          };
          return null;
        }

        if (value.showTag && value.tag) {
          value.tag = {
            ...value.tag,
            color: "#00AEC8",
          };
          return null;
        }

        if (diffDays <= 2) {
          value.tag = {
            name: "NOVIDADE",
            color: "#1C9C40",
          };

          return null;
        }

        if (
          value.descountPrice &&
          value.descountPrice != value.sellingPrice &&
          value.descountPrice < value.sellingPrice
        ) {
          value.tag = {
            name:
              "DESCONTO DE " +
              Math.abs(
                Math.floor(
                  (value.descountPrice / value.sellingPrice) * 100 - 100
                )
              ).toString() +
              "%",
            color: "#EA1E1E",
          };

          return null;
        }
        if (value.tag) {
          if (!value.tag.name) {
            value.tag.color = "transparent";
            value.tag.name = "";
            return null;
          }
        }

        value.tag = {
          ...value.tag,
          color: "#00AEC8",
        };
      }
    }
  }

  function imagens(item) {
    let ImagesTrue = [];
    for (let i = 0; i < item.images.length; i++) {
      if (item.images[i].show != "false") {
        ImagesTrue.push(item.images[i]);
      }
    }
    return (
      <div
        className="img"
        style={{
          position: "relative",
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          backgroundImage:
            'url("' +
            process.env.REACT_APP_API_URL +
            `/file/${item._id}/fotos/${
              ImagesTrue.length > 0 ? ImagesTrue[0].image : ""
            }` +
            '")',
        }}
      >
        {filterTag(item)}
        {item.tag ? (
          item.tag.name == "VENDIDO" ? (
            <Tag
              background={item.tag ? item.tag.color : ""}
              style={{
                width: "120%",
                top: "38%",
                left: "-10%",
                height: "60px",
                transform: "rotate(32.5deg)",
              }}
            >
              <h6 style={{ fontSize: 23 }}>{item.tag ? item.tag.name : ""}</h6>
            </Tag>
          ) : (
            <Tag background={item.tag ? item.tag.color : ""}>
              <h6>{item.tag ? item.tag.name : ""}</h6>
            </Tag>
          )
        ) : (
          ""
        )}
      </div>
    );
  }

  useEffect(
    (e) => {
      if (window.innerWidth <= 600) {
        setSlides(1);
      } else {
        setSlides(3);
      }
    },
    [window.innerWidth]
  );
  return (
    <Carousel
      className="carousel"
      itemsToShow={nSlides}
      renderArrow={myArrow}
      style={{ cursor: "grab", height: "500px", width: "100%" }}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <div>
            {pages.map((page) => {
              const isActivePage = activePage === page;
            })}
          </div>
        );
      }}
    >
      {/* {
                show ?
                <Card>
                    <img src={require('../../assets/carousel/cardText.svg')} alt="Tenha acesso a todas as informações do imóvel para realizar a venda e ganhar a comissão!" />
                    <p>Na Imóvel Vazio você tem uma área exclusiva para relacionamento e negócios. Nosso objetivo é facilitar o seu dia-a-dia e ampliar suas oportunidades. <strong>Venha fazer parte!</strong></p>
                    <Link>Cadastre-se como Corretor</Link>
                </Card>
                : ''
                    
            } */}

      {carouselObj.map((item) => (
        <Link
          key={item._id}
          style={{
            textDecoration: "none",
            marginLeft: window.innerWidth <= 600 ? 0 : 15,
            marginRight: window.innerWidth <= 600 ? 0 : 15,
          }}
          to={`imoveis/${item._id}${
            new URLSearchParams(window.location.search).has("app") ? "?app" : ""
          }`}
        >
          <Item key={item._id}>
            {imagens(item)}

            <div className="description">
              <div className="address">
                <h4 className="neighborhood">
                  {item.neighborhood ? item.neighborhood.name : ""}
                </h4>
                {item.descountPrice ? (
                  <h4 className="price">
                    R$ {item.descountPrice.toLocaleString("pt-BR")}
                  </h4>
                ) : item.sellingPrice ? (
                  <h4 className="price">
                    R$ {item.sellingPrice.toLocaleString("pt-BR")}
                  </h4>
                ) : (
                  ""
                )}

                {/* {item.sellingPrice?<h4 className="price">R$ {item.sellingPrice.toLocaleString('pt-BR')}</h4>:''} */}
              </div>
              <div className="street">
                <p>
                  {item.address}
                  {localStorage.getItem("id")
                    ? (item.showNumber ? ", " + item.addressNumber + "" : "") +
                      (item.showComplement
                        ? item.addressComplement
                          ? " / " + item.addressComplement
                          : ""
                        : "")
                    : ""}
                </p>
              </div>
              <div className="items">
                <div className="item">
                  <img
                    src={require("../../assets/carousel/rule.svg")}
                    alt=""
                    className="item-1"
                  />
                  <p>{item.fullLandSize} m²</p>
                </div>
                <div className="item">
                  <img
                    src={require("../../assets/carousel/parking.svg")}
                    alt=""
                  />
                  <p>
                    {item.parkingSpots}{" "}
                    {item.parkingSpots > 1 ? "vagas" : "vaga"}
                  </p>
                </div>
                <div className="item">
                  <img
                    src={require("../../assets/carousel/bedroom.svg")}
                    alt=""
                  />
                  <p>
                    {item.bedrooms} {item.bedrooms > 1 ? "quartos" : "quarto"}
                  </p>
                </div>
                <div className="item">
                  <img
                    src={require("../../assets/carousel/bedroom.svg")}
                    alt=""
                  />
                  <p>
                    {item.suites} {item.suites > 1 ? "suítes" : "suíte"}
                  </p>
                </div>
              </div>
            </div>
          </Item>
        </Link>
      ))}
    </Carousel>
  );
}
