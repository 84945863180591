import React from 'react'
import Carousel, { consts } from 'react-elastic-carousel'
import arrowleft from '../../assets/carousel/arrowLeft.svg'
import arrowright from '../../assets/carousel/arrowRight.svg'
import '../../global.css';
import { Link } from 'react-router-dom'
import { Item, Card, Tag } from './styles'
import { useState } from 'react';
import moment from 'moment'
import api from '../../api'
export default function CustomCarousel({ itemsToShow = 3, showCard = true, id }) {
    const [data, setData] = useState([])
    useState(() => {
      handleLoadSimilar()
    }, [])

    async function handleLoadSimilar(){
        const {data} = await api.get(`/property?showHome=true&arquive=false&sold=false`).catch(err => console.log(err))
        
        let array = []
        data.propertys.map(prop => {
            if(prop._id != id){
                array.push(prop)
            }
        })
        setData(array)
        if(window.innerWidth <= 600) document.querySelectorAll('.rec-carousel')[0].style.width='117%'
    }

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <img src={arrowleft} alt="" /> : <img src={arrowright} alt="" />
        return (
            <button onClick={onClick} disabled={isEdge} style={{ background: 'transparent', border: 'none' }}>
                {pointer}
            </button>
        )
    }

    
    function filterTag(value) {

        if (value != undefined) {
    
          if (Date.parse(value.createdAt)) {
    
            const date = new Date(value.createdAt)
    
            const createdAt = moment(date)
              .diff(moment(Date.now()))
    
            const age = moment.duration(createdAt)

            let datase=value.createdAt.split(' ')[0].split('/')[2]+'-'+value.createdAt.split(' ')[0].split('/')[1]+'-'+value.createdAt.split(' ')[0].split('/')[0]
                
            var dataBanco = new Date(datase);
            
            if(isNaN(dataBanco.getTime())){
              dataBanco = new Date('2020','01','01')
            }               
            var date2 = new Date();
    
            var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                
            
            if (value.sold) {
    
              value.tag = {
                name: 'VENDIDO',
                color: "#1E1D1D"
              }
    
              return null
    
            }

            if(value.showTag && value.tag){
              value.tag = {
                ...value.tag,
                color: "#00AEC8"
              }
              return null
            }  
    
            if (diffDays <= 2) {
    
              value.tag = {
                name: 'NOVIDADE',
                color: "#1C9C40"
              }
    
              return null
            }
    
            if (value.descountPrice) {
    
              value.tag = {
                name: 'DESCONTO DE ' + Math.abs(Math.floor((value.descountPrice / value.sellingPrice) * 100 - 100)).toString() + '%',
                color: "#EA1E1E"
              }
    
              return null
    
            }
            if(value.tag){
              if(!value.tag.name){
                value.tag.color = 'transparent'
                value.tag.name=''
                return null
              }
            }
    
            value.tag = {
              ...value.tag,
              color: "#00AEC8"
            }

          } else {
            const createdAt = moment(value.createdAt, 'DD/MM/YYYY HH:mm')
              .diff(moment(Date.now()),'days')
    
              let datase=value.createdAt.split(' ')[0].split('/')[2]+'-'+value.createdAt.split(' ')[0].split('/')[1]+'-'+value.createdAt.split(' ')[0].split('/')[0]
                
              var dataBanco = new Date(datase);
              
              if(isNaN(dataBanco.getTime())){
                dataBanco = new Date('2020','01','01')
              }                  
              var date2 = new Date();
      
              var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
              var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
            
            if (value.sold) {
    
              value.tag = {
                name: 'VENDIDO',
                color: "#1E1D1D"
              }
              return null
    
            }
            
            if(value.showTag && value.tag){
              value.tag = {
                ...value.tag,
                color: "#00AEC8"
              }
              return null
            }  
    
            if (diffDays <= 2) {
    
              value.tag = {
                name: 'NOVIDADE',
                color: "#1C9C40"
              }
    
              return null
            }
    
            if (value.descountPrice) {
    
              value.tag = {
                name: 'DESCONTO DE ' + Math.abs(Math.floor((value.descountPrice / value.sellingPrice) * 100 - 100)).toString() + '%',
                color: "#EA1E1E"
              }
    
              return null
    
            }
            if(value.tag){
              if(!value.tag.name){
                value.tag.color = 'transparent'
                value.tag.name=''
                return null
              }
            }
    
            value.tag = {
              ...value.tag,
              color: "#00AEC8"
            }
          }
    
        }
    
    
      }

    return (
        <Carousel className="carousel"
            itemsToShow={itemsToShow}
            renderArrow={myArrow}
            style={{ cursor: 'grab',height:'500px',width:'100%' }}
            renderPagination={({ pages, activePage, onClick }) => {
                return (

                    <div>
                        {pages.map(page => {
                            const isActivePage = activePage === page

                        })}
                    </div>

                )
            }}
        >

            {
                data.map(item => ( 
                    <Link to={window.location.pathname} onClick={e => window.location.href = `/imoveis/${item._id}${new URLSearchParams(window.location.search).has('app')?'?app':''}`} key={item._id} style={{ textDecoration: 'none' }}>
        
                        <Item onClick={() => console.log('clicked')}>
                            <div style={{position: 'relative'}} className="img" image={item.images[0] ? `${process.env.REACT_APP_API_URL}/file/${item._id}/fotos/${item.images[0].image}` : ''}>
                                {
                                    filterTag(item)
                                }

                                <Tag background={item.tag ? item.tag.color : ''}>
                                    <h6>
                                    {item.tag ? item.tag.name : ''}
                                    </h6>
                                </Tag>

                            </div>
                            <div className="description">
                                <div className="address">
                                    <h4 className="neighborhood">{item.neighborhood.name}</h4>
                                    <h4 className="price">
                                      {
                                        item.descountPrice ? (
                                        // Intl.NumberFormat('pt-BR', {
                                        //     style: 'currency',
                                        //     currency: 'BRL',
                                        // }).format(+item.descountPrice)
                                        `R$ ${item.descountPrice.toLocaleString('pt-BR')}`
                                        ) : 
                                        // Intl.NumberFormat('pt-BR', {
                                        // style: 'currency',
                                        // currency: 'BRL',
                                        // }).format(+item.sellingPrice)
                                        `R$ ${item.sellingPrice.toLocaleString('pt-BR')}`
                                        }
                                    </h4>
                                </div>
                                <div className="street">
                                    <p>
                                        {/* Rua example{localStorage.getItem('id') ? ', 200' : ''} */}
                                        {
                                            localStorage.getItem('id') ? item.address : item.address
                                        }
                                        {localStorage.getItem('id') ?
                                            <span className="number">, {item.addressNumber}</span>
                                            : ''
                                        }

                                    </p>
                                </div>
                                <div className="items">
                                    <div className="item">
                                        <img src={require('../../assets/carousel/rule.svg')} alt="" className="item-1" />
                                        <p>{item.landSizeUseful} m²</p>
                                    </div>
                                    <div className="item">
                                        <img src={require('../../assets/carousel/parking.svg')} alt="" />
                                        <p>{item.parkingSpots} {item.parkingSpots > 1 ? 'vagas' : 'vaga'}</p>
                                    </div>
                                    <div className="item">
                                        <img src={require('../../assets/carousel/bedroom.svg')} alt="" />
                                        <p>{item.bedrooms} {item.bedrooms > 1 ? 'quartos' : 'quarto'}</p>
                                    </div>
                                    <div className="item">
                                        <img src={require('../../assets/carousel/bedroom.svg')} alt="" />
                                        <p>{item.suites} {item.suites > 1 ? 'suítes' : 'suíte'}</p>
                                    </div>
                                </div>
                            </div>
                        </Item>
                    </Link>
                ))
            }

        </Carousel>
    )
}
