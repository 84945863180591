import styled from 'styled-components';

export const Container = styled.div`

header{
    width:100vw;
    height:10vh;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
    padding-left:60px;
    padding-right:60px;
    flex-wrap:wrap;
    border-bottom: 1px solid#C4C4C4;
    background-color:#ffffff;
    z-index:1;
    position:fixed;
    
    @media (max-height: 800px){
        height:12vh;
        padding:5px;
        padding-left:60px;
        padding-right:60px;
        //position: unset;
    }
}

header .logo{
    height:100%;
    width:200px;
}

header .links {
    margin-top:30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width:300px;
    justify-content: space-evenly;

    @media (max-height: 800px){
        margin-top:10px;
    }
}

@media (max-width:600px){
    header{
        height:auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px;
    }

}

header .links .link{
    display: flex;
    align-items: center;
    height: 16px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #1E1D1D;
    text-decoration: none;
}

header .links button{
    background: transparent;
    border: none;
    outline: none;
}

header .links .link img{
    padding-right:10px;
}


  
`;
