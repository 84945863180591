import React, {useEffect, useState} from 'react'
import Header from '../../components/header'
import { BgImg, FilterContainer} from './styles'
import Footer from '../../components/Footer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

import Modal from './modal'
export default function UserLogin() {
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [corretor, setCorretor] = useState(true);
    const [proprietario, setProprietario] = useState(false)
    const [flex, setFlex] = useState(false)

    function handleChange(type){
        if(type != 'corretor'){
            setProprietario(true)
            setCorretor(false)
        }else if(type != 'proprietario'){
            setProprietario(false)
            setCorretor(true)
        }
    }
    useEffect(()=>{
        let teste = window.location.href
        if(teste.includes('modal=true')){
            setFlex(true)
            //window.location.href='/banking2'
        }
    }, [])

    async function handleLogin(){
        var teste = window.location.href
        
        if(email.length == 0){
            let emailBox = document.querySelector('.email-box')
            emailBox.style.border="2px solid red"
            setTimeout(() => {
                emailBox.style.border="none"
            }, 3000);
        }
        if(senha.length == 0){
            let passBox = document.querySelector('.password-box')
            
            passBox.style.border="2px solid red"
            setTimeout(() => {
                passBox.style.border="none"
            }, 3000);

            return
        }
        if(email.length > 0 && senha.length > 0){
            const {data} = await api.post('/user/login', {
                email: email,
                password: senha
            })

            if(data.error){
                return alert('Email ou senha incorreto.')
            }
            if(data){
                localStorage.setItem('id', data._id)
                localStorage.setItem('type', data.type)
                
                if(teste.includes('goBack=/imoveis/')){
                    window.location.href=teste.split('goBack=')[1]
                }else{
                    window.location.href="/imoveis"
                }

            }
        }
    }

    return (
        <>
            
            <BgImg>
                <Header />
                {
                    flex ? <Modal setFlex={setFlex}/>:''
                }
                
                <FilterContainer>
                    <div className="login-wrapper">
                        <div className="login-box">
                            <h1>Login</h1>
                            <div className="body-box">
                                <div className="email-box">
                                    <small>E-mail</small>
                                    <input value={email} onChange={text => setEmail(text.target.value)} />
                                </div>
                                <div className="password-box">
                                    <small>Senha</small>
                                    <input value={senha}  onChange={text => setSenha(text.target.value)} type='password'/>
                                </div>
                            </div>

                            <a onClick={handleLogin}>
                                <div className='bt-login'>Entrar</div>
                            </a>
                            <small onClick={e => {
                                window.scrollTo(0,0);
                                if(window.innerWidth <= 600){
                                    window.scrollTo(0,300);
                                }
                                document.querySelector('body').style.overflow='hidden'; 
                                setFlex(true)
                            }}>Esqueci a minha senha</small>
                        </div>
                            <div className="register-box">
                                <h1>Cadastre-se</h1>

                                {/* inicio  */}

                                <div className="body-box">
                                    <div className="group-box">
                                        <FormControlLabel
                                            
                                            control={
                                            <Checkbox
                                                checked={corretor}
                                                onChange={change=>handleChange('corretor')}
                                                name="checkedB"
                                                color="primary"
                                                
                                            />
                                            }
                                            label="Corretor de imóvel"
                                        />

                                        {/* <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={proprietario}
                                                onChange={change=>handleChange('proprietario')}
                                                name="checkedB"
                                                color="primary"
                                                
                                            />
                                            }
                                            label="Proprietário de imóvel"
                                        /> */}
                                    </div>
                                </div>

                                <Link to={corretor ? 'cadastro-corretor' : 'RegisterOwner'}>
                                    <div className='bt-register'>Cadastrar</div>
                                </Link>
                                <small>oi</small>


                                {/* fim  */}
                            </div>
                    </div>
                </FilterContainer>
            </BgImg>

            <Footer />

        </>
    )
}
