import React from 'react'
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';

import Home from './pages/Home'
import PropertiesList from './pages/PropertiesList'
import PropertyDetail from './pages/PropertyDetail'
import CBanking from './pages/CBanking'
import Login from './pages/AdminLogin'
import AdminPropertyOffer from './pages/AdminOfferProperty/index'
import UserLogin from './pages/UserLogin'
import Register from './pages/Register'
import RegisterOwner from './pages/RegisterOwner'
import AdminPropertyList from './pages/AdminPropertyList'
//import tableAvaliation from './pages/tableAvaliation'     BACKUP
import offertImovel from './pages/offertImovel'
import offerSolicitation from './pages/offerSolicitation'
import editOffer from './pages/offerSolicitation/editOffer'
import Privacy from './pages/Politics/Privacy'
import Realtor from './pages/Politics/Realtor'
import Site from './pages/Politics/Site'
import About from './pages/About'
import Contact from './pages/Contact-request'
import EditProperty from './pages/AdminOfferProperty/edit'

import AdminCorrespondenteList from './pages/AdminCorrespondenteList' //
import Visualization from './pages/AdminPropertyList/Visualization' //
import Interested from './pages/AdminPropertyList/Interested' //
import CBanking2 from './pages/CBanking2' //
import Negotiate from './pages/AdminCorrespondenteList/Negotiate' //
import AdminOrdenation from './pages/AdminOrdenation' //
import AdminMarketing from './pages/AdminMarketing' //
import AdminMarketingEdit from './pages/AdminMarketing/edit'
import AdminMetrica from './pages/AdminMetrica'
import AdminCreateTag from './pages/creationTag'
import HomeCorretor from './pages/HomeCorretor'
import PortfolioCorretor from './pages/PortfolioCorretor';
import VendaCorretor from './pages/VendaCorretor';
import SolicitacoesCorretor from './pages/SolicitacoesCorretor';
import AdminProfile from './pages/AdminProfile/edit';
import Download from './components/PhotosModal/download';
import PropostaCorretor from './pages/PropostasCorretor';
import PropostaCorretorEditar from './pages/PropostasCorretor/editar';
import PropostasAdmin from './pages/PropostasAdmin';
import PropostasAdminEditar from './pages/PropostasAdmin/editar';

export default function Routes(){
    return (
        <BrowserRouter>
            <Switch> 
                <Route path="/admin/home-corretor" component={HomeCorretor} /> 
                <Route path="/admin/meu-perfil" component={AdminProfile} />
                <Route path="/admin/portfolio-corretor" component={PortfolioCorretor} /> 
                <Route path="/admin/venda-corretor" component={VendaCorretor} /> 
                <Route path="/admin/solicitacoes-corretor" component={SolicitacoesCorretor} /> 
                <Route path="/admin/criacao-tag" component={AdminCreateTag} />
                <Route path="/admin/metricas-gerais" component={AdminMetrica} />
                <Route path="/admin/marketing/listar" component={AdminMarketing} />
                <Route path="/admin/marketing/editar" component={AdminMarketingEdit} />
                <Route path="/admin/correspondente/listar" component={AdminCorrespondenteList} />
                <Route path="/admin/correspondente/negotiate" component={Negotiate} />
                <Route path="/admin/ordenacao" component={AdminOrdenation} />
                <Route path="/admin/property/visualization" component={Visualization} />{/**/}
                <Route path="/admin/property/interested" component={Interested} />{/**/}
                <Route path="/correspondente-bancario" component={CBanking2}/>
                <Route path="/" exact component={Home}/>
                <Route path="/imoveis" exact component={PropertiesList} />
                <Route path="/imoveis/:id?"  component={PropertyDetail} />
                <Route path="/admin/login" component={Login} />
                <Route path="/admin/property/new" component={AdminPropertyOffer} />
                <Route path="/admin/property/edit" component={EditProperty} />
                <Route path="/admin/property/list" component={AdminPropertyList} />
                <Route path="/admin/propostas-admin/editar" component={PropostasAdminEditar} />
                <Route path="/admin/propostas-admin" component={PropostasAdmin} />
                <Route path="/admin/propostas-corretor/editar" component={PropostaCorretorEditar} />
                <Route path="/admin/propostas-corretor" component={PropostaCorretor} />
                {/* <Route path="/admin/tabela-avaliacao" component={tableAvaliation} />    BACKUP    */} 
                <Route path="/admin/solicitacao-ofertas" component={offerSolicitation} />
                <Route path="/admin/ofertas/editar" component={editOffer} />
                <Route path="/login" component={UserLogin} />
                <Route path="/cadastro-corretor" component={Register} />
                <Route path="/registerOwner" component={RegisterOwner} />
                <Route path="/ofertar-imovel" component={offertImovel} />
                <Route path="/politica-de-privacidade" component={Privacy} />
                <Route path="/politica-de-uso" component={Realtor} />
                <Route path="/termos-e-condicoes" component={Site} />
                <Route path="/sobre-nos" component={About} />
                <Route path="/contato" component={Contact} /> 
                <Route path="/d" component={Download} /> 
            </Switch>
        </BrowserRouter>
    )
}   