import React, {useEffect, useState, useRef} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'

export default function PortfolioCorretor() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const sellingNotes = useRef()
    const [index, setIndex] = useState(0)

    const [total, setTotal] = useState(0)

    const [neighborhoods, setNeighborhoods] = useState([])
    const [neighSelect, setNeighSelect] = useState([])
    const [city, setCity] = useState([])
    const [types, setTypes] = useState([])
    const [valorMaximo, setValorMaximo] = useState('R$ 0,00')
    const [valorMinimo, setValorMinimo] = useState('R$ 0,00')
    const [type, setType] = useState('')
    const [generalType, setGeneralType] = useState('')
    const [quartos, setQuartos] = useState('')
    const [Banheiros, setBanheiros] = useState('')
    const [vagas, setVagas] = useState('')
    const [areaMinima, setAreaMinima] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [status, setStatus] = useState('false')
    const [algo, setAlgo] = useState('')

    const [dataOrder, setDataOrder] = useState([])
    const [verification, setVerification] = useState(true)


    useEffect(() => {
        if(!localStorage.getItem('type_user')){
            window.location.href='/admin/login'
        }

        handleLogin()
    }, [])
    
    useEffect(() => {
        if (data[index] != undefined) {
            if(modal){
                sellingNotes.current.innerHTML = data[index].sellingNotes
            } 
        }
    }, [data])

    useEffect(() => {
        if (data[index] != undefined) {
            if(modal){
                sellingNotes.current.innerHTML = 'Sem instruções no momento.'
                if(data[index].sellingNotes){
                    sellingNotes.current.innerHTML = data[index].sellingNotes
                }
            } 
        }
    }, [modal])

    function removeMoney(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }
    
    async function handleApply(){
        let querys = `?interested=${localStorage.getItem('id_painel')}&`

        if(cidade){
            querys += `city_id=${cidade}&`
        }

        if(bairro){
            querys += `neigh_id=${bairro}&`
        }
         
        if(generalType){
            querys += `generalType=${generalType}&`
        }

        if(type){
            querys += `generalTypes=${type}&`
        }

        if(quartos){
            querys += `bedrooms=${quartos}&`
        }

        if(vagas){
            querys += `parkingSpots=${vagas}&`
        }
        
        if(Banheiros){
            querys += `bathrooms=${Banheiros}&`
        }
        //6989
        if(areaMinima){
            querys += `fullLandSize=${areaMinima}&`
        }

        if(valorMaximo != 'R$ 0,00'){
            querys += `sellingPrice=${removeMoney(valorMaximo)}&`
        }else{
            querys += `sellingPrice=${9000000000}&`
        }
        
        if(valorMinimo != 'R$ 0,00'){
            querys += `sellingPriceMin=${removeMoney(valorMinimo)}&`
        }

        if(algo){
            querys += `endereco=${algo}&`
        }

        if(status){
            if(status == 'arquivado'){
                querys += `arquive=true&`
            }else{
                querys += `sold=${status}&arquive=false&`
            }
        }
        
        console.log(querys)

        let {data} = await api.get(`/property${querys}&limit=10000`)
        
        setData(data.propertys)
    }
    
    function money(value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    function handleChangeCity(value){
        setNeighSelect(neighborhoods.filter(target => {
            return target.city == value
        }))
        
        setCidade(value)
    }

    function cleanStates(){
        setValorMaximo('R$ 0,00')
        setValorMinimo('R$ 0,00')
        setType('')
        setGeneralType('')
        setQuartos('')
        setBanheiros('')
        setVagas('')
        setAreaMinima('')
        setBairro('')
        setCidade('')
        setStatus('false')
        setAlgo('')
    }
    
    async function handleLogin(){
        cleanStates()
        // const response = await api.get(`/property/portfolio/${localStorage.getItem('id_painel')}`)
        const response = await api.get(`/property?limit=20&arquive=false&interested=${localStorage.getItem('id_painel')}`)
        if(response.data){
            setTotal(response.data.total)
            setData(response.data.propertys)
        }

        var {data} = await api.get('/city?limit=999&stateName=Rio de Janeiro')
        setCity([{_id: 7043, name: 'Rio de Janeiro'},{_id: 6989, name: 'Niterói'}])
        
        var {data} = await api.get('/neighborhood?limit=10000&city='+7043)
        setNeighborhoods(data.neighborhoods)
        setNeighSelect(data.neighborhoods)

        var {data} = await api.get('/propertyType')
        
        setTypes(data.propertyTypes)
    }

    function dateBr(value){
        return value.substr(8, 2)+'/'+value.substr(5, 2)+'/'+value.substr(0,4)
    }

    
    function copyTo(row){
        let text = `http://visualizarimovel.com.br/${row._id}`
        var textArea = document.createElement("textarea");
        
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
      
        document.body.appendChild(textArea);
        textArea.select();
      
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful'; 
            window.open(text);
        } catch (err) { 
            window.prompt("Copie para área de transferência: Ctrl+C e tecle Enter", text);
        }
      
        document.body.removeChild(textArea);
    }

    async function handleDelete(id){
        if(window.confirm('Deseja retirar esse imóvel de seu portfolio?')){
            
            let response = await api.delete('/property/portfolio/delete/'+id+'/'+localStorage.getItem('id_painel'))
            
            if(response.data.deletedId){
                handleLogin()
            }
        }
    }

    
function innerRow(row, i){    
    return (
        <>
            <div className="body-active">
            {
                row.sold ? 
                    <div className="home">
                        <img src={require('./icons/error.svg')}/>
                        Inativo
                    </div>
                :
                <div className="home">
                    <img src={require('./icons/success.svg')}/>
                    Ativo
                </div>
            }
        </div>
        <div className="body-imovel">
            <small>Cadastrado em: {String(row.createdAt).split(' ')[0].includes('/') ? String(row.createdAt).split(' ')[0] : dateBr(String(row.createdAt).split(' ')[0])}</small>
            {/* {row.sellingPrice} */}
            <h2 style={{fontSize: 18}}><a style={{color: 'inherit'}}>{row.address ? row.address : ''}{row.addressNumber ? ', '+row.addressNumber : ''} {row.addressComplement? ` / ${row.addressComplement}`:''}</a></h2>
            <h3>{row.neighborhood ? row.neighborhood.name : ''}{row.city ? " - "+row.city.name : ''}{row.neighborhood ? ', '+row.neighborhood.state : ''}</h3>
            
            <h5>{row.type ? row.type.name : ''} {row.generalType ? row.generalType.name : (row.type ? row.type.generalType  == '2372820374682508' ? 'Residencial' : 'Comercial':'')}</h5>
                
        </div>
        <div className="body-engajamento">
            <p>
                Ajudando na venda desse imóvel, você ganhará uma comissão de: <strong>{row.comission? row.comission+'%':'6%'}</strong>
            </p>
        </div>
        <div className="body-actions">
            <div style={{color: row.sold ? '#969696': ''}}>
                <a style={{cursor: !row.sold ? 'pointer':'default'}} onClick={i => {
                    if(!row.sold){
                        copyTo(row)
                    }
                    // window.location.href=`http://visualizarimovel.com.br/${row._id}`
                }} type='_blank'>Link público do imóvel</a>
            </div>
            <div style={{color: row.sold ? '#969696': '', cursor: !row.sold ? 'pointer':'default'}}>
                <a style={{cursor: !row.sold ? 'pointer':'default'}} onClick={() => {
                    if(!row.sold){
                        setIndex(i)
                        setModal(true)
                    }
                }}>Instruções de venda</a>
            </div>
            <div>
                <a onClick={() => handleDelete(row._id)}>Excluir do Meu Portfólio</a>
            </div>
        </div>
    </>
    )
}

async function handleOrder(order){
    setDataOrder([])
    switch(order){
        case 'recent': 
                var response = await api.get(`/property?limit=20&interested=${localStorage.getItem('id_painel')}`, {headers: {orderby: JSON.stringify({_id: -1})}})
                console.log(response.data.propertys)
                
                setData(response.data.propertys)
                setTotal(response.data.total)
                setVerification(true)
                setDataOrder([])
                
            break;

        case 'antigo': 
                var response = await api.get(`/property?limit=20&interested=${localStorage.getItem('id_painel')}`, {headers: {orderby: JSON.stringify({_id: 1})}})
                
                setData(response.data.propertys)
                setTotal(response.data.total)
                setVerification(true)
                setDataOrder([])
            break;
        case 'maior-menor': 
            var response = await api.get(`/property?limit=20&interested=${localStorage.getItem('id_painel')}`, {headers: {orderby: JSON.stringify({sellingPrice: -1 })}})
            
            setData(response.data.propertys)
            setTotal(response.data.total)
            setVerification(true)
            setDataOrder([])
            break;

        case 'menor-maior': 
            var response = await api.get(`/property?limit=20&interested=${localStorage.getItem('id_painel')}`, {headers: {orderby: JSON.stringify({sellingPrice: 1 })}})
            
            setData(response.data.propertys)
            setTotal(response.data.total)
            setVerification(true)
            setDataOrder([])
            break;

        case 'me-maior-menor': 
            var response = await api.get(`/property?limit=20&interested=${localStorage.getItem('id_painel')}`, {headers: {orderby: JSON.stringify({fullLandSize: -1 })}})
            
            setData(response.data.propertys)
            setTotal(response.data.total)
            setVerification(true)
            setDataOrder([])
            break;

        case 'me-menor-maior': 
            var response = await api.get(`/property?limit=20&interested=${localStorage.getItem('id_painel')}`, {headers: {orderby: JSON.stringify({fullLandSize: 1 })}})
            
            setData(response.data.propertys)
            setTotal(response.data.total)
            setVerification(true)
            setDataOrder([])
            break;

        case 'a-z': 
            var sortDatas = data.sort(function (a, b) {
                var textA = ''
                var textB = ''
                if(a.neighborhood && b.neighborhood){
                    textA = a.neighborhood.name.toUpperCase();
                    textB = b.neighborhood.name.toUpperCase();
                }
            
                return textA.localeCompare(textB);
            })
            
            if(!verification){
                setData(sortDatas)
                setVerification(true)
            }else{
                setDataOrder(sortDatas)
                setVerification(false)

            }

            break;

        case 'z-a': 
            var sortDatas = data.sort(function (a, b) {
                var textA = ''
                var textB = ''
                if(a.neighborhood && b.neighborhood){
                    textA = a.neighborhood.name.toUpperCase();
                    textB = b.neighborhood.name.toUpperCase();
                }
            
                return textA.localeCompare(textB);
            }).reverse()
            
            if(!verification){
                setData(sortDatas)
                setVerification(true)
            }else{
                setDataOrder(sortDatas)
                setVerification(false)
            }
            break;
    }
    
}

    return (
        <>

        {
            modal ? 
                <> 
                    <div style={{height: '100vh', top: 0, width: '100vw', backgroundColor: '#000', opacity: 0.3, position: "fixed", zIndex: 99999}}></div>
                    <div style={{height: '80vh', width: '80vw', top: '5vh', left: '10vw', backgroundColor: '#fff', position: "fixed", zIndex: 9999999, borderRadius: 10}}>
                        <div style={{height: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#EA5C1E', fontWeight: 500, fontSize: 19, alignItems: 'center', paddingRight: 20}}><span style={{cursor: "pointer"}} onClick={() => {setModal(false); document.querySelector('body').style.overflow='scroll'}}>X</span></div>
                        <div style={{width: '87%', marginLeft: '5%', height: '95%'}}>
                            <h1 style={{fontSize: window.innerWidth <= 600 ? 20:35, marginTop: '2%', height: '13%'}}>Não perca seu tempo, ofereça este exclusivo imóvel aos seus clientes!</h1>
                            <h4 style={{height: '4%', width: '100%', fontSize: 23, marginTop: window.innerWidth <= 600 ?'18%':'2%'}}>Comissão <span style={{color: '#EA5C1E'}}>{data[index].comission}%</span></h4>

                            {/* <FormControlLabel
                                style={{}}
                                control={
                                <Checkbox
                                    checked={true}
                                    onChange={item => alert(item)}
                                    name="checkedB"
                                    color="primary"
                                    
                                />
                                }
                                label="Adicionar este imóvel ao seu portfólio de vendas"
                            /> */}

                            <div style={{height: '70%'}}>
                                <h4 style={{height: '13%', width: '100%', fontSize: window.innerWidth <= 600 ? 18:23, marginTop: '10%'}}>Instruções</h4>
                                <div style={{height: '67%', fontSize: window.innerWidth <= 600 ? 16:19, overflowY: 'auto'}} ref={sellingNotes}>
                                    {/* Obras: Finalizada <br/><br/>
                                    Chaves: Tirar cópia com o chaveiro Max no End: Rua do Lavradio n°10, Centro. Sendo 1 chave no valor de R$8,00.<br/><br/>
                                    Chaveiro Max Tel: 2252-6950 / 2221-4675 / 98155-6500 / 984224675 (Whatsapp) (Falar em nome de Alexandre Greco)<br/><br/>
                                    Horários: de 2° a 6° das 9:00 às 17:00 e Sábados das 9:00 às 13:00 */}

                                    {/* {data.sellingNotes} */}
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            :
                ''
            }

            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Meu Portfólio (imóveis interessados) 
                    </Link>
                </div>



                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Estado</label>
                        <select>
                            <option>Rio de Janeiro</option>
                        </select>
                    </div>
                    <div className="input-group">
                        
                        <label htmlFor="">Cidade</label>
                         <select onChange={e => e.target.value != 'Selecione:' ? handleChangeCity(e.target.value):handleChangeCity('')}>
                                {/* <option defaultChecked="true">Selecione: </option> */}
                                <option defaultChecked="true">Selecione: </option>
                            {   
                                city.map(city => (
                                    <option value={city._id}>{city.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Bairro</label>
                         <select onChange={select => setBairro(select.target.value)}>
                                <option defaultChecked="true">Selecione: </option>
                            {   
                                neighSelect.map(neigh => (
                                    <option value={neigh._id}>{neigh.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Status</label>
                        <select value={status} onChange={select => setStatus(select.target.value)}>
                            <option selected value="false">À venda</option>
                            <option value="true">Vendido</option>
                            <option value="arquivado">Arquivado</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Residencial ou comercial</label>
                        <select onChange={select => setGeneralType(select.target.value)}>
                            <option>Selecione</option>
                            <option value="2372820374682508">Residencial</option>
                            <option value="1644602035863502">Comercial</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Tipo de imovel</label>
                        <select onChange={select => setType(select.target.value)}>
                            <option defaultChecked="true">Selecione: </option>
                            {   
                                types.map(neigh => (
                                    <option value={neigh._id}>{neigh.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Valor minimo</label>
                        <input value={valorMinimo} onChange={input => setValorMinimo(money(input.target.value))} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Valor maximo</label>
                        <input value={valorMaximo} onChange={input => setValorMaximo(money(input.target.value))} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Quartos</label>
                        <input type="number" value={quartos} onChange={input => setQuartos(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Banheiros</label>
                        <input type="number" value={Banheiros} onChange={input => setBanheiros(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Vagas</label>
                        <input type="number" value={vagas} onChange={input => setVagas(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Área minima</label>
                        <input type="number" value={areaMinima} onChange={input => setAreaMinima(input.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Digite algo</label>
                        <input type="text" value={algo} onChange={input => setAlgo(input.target.value)} />
                    </div>
                    <div className="button-group">
                        <button onClick={handleLogin} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                </div>

                <div className="row-ordenate">
                    <p>Foram encontrados {total} imóveis.</p>
                    <div className="input-group" style={{width: window.innerWidth <= 600 ? '100%': ''}}>
                        <select onChange={option => option.target.value != '' ?handleOrder(option.target.value):''}>
                            <option value="">Ordenar por</option>
                            <option value="recent">Mais Recentes</option>
                            <option value="antigo">Mais Antigos</option>
                            <option value="maior-menor">Valor (Maior-Menor)</option>
                            <option value="menor-maior">Valor (Menor-Maior)</option>
                            <option value="me-maior-menor">Metragem (Maior-Menor)</option>
                            <option value="me-menor-maior">Metragem (Menor-Maior)</option>
                            <option value="a-z">Bairro (A-Z)</option>
                            <option value="z-a">Bairro (Z-A)</option>
                        </select>
                    </div>
                </div>

                
                <div className="header">
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    
                    {
                        window.innerWidth <= 600 ? 
                        <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                            <div style={{width: 78}} className="active">
                                <h2>STATUS</h2>
                            </div>
                            <div style={{width: 200}} className="imovel">
                                <h2>IMÓVEL</h2>
                            </div>
                            <div style={{width: 140}} className="engajamento">
                                <h2>COMISSÃO</h2>
                            </div>
                            <div style={{width: 150}} className="actions">
                                <h2>AÇÕES</h2>
                            </div>
                            
                        </div>
                    :
                    <>
                        <div className="active">
                            <h2>STATUS</h2>
                        </div>
                        <div className="imovel">
                            <h2>IMÓVEL</h2>
                        </div>
                        <div className="engajamento">
                            <h2>COMISSÃO</h2>
                        </div>
                        <div className="actions">
                            <h2>AÇÕES</h2>
                        </div>
                    </>
                }
                </div>

                
                {
                    verification?

                        data.map((row, i) => (
                            <div className="body">
                                <div className="body-cod">{row._id}</div>

                                {
                                    window.innerWidth <= 600 ? 
                                        <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                            {innerRow(row, i)}
                                        </div>    
                                    :
                                    innerRow(row, i)
                                }        
                            </div>
                        ))
                    :
                        dataOrder.map((row, i) => (
                            <div className="body">
                                <div className="body-cod">{row._id}</div>
                                {
                                    window.innerWidth <= 600 ? 
                                        <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                            {innerRow(row, i)}
                                        </div>    
                                    :
                                    innerRow(row, i)
                                }        
                            </div>
                        ))
                }
            </Container>
        </>
    )
}
