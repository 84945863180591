import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'

export default function AdminOrdenation() {
    const [data, setData] = useState([])
    const [status, setStatus] = useState('Home 1')

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }

        handleLoad()
    }, [])

    // useEffect(() => {
    //     if(data.length > 0){
    //         let body = document.querySelectorAll('.body') 
    //         body.forEach((element, index, array)=>{
    //             element.addEventListener('mousedown', e=>console.log(e))
    //             element.addEventListener('mouseup', e=>console.log(e))
    //         })
    //     }
    // }, [data])
     
    function cleanStates(){
    }

    async function handleLoad(){
        if(status == 'Home 1'){
            const {data} = await api.get(`/property?showHome=true&orderby=true`, {
              headers: {
                orderby: JSON.stringify({_id: -1})
              }
            }) 
            data.propertys.sort((a, b)=>{    
                if(a['orderHome'] < b['orderHome']){
                    return 1
                }else if(a['orderHome'] == undefined){
                    return 1
                }else{
                    return -1
                }
            })
            
            let array = []
            data.propertys.map(e=>array.push(e))
            setData(array)
        }else if(status == 'Pagina de Imoveis'){
            const {data} = await api.get(`/property?page=1&arquive=false&limit=21&recent=true&sold=false`)

            data.propertys.sort((a, b)=>{    
                if(a['orderPageList'] < b['orderPageList']){
                    return 1
                }else if(a['orderPageList'] == undefined){
                    return 1
                }else{
                    return -1
                }
            }) 

            setData(data.propertys)
        }
    }

    useEffect(() => {
        handleLoad()
    }, [status])
    
    async function handleApply(){
        let array = []
        data.map(e => {
            array.push(e._id)
        }) 

        const response = await api.post(`/property/order`, {
            array,
            status
        })
        
        if(response.data.status == 'success') alert('Ordem Aplicada')
    }
    
    async function handleMove(target, operation){
        target.style.opacity = 0.1
        setTimeout(() => {
            target.style.opacity = 1
        }, 200);
        if(operation == 'UP'){
            data.map((value, index, array)=>{ 
                if(value._id == target.id && array[0]._id != target.id){
                    let arrays = []
                    var item

                    data.map(e => {
                        if(e._id == array[index-1]._id){
                            item = e
                        }else if(e._id == value._id){
                            arrays.push(e)
                            arrays.push(item)
                        }else{
                            arrays.push(e)
                        }
                    }) 

                    setData(arrays)
                }
            })
        }else{
            data.map((value, index, array)=>{  
                if(value._id == target.id && array[array.length-1]._id != target.id){
                    let arrays = []
                    var item
                    
                    data.map(e => { 
                        if(e._id == value._id){
                            item = e
                        }else if(e._id == array[index+1]._id){
                            arrays.push(e)
                            arrays.push(item) 
                        }else{
                            arrays.push(e)
                        } 
                    })  
                    setData(arrays)
                }
            })
        }
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Ordenação de Imóveis
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Selecione a posição de destaque no site</label>
                        <select value={status} onChange={(e)=>setStatus(e.target.value)}>
                            <option selected>Home 1</option>
                            <option>Pagina de Imoveis</option>
                        </select>
                    </div>
                    <div className="button-group">
                        <button onClick={handleApply} className="full">Aplicar Ordem</button>
                    </div>
                </div>
                <div className="header" style={{marginTop: 25}}>
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    <div className="active">
                        <h2>IMÓVEL</h2>
                    </div>
                </div>
                <div className='container-table'>
                    {
                        data.map(row => (
                            <div className="body">
                                <div className="body-cod">{row._id}</div>
                                
                                <div className="body-actions">

                                    <p>{row.address+''+(row.addressNumber ? ', '+row.addressNumber+'':'')+(row.addressComplement ? ' / '+row.addressComplement:'')}</p>
                                    {/* { (row.showNumber || row.showComplement == null  ? ', '+row.addressNumber+'':'')+(row.showComplement || row.showComplement == null ? (row.addressComplement ? ' / '+row.addressComplement:''):'')} */}
                                     
                                    <div style={{height: '100%', width: window.innerWidth <= 600 ? '80px' : '6vw', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginRight: 5}}>
                                        <img id={row._id} className='arrow-up' onClick={e=>handleMove(e.target, 'UP')} src={require('./arrow-up.svg')}/>
                                        <img id={row._id} onClick={e=>handleMove(e.target, 'DOWN')} src={require('./arrow-down.svg')}/>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}