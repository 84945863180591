import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Container, ImageItem, PlusBtn, BtnWrapper, Carousel, DocItem, Item } from './styles'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import Autosuggest from '../../components/autosuggest'

import api from '../../api'

export default function () {
    const [corretores, setCorretores] = useState(0)
    const [correspondente, setCorrespondente] = useState(0)
    const [ofertas, setOfertas] = useState(0)

    const [dataInicio, setDataInicio] = useState('')//2021-01-01
    const [dataFinal, setDataFinal] = useState('')

    const [anunciosImovel, setAnunciosImovel] = useState(0)
    const [anunciosCorretores, setAnunciosCorretores] = useState(0)

    const [corretoresAtivos, setCorretoresAtivos] = useState([])
    const [corretoresAtivosOffer, setCorretoresAtivosOffer] = useState([])
    const [locaisOffer, setLocaisOffer] = useState([])
    const [locaisAnuncios, setLocaisAnuncios] = useState([])

    const [ofertasCategorias, setOfertasCategorias] = useState({novo: 0, analisando: 0, negociando: 0, naoComprado: 0, comprado: 0})

    useEffect(()=>{
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }
        handleLoad()
    }, [])

    window.onload = ()=>{
        let a = document.querySelectorAll('.content-box-2 div')[1]
    }    

    async function filter(dataI){
        let dataInicial = dataInicio.split('-')[2]+'/'+dataInicio.split('-')[1]+"/"+dataInicio.split('-')[0].slice(0,4)
        let dataFinalizada = dataFinal.split('-')[2]+'/'+dataFinal.split('-')[1]+"/"+dataFinal.split('-')[0].slice(0,4)

        if(dataI){
            dataInicial = dataI.split('-')[2]+'/'+dataI.split('-')[1]+"/"+dataI.split('-')[0].slice(0,4)
        }

        var {data} = await api.get(`/interested?maisAtivo=true&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        setCorretoresAtivos(data.Interested.slice(0, 5))
        
        var {data} = await api.get(`/user?limit=99999&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        setCorretores(data.total)
        
        var {data} = await api.get(`/banking?limit=99999&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        setCorrespondente(data.total)
                
        var {data} = await api.get(`/property?limit=1&maisAtivo=true&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        
        let arrayTeste = []
        let users = []

        data.propertys.map(e => {
            let ver = users.filter(f => f == e.neighborhood.name)
            
            if(ver.length != 0){
            }else{
                users.push(e.neighborhood.name)
                arrayTeste.push(e)
            }
        })

        data.propertys = arrayTeste
        
        data.propertys.sort((a, b) =>{
            return b.somaLocais - a.somaLocais;
        })
        setAnunciosImovel(data.total)
        setLocaisAnuncios(data.propertys.slice(0, 5))
        
        var {data} = await api.get(`/offer?maisAtivo=fe&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)

        if(!data.Offer){
            data = {
                Offer: data
            }
        }

        setCorretoresAtivosOffer(data.Offer ? data.Offer.slice(0, 5):data.slice(0, 5))
        setOfertas(data.total?data.total:data.length?data.length:data?.Offer?.length)
        
        arrayTeste = []
        users = []

        data.Offer.map(e => {
            let ver = users.filter(f => f == (e.bairro.split(',')[0]))
            
            if(ver.length != 0){
            }else{
                users.push(e.bairro.split(',')[0])
                arrayTeste.push(e)
            }
        })

        data.Offer = arrayTeste
        
        data.Offer.sort((a, b) =>{
            return b.somaLocais - a.somaLocais;
        })
        
        setLocaisOffer(data.Offer ? data.Offer.slice(0, 5):data.slice(0, 5))

        var ofertas_aux = {}
        var {data} = await api.get(`/offer?status=Novo&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, novo: data.length}        
        var {data} = await api.get(`/offer?status=Analisando&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, analisando: data.length}
        var {data} = await api.get(`/offer?status=Negociando&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, negociando: data.length}
        var {data} = await api.get(`/offer?status=Não Comprado&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, naoComprado: data.length}
        var {data} = await api.get(`/offer?status=Comprado&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, comprado: data.length}

        setOfertasCategorias(ofertas_aux)
    }

    async function handleLoad(){
        var {data} = await api.get('/interested?maisAtivo=fe')
        
        setCorretoresAtivos(data.Interested.slice(0, 5))

        var {data} = await api.get('/user?limit=1')
        setCorretores(data.total)
        var {data} = await api.get(`/banking?limit=1`)
        setCorrespondente(data.total)
        var {data} = await api.get(`/property?page=1&arquive=false&limit=21&recent=true`)
        var {data} = await api.get(`/property?limit=1&maisAtivo=s`)
        
        let arrayTeste = []
        let users = []

        data.propertys.map(e => {
            let ver = users.filter(f => f == e.neighborhood.name)
            
            if(ver.length != 0){
            }else{
                users.push(e.neighborhood.name)
                arrayTeste.push(e)
            }
        })

        data.propertys = arrayTeste
        
        data.propertys.sort((a, b) =>{
            return b.somaLocais - a.somaLocais;
        })
        setAnunciosImovel(data.total)
        setLocaisAnuncios(data.propertys.slice(0, 5))
        
        let dataInicial='26/12/2020'
        let dataFinalizada='undefined-undefined'
        
        var {data} = await api.get(`/offer?maisAtivo=fe&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        
        if(!data.Offer){
            data = {
                Offer: data
            }
        }

        setCorretoresAtivosOffer(data.Offer ? data.Offer.slice(0, 5):data.slice(0, 5))
        setOfertas(data.total?data.total:data.length?data.length:data?.Offer?.length)

        arrayTeste = []
        users = []

        data.Offer.map(e => {
            let ver = users.filter(f => f == (e.bairro.split(',')[0]))
            
            if(ver.length != 0){
            }else{
                users.push(e.bairro.split(',')[0])
                arrayTeste.push(e)
            }
        })

        data.Offer = arrayTeste
        
        data.Offer.sort((a, b) =>{
            return b.somaLocais - a.somaLocais;
        })
        
        setLocaisOffer(data.Offer ? data.Offer.slice(0, 5):data.slice(0, 5))
        
        var ofertas_aux = {}
        var {data} = await api.get(`/offer?status=Novo&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, novo: data.length}        
        var {data} = await api.get(`/offer?status=Analisando&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, analisando: data.length}
        var {data} = await api.get(`/offer?status=Negociando&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, negociando: data.length}
        var {data} = await api.get(`/offer?status=Não Comprado&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, naoComprado: data.length}
        var {data} = await api.get(`/offer?status=Comprado&dataInicio=${dataInicial}&dataFinal=${dataFinalizada}`)
        ofertas_aux = {...ofertasCategorias, ...ofertas_aux, comprado: data.length}

        setOfertasCategorias(ofertas_aux)
    }

    function handlePrint(){    
            var style = "<style>"
                style = style + `body{
                    height: 1000px;
                    width: 90%;
                    display: flex;
                    margin-left: 5%;
                    flex-direction: column;
                    border-radius: 5px;
                    border: 2px solid #ddd;
                }
        
                .box-container-1{
                    height: 220px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
                .content-box-1{
                    margin-left: 30px;
                    height: 100%;
                    width: 230px;
                    padding-top: 35px;
                }
                .content-box-1 h2{
                    margin-left: 10px;
                }
        
                .content-box-1 div{
                    border-radius: 6px;
                    width: 100%;
                    height: 103px;
                    margin-top: 25px;
                    background: #eee;
                    border: 1px solid #ccc;
                    padding-top: 10px;
                }
        
                .content-box-1:nth-child(1) div{
                    margin-left: -30px;
                }

                .content-box-1:nth-child(2){
                    margin-left: -28px;
                }
                
                .content-box-1 div span{
                    margin-left: 10px;
                }
                                    
                .content-box-1 div h3{
                    color: #8B9495;
                    width: 100%;
                    font-size: 35px;
                    margin-top: 10px;
                    text-align: center;
                }
                .span-share{
                    font-size: 10px;
                }
        
                .content-box-2{
                    height: 103px;
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    flex-direction: row;
                    align-items: space-around;
                    justify-content: space-between;
                }
                .div-offer{
                    width: 95px;
                }   
                .content-box-2 div{
                    border-radius: 6px;
                    width: 230px;
                    height: 103px;
                    background: #eee;
                    border: 1px solid #ccc;
                    padding-top: 10px;
                }
                .content-box-2 div span{
                    margin-left: 10px;
                }
                                    
                .content-box-2 div h3{
                    color: #8B9495;
                    width: 100%;
                    font-size: 35px;
                    margin-top: 10px;
                    text-align: center;
                }
                
                .link-h3{
                    text-align: start;
                    padding-left: 15px;
                    font-size: 15px;
                    margin-top: 0px;
                    font-weight: 400;
                }
                .u{
                    text-decoration: underline;
                    cursor: pointer;
                }
    
                .span-min{
                    display: inline-block;
                    text-align: center;
                    margin-left: 0;
                    width: 100%;
                    font-size: 13px;
                }
                `
            style = style + "</style>"
    
            let win = window.open('', '', 'height=700, width=1000')
            win.document.write('<html><head>')
            win.document.write('<title>Metricas Gerais</title>')
            win.document.write(style)
            win.document.write('</head>')
            win.document.write('<body>')
            win.document.write(document.querySelector('.box-header').innerHTML)
            win.document.write(`</div></body></html>`)
            win.print()
            win.close()
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        MARKETING >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Métricas Gerais de Desempenho
                    </Link>
                </div>
                <div className="box-control">
                    <div className="container-control">
                        <div className="input-tolerant"> 
                            <span>Data de Início</span>
                            {/* 451, 481, 501, 511 */}
                            <input value={dataInicio} onChange={e=> setDataInicio(e.target.value)} type='date'/>
                        </div>
                        <div className="input-tolerant"> 
                            <span>Data de Fim</span>
                            <input value={dataFinal} onChange={e=> setDataFinal(e.target.value)} type='date'/>
                        </div>
                    </div>

                    <div className="container-control flex-end">
                        <button onClick={e => {handleLoad();setDataInicio('')}} className="buttonSave bt-inverse">Remover filtros</button>
                        <button onClick={e=>filter()} className="buttonSave">Aplicar Filtros</button>
                    </div>
                    <input hidden id="ArrayValues"/>
                </div>

                <div style={{display: 'flex', flexDirection: window.innerWidth <= 600 ? 'column':'row', justifyContent: 'space-between', marginTop: 20, width: window.innerWidth <= 600 ? '100%':'123%'}}>
                    <p></p>
                    <a onClick={handlePrint} style={{color: '#00AEC8', textAlign: window.innerWidth <= 600 ? 'center':'flex-end', textDecoration: 'none', cursor: 'pointer'}}>Fazer download das métricas</a>
                </div>

                <div className="box-control box-table">
                    <div className="box-header">
                        <div className="box-container-1">
                            <div className="content-box-1">
                                <h2>Cadastros</h2>
                                <div>
                                    <span>Corretores</span>
                                    <h3>{corretores}</h3>
                                </div>
                            </div>
                            <div className="content-box-1">
                                <h2>Correspondente</h2>
                                <div>
                                    <span>Solicitações</span>
                                    <h3>{correspondente}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="box-container-1" style={{height: window.innerWidth <= 600 ? 780 : 350, flexDirection: 'column', marginTop: 20}} >
                            <h2 style={{ marginLeft: window.innerWidth <= 600 ? 0:40}}>Anúncios</h2>

                            <div className="content-box-2" style={{justifyContent: 'start', alignItems: window.innerWidth <= 600 ? 'center': 'start',flexDirection: window.innerWidth <= 600 ? 'column':'row', height: window.innerWidth <= 600 ? 350: 'unset'}}>
                                <div>
                                    <span>Total de Anúncios</span>
                                    <h3>{anunciosImovel+anunciosCorretores}</h3>
                                </div>
                                <div style={{marginLeft: window.innerWidth <= 600 ? 0:30, marginTop: window.innerWidth <= 600 ? 10:0}}>
                                    <span>Anúncios Imóvel Vazio</span>
                                    <h3>{anunciosImovel}</h3>
                                </div>
                                <div style={{marginLeft: window.innerWidth <= 600 ? 0:30, marginTop: window.innerWidth <= 600 ? 10:0}}>
                                    <span>Anúncios Corretores</span>
                                    <h3>{anunciosCorretores}</h3>
                                    {/* <span style={{fontSize: 11}}>46% com comissão compartilhada</span> */}
                                </div>
                            </div>
                            
                            <div className="content-box-2" style={{marginTop: 25, alignItems: window.innerWidth <= 600 ? 'center': 'start', height: window.innerWidth <= 400?500:'auto', flexDirection: window.innerWidth <= 600 ? 'column':'row'}}>
                                <div style={{width: window.innerWidth <= 600 ? '95%':'48%', height: '165px'}}>
                                    <span>Locais Mais Ofertados nos Anúncios</span>
                                    
                                    {
                                        locaisAnuncios.map((e, i)=>(
                                            <h3 className="link-h3" style={{fontSize: 15, textAlign: 'start', marginBottom: 0, marginTop: i == 0 ? 10:0}}>{e.neighborhood? e.neighborhood.name:e.neighborhood}/{e.city? e.city.name:'Rio de Janeiro'}/{e.neighborhood?e.neighborhood.state:'RJ'} ({e.somaLocais})</h3>
                                        ))
                                    }
                                </div>
                                <div style={{width: window.innerWidth <= 600 ? '95%':'48%', height: '165px', marginTop: window.innerWidth <= 600 ? 10:0}}>
                                    <span>Corretores Mais Ativos (imóveis interessados)</span>
                                    {corretoresAtivos.map((e, i)=>(
                                        <h3 style={{fontSize: 15, textAlign: 'start',marginTop: i == 0 ? 10:0, marginBottom: 0}} onClick={e=>window.open(`/admin/marketing/editar?id=${corretoresAtivos[i].user?corretoresAtivos[i].user._id:corretoresAtivos[i].user}`)} className="link-h3 u">{e.user? e.user.name:e.user} ({e.soma} imóveis)</h3>
                                    ))}
                                </div>
                            </div>
                        </div>


                        <div className="box-container-1" style={{height: window.innerWidth <= 600 ? 500:300, flexDirection: 'column', marginTop: 25}} >
                            <h2 style={{ marginLeft: 40}}>
                            COMPRAMOS SEU IMÓVEL </h2>

                            <div className="content-box-2">
                                <div>
                                    <span>Total de Ofertas Recebidas</span>
                                    <h3>{ofertas}</h3>
                                </div>
                                <div className="div-offer">
                                    <span className="span-min">Novo</span>
                                    <h3>{ofertasCategorias.novo}</h3>
                                </div>
                                <div className="div-offer">
                                    <span className="span-min">Analisando</span>
                                    <h3>{ofertasCategorias.analisando}</h3>
                                </div>
                                <div className="div-offer">
                                    <span className="span-min">Negociando</span>
                                    <h3>{ofertasCategorias.negociando}</h3>
                                </div>
                                <div className="div-offer">
                                    <span className="span-min">Não Comprado</span>
                                    <h3>{ofertasCategorias.naoComprado}</h3>
                                </div>
                                <div className="div-offer">
                                    <span className="span-min">Comprado</span>
                                    <h3>{ofertasCategorias.comprado}</h3>
                                </div>
                            </div>

                            <div className="content-box-2" style={{marginTop: 25}}>
                                <div style={{width: window.innerWidth <= 600 ? '90%':'48%', height: window.innerWidth <= 600 ? '160px':'135px'}}>
                                    <span>Locais Mais Ofertados para Imóvel Vazio</span>
                                    {
                                        locaisOffer.map((e, i)=>(
                                            <h3 className="link-h3" style={{fontSize: 15, textAlign: 'start', marginBottom: 0, marginTop: i == 0 ? 10:0}}>{e.bairro.split(',')[0]?e.bairro.split(',')[0]:e.bairro}/{e.bairro.split(', ')[1]? e.bairro.split(', ')[1].split(' - ')[0]:'Rio de Janeiro'}/{'RJ'} ({e.somaLocais == 0? 1:e.somaLocais})</h3>
                                        ))
                                    }
                                    {/* <h3 className="link-h3" style={{fontSize: 15, textAlign: 'start', marginBottom: 0, marginTop: 0}}>dsfdsfsdfdsfsdfsdfsd</h3>
                                    <h3 className="link-h3" style={{fontSize: 15, textAlign: 'start', marginBottom: 0, marginTop: 0}}>dsfdsfsdfdsfsdfsdfsd</h3>
                                    <h3 className="link-h3" style={{fontSize: 15, textAlign: 'start', marginBottom: 0, marginTop: 0}}>dsfdsfsdfdsfsdfsdfsd</h3>
                                    <h3 className="link-h3" style={{fontSize: 15, textAlign: 'start', marginBottom: 0, marginTop: 0}}>dsfdsfsdfdsfsdfsdfsd</h3> */}
                                </div>
                                <div style={{width: window.innerWidth <= 600 ? '90%':'48%', height: window.innerWidth <= 600 ? '160px':'135px'}}>
                                    <span>Corretores Mais Ativos (imóveis ofertados)</span>
                                    {corretoresAtivosOffer.map((e, i)=>(
                                        <h3 onClick={e=>window.open(`/admin/marketing/editar?id=${corretoresAtivosOffer[i].idUser?corretoresAtivosOffer[i].idUser._id:corretoresAtivosOffer[i].idUser}`)} className="link-h3 u"style={{fontSize: 14, textAlign: 'start', marginBottom: 0, marginTop: i == 0 ? 10:0}}>{e.idUser? e.idUser.name:e.idUser} ({e.soma} imóveis)</h3>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
            

        </>
    )
}
