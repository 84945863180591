import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'
import InputMask from "react-input-mask";

export default function AdminMarketingList() {
    const [data, setData] = useState([])
    const [algo, setAlgo] = useState('') 
    const [nome, setNome] = useState('') 
    const [cpf, setCPF] = useState('') 
    const [creci, setCreci] = useState('') 
    const [email, setEmail] = useState('') 
    const [celular, setCelular] = useState('') 
    const [celular2, setCelular2] = useState('') 
    const [checked, setChecked] = useState('') 
    const [correspondentes, setCorrespondentes] = useState(0) 
    const [interesses, setInteressados] = useState(0) 
    const [ofertas, setOfertas] = useState(0)
    const [totalTelephone, setTotalTelephone] = useState([])
        
    const [password, setPassword] = useState('') 
    const [passwordConfirm, setPasswordConfirm] = useState('') 

    const [border, setBorder] = useState('')
    useEffect(() => {
        if(!localStorage.getItem('type_user')){
            window.location.href='/admin/login'
        }

        //673
        handleLoad()
    }, [])

    function cleanStates(){
        setAlgo('') 
    }

    async function handleLoad(){
        cleanStates()
        
        if(localStorage.getItem('id_painel')){
            const response = await api.get(`/user/${localStorage.getItem('id_painel')}?limit=20`) 

            setData(response.data)
            setInteressados(response.data[0].totalInteresses)
            setCorrespondentes(response.data[0].totalCorrespondente)
            setOfertas(response.data[0].totalOfertas)
            setNome(response.data[0].name)
            setCPF(response.data[0].cpf)
            setCreci(response.data[0].creci)
            setEmail(response.data[0].email)
            setCelular(response.data[0].telephone[0])
            setCelular2(response.data[0].telephone[1])
            setChecked(response.data[0].receiveEmail != undefined ? response.data[0].receiveEmail : true)
            setTotalTelephone(response.data[0].telephone)
        }else{
            window.location.href='/admin/login'
        }
    } 

    async function handleSave(){
        if(!window.confirm('Tem certeza que deseja alterar as informações?')){
            return
        }

        var object = {
            receiveEmail: checked
        }

        if(nome){
            object = {
                ...object,
                name: nome
            }
        }

        if(validaCpf(cpf)){
            object = {
                ...object,
                cpf: cpf.replaceAll('.', '').replaceAll('-', '')
            }
        }

        if(creci){
            if(creci.length >= 3){
                object = {
                    ...object,
                    creci
                }
            }
        }
        
        if(email){
            object = {
                ...object,
                email
            }
        }

        if(celular && !celular.includes('_')){
            let telephones = totalTelephone
            telephones[0] = celular
            object = {
                ...object,
                telephone: telephones
            }
        }

        if(celular2 && !celular2.includes('_')){
            let telephones = totalTelephone
            telephones[1] = celular2
            object = {
                ...object,
                telephone: telephones
            }
        }

        let query = ''
        if(password == passwordConfirm && passwordConfirm.length > 5){
            query = '?teste=true'
            object = {
                ...object,
                passwords: password,
            }
        }else if(passwordConfirm.length > 5 || password.length > 5){
            setBorder('1px solid red')
            return
        }

        let res = await api.put(`/user/${data[0]._id}${query}`, object)
        if(res.data.password == 'edited'){
            alert("Senha alterada")
            return window.location.href='/admin/home-corretor'
                
        }
        if(res.data._id){
            window.location.href='/admin/home-corretor'
        }
    }

    function validaCpf(val) {
        if(!val){
            return false
        }
        var cpf = val.trim();
        
        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;
        
        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;   
            }
        } 
        
        if (aux == false) {
            return false; 
        } 
        
        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p; 
        } 
        
        v1 = ((v1 * 10) % 11);
        
        if (v1 == 10) {
            v1 = 0; 
        }
        
        if (v1 != cpf[9]) {
            return false; 
        } 
        
        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p; 
        } 
        
        v2 = ((v2 * 10) % 11);
        
        if (v2 == 10) {
            v2 = 0; 
        }
        
        if (v2 != cpf[10]) {
            return false; 
        } else {   
            return true; 
        }
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        Minha Conta > 
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Editar
                    </Link>
                </div>                

                <div className="header" style={{marginTop: 30}}>
                    <div className="cod" style={{width: '55%'}}>
                        <h2>Editar</h2>
                    </div>
                    {
                        window.innerWidth <= 600?
                        '':
                        <div className="active" style={{width: '45%'}}>
                            <h2>ATIVIDADES</h2>
                        </div>
                    }
                </div>

                        <div className="body" style={{height: 'auto', border: 'none'}}>
                            <div className="body-cod" style={{width: window.innerWidth <= 600?'100%':'55%', flexDirection: 'column', border: 'none', paddingRight: window.innerWidth <= 600?'0%':'4%'}}>

                                <div className="input-group">
                                    <label htmlFor="">Nome</label>
                                    <input placeholder='Adhemar Figueira Filho' value={nome} onChange={e => setNome(e.target.value)}/>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}> 
                                    <div className="input-group" style={{width: '48%'}}>
                                        <label htmlFor="">CPF</label>
                                        {/* <input placeholder='879.843.937-53' value={cpf} onChange={e => setCPF(e.target.value)}/> */}
                                        <InputMask mask="999.999.999-99" onChange={text => setCPF(text.target.value)} value={cpf} placeholder="Ex.: 000.000.000-00"/>
                                    </div>
                                    <div className="input-group" style={{width: '48%'}}>
                                        <label htmlFor="">CRECI</label>
                                        <input placeholder='39751' value={creci} onChange={e => setCreci(e.target.value)}/>
                                    </div> 
                                </div>

                                <div className="input-group">
                                    <label htmlFor="">Email</label>
                                    <input placeholder='adhemarfigueirafilho@hotmail.com' value={email} onChange={e => setEmail(e.target.value)}/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="">Celular</label>
                                    {/* <input placeholder='(21) 9866-85230' value={celular} onChange={e => setCelular(e.target.value)}/> */}
                                    <InputMask mask="(99) 99999-9999" value={celular}  onChange={text => setCelular(text.target.value)} placeholder="Ex.: (21) 9866-85230" pattern="\(\d{2}\)\d{4}-\d{4}"/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="">Outro Telefone</label>
                                    {/* <input placeholder='(21) 3315-6500' value={celular2} onChange={e => setCelular2(e.target.value)}/> */}
                                    <InputMask mask="(99) 99999-9999" value={celular2}  onChange={text => setCelular2(text.target.value)} placeholder="Ex.: (21) 3315-6500" pattern="\(\d{2}\)\d{4}-\d{4}"/>
                                </div>
                            </div>
                    {
                        window.innerWidth <= 600?
                        <div className="header" style={{marginTop: 10, marginBottom: 15}}>
                            <div className="active" style={{width: '45%'}}>
                                <h2>ATIVIDADES</h2>
                            </div>
                        </div>
                        :''
                    }
                            
                            <div className="body-active" style={{width: window.innerWidth <= 600?'100%':'45%', flexFlow: 'wrap', border: 'none', height: 'inherit'}}> 
                                <div className="square" style={{marginBottom: window.innerWidth <= 600? 15:0}}>
                                    <strong>Anúncios</strong>

                                    <h3>0</h3>
                                </div>
                                <div className="square">
                                    <strong>Imóveis Interessados</strong>

                                    <h3>{interesses}</h3>
                                </div>
                                <div className="square" style={{marginBottom: window.innerWidth <= 600? 15:0}}>
                                    <strong>Ofertas para Imóvel Vazio</strong>

                                    <h3>{ofertas}</h3>
                                </div>
                                <div className="square">
                                    <strong>Correspondente Bancário</strong>

                                    <h3>{correspondentes}</h3>
                                </div>


                                <div className="select-group">
                                    <input type="checkbox" name="" id="" checked={checked} onChange={e => setChecked(!checked)}/>
                                    <span>Quer receber informações por e-mail sobre os imóveis à venda.</span>
                                </div>
                                {/* <button onClick={handleSave}>
                                    Salvar
                                </button> */}
                            </div>                         

                        </div>
                        
                    <div className="header" style={{marginTop: 20}}>
                        <div className="cod" style={{width: '85%'}}>
                            <h2>Segurança</h2>
                        </div>
                    </div>

                <div className="body" style={{height: 'auto', border: 'none', flexDirection: window.innerWidth <= 600?'column':'row'}}>
                    <div className="body-cod" style={{width: window.innerWidth <= 600?'100%':'55%', flexDirection: 'column', border: 'none', paddingRight: window.innerWidth <= 600?'0%':'4%'}}>
                
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}> 
                            <div className="input-group" style={{width: '48%'}}>
                                <label htmlFor="">Nova Senha</label>
                                <input type="password" placeholder='*******' value={password} onChange={e => setPassword(e.target.value)}/>
                            </div> 
                            <div className="input-group" style={{width: '48%', border}}>
                                <label htmlFor="">Repetir nova Senha</label>
                                <input type="password" placeholder='*******' value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}/>
                            </div> 
                        </div>

                    </div>

                    <div className="body-active" style={{width: window.innerWidth <= 600?'100%':'45%', flexFlow: 'wrap', border: 'none', height: 'inherit'}}> 
                            
                        <button onClick={handleSave}>
                            Salvar
                        </button>
                    </div>
                </div>
            </Container>
        </>
    )
}