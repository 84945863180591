import React, { useState, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import '../../global.css';
import './styles.css'

export default function Autocomplete({ placeholder, searchField = 'name', onChange, inputName = '',objs = [],defaultValue = '', values }) {

    const [data, setData] = useState([])
    const [value, setValue] = useState(defaultValue)
    const [suggestions, setSuggestions] = useState(objs)

    async function fetchData() {
        setData(objs)
    }

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const getSuggestions = value => {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return data.filter(searchTerms =>
            regex.test(searchTerms[searchField])
        )
    }

    const getSuggestionsValue = suggestion => suggestion[searchField]

    const renderSuggestion = suggestion => (
        <span>
            {suggestion[searchField]}
        </span>
    )

    const customOnChange = (e, value) => {
        setValue(value.newValue)
    }

    const renderInputComponent = inputProps => (
        <div className="inputContainer">
            <input {...inputProps} />
            <img className="icon" src={require('../../assets/arrowDown.svg')} />
        </div>
    );

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: placeholder,
        value: values? values:value,
        onChange: customOnChange
    };

    useEffect(() => {
        fetchData()
    }, [objs])

    return (
        <div className="wrapper">
            <label htmlFor="">{inputName}</label>
            <Autosuggest className="input"

                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionsValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={(e, { suggestion }) => {
                    onChange(suggestion)
                }}
                shouldRenderSuggestions={()=>true}
                renderInputComponent={renderInputComponent}
            />
        </div>
    )
}
