/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import socketClient from "socket.io-client"

export default function VendaCorretor() {
    const [data, setData] = useState([])
    const [socket, setSocket] = useState(null)
    const [newMessage, setNewMessage] = useState(false)

    const [preAnalise, setPreAnalise] = useState('')
    const [status, setStatus] = useState('')
    const [cancel, setCancel] = useState(Axios.CancelToken.source())

    useEffect(() => {
        if(!localStorage.getItem('type_user')){
            window.location.href='/admin/login'
        }
        
        setSocket(socketClient(process.env.REACT_APP_API_URL, {query: {
            user: localStorage.getItem('id_painel'), 
            type: localStorage.getItem('type_user')
        }}))
        
        reloadMessages()

        handleLogin()
    }, [])
    
    const reloadMessages = async () => {
        setTimeout(async () => {
            await codeNewMessage()
        }, 500)
    }
    
    useEffect(() => {
        if(socket != null){ 
            socket.on('listMessages', data => {
                reloadMessages()
            })
        }
    }, [socket])

    
    // window.onfocus = () => {
    //     reloadMessages()
    // }   
    
    function cleanStates(){
        setStatus('')
        setPreAnalise('')
        setCancel(Axios.CancelToken.source())
    }
    
    async function handleLogin(){
        cleanStates()

        await codeNewMessage()

        const {data} = await api.get(`/offer?user=${localStorage.getItem('id_painel')}`, {
            cancelToken: cancel.token
        })

        if(data.Offer){
            setData(data.Offer)   
        }       
    }

    const codeNewMessage = async () => {
        var response = await api.get(`/offer?user=${localStorage.getItem('id_painel')}&message=only-new&type=admin&cod=1`)
        
        if(response.data.length > 0){
            setNewMessage(true)
        }else{
            setNewMessage(false)
        }
    }

    async function handleNewMessages(){
        cleanStates()
        cancel.cancel() 
        let query = ''
        query+=`user=${localStorage.getItem('id_painel')}&`
        query+=`message=only-new&type=admin&cod=2`
        document.body.style.cursor = 'wait'
        const {data} = await api.get(`/offer?${query}`)
        if(data.length == 0){
            setNewMessage(false)
        }else{
            setData(data)
        }
        
        document.body.style.cursor = 'default'
    }

    function transform(data) {
        if(!String(data).includes('.')){
            return money(String(data)+'00')
        }
        return money(String(data))
    }

    function money (value) {
        return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }


    async function filter(){
        if(preAnalise || status){
            let query = ''
            if(preAnalise) query += `analise=${preAnalise}&`
            if(status) query += `status=${status}&`
            query+=`user=${localStorage.getItem('id_painel')}&`
            
            const {data} = await api.get(`/offer?${query}`)
            setData(data)
        }
    }
    
    function innerRow(row, i){    
        return (
            <>
            <div className="body-imovel">
                <div className="container-divide">
                    <div className="divData">Cadastrado em: {row.createdAt.split('-')[2].substr(0, 2)+'/'+row.createdAt.split('-')[1]+'/'+row.createdAt.split('-')[0]}</div>
                    <div className="border-top divDescription">{row.address}{row.addressNumber ? ', '+row.addressNumber : ''}{!row.addressComplement ? '' : ' / '+row.addressComplement}</div>
                    <div className="border-top divState">{row.bairro ? row.bairro : 'Rio de Janeiro, RJ'}</div>
                </div>
            </div>
            <div className="body-engajamento">
                <p>
                    Proposta: {transform(row.valueDesejado)}
                </p>
            </div>
            <div className="body-actions">
                <div>
                    <a style={{cursor: 'default', color: '#555', fontSize: 17}}>{row.status}</a>
                </div>
                <div>
                    <a onClick={()=> {window.location.href = `/admin/ofertas/editar?id=${row._id}`}}>Negociar</a>
                </div>
            </div>
        </>
        )
    }
    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        COMPRAMOS SEU IMÓVEL >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Ofertas de Venda para Imóvel Vazio
                    </Link>
                </div> 

                <div className="filter">
                    <div className="input-group">
                        <span>Status</span>
                        <select value={status} onChange={(e) => e.target.value == 'Selecione' ? setStatus('') : setStatus(e.target.value)} id="input-tolerancia" type="number" >
                            <option>Selecione</option>
                            <option value="Novo">Novo</option>
                            <option value="Analisando">Analisando</option>
                            <option value="Sem Interesse">Sem Interesse</option>
                            <option value="Negociando">Negociando</option>
                            <option value="Não Comprado">Não Comprado</option>
                            <option value="Acima do Preço">Acima do Preço</option>
                            <option value="Avaliado">Avaliado</option>
                            <option value="Comprado">Comprado</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <span>Pré-análise</span>
                        <select value={preAnalise} onChange={(e) => e.target.value == 'Selecione' ? setPreAnalise('') : setPreAnalise(e.target.value)} id="input-tolerancia" type="number" >
                            <option>Selecione</option>
                            <option value="Aceita">Aceita</option>
                            <option value="Negada">Negada</option>
                            <option value="Alterada">Alterada</option>
                            <option value="Nao Negada">Não Negada</option>
                        </select>
                    </div>
                    <div className="button-group">
                        {
                            newMessage ?
                                <button style={{marginRight: window.innerWidth <= 600?0:8, marginBottom : window.innerWidth <= 600? 15:0, color: '#31AA26', border: '1px solid #31AA26'}} onClick={handleNewMessages} className="outline">Novas Mensagens</button>
                            :''
                        }
                        <button onClick={handleLogin} className="outline">Remover filtros</button>
                        <button onClick={filter} className="full">Aplicar filtros</button>
                    </div>
                </div>
                
                <div className="header">
                    <div className="cod">
                        <h2>SEQ.</h2>
                    </div>
                    
                    {
                        window.innerWidth <= 600 ? 
                        <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                            <div style={{width: 200}} className="imovel">
                                <h2>IMÓVEL</h2>
                            </div>
                            <div style={{width: 140}} className="engajamento">
                                <h2>COMISSÃO</h2>
                            </div>
                            <div style={{width: 150}} className="actions">
                                <h2>AÇÕES</h2>
                            </div>
                            
                        </div>
                    :
                    <>
                        <div className="imovel">
                            <h2>IMÓVEL</h2>
                        </div>
                        <div className="engajamento">
                            <h2>VALOR</h2>
                        </div>
                        <div className="actions">
                            <h2>STATUS</h2>
                        </div>
                    </>
                }
                </div>

                
                {
                    data.map((row, i) => (
                        <div className="body">
                            <div className="body-cod">{row._id}</div>

                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row, i)}
                                    </div>    
                                :
                                innerRow(row, i)
                            }        
                        </div>
                    ))
                }
            </Container>
        </>
    )
}
