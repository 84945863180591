import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Container, ImageItem, PlusBtn, BtnWrapper, Carousel, DocItem, Item } from './styles'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import Autosuggest from '../../components/autosuggest'

import api from '../../api'

export default function () {
    const [data, setData] = useState([])
    const [tag, setTag] = useState('')
    const [tagLength, setTagLength] = useState(0)
    const [operation, setOperation] = useState('create')
    const [ID, setId] = useState(0)
    const [valorMax, setValorMax] = useState(15)

    useEffect(()=>{
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }
        handleLoad()
    }, [])

    async function handleLoad(){
        const {data} = await api.get('/tag')
        
        setData(data.tags)
    }

    async function handleCreate(){
        if(operation == 'create'){
            if(tag == '' || tag == ' '){
                return
            }

            const {data} = await api.post('/tag', {
                name: tag
            })
    
            if(!data.error){
                setData(data)
            }else if(data.error == 'Tag Existente'){
                alert('Essa TAG já existe.')
                setTag('')
                setTagLength(0)
            }
        }else{
            if(tag == '' || tag == ' '){
                return
            }

            const {data} = await api.put('/tag/'+ID, {
                name: tag
            })
    
            if(!data.error){
                setData(data)
                setOperation('create')
                setTag('')
                setTagLength(0)
                setValorMax(15)
            }
        }
    }

    async function handleDelete(id){
        if(!window.confirm('Tem certeza que quer deletar esta TAG?')){
            return
        }
        const {data} = await api.delete('/tag/'+id)
        setData(data)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        MARKETING >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Criação de TAGs
                    </Link>
                </div>
                <div className="box-control">
                    <div className="container-control input-padding">
                        <div className="input-tolerant" style={{width: window.innerWidth <= 600 ? '100%': '80%', position: 'relative'}}>
                            <p style={{position: 'absolute', width: '100%', marginLeft: '-15px', marginTop: '8px', textAlign: 'end'}}>{tagLength}/{valorMax}</p>
                            <span>{operation == 'create'?'Criar TAG':'Editar TAG'}</span>
                            <input value={tag} onChange={e=>{
                                if(e.target.value.length >= valorMax){
                                    setTag(e.target.value.substr(0, valorMax).toUpperCase())
                                    setTagLength(e.target.value.substr(0, valorMax).length)
                                }else{
                                    setTag(e.target.value.toUpperCase())
                                    setTagLength(e.target.value.length)
                                }
                                }} />
                        </div>
                    </div>


                    <div className="container-control flex-end">
                        <p style={{marginRight: 'auto', fontSize: 15}}>Utilize no máximo {valorMax} letras</p>
                        {operation == 'edit'? (
                            <button onClick={e=>{
                                setOperation('create')
                                setTag('')
                                setTagLength(0)
                                setValorMax(15)
                            }} className="buttonSave">Cancelar</button>
                        ):''}
                        <button onClick={handleCreate} className="buttonSave">{operation == 'create'?'Criar':'Editar'}</button>
                    </div>
                    <input hidden id="ArrayValues"/>
                </div>

                <div className="box-control box-table">
                    <div className="box-header">
                        <h1 className="h1-border h1-5">TAGS PERSONALIZADAS</h1>
                        <h1 className="h1-border h1-6">AÇÕES</h1>
                    </div>
        
                    {
                        data.map(row => (
                            <div className="box-header box-body">
                                <div className="table-row">
                                    <div className="border-div h1-5">
                                        <div className="container-divide">
                                            <div style={{fontSize: 15}} className="divEmail">{row.name}</div>
                                        </div>
                                    </div>
                                    <div className="border-div row-last h1-6">
                                        <div className="container-divide">
                                            <div style={{borderRight:'2px solid #ddd'}} className="btOptions" onClick={()=> {
                                                setTag(row.name)
                                                setTagLength(row.name.length)
                                                setOperation('edit')
                                                setValorMax(row.name.length >= 15?row.name.length:15)
                                                setId(row._id)
                                                window.scrollTo(0,0)
                                            }}>Editar</div>
                                            <div className="btOptions" onClick={e=>handleDelete(row._id)}>Excluir</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
            

        </>
    )
}
