/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import '../../global.css';
import api from '../../api'
import Autocomplete from '../../components/autosuggest'
import InputMask from "react-input-mask";
import axios from 'axios'

export default function StepOne({setStep, setEndereco, step, endereco, setNumero, numero, setComplemento, complemento, setTipo, tipoSelected, setBairro, block, setBlock}) {
  const [tipo, setTipoArray] = useState([])      
  const [tipe, setTipe] = useState('')      

    function handleNext(){ 
        let enderecos = document.querySelector('#autocomplete').value 
        var end
        end = enderecos.split(',')[0]
        if(String(enderecos).split('-').length > 2){
          let bairro = String(enderecos).split(',')[0].split(' - ')[1]
          end = String(enderecos).split(',')[0].split(' - ')[0]
          
          setBairro(bairro+','+String(enderecos).split(',')[1])
        }
        
        setEndereco(enderecos)
        if(enderecos.length >= 5 && String(end).toUpperCase().includes(block.toUpperCase()) && numero.length >= 1 && tipoSelected > 0){
            setEndereco(end)
            setBlock(end)
            setStep(2)
        }else{
            let errors = document.querySelectorAll('.input-box')
            
            if(!String(end).includes(block)){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
                alert('Digite o endereço e selecione uma das sugestões.')
            }
            if(numero.length < 1){
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }
            if(!tipoSelected){
                errors[3].style.border = "2px solid red"
                styleBorderError(errors[3])
            }
        }
    }

    function styleBorderError(object){
        setTimeout(() => {
            object.style.border = "none"
        }, 3000);
    }

    function handleFixedMozilla(){ 
      var nav = navigator.userAgent.toLowerCase(); 
      if(nav.indexOf("mozilla") != -1 && document.querySelector('#autocomplete').length != 0){
        if(nav.indexOf("firefox") != -1){
          handleGamg()
          return
        }else if(nav.indexOf("firefox") != -1){
          handleGamg()
          return
        }
      }  
    }

    useEffect(() => {
        // let wrapper = document.querySelectorAll('.wrapper')
        // wrapper[0].style.width = '100%'
        
        // document.querySelectorAll('.wrapper label')[0].style.display = 'none'
        // document.querySelectorAll('.react-autosuggest__container')[0].style.height = 'auto'
        handleLoadTypes()
        let body = document.querySelector('body')

        document.querySelector('#autocomplete').addEventListener('change', f => {  
          var nav = navigator.userAgent.toLowerCase(); 
          if(nav.indexOf("mozilla") != -1){
            if(nav.indexOf("firefox") != -1){
              handleGamg()
              return
            }else if(nav.indexOf("firefox") != -1){
              handleGamg()
              return
            }
          }  
          if(window.innerWidth < 1000){
            handleGamg()
          }
        })

        document.querySelector('#autocomplete').addEventListener('keydown', f => {
          if(f.key == 'Enter'){
            // alert('Selecione uma opção utilizando o mouse.') 
            handleGamg()
          }
        })

        if(document.querySelector('#scriptMap') && document.querySelector('#scriptInput')){
          body.removeChild(document.querySelector('#scriptMap'))
          body.removeChild(document.querySelector('#scriptInput'))
          body.removeChild(document.querySelector('.pac-container'))
        }

        var script = document.createElement('script')
        
        script.src = `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg'}&callback=initAutocomplete&libraries=places&v=weekly`
        script.setAttribute("defer", '')
        script.id='scriptMap'

        body.appendChild(script)
        

        script = document.createElement('script')
        script.innerHTML=`

        var placeSearch;
        var autocomplete;
        var componentForm = {
          street_number: "short_name",
          route: "long_name",
          locality: "long_name",
          administrative_area_level_1: "short_name",
          country: "long_name",
          postal_code: "short_name",
        };
        
        function initAutocomplete() {
          autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            { types: ["geocode"] }
          );
          autocomplete.setFields(["address_component"]);
          autocomplete.addListener("place_changed", fillInAddress);
        }
        
        function fillInAddress() {
          var place = autocomplete.getPlace();
        
          for (var component in componentForm) {
            document.getElementById(component).value = "";
            document.getElementById(component).disabled = false;
          }
        
          for (var component of place.address_components) {
            var addressType = component.types[0];
        
            if (componentForm[addressType]) {
              var val = component[componentForm[addressType]];
              document.getElementById(addressType).value = val;
            }
          }
        }
        
        function geolocate() {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              const circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy,
              });
              autocomplete.setBounds(circle.getBounds());
            });
          }
        }

        `

        script.id="scriptInput"
        body.appendChild(script)

        document.querySelector('#autocomplete').setAttribute("onFocus", "geolocate()")

        
        window.onload = () => {
          var target = document.querySelector('.pac-container');
          var observers = new MutationObserver(function(mutationsList, observer) {
            var targetItem = document.querySelectorAll('.pac-item');
            if(targetItem.length != 0){
              for(let i = 0; i < targetItem.length; i++){
                targetItem[i].addEventListener('mousedown', e => handleChangeAddress(e.path, e)) 
              }
            }
          });

          observers.observe(target, {characterData: false, childList: true, attributes: false});
        }

        window.onclick = () => {
          var target = document.querySelector('.pac-container');
          var observers = new MutationObserver(function(mutationsList, observer) {
            var targetItem = document.querySelectorAll('.pac-item');
            if(targetItem.length != 0){
              for(let i = 0; i < targetItem.length; i++){ 
                targetItem[i].addEventListener('mousedown', e => handleChangeAddress(e.path, e))
              }
            }
          });

          observers.observe(target, {characterData: false, childList: true, attributes: false});
        }
        
    }, [])  

    function handleGamg(){ 
      let input = document.querySelector('#autocomplete').value

      if(input.split('-').length == 2){
        setBlock(input.split(' - ')[0])
        return setEndereco(input)
      }

      setBlock(input.split(' - ')[0])
      setEndereco(input)
      // setBlock(path[4].split('<')[0] +path[5].split('<')[0])
      
      // setEndereco(first)
    }

     
    function handleChangeAddress(target, e) { 
      var nav = navigator.userAgent.toLowerCase(); 
      if(nav.indexOf("mozilla") != -1){ 
        if(nav.indexOf("firefox") != -1){  
          return
        }
      } 

      let path = target.filter(e => {
        return e.className == 'pac-item'
      })

      path = path[0].innerHTML.split('>')

      let first = path[4].split('<')[0] +path[5].split('<')[0]+', '+path[7].split('<')[0]
      console.log(first)
      if(first.split(',').length == 4){
        first = path[4].split('<')[0] +path[5].split('<')[0]+' - '+path[7].split('<')[0]
      }

      setBlock(path[4].split('<')[0] +path[5].split('<')[0])
      
      setEndereco(first)
    }
    
    // function debounce(func, wait, immediate) {
    //     var timeout;
      
    //     return function executedFunction() {
    //       var context = this;
    //       var args = arguments;
              
    //       var later = function() {
    //         timeout = null;
    //         if (!immediate) func.apply(context, args);
    //       };
      
    //       var callNow = immediate && !timeout;
          
    //       clearTimeout(timeout);
      
    //       timeout = setTimeout(later, wait);
          
    //       if (callNow) func.apply(context, args);
    //     };
    //   };

    async function handleLoadTypes(){
        const {data} = await api.get('/propertyType?limit=9999')

        let tips = new Array()
        data.propertyTypes.map(item => {
            tips.push({value: item._id, name: item.name})
            if(item._id == tipoSelected){
              setTipe(item.name)
            }
        })
        
        setTipoArray(tips)
    }

    useEffect(()=>{
      if(tipe != ''){ 
        document.getElementById('optiTeste').innerHTML = tipe
      } else document.getElementById('optiTeste').innerHTML = 'Selecione'
    }, [tipe])

    return (
        <> 
        
            <div className="step">
                <div className="input-box">
                    <small>Endereço (sem número)</small>
                    <input id="autocomplete" className="controls" type="text"  value={endereco}  onChange={text => {setEndereco(text.target.value)}} placeholder="" autoCapitalize/>
                    {/* <Autocomplete placeholder="" onChange={(value) => console.log(value)} objs={address}/> */}
                </div>

                <div className="container-input-box">
                    <div className="input-box">
                        <small>Número</small>
                        <InputMask type="number" onChange={text => {
                          handleFixedMozilla() 
                          setNumero(text.target.value)}
                          } value={numero}/>
                    </div>
                    <div className="input-box">
                        <small>Complemento</small>
                        <InputMask value={complemento}  onChange={text => {
                          handleFixedMozilla() 
                          setComplemento(text.target.value)
                        }} />
                    </div>
                </div>
                <div className="input-box">
                    <small>Tipo de imóvel</small>
                    <select inputName="Tipo" onChange={(value) => {
                          handleFixedMozilla() 
                          if(value.target.value != 'Selecione') setTipo(value.target.value)
                          }}>
                        <option id='optiTeste'>Selecione</option>
                      {tipo.map(items=>(
                        <option value={items.value}>{items.name}</option>
                      ))}
                    </select>
                    {/* <Autocomplete onChange={(value) => {setTipo(value.value)}} objs={tipo} inputName="Tipo" placeholder={tipe? tipe:"Tipo: "} /> */}
                    {/* <input value={tipo}  onChange={text => setTipo(text.target.value)} placeholder="Apartamento, Casa, etc" autoCapitalize/> */}
                </div>

                <div className="container-next">
                    <button onClick={handleNext}>Avançar</button>
                </div>
            </div>
        </>
    )
}
