import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import { BgImg, FilterContainer} from './styles'
import Footer from '../../components/Footer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour'
import Finish from './finish'

export default function Register() {
    const [step, setStep] = useState(1)
    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [email, setEmail] = useState('')
    const [creci, setCreci] = useState('')
    const [emailConfirm, setEmailConfirm] = useState('')
    const [celular, setCelular] = useState('')
    const [otherCelular, setOtherCelular] = useState('')
    const [senha, setSenha] = useState('')
    const [senhaConfirm, setSenhaConfirm] = useState('')
    const [termo, setTermo] = useState({termo: false, email: true})
    const [codigo, setCodigo] = useState('')
    const [receiveEmail, setReceiveEmail] = useState(true)

    async function handleRegister(){
        const {data} = await api.post('/user/', {
            password: senha,
            email,
            telephone: [celular, otherCelular],
            name: nome,
            cpf,
            creci,
            type: 'agent',
            receiveEmail
        })
        if(data.error) {
            alert(data.error)
            setStep(1)
            return false
        }
        
        setStep(5)
        localStorage.setItem('id', data._id)
        return true
    }

    return (
        <>
            <BgImg>
                <Header />
                <FilterContainer>
                    <div className="login-wrapper">
                        <div className="info-box">
                            <h1>Tenha acesso a todas as informações do imóvel para realizar a venda e ganhar a comissão!</h1>

                            <div className="card-retangle">
                                <img src={require('./icons/palm.svg')}/>
                                Nossos imóveis têm preços abaixo do mercado 
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/porcent.svg')}/>
                                Até 6% de corretagem para corretores e imobiliárias
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/body.svg')}/>
                                Facilidade de visitação aos imóveis
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/work.svg')}/>
                                Serviços gratuitos de despachante em todo o processo de compra e venda
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/transfer.svg')}/>
                                Possibilidade de permuta por automóveis e outros imóveis
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/star.svg')}/>
                                Novos imóveis todos os meses
                            </div>
                        </div>

                        <div className="register-box" style={{height: window.innerWidth <= 600 ? step == 3? '55%':'' : ''}}>
                            <h1>Cadastro de Corretor de Imóveis</h1>
                            <h4>Todos os nossos imóveis são vendidos por vocês corretores!</h4>

                            <div className="body-box">

                                <div className="steps-box">
                                    <div className="step-horizontal">
                                        <div className={step == 1 ? "step-circle" : "step-complete"}>1</div>
                                        {window.innerWidth <= 600 ? step == 1 ? 'Identificação' : '' :'Identificação'}
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 2 ? "step-circle" : step < 2 ? "step-disable" : "step-complete"}>2</div>
                                        {window.innerWidth <= 600 ? step == 2 ? 'Contato' : '' :'Contato'}
                                        
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 3 ? "step-circle" : step < 3 ? "step-disable" : "step-complete"}>3</div>
                                        {window.innerWidth <= 600 ? step == 3 ? 'Acesso' : '' :'Acesso'}
                                        
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 4 ? "step-circle" : step < 4 ? "step-disable" : "step-complete"}>4</div>
                                        {window.innerWidth <= 600 ? step == 4 ? 'Confirmação' : '' :'Confirmação'}
                                        
                                    </div>
                                </div>

                                {step == 1 ? <StepOne nome={nome} setNome={setNome} cpf={cpf} setCpf={setCpf} creci={creci} setCreci={setCreci} setStep={setStep}/> : step == 2 ? <StepTwo otherCelular={otherCelular} setOtherCelular={setOtherCelular} celular={celular} setCelular={setCelular} email={email} setEmail={setEmail} emailConfirm={emailConfirm} setEmailConfirm={setEmailConfirm} setStep={setStep}/> : step == 3 ? <StepThree senha={senha} senhaConfirm={senhaConfirm} setSenha={setSenha} setReceiveEmail={setReceiveEmail} setSenhaConfirm={setSenhaConfirm}  setTermos={setTermo} termos={termo} email={email}  setStep={setStep}/> : step == 4 ? <StepFour setCodigo={setCodigo} codigo={codigo} handleRegister={handleRegister} celular={celular} setStep={setStep}/> : <Finish setStep={setStep}/>}
                                

                            </div>
                        </div>
                    </div>
                </FilterContainer>
            </BgImg>

            <Footer />

        </>
    )
}
