const { default: styled } = require("styled-components");


export const ImagesContainer = styled.div`
    padding:30px;
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    grid-template-rows: 30vh 30vh;
    row-gap: 5px;
    column-gap: 5px;
    margin-top:100px;

    
`
export const Tag = styled.div`
            position:absolute;
            bottom:20px;
            left:20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #FFFFFF;
            padding:5px;
            height: 22px;
            background: #EA5C1E;
            display:flex;
            justify-content:center;
            align-items:center;
            opacity: 0.9;
            border-radius: 2px;

            @media(max-width:600px){
                height: 30px;        
            }
            
`

export const ImageFull = styled.div`
        height: 99%;
        background:url(${props=> props.image ? props.image : 'https://www.ufukkimya.com/media/resim-yok.png'});
        background-size:cover;
        background-position:center;
        grid-column: 1/2;
        grid-row: 1/4;
        border-radius: 8px 0px 0px 8px;
        position:relative;
`
export const Image1 = styled.div`
    height: 100%;
    background:url(${props=> props.image ? props.image : 'https://www.ufukkimya.com/media/resim-yok.png'});
    background-size:cover;
    background-position:center;
    grid-row: 1/2;
    grid-column: 2/3;
    
`

export const Image2 = styled.div`
    height: 100%;
    background:url(${props=> props.image ? props.image : 'https://www.ufukkimya.com/media/resim-yok.png'});
    background-size:cover;
    background-position:center;
    grid-row: 2/3;
    grid-column: 2/3;
`

export const Image3 = styled.div`
    height: 100%;
    background:url(${props=> props.image ? props.image : 'https://www.ufukkimya.com/media/resim-yok.png'});
    background-size:cover;
    background-position:center;
    grid-row: 1/2;
    grid-column: 3/4;
    border-radius:0px 8px 0px 0px;
`
export const Image4 = styled.div`
    height: 100%;
    background-color: brown;
    grid-row: 2/3;
    grid-column: 3/4;
    border-radius: 0px 0px 8px 0px;
    position:relative;
    background:url(${props=> props.image ? props.image : 'https://www.ufukkimya.com/media/resim-yok.png'});
    background-size:cover;
    background-position:center;


        button{
            position: absolute;
            width: 115px;
            height: 28px;
            right: 20px;
            bottom: 20px;
            background: #FFFFFF;
            border-radius: 4px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #00AEC8;
            border-radius: 4px;
            border:none;
            outline:none;
        }
`

export const Container = styled.div`
    display:flex;
    padding:30px;
    flex-wrap:wrap;
    width:60vw;

    .street{    
        display:flex;
        width:100%;
        align-items:center;
        justify-content:space-between;
        h1{
            font-size: 36px;
            line-height: 41px;
            color: #1E1D1D;
        }

        button, .btBook{
            display:flex;
            border:none;
            outline:none;
            width: 154px;
            height: 28px;
            background: #F2F2F2;    
            border-radius: 4px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #00AEC8;
            justify-content:center;
            align-items:center;
            
            img{
                margin-right:5px;
            }
        }
    }

    .city{
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
    
    .btCompartilhe{
        margin-top: 10px;
        display:flex;
        border:none;
        outline:none;
        width: 154px;
        height: 28px;
        background: #F2F2F2;    
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #00AEC8;
        justify-content:center;
        align-items:center;
        
        img{
            margin-right:5px;
        }
    }

    .row{
        display:flex;
        flex-wrap:wrap;
        width:100%;
        margin-top:30px;
        align-items:center;

        .icon-group{
            display:flex;
            margin-right:20px;
            align-items:center;
            
            img{
                margin-right:5px;
            }

            p{
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                color: #1E1D1D;
            }
        }

    }
    
    a{
        margin-top:15px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        letter-spacing: 0.05em;
        text-decoration-line: underline;
        width:100%;
        /* #00AEC8 */

        color: #00AEC8;
    }

    h1{
        font-size: 24px;
        line-height: 28px;
        margin-bottom:30px;
        color: #1E1D1D;
        width:100%;
    }

    p{
        font-size: 18px;
        line-height: 26px;
        text-align: start;
        width:100%;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    iframe{
        width:100%;
        height:500px;
    }

    @media(max-width:600px){
        width: 100%;
        border-top: 2px solid #eee;
        margin-top: 15px;

    }
`
export const Body = styled.div`
    display:flex;
    @media(max-width:600px){
        flex-direction: column-reverse;
    }
`
export const Aside = styled.aside`
    width:40vw;
    display:flex;
    align-items:center;
    flex-direction:column;

    .card{
        height: 454px;
        display:flex;
        flex-direction:column;
        background: #FFFFFF;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        padding:35px;

        @media(min-width:600px){
            width: 421px;
        }
        @media(max-width:600px){
            height: auto;
            width: 116%;
            padding: 15px;
        }
        .price{
            margin-left:auto;
            align-items:center;
            margin-bottom:15px;

            p{
                margin-top:auto;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
            }

            h1{
                margin-left:10px;
                font-size: 36px;
                line-height: 41px;
                color: #00AEC8;
            }
        }

        .condo{
            margin-bottom:10px;
            margin-right:15px;

            
            @media(max-width:600px){ 
                width: auto;
            }
        }

        .margin-left{
            margin-left:auto;
        }

        .price{
            font-weight: 500;
            font-size: 18px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            @media(max-width:600px){ 
                width: auto;
            }
        }

        .descount{
            width:100%;
            font-size: 14px;
            line-height: 24px;
            text-align: right;
            letter-spacing: 0.05em;
            color: #4F4F4F;
        }

        .row{
            display:flex;
            align-items:center;
        }

        a{
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            border-radius: 4px;
            text-decoration:none;
            height: 45px;
            display:flex;
            justify-content:center;
            align-items:center;
        }

        .text-center{
            text-align:center;
            margin-top:15px;
            margin-bottom:15px;
        }

        .exclusive{
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #1E1D1D;
        }
    }

    .contactUs{
        margin-top:20px;
        text-align:center;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        color: #00AEC8;
    }
    .download{
        border: 2px solid #EA5C1E;
        height: 50px;
        width: 100%;//350px;
        color: #EA5C1E;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 20px;
        text-align: center;
        text-decoration: initial;   
    }
    @media(max-width:600px){
        // width: 90%;
        // margin-left: 5%;
        width: 100%;
    }
`