const { default: styled } = require("styled-components");

export const Container = styled.div`
    margin-left:20vw;
    width:80vw;
    height:100%;
    padding:30px;
    padding-top:12vh;

    .input-group-teste{
        width: 230px;
        height: 59px;
        background: #DADADA;
        border-radius: 4px;
        padding:5px;
        margin-right:10px;
        margin-bottom:10px;
        display: flex;
        flex-direction: column;
        
        label{
            width:100%;
        }

        input,select{
            width:100%;
            height:60%;
            background:transparent;
            border:none;
            outline:none;
            margin-top: 3px;
            padding-left: 10px;
        }
       
        @media only screen and (max-width: 600px) {
            margin-right:0px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 600px) {
        margin-left: 0vw;
        width: 100vw;
        padding-top:2vh;
    }

    .container-input-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .input-box{
            width: 48%;
            margin-left: 0;
        }
        .input-center{
            width: 45%;
            margin-right: 2%;
        }

        .input-box{
            background-color: #eee;
            border-radius: 5px;
            height: 60px;
            width: 100%;
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 15px;
            padding-top: 3px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;

            small{
                font-weight: 500;
                                        
            }

            input, select{
                background-color: transparent;
                border: transparent;
                border-bottom: 2px solid #ccc;
                width: 100%;
            }
        }

    }

    .container-next{
        height: 50px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: space-evenly;
        justify-content: center;

        
        button{
            border: transparent;
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            width: 60%;
            color: white;
            height: 100%;
            border-radius: 4px;
        }

        button:hover{
            color: #EA5C1E;
            background: white;
            text-decoration: none;
            border: 2px solid #EA5C1E;
            filter: none;
            background-color: white;
            background-image: none;
        }

        .btBack{
            margin-right: 4%;
            border: 2px solid #EA5C1E;
            background-color: white;
            background: white;
            color: #EA5C1E;
        }

        .btBack:hover{
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            color: white;
            border: none;
        }
    }
    
  .bread-crumb{
    display:flex;
    align-items:center; 
    margin-bottom:40px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }

    .category{
        color: #0A677C;
        margin-right:3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    .where{
        color: #EB5C1C;
        text-decoration:none;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
  }

    .filter{
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        padding:20px;
        display:flex;
        flex-wrap:wrap;
        .input-group{
            width: 230px;
            height: 59px;
            background: #DADADA;
            border-radius: 4px;
            padding:5px;
            margin-right:10px;
            margin-bottom:10px;
            
            label{
                width:100%;
            }
            input,select{
                width:100%;
                height:60%;
                background:transparent;
                border:none;
                outline:none;
                margin-top: 3px;
                padding-left: 10px;
            }
           
            @media only screen and (max-width: 600px) {
                margin-right:0px;
                width: 100%;
            }
        }
        .button-group{
            margin-left:auto;
            margin-right:85px;
            
            @media only screen and (max-width: 600px) {
                margin-left:0px;
                margin-right:0px;
            }

            .outline{
                background:transparent;
                outline:none;
                border: 1px solid #EA5C1E;
                padding:10px;
                border-radius: 4px;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */
                text-align: center;
                letter-spacing: 0.05em;
                /* #EA5C1E */
                color: #EA5C1E;
                
                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
            }
            .full{
                outline:none;
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                border-radius: 4px;
                border:none;
                padding:10px;
                color:#ffffff;
                margin-left:10px;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    margin-left: 0px;
                    margin-top:6px;
                }
            }
        }
    }
    .row-ordenate{
        display:flex;
        width:100%;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
        margin-bottom:20px;
        .input-group{
            width: 230px;
            height: 59px;
            background: #DADADA;
            border-radius: 4px;
            padding:5px;
            margin-right:10px;
            margin-bottom:10px;
            
            label{
                width:100%;
            }
            input,select{
                width:100%;
                height:100%;
                background:transparent;
                border:none;
                outline:none;
            }
           
            @media only screen and (max-width: 600px) {
                width: 100%;
                margin-right: 0px;
            }
        }
    }
    .header{
        display:flex;
        background: #0A677C;
        border: 1px solid #0A677C;
        border-radius: 4px;
        color:#ffffff;
        height: 50px;

        div{
            height: 100%;
            h2{
                padding-left: 12px;
                display: flex;
                align-items: center;
                height: 100%
            }
        }

        div + div{
            border-left:1px solid #ffffff;
        }

        .cod{
            width: 7%;
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }

        .active{
            width: 35%;
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }

        .imovel{
            width: 32%;
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }
        
        .engajamento{
            width: 21%;
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }

        .stats{
            width: 15%;
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }

        .actions{
            width: 10%;
            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }
    }

    .body{
        height: 150px;
        width: 100%;
        margin-top: 20px;
        border-radius: 5px;
        border: 1px solid #aaa;
        display: flex;
        flex-direction: row;

        div{
            display: flex;
        }
        
        .body-cod{
            border-right: 1px solid #aaa;
            width: 7%;
            height: 100%;
            color: #0A677C;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 17px;

            @media only screen and (max-width: 600px) {
                width: 18%;
            }
        }

        .body-active{
            border-right: 1px solid #aaa;
            width: 20%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            div{ 
                overflow-x: auto;
                padding-left: 22px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 16px;


                a{
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
        .body-imovel{
            border-right: 1px solid #aaa;
            width: 35%;
            height: 100%;
            flex-direction: row;
            align-items: center;
            flex-direction: column;
            padding-top: 15px;
            
            @media only screen and (max-width: 600px) {
                width: 250px;
            }

            .opt-left{
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;

                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    overflow-x: auto;
                    height: 50%;
                    width: 100%;

                    img{
                        padding-right: 8px;
                    }
                }

                a+a{
                    border-top: 1px solid #aaa;
                }
            }
            .opt-right{
                width: 50%;
                height: 100%;
                border-left: 1px solid #aaa;
                display: flex;
                flex-direction: column;


                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    overflow-x: auto;
                    height: 50%;
                    width: 100%;
                }

                a+a{
                    border-top: 1px solid #aaa;
                }
            }
            small {
                width: 100%;
                font-size: 16px;
                padding-left: 20px;

                @media only screen and (max-width: 600px) {
                    font-size: 14px;   
                    padding-left: 15px; 
                }
            }

            h2, h3{
                width: 100%;
                color: #0A677C;
                padding-left: 20px;

                @media only screen and (max-width: 600px) {
                    font-size: 14px;   
                    padding-left: 15px; 
                }
            }
            h2{
                font-size: 20px;
                @media only screen and (max-width: 600px) {
                    font-size: 17px;   
                    padding-left: 15px; 
                }
            }

            h3{
                font-weight: 400;
                font-size: 18px;
                @media only screen and (max-width: 600px) {
                    font-size: 16px;   
                    padding-left: 15px; 
                }
            }

            h5{
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
                font-weight: 400;
                margin-top: 10px;
            }
            h6{
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
                font-weight: 400;
            }
        }
        .body-engajamento{
            border-right: 1px solid #aaa;
            width: 32%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            
            @media only screen and (max-width: 600px) {
                width: 150px;
                font-size: 14px;
            }

            div{ 
                overflow-x: auto;
                padding-left: 5px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 16px;


                a{
                    overflow-x: auto;
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
        .body-stats{
            width: 26%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #aaa;

            @media only screen and (max-width: 600px) {
                width: 140px;
            }
            

            .outline{
                background:transparent;
                outline:none;
                border: 1px solid #EA5C1E;
                padding:10px;
                border-radius: 4px;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */
                text-align: center;
                letter-spacing: 0.05em;
                /* #EA5C1E */
                color: #EA5C1E;
                width: 80%;
                
                @media only screen and (max-width: 600px) {
                    width: 80%;
                }
            }
            
            div{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: #1E1D1D;
                font-weight: 300;
                font-size: 1.2vw;

                a{
                    cursor: pointer;
                    @media only screen and (max-width: 600px) {
                        font-size: 15px;
                    }
                }
            }

        }
        .body-actions{
            width: 11%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px) {
                width: 150px;
            }
                        
            div{
                height: 100%;
                display: flex;
                justify-content: flex-start;
                padding-left: 18px;
                align-items: center;
                width: 100%;
                color: #00AEC8;
                font-size: 1.3vw;

                a{
                    cursor: pointer;
                    @media only screen and (max-width: 600px) {
                        font-size: 15px;
                    }
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
    }

    .paginate{
        width: 100%;
        height: 30px;
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .pagination{
            display: flex;
            height: 100%;
            list-style-type: none;
            
            li{
                list-style-type: none;

                a{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            
        }

        div, li{
            height: 100%;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #aaa;
            border-top: 1px solid #aaa;
            border-left: 1px solid #aaa;
        }

        .previous {
            width: 70px;
            font-size: 16px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .next{
            width: 70px;
            border-right: 1px solid #aaa;
            border-top-right-radius: 5px;
            font-size: 16px;
            border-bottom-right-radius: 5px;
        }

        li:hover {
            color: white;
            background: #0A677C;
            cursor: pointer;
        }

        .active {
            background: #0A677C;
            color: white;
        }
    }

    .box-divide{
        background-color: #eee;
        border-radius: 5px;
        height: 60px;
        width: 100%;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 15px;
        padding-top: 3px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        small{
            font-weight: 500;
                                    
        }

        input{
            background-color: transparent;
            border: transparent;
            border-bottom: 2px solid #ccc;
            width: 100%;
        }
        textarea{
            background-color: transparent;
            border: transparent;
            border-bottom: 2px solid #ccc;
            width: 100%;
        }
        
        select{
            background: transparent;
            border: none;
            color: #888;
            border-bottom: 2px solid #ccc;
            width: 100%;
        }
    }

    .max{
        height: 180px;
        textarea{
            height: 80%;
            resize: none
        }
    }
`
export const Carousel = styled.div`
    width:100%;
    height:auto;
    overflow-x:auto;
    display:flex;

`
export const PlusBtn = styled.button`  
    margin-left:20px;
    background-color:transparent;
    border:none;
`
export const BtnWrapper = styled.div`
    width: 100%;
    height: 128px;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const DocItem = styled.label`
    min-width: 178px;
    min-height: 128px;
    border-radius: 4px;
    background:url(${props => props.image ? props.image : require('../../assets/adminOfferProperty/docplaceholder.svg')});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    cursor:pointer;

    input{
        display:none;
    }

    .close{
        display : ${props => props.children[1].props.show ? 'flex' : 'none '};
        position:absolute;
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        right : 10px;
        top : 10px;
        border:none;
        font-size:16px;
        border-radius:8px;
        align-items:center;
        justify-content:center;
    }
`
export const Item = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

export const ItemLink = styled.a`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`