import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import '../../global.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
export default function ListCheckbox({apart, setApart, casa, setCasa, condo, setCondom, vila, setVila, cobertura, setCobertura, flat, setFlat, loft, setLoft}) {

    return (
        <>
            <FormControlLabel
                 style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={apart}
                    onChange={item => setApart(!apart)}
                    name="checkedB"
                    color="primary"
                    
                />
                }
                label="Apartamento"
            />
            
            <FormControlLabel
                style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={casa}
                    onChange={item => setCasa(!casa)}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Casa"
            />
            
            <FormControlLabel
                style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={condo}
                    onChange={item => setCondom(!condo)}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Casa de Condomínio"
            />
            
            <FormControlLabel
                style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={vila}
                    onChange={item => setVila(!vila)}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Casa de vila"
            />

            <FormControlLabel
                style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={cobertura}
                    onChange={item => setCobertura(!cobertura)}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Cobertura"
            />

            <FormControlLabel
                style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={flat}
                    onChange={item => setFlat(!flat)}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Flat"
            />

            <FormControlLabel
                style={{marginTop: 20}}
                control={
                <Checkbox
                    checked={loft}
                    onChange={item => setLoft(!loft)}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Loft"
            />
        </>
    )
}
