/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/header'
import { ImagesContainer, ImageFull, Image1, Image2, Image3, Image4, Container, Aside, Body, Tag } from './style';
import Carousel from '../../components/SimilarImovelCarousel'
import { Link, useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import moment from 'moment'
import PhotosModal from '../../components/PhotosModal'

import api from '../../api'

function PropertyDetail() {
    const [data, setData] = useState()
    const [modalCaract, setCaract] = useState(false)
    const [autoTag, setTag] = useState('')
    const [modal, setModal] = useState(false)
    const [photoModal, setPhotoModal] = useState(false)

    const tag = useRef()
    const description = useRef()
    const sellingNotes = useRef()

    const params = useParams()

    async function fetchData() {
        const response = await api.get(`/property/${params.id}`).catch(err => console.log(err))
        
        handleCompact(params.id)
        handleVisualization(params.id)
        const obj = response.data

        obj.images.sort((a, b)=>{
            if(a['order'] > b['order']){
                return 1
            }else{
                return -1
            }
        })
        
        setData(obj)
    }

    useEffect(() => {
        fetchData()
    }, [])

    // useEffect(()=>{
    //     document.querySelector('body').addEventListener('keydown', (e)=>{
    //         //alert(e)
    //         if(e.key == "Tab"){
    //             e.preventDefault();
    //         }
    //     })
    // }, [])

    useEffect(() => {
        filterTag()
        if (data != undefined) {
            description.current.innerHTML = data.description
            if(modal){
                sellingNotes.current.innerHTML = data.sellingNotes
            } 
        }
    }, [data])

    useEffect(() => {
        if (data != undefined) {
            if(modal){
                sellingNotes.current.innerHTML = 'Sem instruções no momento.'
                if(data.sellingNotes){
                    sellingNotes.current.innerHTML = data.sellingNotes
                }
            } 
        }
    }, [modal])

    function copyTo(){
        let text = `http://visualizarimovel.com.br/${data._id}`
        var textArea = document.createElement("textarea");
        
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
      
        document.body.appendChild(textArea);
        textArea.select();
      
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful'; 
            window.open(text);
        } catch (err) { 
            window.prompt("Copie para área de transferência: Ctrl+C e tecle Enter", text);
        }
      
        document.body.removeChild(textArea);
    }

    function filterTag() {

        if (data != undefined) {
            if (Date.parse(data.createdAt)) {

                const createdAt = moment(new Date(data.createdAt))
                    .diff(moment(Date.now()))

                const age = moment.duration(createdAt)
                
                let datase=data.createdAt.split(' ')[0].split('/')[2]+'-'+data.createdAt.split(' ')[0].split('/')[1]+'-'+data.createdAt.split(' ')[0].split('/')[0]
                
                var dataBanco = new Date(datase);
                
                if(isNaN(dataBanco.getTime())){
                    dataBanco = new Date('2020','01','01')
                }                
                var date2 = new Date();

                var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                              
                if (data.sold) {
                    tag.current.style.backgroundColor = "#1E1D1D"
                    return setTag('VENDIDO')
                }

                if(data.showTag && data.tag){
                    tag.current.style.backgroundColor = '#00AEC8'
                    return setTag(data.tag.name)
                }  
                
                if (diffDays <= 2) {
                    tag.current.style.backgroundColor = "#1C9C40"
                    return setTag('NOVIDADE')
                }

                if (data.descountPrice && (data.descountPrice != data.sellingPrice && data.descountPrice < data.sellingPrice)) {
                    tag.current.style.backgroundColor = "#EA1E1E"
                    return setTag('DESCONTO DE ' + Math.abs(Math.floor((data.descountPrice / data.sellingPrice) * 100 - 100)).toString() + '%')
                }
                
                if(data.tag){
                    tag.current.style.backgroundColor = '#00AEC8'
                    return setTag(data.tag.name)
                }else{
                    tag.current.style.opacity = '0';
                }
                

            } else {
                const createdAt = moment(data.createdAt, 'DD/MM/YYYY HH:mm')
                    .diff(moment(Date.now()))
                    
                const age = moment.duration(createdAt)

                let datase=data.createdAt.split(' ')[0].split('/')[2]+'-'+data.createdAt.split(' ')[0].split('/')[1]+'-'+data.createdAt.split(' ')[0].split('/')[0]
                
                var dataBanco = new Date(datase);
                
                if(isNaN(dataBanco.getTime())){
                    dataBanco = new Date('2020','01','01')
                }                
                var date2 = new Date();

                var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                          
                if (data.sold) {
                    tag.current.style.backgroundColor = "#1E1D1D"
                    return setTag('VENDIDO')
                }
                       
                if(data.showTag && data.tag){
                    tag.current.style.backgroundColor = '#00AEC8'
                    return setTag(data.tag.name)
                }  

                if (diffDays <= 2) {
                    tag.current.style.backgroundColor = "#1C9C40"
                    return setTag('NOVIDADE')
                }

                if (data.descountPrice && (data.descountPrice != data.sellingPrice && data.descountPrice < data.sellingPrice)) {
                    tag.current.style.backgroundColor = "#EA1E1E"
                    return setTag('DESCONTO DE ' + Math.abs(Math.floor((data.descountPrice / data.sellingPrice) * 100 - 100)).toString() + '%')
                }
                if(data.tag){
                    tag.current.style.backgroundColor = '#00AEC8'
                    return setTag(data.tag.name)
                }else{
                    tag.current.style.opacity = '0';
                }
                

            }

        }


    }

    function money (value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }


    async function handleCompact(id){
        let response = await api.post('/download', {id: id})
    }
    async function handleVisualization(id){
        let response = await api.put(`/visualization/${id}`)

    }

    function Imagens(){
        let ImagesTrue = []
        for(let i = 0; i < data.images.length; i++){
            if(data.images[i].show != 'false'){
                ImagesTrue.push(data.images[i])
            }
        }
        
        return (
            <>
                <ImageFull style={{cursor: 'pointer'}} onClick={() => setPhotoModal(true)} image={ImagesTrue[0] ? `'${process.env.REACT_APP_API_URL}/file/${data._id}/fotos/${ImagesTrue[0].image}'` : ''}>
                    <Tag ref={tag}>
                        {
                            autoTag
                        }
                    </Tag>
                </ImageFull>
                <Image1 style={{cursor: 'pointer'}} onClick={() => setPhotoModal(true)} image={ImagesTrue[1] ? `'${process.env.REACT_APP_API_URL}/file/${data._id}/fotos/${ImagesTrue[1].image}'` : ''}/>
                <Image2 style={{cursor: 'pointer'}} onClick={() => setPhotoModal(true)} image={ImagesTrue[2] ? `'${process.env.REACT_APP_API_URL}/file/${data._id}/fotos/${ImagesTrue[2].image}'` : ''}/>
                <Image3 style={{cursor: 'pointer'}} onClick={() => setPhotoModal(true)} image={ImagesTrue[3] ? `'${process.env.REACT_APP_API_URL}/file/${data._id}/fotos/${ImagesTrue[3].image}'` : ''}/>
                <Image4 style={{cursor: 'pointer'}} onClick={() => setPhotoModal(true)} image={ImagesTrue[4] ? `'${process.env.REACT_APP_API_URL}/file/${data._id}/fotos/${ImagesTrue[4].image}'` : ''}>
                    {
                        ImagesTrue.length > 5 ? (
                            <button onClick={() => setPhotoModal(true)}>
                                Mais fotos
                            </button>
                        ) : null
                    }
                </Image4>
            </>
        )
        
    }

    function AsideComponent(){

        return <Aside style={{marginTop: window.innerWidth <= 600 ? 25:''}}>
            <div className="card">
                <div className="row price">
                    <p style={{width: window.innerWidth <= 600? 'auto':'', marginTop: window.innerWidth <= 600? '0':''}}>Valor</p>
                    <h1 style={{width: window.innerWidth <= 600? 'auto':'', marginBottom: window.innerWidth <= 600? '0':''}}>
                        {
                            data.descountPrice && (data.descountPrice != data.sellingPrice && data.descountPrice < data.sellingPrice) ? (
                                // Intl.NumberFormat('pt-BR', {
                                //     style: 'currency',
                                //     currency: 'BRL',
                                // }).format(+data.descountPrice)
                                `R$ ${data.descountPrice.toLocaleString('pt-BR')}`
                            ) : (
                                    // Intl.NumberFormat('pt-BR', {
                                    //     style: 'currency',
                                    //     currency: 'BRL',
                                    // }).format(+data.sellingPrice)
                                `R$ ${data.sellingPrice.toLocaleString('pt-BR')}`
                                )
                        }
                    </h1>
                </div>
                {
                    data.descountPrice && (data.descountPrice != data.sellingPrice && data.descountPrice < data.sellingPrice) ? (
                        <div className="row descount" style={{marginBottom: 15, justifyContent: 'flex-end'}}>
                            <p style={{textAlign: 'end'}}>Valor reduzido de
                                {
                                    // ' ' + Intl.NumberFormat('pt-BR', {
                                    //     style: 'currency',
                                    //     currency: 'BRL',
                                    // }).format(+data.sellingPrice) + ' '
                                    ` R$ ${data.sellingPrice.toLocaleString('pt-BR')} `
                                }
                                para
                                {
                                    // ' ' + Intl.NumberFormat('pt-BR', {
                                    //     style: 'currency',
                                    //     currency: 'BRL',
                                    // }).format(+data.descountPrice)
                                    ` R$ ${data.descountPrice.toLocaleString('pt-BR')}`
                                }
                            </p>
                        </div>
                    ) : null
                }
                <div className="row margin-left">
                    <p className="condo">Condomínio</p>
                    {/* <p className="price">R$ {money(String(data.condominiumPrice).includes('.')? data.condominiumPrice:data.condominiumPrice+'00')}</p> */}
                    <p className="price">R$ {Number(String(data.condominiumPrice).split('.')[0]).toLocaleString('pt-BR')}</p>
                </div>
                <div className="row margin-left">
                    {
                        /*localStorage.getItem('id') &&*/ data.IPTU ? (
                            <>
                                <p className="condo">IPTU (mensal):</p>
                                <div className="price">
                                     {/* {data.IPTU? 'R$ '+money(String(data.IPTU).includes('.')? data.IPTU:data.IPTU+'00'):''} */}
                                     {data.IPTU? `R$ ${String(data.IPTU).toLocaleString('pt-BR') == '00.00'?'0':Number(String(data.IPTU).split('.')[0]).toLocaleString('pt-BR')}`:''}
                                </div>
                            </>
                            ) : ''
                    }
                </div>
                {
                    /*localStorage.getItem('id') &&*/ data.IPTUNumber ? (
                        <>
                            <div style={{width: '100%', textAlign: 'right', color: '#999', marginTop: -5}}>({data.IPTUNumber})</div>
                        </>
                        ) : ''
                }
                <div  style={{display: "flex", flexDirection: 'column', height: '100%', justifyContent: 'flex-end'}}>

                {
                    !data.sold /*&& localStorage.getItem('id')*/ ? (
                        <>
                            {/* <Link>Tenho Interesse em Comprar</Link>
                            <p className="text-center">ou</p> */}
                            {localStorage.getItem('id') ?<p style={{fontSize: 18, textAlign: "center", marginBottom: 15}}>
                                Comissão de <span style={{fontSize: 25, color: '#EA5C1E', fontWeight: 600}}>{data.comission? data.comission : '6'}% </span> 
                                ({

                                // Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', }).format(+(data.descountPrice? data.descountPrice : data.sellingPrice) * (data.comission? data.comission/100: 6/100))
                                `R$ ${((data.descountPrice? data.descountPrice : data.sellingPrice) * (data.comission? data.comission/100: 6/100)).toLocaleString('pt-BR')}`
                                })
                                </p>:''
                                }
                            <Link to={window.location.pathname} onClick={async () => {
                                if(localStorage.getItem('id')){
                                    setModal(true); 

                                    let response = await api.post(`/interested/${data._id}/${localStorage.getItem('id')}`) 
                                }else{
                                    window.location.href=`/login?goBack=/imoveis/${data._id}`
                                }
                            }}>Esquema de Chaves</Link>
                            
                            {localStorage.getItem('id')&&    
                                <Link to={window.location.pathname} style={{marginTop: 15, background: 'linear-gradient(90deg, #E5983D 0%, #EB5C1C 100%)'}} onClick={async () => {
                                    try{ 
                                        const response = await api.post(`/proposalImovel/`, {
                                            Property: data._id,
                                            User: localStorage.getItem('id'),
                                            createdAt: new Date().toLocaleDateString('pt-BR', {day: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {month: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {year: 'numeric'})
                                        })  

                                        if(response.data._id) {
                                            await api.post(`/interested/${data._id}/${localStorage.getItem('id')}`)
                                            return window.location.href='/admin/propostas-corretor/editar?id='+response.data._id
                                        }
                                    }catch(err) {
                                        if(err.response.data.imovel){
                                            window.location.href = '/admin/propostas-corretor/editar?id='+err.response.data.imovel
                                        }
                                        
                                        if(err.response.data.error){
                                            alert(err.response.data.error)
                                        }
                                    }
                                }}>Enviar Proposta para este Imóvel</Link>
                            }
                            <p className="text-center exclusive">Exclusivo para corretores de imóveis.</p>
                            
                        </>
                    ) : ''
                }
                {
                    data.sold ? (
                        <>
                            <p style={{fontSize: 20, textAlign: "center", marginBottom: 15}}>Imóvel Vendido</p>
                            <Link style={{marginBottom: 25}} to='/imoveis'>Ver outros imóveis</Link>
                        </>
                    )
                    :
                    ''
                }

                </div>
            </div>
            <div className="contactUs">
                <Link onClick={e=>window.scrollTo(0,0)} to="/contato">Ainda com dúvidas? Fale conosco</Link>
            </div>
                {
                    localStorage.getItem('id') ? (
                    <>
                        <h1 style={{fontSize: 27, marginTop: 40}}>Fotos e Documentação</h1>

                        <a className="download" target="_blank" style={{width: window.innerWidth > 600 ? '350px':''}} href={`${process.env.REACT_APP_API_URL}/file/${data._id}/fotos.zip`} >{/*download>*/}
                            Baixar fotos como zip
                        </a>
                        <a className="download" target="_blank" style={{width: window.innerWidth > 600 ? '350px':''}} href={`${process.env.REACT_APP_API_URL}/file/${data._id}/docs.zip`} >{/*download>*/}
                            Baixar documentos como zip
                        </a>
                    </>)
                    :''
                }
        </Aside>
    }

    return (
        <>
            {data?(
                <>
                    {
                        modal ? 
                            <> 
                                <div style={{height: '100vh', top: 0, width: '100vw', backgroundColor: '#000', opacity: 0.3, position: "fixed", zIndex: 99999}}></div>
                                <div style={{height: '80vh', width: '80vw', top: '5vh', left: '10vw', backgroundColor: '#fff', position: "fixed", zIndex: 9999999, borderRadius: 10}}>
                                    <div style={{height: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#EA5C1E', fontWeight: 500, fontSize: 19, alignItems: 'center', paddingRight: 20}}><span style={{cursor: "pointer"}} onClick={() => {setModal(false); document.querySelector('body').style.overflow='scroll'}}>X</span></div>
                                    <div style={{width: '87%', marginLeft: '5%', height: '95%'}}>
                                        <h1 style={{fontSize: window.innerWidth <= 600 ? 20:35, marginTop: '2%', height: '13%'}}>Não perca seu tempo, ofereça este exclusivo imóvel aos seus clientes!</h1>
                                        <h4 style={{height: '4%', width: '100%', fontSize: 23, marginTop: window.innerWidth <= 600 ?'18%':'2%'}}>Comissão <span style={{color: '#EA5C1E'}}>{data.comission}%</span></h4>

                                        {/* <FormControlLabel
                                            style={{}}
                                            control={
                                            <Checkbox
                                                checked={true}
                                                onChange={item => alert(item)}
                                                name="checkedB"
                                                color="primary"
                                                
                                            />
                                            }
                                            label="Adicionar este imóvel ao seu portfólio de vendas"
                                        /> */}

                                        <div style={{height: '70%'}}>
                                            <h4 style={{height: '13%', width: '100%', fontSize: window.innerWidth <= 600 ? 18:23, marginTop: '10%'}}>Instruções</h4>
                                            <div style={{height: '67%', fontSize: window.innerWidth <= 600 ? 16:19, overflowY: 'auto'}} ref={sellingNotes}>
                                                {/* Obras: Finalizada <br/><br/>
                                                Chaves: Tirar cópia com o chaveiro Max no End: Rua do Lavradio n°10, Centro. Sendo 1 chave no valor de R$8,00.<br/><br/>
                                                Chaveiro Max Tel: 2252-6950 / 2221-4675 / 98155-6500 / 984224675 (Whatsapp) (Falar em nome de Alexandre Greco)<br/><br/>
                                                Horários: de 2° a 6° das 9:00 às 17:00 e Sábados das 9:00 às 13:00 */}

                                                {/* {data.sellingNotes} */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        :
                            ''
                        }
                        {
                            photoModal ? <PhotosModal images={data.images} id={data._id}  setModal={setPhotoModal}  /> : ''
                        }
                </>
            ):''}
            <Header />
            {
                data ? (
                    <>  

                        {
                            modalCaract ?
                            <>
                                <div style={{height: 2000, width: '100%', backgroundColor: '#000', opacity: 0.3, position: "absolute", zIndex: 1}}></div>
                                <div style={{height: window.innerWidth <= 600 ? '80%':'70%', width: window.innerWidth <= 600 ? '90%':'60%', top: window.innerWidth <= 600 ? '55%':'22%', left: window.innerWidth <= 600 ? '5%':'20%', backgroundColor: '#fff', position: "absolute", zIndex: 1, borderRadius: 10}}>
                                    <div style={{height: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#EA5C1E', fontWeight: 500, fontSize: 19, alignItems: 'center', paddingRight: 20}}><span style={{cursor: "pointer"}} onClick={() => {setCaract(false); document.querySelector('body').style.overflow='scroll'}}>X</span></div>
                                    <div style={{width: '87%', marginLeft: '5%', height: '92%'}}>
                                        <h1 style={{fontSize: 35, height: '13%'}}>Todas as Características</h1>

                                        <div style={{display: 'flex', height: '7%', flexDirection: 'row', alignItems: 'center'}}>
                                            <img src={require('./home.svg')} height="22" width="25" alt="" />
                                            <h4 style={{fontSize: 18, marginLeft: 9}}>{data.type.name} {data.generalType? data.generalType.name : data.type.generalType == 1644602035863502 ? "Comercial":"Residencial"}</h4>
                                        </div>
                                        <div style={{display: 'flex', height: '25%', flexDirection: 'row', alignItems: 'center'}}>
                                            <div style={{width: '15%', height: '100%', display: 'flex',marginRight: '3%', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start"}}>
                                                <img style={{marginBottom: 10, marginTop: '50%'}} src={require('../../assets/outline_icons/grey/ruler.png')} height="25" width="12" alt="" />
                                                <p style={{marginBottom: 8, fontSize: 15}} >Área Útil</p>
                                                <p style={{fontWeight: 600, fontSize: 19}}>{data.fullLandSize} m²</p>
                                            </div>
                                            <div style={{width: '15%', height: '100%', display: 'flex',marginRight: '3%', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start"}}>
                                                <img style={{marginBottom: 10, marginTop: '50%'}} src={require('../../assets/outline_icons/grey/ruler.png')} height="25" width="12" alt="" />
                                                <p style={{marginBottom: 8, fontSize: 15}} >Área Total</p>
                                                <p style={{fontWeight: 600, fontSize: 19}}>{data.fullLandSize} m²</p>
                                            </div>
                                            <div style={{width: '15%', height: '100%', display: 'flex',marginRight: '3%', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start"}}>
                                                <img style={{marginBottom: 10, marginTop: '50%'}} src={require('../../assets/outline_icons/grey/bed.png')} height="25" width="20" alt="" />
                                                <p style={{marginBottom: 8, fontSize: 15}} >N° de Quartos</p>
                                                <p style={{fontWeight: 600, fontSize: 19}}>{data.bedrooms}</p>
                                            </div>
                                            <div style={{width: '15%', height: '100%', display: 'flex',marginRight: '3%', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start"}}>
                                                <img style={{marginBottom: 10, marginTop: '50%'}} src={require('../../assets/outline_icons/grey/bed.png')} height="25" width="20" alt="" />
                                                <p style={{marginBottom: 8, fontSize: 15}} >N° de Suítes</p>
                                                <p style={{fontWeight: 600, fontSize: 19}}>{data.suites}</p>
                                            </div>
                                            <div style={{width: '15%', height: '100%', display: 'flex',marginRight: '3%', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start"}}>
                                                <img style={{marginBottom: 10, marginTop: '50%'}} src={require('../../assets/outline_icons/grey/bathtub.png')} height="25" width="20" alt="" />
                                                <p style={{marginBottom: 8, fontSize: 15}} >N° de Banheiros</p>
                                                <p style={{fontWeight: 600, fontSize: 19}}>{data.bathrooms}</p>
                                            </div>
                                            <div style={{width: '15%', height: '100%', display: 'flex',marginRight: '3%', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start"}}>
                                                <img style={{marginBottom: 10, marginTop: '50%'}} src={require('../../assets/outline_icons/grey/car_parking.png')} height="25" width="20" alt="" />
                                                <p style={{marginBottom: 8, fontSize: 15}} >N° de Vagas</p>
                                                <p style={{fontWeight: 600, fontSize: 19}}>{data.parkingSpots}</p>
                                            </div>
                                        </div>

                                        <div style={{height: '50%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridAutoRows: '0.2fr 0.2fr 0.2fr 0.2fr', borderTop: '1px solid #aaa', marginTop: '5%', paddingTop: 15, paddingBottom: 15}}>
                                            {
                                                data.features ? 
                                                    data.features.map(row => (
                                                        <>
                                                            <div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: "center"}}>
                                                                <img style={{marginRight: 8}} src={require('./check.svg')} height="25" width="20" alt="" />
                                                                <p>{row.name}</p>
                                                            </div>
                                                        </>
                                                    ))
                                                :
                                                <h3>Nenhuma característica definida.</h3>
                                            }
                                        </div>  

                                    </div>
                                </div>
                            </>
                            :
                            ''
                        }
                        
                        <ImagesContainer style={photoModal ? {marginTop : '0px'} : {}}>
                            {
                                Imagens()
                            }
                        </ ImagesContainer>
                        <Body>
                            <Container>
                                <div className="street">
                                    <h1>{data.address?data.address:''}{localStorage.getItem('id') ? data.showNumber ? ', '+data.addressNumber:'': ''}</h1>
                                    {   
                                        data.pdf ? 
                                            localStorage.getItem('id') ? 
                                                window.innerWidth > 600 ? 
                                                    <a href={`${process.env.REACT_APP_API_URL}/file/${data._id}/${data.pdf}`} target='_blank' className='btBook'  style={{marginRight: 10, background: '#EA5C1E', color: 'white', marginTop: 0, textDecoration: 'none'}}>
                                                        <img style={{marginRight: 8}} src={require('./cam.svg')} alt="" />
                                                        Book
                                                    </a>
                                                :
                                                ''
                                            :
                                            ''
                                        :
                                        ''
                                    }
                                    {
                                        localStorage.getItem('id') ? 
                                            window.innerWidth > 600 ? 
                                                <button onClick={copyTo}>
                                                    <img src={require('../../assets/propertyDetail/share.svg')} alt="" />
                                                    Compartilhe
                                                </button>
                                            :
                                            ''
                                        :
                                        ''
                                    }
                                </div>
                                <p className="city"><strong>{localStorage.getItem('id') ? data.showComplement ? (data.addressComplement ? data.addressComplement+' - ':''):'':''}</strong>{data.neighborhood ? data.neighborhood.name:''} - {data.neighborhood.state}</p>
                                
                                {
                                    localStorage.getItem('id') ? 
                                        window.innerWidth < 600 ? 
                                            <button style={{width: '50%'}} onClick={copyTo} className="btCompartilhe">
                                                <img src={require('../../assets/propertyDetail/share.svg')} alt="" />
                                                Compartilhe
                                            </button>
                                        :
                                        ''
                                    :
                                    ''
                                }
                                {
                                    data.pdf ? 
                                        localStorage.getItem('id') ? 
                                            window.innerWidth < 600 ? 
                                                <a href={`${process.env.REACT_APP_API_URL}/file/${data._id}/${data.pdf}`} target='_blank' style={{marginLeft: 10, background: '#EA5C1E', color: 'white', width: '40%', textDecoration: 'none'}} className="btCompartilhe">
                                                    <img style={{marginRight: 8}} src={require('./cam.svg')} alt="" />
                                                    Book
                                                </a>
                                            :
                                            ''
                                        :
                                        ''
                                    :
                                    ''
                                }
                                <div className="row">
                                    <div className="icon-group" style={window.innerWidth <= 600 ? {width:'100%'}:{}}>
                                        <img src={require('../../assets/outline_icons/orange/house.png')} height="15" width="15" alt="" />
                                        <p><strong>{data.type.name} {data.generalType? data.generalType.name : data.type.generalType == 1644602035863502 ? "Comercial":"Residencial"}</strong></p>
                                    </div>
                                    <div className="icon-group" style={window.innerWidth <= 600 ? {width:'50%', marginTop: 15}:{}}>
                                        <img src={require('../../assets/outline_icons/grey/ruler.png')} height="15" width="7" alt="" />
                                        <p>{data.fullLandSize} m²</p>
                                    </div>
                                    <div className="icon-group" style={window.innerWidth <= 600 ? {marginTop: 15}:{}}>
                                        <img src={require('../../assets/outline_icons/grey/bed.png')} height="12" width="15" alt="" />
                                        <p>{data.bedrooms} {data.bedrooms > 1 ? 'quartos':'quarto'}</p>
                                    </div>
                                    <div className="icon-group" style={window.innerWidth <= 600 ? {width:'50%', marginTop: 15}:{}}>
                                        <img src={require('../../assets/outline_icons/grey/bed.png')} height="12" width="15" alt="" />
                                        <p>{data.suites} {data.suites > 1 ? 'suítes':'suíte'}</p>
                                    </div>
                                    <div className="icon-group" style={window.innerWidth <= 600 ? {marginTop: 15}:{}}>
                                        <img src={require('../../assets/outline_icons/grey/bathtub.png')} height="12" width="15" alt="" />
                                        <p>{data.bathrooms} {data.bathrooms > 1 ? 'banheiros':'banheiro'}</p>
                                    </div>
                                    <div className="icon-group" style={window.innerWidth <= 600 ? {marginTop: 15}:{}}>
                                        <img src={require('../../assets/outline_icons/grey/car_parking.png')} height="12" width="15" alt="" />
                                        <p>{data.parkingSpots} {data.parkingSpots > 1 ? 'vagas':'vaga'}</p>
                                    </div>
                                </div>
                                <Link to={window.location.pathname} onClick={() => {document.querySelector('body').style.overflow='hidden'; window.scrollTo(0, window.innerWidth <= 600 ? 280:120); setCaract(true);}}>Mostrar todas as características </Link>

                                {
                                    window.innerWidth <= 600 ? AsideComponent() : ''
                                }

                                <h1 style={{ marginTop: '60px' }}>Descrição</h1>
                                <p ref={description}></p>

                                <h1 style={{ marginTop: '60px' }}>Localização</h1>
                                <iframe src={`https://www.google.com/maps/embed/v1/place?key=${'AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg'}&q=${data.neighborhood.name}+${data.city.name}+${data.neighborhood.state}+${localStorage.getItem('id')?data.address:''}+${localStorage.getItem('id')?data.addressNumber:''}`} frameBorder="0">
                                {/* https://maps.google.com/maps/api/geocode/json?address=Av%20Santo%20amaro,%20artur%20nogueira,%20sao%20paulo%20&sensor=false&key=AIzaSyCfZAKxUg065qNJ33P6NMjbPCY3NtPASMY */}
                                </iframe>

                                <h1 style={{ marginTop: '60px' }}>Imóveis similares</h1>
                                <Carousel className="teste" id={data._id} className="carousel" itemsToShow={window.innerWidth <= 600 ? 1:2} showCard={false} />
                            </Container>
                            {
                                window.innerWidth > 600 ? AsideComponent() : ''
                            }
                        </Body>
                    </>
                ) : ''
            }
            <Footer />
        </>
    );
}

export default PropertyDetail;