import styled from 'styled-components';

export const Container = styled.div`
  margin-left:20vw;
  width:80vw;
  height:100%;
  padding:30px;
  padding-right:20vw;
  
  @media only screen and (max-width: 600px) {
        width: 100%;
        margin-left: 0;
        padding-right: 0;
        padding: 30px;
    }   

  .bread-crumb{
    display:flex;
    align-items:center; 
    margin-bottom:40px;
    margin-top:10vh;
    
    @media only screen and (max-width: 600px) {
        margin-top: 1vh;
        flex-direction: column;
    }

    .category{
        color: #0A677C;
        margin-right:3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    .where{
        color: #EB5C1C;
        text-decoration:none;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
  }

  .input-group{
    display:flex;
    align-items:center;
    width:100%;
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }

    .select-group{
        display:flex;
        align-items:center;
        width:50%;
        
        @media only screen and (max-width: 600px) {
            width: 100%;
            margin-top: 10px;
        }
        span{
            margin-left:5px;
        }
    }

    .input-compose{
        width:100%;
        background: #DADADA;
        border-radius: 4px;
        padding:10px;
        margin-bottom:20px;

        label{
            margin-left:0px;
            width:100%;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
        }

        input,select,textarea{
            width:100%;
            border:none;
            padding-top:5px;
            border-bottom:1px solid #C4C4C4;
            background:transparent;
            resize:none;
        }
    }

    .input-compose + .input-compose {
        margin-left:10px;

        @media only screen and (max-width: 600px) {
            margin-left: 0;
        }
    }
  }

  .share{
    display:flex;
    width:50%;
    text-align:justify;
    border-top:5px solid #C3C8C9;
    border-bottom:5px solid #C3C8C9;
    padding-top:10px;
    padding-bottom:10px;
    width:100%;
    margin-bottom:15px;

    .row{
        display:flex;

        input{
            margin-right:5px;
            margin-top:8px;
        }
    }
        
    .text{
        width:50%;
    }

        p{
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */
            margin-left:20px;
            letter-spacing: 0.05em;

            color: #1E1D1D;
        }
    
    .column{
        display:flex;
        flex-direction:column;
        margin-left:20px;
        width:50%;

        textarea{
            height:100%;
            border: 1px solid #8B9495;
            box-sizing: border-box;
            border-radius: 4px;
            resize:none;
            padding:10px;
        }

        .input-compose{
        width:100%;
        background: #DADADA;
        border-radius: 4px;
        padding:10px;
        margin-bottom:20px; 

        p{
            margin-left:0px;
            width:100%;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
        }

        input{
            width:100%;
            border:none;
            padding-top:5px;
            border-bottom:1px solid #C4C4C4;
            background:transparent;
        }
    }
    
    }
  }

  .save{
      width:100%;
      text-align:right;
      margin-top: -70px;
      
      button{
        width: 199px;
        height: 45px;
        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
        border-radius: 4px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        border:none;    
        color: #FFFFFF;
      }
      
      @media only screen and (max-width: 600px) {
        margin-top:0;
        button {
            width: 100%;
        }
    }
  }
`;

export const Item = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

export const ImageItem = styled.label`
    min-width: 178px;
    min-height: 128px;
    border-radius: 4px;
    background:url(${props => props.image ? props.image : require('../../assets/adminOfferProperty/imgplaceholder.svg')});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    cursor:pointer;

    input{
        display:none;
    }

    .close{
        display : ${props => props.children[1].props.show ? 'flex' : 'none '};
        position:absolute;
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        right : 10px;
        top : 10px;
        border:none;
        font-size:16px;
        border-radius:8px;
        align-items:center;
        justify-content:center;
    }
`
export const BtnWrapper = styled.div`
    width: 100%;
    height: 128px;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const PlusBtn = styled.button`  
    margin-left:20px;
    background-color:transparent;
    border:none;
`

export const Carousel = styled.div`
    width:100%;
    height:auto;
    overflow-x:auto;
    display:flex;

`

export const DocItem = styled.label`
    min-width: 178px;
    min-height: 128px;
    border-radius: 4px;
    background:url(${props => props.image ? props.image : require('../../assets/adminOfferProperty/docplaceholder.svg')});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    cursor:pointer;

    input{
        display:none;
    }

    .close{
        display : ${props => props.children[1].props.show ? 'flex' : 'none '};
        position:absolute;
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        right : 10px;
        top : 10px;
        border:none;
        font-size:16px;
        border-radius:8px;
        align-items:center;
        justify-content:center;
    }
`