import React, { useEffect, useState } from 'react'
import Header from '../../components/adminHeader'
import { useHistory } from 'react-router-dom'

import { Container } from './styles.js'
import api from '../../api'

export default function AdminLogin() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const history = useHistory()

    useEffect(() => {
        document.querySelector('form').addEventListener('submit', (e) => e.preventDefault())
    }, [])

    async function handleLogin(){
        const {data} = await api.post('/user/login', {
            email,
            password
        })
        
        if(data.type == 'admin'){
            localStorage.setItem('id_painel', data._id)
            localStorage.setItem('type_user', 'admin')
            history.push('/admin/property/list')
        }else if(data.type == 'agent'){
            localStorage.setItem('id_painel', data._id)
            localStorage.setItem('type_user', 'agent')
            history.push('/admin/home-corretor')
        }else{
            alert('Voçe não possui acesso ao painel.')
        }

        // localStorage.setItem('id_painel', data._id)
        // localStorage.setItem('type_user', data.type)
        // if(data.type == "admin"){
        //     history.push('/admin/property/new')
        // }
    }

    return (
        <>
            <Header show={false} />
            <Container>
                <form>
                    <h1>Login</h1>
                    <div className="inputs">
                        <div className="input-group">
                            <label htmlFor="">Email</label>
                            <input type="text" value={email} onChange={(input) => setEmail(input.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label htmlFor="">Senha</label>
                            <input type="password" value={password} onChange={(input) => setPassword(input.target.value)}/>
                        </div>
                    </div>
                    <button onClick={handleLogin}>
                        Entrar
                    </button>
                </form>
            </Container>
        </>
    )
}
