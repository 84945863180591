import React, {useEffect, useState} from 'react'
import Header from '../../components/header'
import { BgImg, CardBottom, ContainerQuestion} from './styles'
import Footer from '../../components/Footer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../global.css';
import { Link } from 'react-router-dom'
import InputMask from "react-input-mask";
import api from '../../api'
import ComponetsQuestions from './questions'

export default function Contact() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const [assunto, setAssunto] = useState('Selecione')
    const [telefone, setTelefone] = useState('')
    
    async function handleSendEmail(){
        if(assunto != 'Selecione' && email.length > 4 && name.length > 2 && !telefone.includes('_') && msg.length > 3){
            const {data} = await api.post('/sendEmail', {
            assunto,
            content: `
                <h2>Nome: ${name}</h2>
                <h2>Email: ${email}</h2>
                <h3>Telefone: ${telefone}</h3>
                <h4>${msg}</h4>
            `
            })
            
            if(data){
                alert('Sua mensagem foi enviada. Responderemos o mais breve possível')
                setName('')
                setEmail('')
                setMsg('')
                setTelefone('')
                setAssunto('Selecione')
            }
        }
    }
    return (
        <>
            <BgImg>
                <Header />
                    <CardBottom>
                        <div>
                            <h1>Perguntas Frequentes</h1>
                            <h3>
                            Aqui você encontra as respostas para as perguntas mais comuns. Caso ainda tenha alguma dúvida, entre em contato pelos nossos canais de atendimento.
                            </h3>
                        </div>
                    </CardBottom>
                    
                    <ContainerQuestion>
                        <div className="context-questions">
                            <h1>Ajuda para corretores de imóveis</h1>

                            <ComponetsQuestions type='corretor'/>

                            <h1 style={{marginTop: 50, marginBottom: 15}}>Ajuda para quem quer comprar um imóvel</h1>

                            <ComponetsQuestions type='comprador'/>


                            <h1 style={{marginTop: 50, marginBottom: 15}}>Ajuda para quem quer vender um imóvel</h1>

                            <ComponetsQuestions type='vendedor'/>
                        </div>
                        <div className="context-contact">
                            <h1>Contato</h1>

                            {
                                localStorage.getItem('id') ?
                                    (
                                        <>
                                            <div className="phone">
                                                <img src={require('./phone.svg')}/>
                                                +55 (21) 98101-0250
                                            </div>
                                            <div className="email">
                                                <img src={require('./mail.svg')}/>
                                                contato@imovelvazio.com.br
                                            </div>
                                        </>
                                    )
                                :
                                    ''
                            }

                            <div style={{marginTop: 25}} className="input-box">
                                <small>Nome</small>
                                <input value={name} onChange={e => setName(e.target.value)} placeholder="Ex.: João Silva" />
                            </div>
                            <div className="input-box">
                                <small>Telefone</small>
                                {/* <input value={telefone} onChange={e => setTelefone(e.target.value)} placeholder="Ex.: (00) 0000-0000" /> */}
                                <InputMask mask="(99) 99999-9999" value={telefone}  onChange={text => setTelefone(text.target.value)} placeholder="Ex.: (00) 00000-0000" />
                            </div>
                            <div className="input-box">
                                <small>Email</small>
                                <input value={email} onChange={e => setEmail(e.target.value)} placeholder="Ex.: joao.silva@email.com.br" />
                            </div>
                            <div className="input-box">
                                <small>Assunto</small>
                                <select value={assunto} onChange={e => setAssunto(e.target.value)} >
                                    <option>Selecione</option>
                                    <option>Imóveis a Venda</option>
                                    <option>Compramos seu Imóvel</option>
                                    <option>Correspondente Bancário</option>
                                    <option>Outros</option>
                                </select>
                            </div>

                            <div className="input-box max">
                                <small>Mensagem</small>
                                <textarea value={msg} onChange={e => setMsg(e.target.value)} placeholder="Digite aqui..."/>
                            </div>

                            <div onClick={handleSendEmail} className="btnSend">
                                Enviar
                            </div>
                        </div>
                    </ContainerQuestion>

            </BgImg>

            <Footer />

        </>
    )
}
