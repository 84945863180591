import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'

import { ModalHistorico, ModalVendido, ModalExcluir } from './modal/models'


export default function PropostaAdminEditar() {
    const [data, setData] = useState([])
    const [status, setStatus] = useState('false')
    const [modal, setModal] = useState({
        _id: 0,
        excluir: false, 
        historico: false, 
        vendido: false, 
        excluirFunction: () => {},
        vendidoFunction: () => {},
        historicoFunction: () => {}    
    })
    const [valorVista, setValorVista] = useState([])
    const [valorFinanciado, setValorFinanciado] = useState([])
    const [valorProposta, setValorProposta] = useState([])
    const [ID, setID] = useState(0)

    const [indexValores, setIndex] = useState(0)

    useEffect(() => {
        if(!localStorage.getItem('type_user')){
            return window.location.href='/admin/login'
        }

        if(localStorage.getItem('type_user') !== 'admin'){
            return window.location.href='/admin/propostas-corretor'
        }

        handleLoad()
    }, [])
    

    function cleanStates(){
        setValorFinanciado('')
        setValorProposta('')
        setValorVista('')
        setStatus('')
    }

    async function handleLoad(){
        cleanStates()
        let id = new URL(window.location).searchParams.get('id')
        setID(id)
        
        const response = await api.get(`/proposalImovel?Property=${id}`)
        let array = []
        if(response.data.ProposalImovel.length === 0) return window.history.go(-1)
        
        const valorVistaArray = []
        const valorFinanciadoArray = []
        const valorPropostaArray = []

        await Promise.all(
            response.data.ProposalImovel.map(async (e, i)=>{
                
                if(!String(e.valorVista).includes('.')){
                    valorVistaArray.push(money(String(e.valorVista)+'00'))
                }else{
                    valorVistaArray.push(money(String(e.valorVista)))
                }
                
                if(!String(e.valorProposta).includes('.')){
                    valorPropostaArray.push(money(String(e.valorProposta)+'00'))
                }else{
                    valorPropostaArray.push(money(String(e.valorProposta)))
                }
                
                if(!String(e.valorFinanciado).includes('.')){
                    valorFinanciadoArray.push(money(String(e.valorFinanciado)+'00'))
                }else{
                    valorFinanciadoArray.push(money(String(e.valorFinanciado)))
                }
                
                if(e.Property){
                    const {data: city} = await api.get('/city/'+e.Property.city)
                    e.Property.city=city
                    
                    const {data: neighborhood} = await api.get('/neighborhood/'+e.Property.neighborhood)
                    e.Property.neighborhood=neighborhood
                    
                    const {data: propertyType} = await api.get('/propertyType/'+e.Property.type)
                    e.Property.type=propertyType

                    if(e.Property.generalType) {
                        const {data: generalPropertyType} = await api.get('/generalPropertyType/'+e.Property.generalType)
                        e.Property.generalType=generalPropertyType
                    }
                }

                array.push(e)
            })
        )

        setValorVista(valorVistaArray)
        setValorProposta(valorPropostaArray)
        setValorFinanciado(valorFinanciadoArray)

        if(array.length === 0) return window.location.href='/admin/propostas-admin'
        
        array.sort((a, b) => {
            if(a.status == 'Novo'){
                return -1
            }
        })

        setData(array)
    }
    
    function moneyToNumber(value){
        value = String(value)
        return (value.replace('R', '').replace('$', '').replaceAll(' ', '').replaceAll('.', '*').replace(',', '.').replaceAll('*', '')).substr(1, value.length - 1)
    }

    function money (value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }

    const handleEditar = async (id, index, historic) => {
        if (!window.confirm("Deseja salvar as alterações?")) throw ''       
        
        const response = await api.put(`/proposalImovel/${id}`, {
            valorFinanciado: moneyToNumber(valorFinanciado[index]),
            valorVista: moneyToNumber(valorVista[index]),
            valorProposta: moneyToNumber(valorProposta[index]),
            createdAt: new Date().toLocaleDateString('pt-BR', {day: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {month: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {year: 'numeric'})
        })
                
        if(historic.length > 0) {
            await api.put(`/proposalImovel/Historic/${id}`, {
                isCorretor: false,
                valorFinanciado: moneyToNumber(valorFinanciado[index]),
                valorVista: moneyToNumber(valorVista[index]),
                valorProposta: moneyToNumber(valorProposta[index]),
                createdAt: new Date().toLocaleDateString('pt-BR', {day: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {month: '2-digit'})+'/'+new Date().toLocaleDateString('pt-BR', {year: 'numeric'})
            })
        }

        handleLoad()

        if(response.data.success) alert('Proposta salva!')
    }

    const handleRemove = async (id) => {
        const response = await api.delete('/proposalImovel/'+id)

        if(response.data.deletedId)
            setData(data.filter(filter => filter._id != id))
        
    }

    const handleSold = async (id, {valorFinanciado, valorInscritura, valorVista, totalProposta, valorComissao}) => {
        await api.put(`/proposalImovel/${id}`, {
            statusImovel: 'Vendido',
            status: 'Proposta Ativa',
            sold: {
                valorVista: moneyToNumber(valorVista),
                valorFinanciado: moneyToNumber(valorFinanciado),
                valorInscritura: moneyToNumber(valorInscritura),
                valorComissao: moneyToNumber(valorComissao),
                valorTotalProposta: moneyToNumber(totalProposta)
            }
        })

        handleLoad()
    }    

    useEffect(() => {
        if(valorProposta.length>0){
            let arrayValores = []
    
            valorProposta.map((valor, index) => {
                if(index == indexValores){
                    const vista = Number(moneyToNumber(valorVista[indexValores]))
                    const financiado = Number(moneyToNumber(valorFinanciado[indexValores]))
                    const soma = vista+financiado
    
                    arrayValores.push(money(String(Math.floor(soma*100))))  
                }else{
                    arrayValores.push(valor)
                }
            })
            setValorProposta(arrayValores)
        }
    }, [valorFinanciado, valorVista])

    function innerRow(row, i){
        
        return <>
            <div className="body-imovel" style={{width: window.innerWidth <= 600?'123%':'73%', flexDirection: 'column'}}>
                <div style={{width: '100%',height: '50%', paddingLeft: 20, display: 'flex', flexDirection: window.innerWidth<=600 ? 'column':'row'}}>
                    <div style={{ flexDirection: 'column', width: '230px'}}>
                        <p>Data da Proposta: {row.createdAt}</p><br></br>
                        <p>Status: <b style={{color: '#EA5C1E'}}>{row.status}</b></p>
                    </div>
                    <div style={{ flexDirection: 'column', paddingLeft: window.innerWidth <= 600 ? 0:15}}>
                        <b>{row.User.name}</b><br></br>
                        <p>Email: {row.User.email}</p>
                        <p>
                            {row.User.telephone && row.User.telephone.length > 0 ? 
                                row.User.telephone.length == 1 ? 
                                    <a target='_blank' href={`https://web.whatsapp.com/send?phone=55${row.User.telephone[0]}`}>{row.User.telephone[0]}</a>
                                : 
                                <>
                                    <a target='_blank' href={`https://web.whatsapp.com/send?phone=55${row.User.telephone[0]}`}>{row.User.telephone[0]}</a>
                                    {' - '}
                                    <a target='_blank' href={`https://web.whatsapp.com/send?phone=55${row.User.telephone[1]}`}>{row.User.telephone[1]}</a>
                                </>
                            : 
                            ''}</p>
                    </div>
                </div>
                <div style={{width: '100%', paddingTop: window.innerWidth <=600 ? 15:0, height: window.innerWidth<=600 ? '70%':'50%', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: window.innerWidth<=600 ? 'column':'row'}}>
                    <div className="input-group-teste">                        
                        <label htmlFor="">Valor à Vista</label>
                         <input placeholder="Ex: R$ 1.200.000,00" value={valorVista[i]} onChange={element => {
                            const arrayVista = []

                            valorVista.map((valor, index) => {
                                if(index == i) {       
                                    arrayVista.push(money(element.target.value))
                                }else{
                                    arrayVista.push(valor)
                                }
                            })
                            
                            setIndex(i)
                            setValorVista(arrayVista)
                         }}/></div>
                    <div className="input-group-teste">                        
                        <label htmlFor="">Valor Financiado</label>
                         <input placeholder="Ex: R$ 500.000,00" value={valorFinanciado[i]} onChange={element => {
                            const arrayFinanciado = []
                            
                            valorFinanciado.map((valor, index) => {
                                if(index == i) {                                    
                                    arrayFinanciado.push(money(element.target.value))
                                }else{
                                    arrayFinanciado.push(valor)
                                }
                            })
                            
                            setIndex(i)
                            setValorFinanciado(arrayFinanciado)
                         }}/>
                    </div>
                    <div className="input-group-teste">                        
                        <label htmlFor="">Total da Proposta</label>
                         <input disabled={true} placeholder="Ex: R$ 1.700.000,00" value={valorProposta[i]} onChange={element => {}}/>
                    </div>
                </div>
            </div>
            
            <div className="body-stats" style={{flexDirection: 'column', justifyContent: 'space-around', width: window.innerWidth <= 600 ? '45%':'10%'}}>
                <div style={{color: '#00AEC8', fontSize: '1.3vw'}}>
                    <a style={{cursor: 'pointer', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={e => handleEditar(row._id, i, row.historic)}>Salvar</a>
                </div>
                <div style={{color: '#00AEC8', fontSize: '1.3vw', borderTop: '1px solid #aaa'}}>
                    <a style={{cursor: 'pointer', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    onClick={async e => setModal({...modal, historico: true, historicoFunction: handleRemove, row: row})}     
                    >Histórico</a>
                </div>
            </div>
            
            <div className="body-stats" style={{flexDirection: 'column', justifyContent: 'space-around', width: window.innerWidth <= 600 ? '45%':'10%'}}>
                <div style={{color: '#00AEC8', fontSize: '1.3vw'}}>
                    <a style={{cursor: 'pointer', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={async e => {
                        try{
                            if(row.status.includes('Cancelada')){
                                if (!window.confirm("Deseja ativar a proposta?")) throw ''
                                
                                await api.put(`/proposalImovel/${row._id}`, {
                                    status: 'Proposta Ativa'
                                })
                                
                                let filter = data.filter(data => data._id !== null)
                                filter.map(filt => {
                                    if(filt._id == row._id){
                                        filt.status='Proposta Ativa'
                                    }
                                    return filt
                                })

                            setData(filter)
                                return
                            }

                            if (!window.confirm("Deseja cancelar a proposta?")) throw ''

                            await api.put(`/proposalImovel/${row._id}`, {
                                status: 'Cancelada (Admin IV)'
                            })
                            
                            let filter = data.filter(data => data._id !== null)
                            filter.map(filt => {
                                if(filt._id == row._id){
                                    filt.status='Cancelada (Admin IV)'
                                }
                                return filt
                            })

                            setData(filter)
                        }catch(err){}
                    }}>{row.status.includes('Cancelada') ? 'Ativar':'Cancelar'}</a>
                </div>
                <div style={{color: '#00AEC8', fontSize: '1.3vw', borderTop: '1px solid #aaa'}}>
                    <a style={{cursor: 'pointer', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    onClick={async e => setModal({...modal, vendido: true, vendidoFunction: handleSold, i, row: row})}    
                    >
                        Vendido
                    </a>
                </div>   
                <div style={{color: '#00AEC8', fontSize: '1.3vw', borderTop: '1px solid #aaa'}}>
                    <a style={{cursor: 'pointer', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} 
                    onClick={async e => setModal({...modal, excluir: true, excluirFunction: handleRemove, _id: row._id})}                    
                    >Excluir</a>
                </div>    
            </div>
        </>
    }

    return (
        <>

            <ModalExcluir 
                modal={modal} 
                setModal={setModal}
                money={money}
                moneyToNumber={moneyToNumber}/>
            <ModalVendido 
                modal={modal} 
                setModal={setModal} 
                money={money}
                moneyToNumber={moneyToNumber}
            />
            <ModalHistorico 
                modal={modal} 
                setModal={setModal}
                money={money}
            />

            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        IMÓVEIS À VENDA > 
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Propostas de Compra Enviadas
                    </Link>
                </div>
                
                <div className="header" style={{marginTop: 30}}>
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>

                    <div className="active">
                        <h2>IMÓVEL</h2>
                    </div>
                </div>

                {
                data[0] && data[0].Property && 
                        <div className="body" style={{height: window.innerWidth <= 600 ? 250:150}}>
                            <div className="body-cod">{data[0].Property._id}</div>
                            <div className="body-imovel" style={{width: '93%', alignItems: 'start', paddingLeft: 15, paddingRight: 15}}>
                            <small>Cadastrado em: {String(data[0].Property.createdAt.split(' ')[0])}</small>
                            <h2 style={{fontSize: 18, marginTop: 20}}><a style={{color: 'inherit'}}>{data[0].Property.address ? data[0].Property.address : ''}{data[0].Property.addressNumber ? ', '+data[0].Property.addressNumber : ''} {data[0].Property.addressComplement? ` / ${data[0].Property.addressComplement}`:''}</a></h2>
                            <h3>{data[0].Property.neighborhood ? data[0].Property.neighborhood.name : ''}{data[0].Property.city ? " - "+data[0].Property.city.name : ''}{data[0].Property.neighborhood ? ', '+data[0].Property.neighborhood.state : ''}</h3>
                            
                            <h5>{data[0].Property.type ? data[0].Property.type.name : ''} {data[0].Property.generalType ? data[0].Property.generalType.name : (data[0].Property.type ? data[0].Property.type.generalType  == '2372820374682508' ? 'Residencial' : 'Comercial':'')}</h5>
                        </div>
                    </div>
                }
                {
                    data.map((row, index) => row.Property && row.User && (
                        <div className="body" style={{height: window.innerWidth <= 600 ? 350:200}}>
                            <div className="body-cod">{row._id}</div>
                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row, index)}
                                    </div>    
                                :
                                innerRow(row, index)
                            }    
                        </div>
                    ))
                }

                <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginTop: 40}}>
                    <button onClick={() => window.location.href = '/admin/propostas-admin'} style={{color: 'white', background: 'linear-gradient(90deg, #EA5C1E 0%, #C13818 100%)', border: 'none', borderRadius: 5,
                        width: window.innerWidth <= 600? '100%':250, height:45}}>
                        Voltar
                    </button>
                </div>

            </Container>
        </>
    )
}

