import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

export default function Visualization() {
    const [data, setData] = useState([])

    useEffect(() => {
        if(localStorage.getItem('type_user') != 'admin'){
            window.location.href='/admin/login'
        }
        handleLoad()
    }, [])

    async function handleLoad(){
        let ID = new URL(window.location).searchParams.get('id')
         
        const {data} = await api.get(`/visualization/${ID}`)

        setData(data)
    }

    function dateBr(value){
        return value.substr(8, 2)+'/'+value.substr(5, 2)+'/'+value.substr(0,4)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        ANÚNCIOS >
                    </h1>
                    <Link to={window.location.pathname} onClick={e=>e.preventDefault()} className="where">
                        Anúncios Imóvel Vazio > Visualizações
                    </Link>
                </div>
                
                <div className="header">
                    <div className="cod" style={{width: '8%'}}>
                        <h2>SEQ.</h2>
                    </div>
                    <div className="active" style={{width: '40%'}}>
                        <h2>DATA</h2>
                    </div>
                    <div className="imovel" style={{width: '52%'}}>
                        <h2>QTDE DE VISUALIZAÇÕES</h2>
                    </div>
                </div>

                {
                    data.map((row, index) => (
                        <div className="body" style={{height: 60}}>
                            <div className="body-cod" style={{width: '8%'}}>{index+1}</div>
                            <div className="body-active" style={{width: '40%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17}}>
                                {String(row.createdAt).split(' ')[0].includes('/') ? String(row.createdAt).split(' ')[0] : dateBr(String(row.createdAt).split(' ')[0])}
                            </div>
                            <div className="body-imovel" style={{width: '52%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17}}>
                                {row.visualizations}
                            </div>
                        </div>
                    ))
                }

            </Container>
        </>
    )
}