import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import '../../global.css';
import Select from 'react-select';
import api from '../../api'
import Slider from '@material-ui/core/Slider'
import ListCheckbox from './ListCheckbox'

export default function ModalFilter({flex, setFlex, setPropertys, removeFilter, setPage}) {
    const [city, setCity] = useState([{}])
    const [precoMinimo, setPrecoMinimo] = useState({value: 0, label: 0})
    const [precoMaximo, setPrecoMaximo] = useState({value: 100, label: '10.000.000'})
    const [apart, setApart] = useState(false)
    const [casa, setCasa] = useState(false)
    const [condo, setCondom] = useState(false)
    const [vila, setVila] = useState(false)
    const [cobertura, setCobertura] = useState(false)
    const [flat, setFlat] = useState(false)
    const [loft, setLoft] = useState(false)
    const [tipo, setTipo] = useState('')
    const [bairros, setBairros] = useState([])
    const [bairro, setBairro] = useState('')
    const [citySelected, setCitySelected] = useState('')
    
    const [quarto, setQuarto] = useState('')
    const [banheiros, setBanheiros] = useState('')
    const [vagas, setVagas] = useState('')

    useState(() => {
        handleCity()
    }, [])

    async function handleCity(){
        await api.get('/city?stateName=Rio de Janeiro').then((res) => {
            let citysOptions = new Array()
            res.data.citys.map(item => {
                citysOptions.push({value: item._id, label: item.name2})
            })
            setCity(citysOptions)
        })

        await api.get('/neighborhood?city=7043').then((res) => {
            let bairrosAr = new Array()
            res.data.neighborhoods.map(item => {
                bairrosAr.push({value: item._id, label: item.name})
            })
            setBairros(bairrosAr)
        })
    }

    function handleDesmarcar(){
        setTipo(''); setApart(false); setCasa(false); setCondom(false); setVila(false); setCobertura(false); setFlat(false); setLoft(false)
    }
    function handleDesmarcarTwo(){
        setQuarto(false); setVagas(false); setBanheiros(false);setPrecoMaximo({value: 100, label: '1.000.000.000'}); setPrecoMinimo({value: 0, label: 0})
    }

    function handleClearFilter(){
        setPage(1)
        removeFilter(true)
        handleDesmarcar()
        handleDesmarcarTwo()
        setCitySelected('')
        setFlex('none')
        document.querySelector('body').style.overflow='scroll'
    }

    async function handleFilter(){
        if(true){
            let precoMin = String(precoMinimo.label)
            let precoMax = String(precoMaximo.label)
                
            let querys = `?sellingPriceMin=${precoMin.replace(/[^\d]+/g,'')}&sellingPrice=${precoMax.replace(/[^\d]+/g,'')}&arquive=false&recent=true`

            if(citySelected.value){
                querys += `&city_id=${citySelected.value}`
            }
            if(tipo.value){
                querys += `&generalType=${tipo.value}`
            }
            if(quarto.value){
                querys += `&bedrooms=${quarto.value}`
            }
            if(vagas.value){
                querys += `&parkingSpots=${vagas.value}`
            }
            if(banheiros.value){
                querys += `&bathrooms=${banheiros.value}`
            }

            if(bairro.value){
                querys += `&neighborhood_id=${bairro.value}`
            }

            let {data} = await api.get(`/property${querys}`)
            setPropertys(data.propertys)

            setFlex('none')
            document.querySelector('body').style.overflow='scroll'
        }else{
        }
    }


    return (
        <>
            <div onClick={item => {setFlex('none'); document.querySelector('body').style.overflow='scroll'}} style={{display: flex, position: 'absolute', height: '500%', width: '100%', backgroundColor: '#111', zIndex: 1, opacity: '0.3', left: 'auto'}}>
            </div>
            <div style={{display: flex, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'absolute', backgroundColor: '#fff', height: '75%', width: window.innerWidth <= 600 ? '100%':'35%', zIndex: 2, marginTop: '1%', left: window.innerWidth <= 600 ? '0%':'32%', opacity: '1', borderRadius: '6px'}}>
                <div style={{display: 'flex', width: '100%', height: '8%', borderBottom: '1px solid #ddd', flexDirection: 'row', alignItems: 'center'}}>

                    <h2 style={{width: '95%', display: 'flex', justifyContent: 'center'}}>Filtros</h2>
                    <h4 onClick={item => {setFlex('none'); document.querySelector('body').style.overflow='scroll'}} style={{color: "#EA5C1E", cursor: 'pointer'}}>X</h4>
                
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '84%', borderBottom: '1px solid #ddd', overflowY: 'scroll'}}>
                    
                    <div style={{height: '100%', width: '90%', marginLeft: '6%', paddingTop: '20px'}}>
                        <h3>Localização</h3>
                        <div style={{display: 'flex', height: '60px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            
                            <div style={{width: '48%', height: '90%', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', borderRadius: '5px'}}>
                                <Select
                                    styles={{control: styles => ({ ...styles, border: '2px solid #EA5C1E' })}}
                                    closeMenuOnSelect={true}
                                    defaultValue={[{value: 'rio de janeiro', label: 'Rio de Janeiro'}]}
                                    placeholder='Estado'
                                    options={[{value: 'rio de janeiro', label: 'Rio de Janeiro'}]}
                                    />
                            </div>
                            
                            <div style={{width: '48%', height: '90%', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', borderRadius: '5px'}}>
                                <Select
                                    styles={{
                                        control: styles => ({ ...styles, backgroundColor: 'white', border: '2px solid #EA5C1E'})}}
                                    closeMenuOnSelect={true}
                                    defaultValue={'strawberry'}
                                    placeholder='Cidade'
                                    value={citySelected}
                                    onChange={item => setCitySelected(item)}
                                    options={city}
                                    />
                            </div>
                        </div>
                        <div style={{marginTop: '25px', display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                            <h2>Bairros</h2>
                            <small onClick={() => setBairro('')} style={{textDecorationLine: 'underline', marginLeft: '10px', cursor: 'pointer', fontWeight: 0}}>Desmarcar todos</small>
                        </div>

                        <Select
                                styles={{control: styles => ({ ...styles, border: '2px solid #EA5C1E', marginTop: '25px' })}}
                                closeMenuOnSelect={true}
                                placeholder='Bairros'
                                value={bairro}
                                onChange={item => setBairro(item)}
                                options={bairros}
                                />
                        
                        <div style={{marginTop: '25px', display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                            <h2>Tipo</h2>
                            <small onClick={handleDesmarcar} style={{textDecorationLine: 'underline', marginLeft: '10px', cursor: 'pointer', fontWeight: 0}}>Desmarcar todos</small>
                        </div>
                        
                        <div style={{width: '48%', marginTop: '20px', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', borderRadius: '5px', marginBottom: 10}}>
                            <Select
                                styles={{control: styles => ({ ...styles, border: '2px solid #EA5C1E' })}}
                                closeMenuOnSelect={true}
                                placeholder='Residencial ou Comercial'
                                value={tipo}
                                onChange={item => setTipo(item)}
                                options={[{value: 2372820374682508, label: 'Residencial'}, {value: 1644602035863502, label: 'Comercial'}]}
                                />
                        </div>
                        
                        <ListCheckbox apart={apart} setApart={setApart} condo={condo} setCondom={setCondom} casa={casa} setCasa={setCasa} cobertura={cobertura} setCobertura={setCobertura} flat={flat} setFlat={setFlat} loft={loft} setLoft={setLoft} vila={vila} setVila={setVila}/>

                        <div style={{display: 'flex', marginTop: '20px', alignItems: 'center', flexDirection: 'row'}}>
                            <h2>Características</h2>
                            <small onClick={handleDesmarcarTwo} style={{textDecorationLine: 'underline', marginLeft: '10px', cursor: 'pointer', fontWeight: 0}}>Desmarcar todos</small>
                        </div>
                        
                        <div style={{display: 'flex', height: '60px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            
                            <div style={{width: '48%', height: '90%', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', borderRadius: '5px'}}>
                                <Select
                                    styles={{control: styles => ({ ...styles, border: '2px solid #EA5C1E' })}}
                                    closeMenuOnSelect={true}
                                    placeholder='Quartos'
                                    value={quarto}
                                    onChange={item => setQuarto(item)}
                                    options={[{value: 1, label: '1 Quarto'}, {value: 2, label: '2 Quartos'}, {value: 3, label: '3 Quartos'}, {value: 4, label: '4 Quartos ou mais'}]}
                                    />
                            </div>
                            
                            <div style={{width: '48%', height: '90%', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', borderRadius: '5px'}}>
                                <Select
                                    styles={{control: styles => ({ ...styles, backgroundColor: 'white', border: '2px solid #EA5C1E'})}}
                                    closeMenuOnSelect={true}
                                    placeholder='Banheiros'
                                    value={banheiros}
                                    onChange={item => setBanheiros(item)}
                                    options={[{value: 1, label: '1 Banheiro'}, {value: 2, label: '2 Banheiros'}, {value: 3, label: '3 Banheiros'}, {value: 4, label: '4 Banheiros ou mais'}]}
                                    />
                            </div>
                        </div>

                        <div style={{display: 'flex', height: '60px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            
                            <div style={{width: '48%', height: '90%', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', borderRadius: '5px'}}>
                                <Select
                                    styles={{control: styles => ({ ...styles, border: '2px solid #EA5C1E' })}}
                                    closeMenuOnSelect={true}
                                    placeholder='Vagas'
                                    value={vagas}
                                    onChange={item => setVagas(item)}
                                    options={[{value: 1, label: '1 Vaga'}, {value: 2, label: '2 Vagas'}, {value: 3, label: '3 Vagas'}, {value: 4, label: '4 Vagas ou mais'}]}
                                    />
                            </div>
                            
                            <div style={{width: '48%', height: '90%', flexDirection: 'column', justifyContent: 'space-evenly', display: 'flex', borderRadius: '5px'}}>  
                                <h5 style={{color: '#888', marginTop: -20}}>Preço de venda</h5>
                                <label>R$ {precoMinimo.label} - R$ {precoMaximo.label}</label>
                                <Slider
                                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                    defaultValue={[precoMinimo.value, precoMaximo.value]}
                                    style={{color: '#EA5C1E'}}
                                    onChange={(event, value) => {
                                        setPrecoMinimo({value: value[0], label: (value[0] * 10000).toLocaleString('pt-BR')})
                                        setPrecoMaximo({value: value[1], label: (value[1] * 100000).toLocaleString('pt-BR')})
                                    }}
                                    value={[precoMinimo.value, precoMaximo.value]}
                                />
                            </div>
                        </div>


                    </div>




                </div>
                <div style={{display: 'flex', width: '100%', height: '8%', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={handleClearFilter} id='btn-personal-reverse' style={{display: 'flex', height: '60%', justifyContent: 'center', alignItems: 'center'}}>
                        Remover Filtros
                    </div>
                    <div onClick={handleFilter} id='btn-personal' style={{display: 'flex', height: '60%', justifyContent: 'center', alignItems: 'center'}}>
                        Aplicar Filtros
                    </div>
                </div>
            </div>
        </>
    )
}
