import styled from 'styled-components'
import favoriteIcon from '../../assets/propertiesList/favorite.svg'
const placeholder = 'https://www.ufukkimya.com/media/resim-yok.png'

export const Body = styled.div`
    width:100%;
    margin-top:100px;
    padding:60px;

    h1{
        margin-left:40px;
    }

    @media(max-width:600px){
        margin-top:0px;
        h1{
            margin-left:0px;
            width: 100%;
            text-align: center;
        }
    }
    
`
export const SeeMore = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;

    button{
        width: 236px;
        height: 38px;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        background:transparent;
        
        border-radius: 4px;
        outline:none;

        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EA5C1E;
    }
`

export const Menu = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:20px;
    flex-wrap:wrap;
    margin-left:40px;

    @media(max-width:600px){
        margin-left:0px;
        
        .input-group{
            .filters{
                margin-right: 0px;
            }

            select{
                text-align-last: center;
            }
        }
    }

    a{
        text-decoration:none;
        font-size:18px;
        color: #00AEC8;
        font-weight:bold;
        background: #F2F2F2;
        display:flex;
        justify-content:center;
        align-items:center;
        padding-left:40px;
        padding-right:40px;
        border-radius: 8px;
    }

    .input-group{
        display:flex;
        flex-wrap:wrap;

        @media(max-width:600px){
            width:100%;
        }

        select{
            background: #F2F2F2;
            border-radius: 8px;
            width: 310px;
            height: 56px;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            padding-left:10px;
            border:none;
            margin-left:20px;

            @media(max-width:600px){
                margin-left:0px;
                width:100%;
                margin-top:10px;
            }
        }   
        div{
            background: #F2F2F2;
            border-radius: 8px;
            width: 240px;
            height: 56px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            padding-left:10px;
            border:none;
            cursor: pointer;
            margin-right:20px;

            @media(max-width:600px){
                margin-left:0px;
                width:100%;
                margin-top:10px;
            }
        }  

    }

`

export const ItemsContainer = styled.div`
    width:100%;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
    margin-top:30px;

    @media(max-width:1380px){
        width:120%;
        margin-left: -10%;
    }

    @media(max-width:340px){
        width:150%;
        margin-left: -25%;
    }

    a{
        text-decoration:none;
        margin-left:20px;
        margin-bottom:20px;
        max-width: 412px; //340
		width: 412px;
        height: 385px;
        background: #FFFFFF;
        border-radius:8px;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
        color: #1E1D1D;

        @media(max-width:340px){
            height: 430px;
        }

        
        @media(max-width:600px){
            width:100%;
        }
        @media(max-width:1380px){
            max-width: 380px;
        }
    }
    
`

export const ItemHeader = styled.div`
    background: url(${props => props.image ? props.image : 'https://www.ufukkimya.com/media/resim-yok.png'});
    background-size:cover;
    background-position:center;
    width:100%;
    height:256px;
    position:relative;        
    border-radius: 5px;
`

export const Tag = styled.div`

    position:absolute;
    top:20px;
    left:20px;
    color: #FFFFFF;
    height:22px;
    display:flex;
    padding:5px;
    justify-content:center;
    align-items:center;
    border-radius: 2px;
    background-color:${props => props.background ? props.background : '#1E1D1D  '};
    opacity:0.8;

    h6{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
        
`

export const Favorite = styled.button`
    outline:0;
    border:none;
    position:absolute;
    right:20px;
    top:20px;
    width:18px;
    height:15px;
    background:url(${favoriteIcon});
    background-size:cover;  
`

export const OurProperty = styled.div`
    position: absolute;
    left: 0%;
    right: 0%;
    width:100%;
    height:28px;
    bottom: 0%;
    background: #EA5C1E;
    opacity: 0.9;
    display:flex;
    justify-content:flex-start;
    padding-left:20px;
    align-items:center;
    
    h6{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
`
export const Description = styled.div`
    height:129px;
    width:100%;
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    display:flex;
    flex-direction:column;
    padding-bottom:15px;

    .row{
        width:100%;
        display:flex;
        justify-content:space-between; 
        align-items:flex-start;

        .city{
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
        }

        .price{
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            text-align: right;
            color: #00AEC8;
        }

        .street{
            font-weight: 300;
            font-size: 14px;
			width: 76%;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            display:flex;
			height: 100%;
			align-items: flex-end;
        }

        .commission{
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            height: 100%;
            min-inline-size: fit-content;
            color: #1E1D1D;

            span{
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                text-align: right;
                color: #EA5C1E;
            }

        }

        .icon-group{
            display:flex;
            align-items:center;
            justify-content:center;
            width:25%;
            //margin-right:15px;

            @media only screen and (max-width: 600px) {
                flex-direction: column;
            }

            img{              
                margin-right:5px;
            }
            
            .ruler{
                width:7px;
                height:15px;
            }

            .parking{
                width:15px;
                height:15px;
            }

            .bed{
                width:15px;
                height:15px;
            }

        }
    }

    .faq{
        margin-top:auto;

        
        @media only screen and (max-width: 600px) {
            width: 120%;
            margin-left: -10%;
        }
    }
`